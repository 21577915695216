/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import { __decorate, __metadata, __param } from 'tslib';
import { EventEmitter, Input, Output, ViewChild, ElementRef, HostBinding, Component, Renderer2, Directive, TemplateRef, Injectable, ViewChildren, QueryList, ChangeDetectorRef, NgZone, InjectionToken, forwardRef, isDevMode, ContentChild, ViewContainerRef, Optional, Inject, HostListener, KeyValueDiffers, ContentChildren, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { isDocumentAvailable, Keys, isChanged, hasObservers, KendoInput, anyChanged, guid, ResizeSensorModule, EventsModule } from '@progress/kendo-angular-common';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { validatePackage } from '@progress/kendo-licensing';
import { getter, pointers, touchEnabled } from '@progress/kendo-common';
import { LocalizationService, L10N_PREFIX, ComponentMessages } from '@progress/kendo-angular-l10n';
import { merge, fromEvent, Subject, Subscription, of, interval } from 'rxjs';
import { PopupService, PopupModule } from '@progress/kendo-angular-popup';
export { PopupComponent } from '@progress/kendo-angular-popup';
import { map, switchMap, take, auditTime, tap, filter, partition, throttleTime, catchError, skipWhile, concatMap, takeUntil } from 'rxjs/operators';
import { TreeViewComponent, DataBoundComponent, ExpandableComponent, FlatDataBindingDirective, HierarchyBindingDirective, ExpandDirective, TreeViewModule } from '@progress/kendo-angular-treeview';
import { CommonModule } from '@angular/common';

/**
 * @hidden
 */
const packageMetadata = {
    name: '@progress/kendo-angular-dropdowns',
    productName: 'Kendo UI for Angular',
    productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
    publishDate: 1646640638,
    version: '',
    licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/?utm_medium=product&utm_source=kendoangular&utm_campaign=kendo-ui-angular-purchase-license-keys-warning'
};

/* tslint:disable:no-bitwise */
/**
 * @hidden
 */
const isPresent = (value) => value !== null && value !== undefined;
/**
 * @hidden
 */
const isNumber = (value) => !isNaN(value);
/**
 * @hidden
 */
const guid$1 = () => {
    let id = "";
    let i;
    let random;
    for (i = 0; i < 32; i++) {
        random = Math.random() * 16 | 0;
        if (i === 8 || i === 12 || i === 16 || i === 20) {
            id += "-";
        }
        id += (i === 12 ? 4 : (i === 16 ? (random & 3 | 8) : random)).toString(16);
    }
    return id;
};
/**
 * @hidden
 */
const combineStr = (begin, end) => {
    return begin.concat(end.substr(end.toLowerCase().indexOf(begin.toLowerCase()) + begin.length));
};
/**
 * @hidden
 */
const isArray = (value) => Array.isArray(value);
/**
 * @hidden
 */
const isObject = (value) => isPresent(value) && typeof value === 'object';
/**
 * @hidden
 */
const isEmptyString = (value) => typeof value === 'string' && value.length === 0;
/**
 * @hidden
 */
const resolveValuesInArray = (values, data = [], valueField) => values
    .map(value => {
    return data.find(item => getter$1(item, valueField) === value);
})
    .filter(value => value !== undefined);
/**
 * @hidden
 */
const validateComplexValues = (values, valueField) => isArray(values) && values.filter(item => {
    return isObject(item) && isPresent(getter$1(item, valueField));
});
/**
 * @hidden
 */
const resolveAllValues = (value, data, valueField) => {
    const customValues = validateComplexValues(value, valueField) || [];
    const resolvedValues = resolveValuesInArray(value, data, valueField) || [];
    return resolvedValues.concat(customValues);
};
/**
 * @hidden
 */
const isObjectArray = (values) => {
    return isArray(values) && values.every(item => isObject(item));
};
/**
 * @hidden
 */
const selectedIndices = (values, data, valueField) => {
    const extractedValues = data.map(item => {
        return isPresent(item) && isPresent(getter$1(item, valueField)) ? getter$1(item, valueField) : item;
    });
    return values.reduce((arr, item) => {
        const value = isPresent(item) && isPresent(getter$1(item, valueField)) ? getter$1(item, valueField) : item;
        const index = extractedValues.indexOf(value);
        if (index !== -1) {
            arr.push(index);
        }
        return arr;
    }, []);
};
/**
 * @hidden
 */
const getter$1 = (dataItem, field) => {
    if (!isPresent(dataItem)) {
        return null;
    }
    if (!isPresent(field) || !isObject(dataItem)) {
        return dataItem;
    }
    // creates a field accessor supporting nested fields processing
    const valueFrom = getter(field);
    return valueFrom(dataItem);
};
/**
 * @hidden
 */
const sameCharsOnly = (word, character) => {
    for (let idx = 0; idx < word.length; idx++) {
        if (word.charAt(idx) !== character) {
            return false;
        }
    }
    return true;
};
/**
 * @hidden
 */
const shuffleData = (data, splitIndex, defaultItem) => {
    let result = data;
    if (defaultItem) {
        result = [defaultItem].concat(result);
    }
    return result.slice(splitIndex).concat(result.slice(0, splitIndex));
};
/**
 * @hidden
 */
const matchText = (text, word, ignoreCase) => {
    if (!isPresent(text)) {
        return false;
    }
    let temp = String(text);
    if (ignoreCase) {
        temp = temp.toLowerCase();
    }
    return temp.indexOf(word) === 0;
};
/**
 * @hidden
 *
 * Checks whether the passed object has all of the listed properties.
 */
const hasProps = (obj, props) => {
    if (!isPresent(obj)) {
        return false;
    }
    return props.every(prop => obj.hasOwnProperty(prop));
};
/**
 * @hidden
 *
 * Checks whether an element is untouched by looking for the ng-untouched css class
 */
const isUntouched = (element) => element.className.includes('ng-untouched');
/**
 * @hidden
 */
const noop = (_) => { };
/**
 * IE element `matches` polyfill.
 * https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
 */
const matches = (element, selector) => {
    const matcher = element.matches || element.msMatchesSelector || element.webkitMatchesSelector;
    if (!matcher) {
        return false;
    }
    return matcher.call(element, selector);
};
/**
 * @hidden
 *
 * IE element `closest` polyfill.
 * https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
 */
const closest = (element, selector) => {
    let parent = element;
    while (parent !== null && parent.nodeType === 1) {
        if (matches(parent, selector)) {
            return parent;
        }
        parent = parent.parentElement || parent.parentNode;
    }
    return null;
};
/**
 * @hidden
 *
 * Parses a provided value to its type 'number' representation.
 * If the parsed value (via Number(value)) is NaN, the provided default value is returned.
 * Uses 0 as default value if a second param is not provided.
 */
const parseNumber = (num, defaultValue = 0) => {
    const normalizedValue = Number(num);
    return isNaN(normalizedValue) ? defaultValue : normalizedValue;
};
/**
 * @hidden
 *
 * Checks whether the passed target element is inside the provided host or popupRef.
 */
const inDropDown = (host, target, popupRef) => {
    return host.nativeElement.contains(target) || (popupRef && popupRef.popupElement.contains(target));
};
/**
 * @hidden
 *
 * Calculates the hierarchical level of an item, based on the provided index.
 * The result level is zero-based (starts from 0).
 */
const getHierarchicalItemLevel = (index) => {
    return (index || '').split('_').length - 1;
};
/**
 * @hidden
 *
 * Retrieves all descendant nodes' lookups which are currently registered in the provided lookup item as a flat array.
 */
const fetchDescendentNodes = (lookup, filterExpression) => {
    if (!isPresent(lookup) || lookup.children.length === 0) {
        return [];
    }
    let descendants = lookup.children;
    if (isPresent(filterExpression)) {
        descendants = descendants.filter(descendent => filterExpression(descendent.item));
    }
    descendants.forEach(child => descendants = descendants.concat(fetchDescendentNodes(child, filterExpression)));
    return descendants;
};
/**
 * @hidden
 *
 * Retrieves the correct value based on the item's level and the provided value field/s.
 * Used in the MultiSelectTree component.
 */
const valueFrom = ({ dataItem, index, level }, valueField) => {
    const fields = Array.isArray(valueField) ? valueField : [valueField];
    // either use the explicitly provided value level, or infer it from the item index
    const valueLevel = isPresent(level) ? level : getHierarchicalItemLevel(index);
    // fall-back to the last available one, if the current node is in a deeper level
    const normalizedLevel = Math.min(valueLevel, fields.length - 1);
    const field = fields[normalizedLevel];
    return getter(field)(dataItem);
};
/**
 * @hidden
 * Returns the size class based on the component and size input.
 */
const getSizeClass = (component, size) => {
    const SIZE_CLASSES = {
        'small': `k-${component}-sm`,
        'medium': `k-${component}-md`,
        'large': `k-${component}-lg`
    };
    return SIZE_CLASSES[size];
};
/**
 * @hidden
 * Returns the rounded class based on the rounded input.
 */
const getRoundedClass = (rounded) => {
    const ROUNDED_CLASSES = {
        'small': 'k-rounded-sm',
        'medium': 'k-rounded-md',
        'large': 'k-rounded-lg',
        'full': 'k-rounded-full'
    };
    return ROUNDED_CLASSES[rounded];
};
/**
 * @hidden
 * Return the fillMode class based on the component and fillMode input.
 */
const getFillModeClass = (component, fillMode) => {
    const FILLMODE_CLASSES = {
        'solid': `k-${component}-solid`,
        'flat': `k-${component}-flat`,
        'outline': `k-${component}-outline`
    };
    return FILLMODE_CLASSES[fillMode];
};

/* tslint:disable:member-ordering */
/**
 * @hidden
 */
let SearchBarComponent = class SearchBarComponent {
    constructor(localization, renderer) {
        this.localization = localization;
        this.valueChange = new EventEmitter();
        this.onBlur = new EventEmitter();
        this.onFocus = new EventEmitter();
        this.onClick = new EventEmitter();
        this.onNavigate = new EventEmitter();
        this.searchBarClass = true;
        this._userInput = "";
        this._previousValue = "";
        this._placeholder = "";
        this.direction = localization.rtl ? 'rtl' : 'ltr';
        this.renderer = renderer;
    }
    get userInput() {
        return this._userInput;
    }
    set userInput(userInput) {
        this._userInput = userInput || "";
    }
    get value() {
        return this.input.nativeElement.value;
    }
    set placeholder(text) {
        this._placeholder = text || '';
        this.setInputSize();
    }
    get placeholder() {
        return this._placeholder;
    }
    get ariaExpanded() {
        return this.role === 'combobox' ? this.popupOpen : null;
    }
    ngOnInit() {
        this.localizationChangeSubscription = this.localization
            .changes.subscribe(({ rtl }) => this.direction = rtl ? 'rtl' : 'ltr');
    }
    ngOnChanges(changes) {
        let previousUserInput;
        if (this.input && (changes.userInput || changes.suggestedText)) {
            if (changes.userInput && changes.userInput.previousValue) {
                if (this._previousValue === changes.userInput.previousValue) {
                    previousUserInput = this._previousValue;
                }
                else {
                    previousUserInput = changes.userInput.currentValue || "";
                }
            }
            else {
                previousUserInput = this._previousValue;
            }
            const caretIndex = this.input.nativeElement.selectionStart;
            const caretAtEnd = previousUserInput.length === caretIndex;
            this.writeInputValue(this.suggestedText ? combineStr(this.userInput, this.suggestedText) : this.userInput);
            if (this.suggestedText) {
                this.setInputSelection(this.userInput.length, this.suggestedText.length);
            }
            else if (caretAtEnd) {
                this.setInputSelection(this.userInput.length, this.userInput.length);
            }
            else {
                this.setInputSelection(caretIndex, caretIndex);
            }
            this._previousValue = this.userInput;
        }
    }
    ngOnDestroy() {
        if (this.localizationChangeSubscription) {
            this.localizationChangeSubscription.unsubscribe();
        }
    }
    writeInputValue(text) {
        if (isDocumentAvailable()) {
            this.renderer.setProperty(this.input.nativeElement, 'value', text);
        }
    }
    setInputSelection(start, end) {
        if (isDocumentAvailable() && this.input.nativeElement === document.activeElement) {
            try {
                this.input.nativeElement.setSelectionRange(start, end);
            }
            catch (e) {
                //Make sure that the element is in the DOM before you invoke its methods
            }
        }
    }
    handleInput(event) {
        const value = event.target.value;
        if (value !== this.userInput) {
            this._previousValue = value;
            this.valueChange.emit(value);
        }
    }
    handleFocus(event) {
        this.onFocus.emit(event);
    }
    handleBlur(event) {
        this.onBlur.emit(event);
    }
    handleKeydown(event) {
        const keyCode = event.keyCode;
        const keys = [Keys.ArrowUp, Keys.ArrowDown, Keys.ArrowLeft, Keys.ArrowRight, Keys.Enter,
            Keys.Escape, Keys.Delete, Keys.Backspace, Keys.Home, Keys.End];
        if (keys.indexOf(keyCode) > -1) {
            this.onNavigate.emit(event);
        }
    }
    focus() {
        if (isDocumentAvailable()) {
            this.input.nativeElement.focus();
        }
    }
    blur() {
        if (isDocumentAvailable()) {
            this.input.nativeElement.blur();
        }
    }
    setInputSize() {
        const lengthOf = x => x ? x.length : 0;
        const input = this.input.nativeElement;
        const placeholderLength = lengthOf(this.placeholder);
        const textLength = lengthOf(this.value);
        const size = Math.max(placeholderLength, textLength, 1);
        this.renderer.setAttribute(input, 'size', size.toString());
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], SearchBarComponent.prototype, "id", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], SearchBarComponent.prototype, "listId", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], SearchBarComponent.prototype, "tagListId", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], SearchBarComponent.prototype, "activeDescendant", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], SearchBarComponent.prototype, "noDataLabel", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], SearchBarComponent.prototype, "disabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], SearchBarComponent.prototype, "readonly", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], SearchBarComponent.prototype, "tabIndex", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], SearchBarComponent.prototype, "popupOpen", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], SearchBarComponent.prototype, "role", void 0);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], SearchBarComponent.prototype, "userInput", null);
__decorate([
    Input(),
    __metadata("design:type", String)
], SearchBarComponent.prototype, "suggestedText", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], SearchBarComponent.prototype, "valueChange", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], SearchBarComponent.prototype, "onBlur", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], SearchBarComponent.prototype, "onFocus", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], SearchBarComponent.prototype, "onClick", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], SearchBarComponent.prototype, "onNavigate", void 0);
__decorate([
    ViewChild('input', { static: true }),
    __metadata("design:type", ElementRef)
], SearchBarComponent.prototype, "input", void 0);
__decorate([
    HostBinding('class.k-searchbar'),
    __metadata("design:type", Boolean)
], SearchBarComponent.prototype, "searchBarClass", void 0);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], SearchBarComponent.prototype, "placeholder", null);
SearchBarComponent = __decorate([
    Component({
        selector: 'kendo-searchbar',
        template: `
        <input #input
            autocomplete="off"
            [id]="id"
            [disabled]="disabled"
            [readonly]="readonly"
            [placeholder]="placeholder"
            [class]="'k-input-inner'"
            (input)="handleInput($event)"
            (keydown)="handleKeydown($event)"
            [kendoEventsOutsideAngular]="{
                focus: handleFocus,
                blur: handleBlur
            }"
            [scope]="this"
            [attr.tabIndex]="tabIndex"
            [attr.dir]="direction"
            [attr.role]="role"
            [attr.aria-disabled]="disabled"
            [attr.aria-readonly]="readonly"
            aria-haspopup="listbox"
            [attr.aria-expanded]="ariaExpanded"
            [attr.aria-owns]="listId"
            [attr.aria-describedby]="tagListId"
            [attr.aria-activedescendant]="activeDescendant"
            [attr.aria-label]="noDataLabel"
        />
   `
    }),
    __metadata("design:paramtypes", [LocalizationService,
        Renderer2])
], SearchBarComponent);

/**
 * Renders the list item content. To define the item template, nest an `<ng-template>` tag
 * with the `kendo<ComponentName>ItemTemplate` directive inside the component tag. The template context is
 * set to the current component. To get a reference to the current data item, use the `let-dataItem` directive.
 *
 * - [Using `ItemTemplate` with the AutoComplete]({% slug templates_autocomplete %}#toc-item-template)
 * - [Using `ItemTemplate` with the ComboBox]({% slug templates_combobox %}#toc-item-template)
 * - [Using `ItemTemplate` with the DropDownList]({% slug templates_ddl %}#toc-item-template)
 * - [Using `ItemTemplate` with the MultiSelect]({% slug templates_multiselect %}#toc-item-template)
 *
 * @example
 * ```ts
 * _@Component({
 * selector: 'my-app',
 * template: `
 *  <kendo-combobox [data]="listItems">
 *    <ng-template kendoComboBoxItemTemplate let-dataItem>
 *      <span>{{dataItem}} option</span>
 *    </ng-template>
 *  </kendo-combobox>
 * `
 * })
 * class AppComponent {
 *   public listItems: Array<string> = ["Item 1", "Item 2", "Item 3", "Item 4"];
 * }
 * ```
 */
let ItemTemplateDirective = class ItemTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
ItemTemplateDirective = __decorate([
    Directive({
        selector: '[kendoDropDownListItemTemplate],[kendoComboBoxItemTemplate],[kendoAutoCompleteItemTemplate],[kendoMultiSelectItemTemplate]'
    }),
    __metadata("design:paramtypes", [TemplateRef])
], ItemTemplateDirective);

/**
 * Renders the header content of the list. To define the header template, nest an `<ng-template>` tag
 * with the `kendo<ComponentName>HeaderTemplate` directive inside the component tag.
 *
 * - [Using `HeaderTemplate` with the AutoComplete]({% slug templates_autocomplete %}#toc-header-template)
 * - [Using `HeaderTemplate` with the ComboBox]({% slug templates_combobox %}#toc-header-template)
 * - [Using `HeaderTemplate` with the MultiColumnComboBox]({% slug templates_multicolumncombobox %}#toc-header-template)
 * - [Using `HeaderTemplate` with the DropDownList]({% slug templates_ddl %}#toc-header-template)
 * - [Using `HeaderTemplate` with the DropDownTree]({% slug templates_ddt %}#toc-header-template)
 * - [Using `HeaderTemplate` with the MultiSelect]({% slug templates_multiselect %}#toc-header-template)
 *
 * @example
 * ```ts
 * _@Component({
 * selector: 'my-app',
 * template: `
 *  <kendo-combobox [data]="listItems">
 *    <ng-template kendoComboBoxHeaderTemplate>
 *      <h4>Header template</h4>
 *    </ng-template>
 *  </kendo-combobox>
 * `
 * })
 * class AppComponent {
 *   public listItems: Array<string> = ["Item 1", "Item 2", "Item 3", "Item 4"];
 * }
 * ```
 */
let HeaderTemplateDirective = class HeaderTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
HeaderTemplateDirective = __decorate([
    Directive({
        selector: '[kendoDropDownListHeaderTemplate],[kendoComboBoxHeaderTemplate],[kendoDropDownTreeHeaderTemplate],[kendoMultiColumnComboBoxHeaderTemplate],[kendoAutoCompleteHeaderTemplate],[kendoMultiSelectHeaderTemplate],[kendoMultiSelectTreeHeaderTemplate]'
    }),
    __metadata("design:paramtypes", [TemplateRef])
], HeaderTemplateDirective);

/**
 * Renders the footer content of the list. To define the footer template, nest an `<ng-template>` tag
 * with the `kendo<ComponentName>FooterTemplate` directive inside the component tag.
 *
 * - [Using `FooterTemplate` with the AutoComplete]({% slug templates_autocomplete %}#toc-footer-template)
 * - [Using `FooterTemplate` with the ComboBox]({% slug templates_combobox %}#toc-footer-template)
 * - [Using `FooterTemplate` with the MultiColumnComboBox]({% slug templates_multicolumncombobox %}#toc-footer-template)
 * - [Using `FooterTemplate` with the DropDownList]({% slug templates_ddl %}#toc-footer-template)
 * - [Using `FooterTemplate` with the DropDownTree]({% slug templates_ddt %}#toc-footer-template)
 * - [Using `FooterTemplate` with the MultiSelect]({% slug templates_multiselect %}#toc-footer-template)
 *
 * @example
 * ```ts
 * _@Component({
 * selector: 'my-app',
 * template: `
 *  <kendo-combobox [data]="listItems">
 *    <ng-template kendoComboBoxFooterTemplate>
 *      <h4>Footer template</h4>
 *    </ng-template>
 *  </kendo-combobox>
 * `
 * })
 * class AppComponent {
 *   public listItems: Array<string> = ["Item 1", "Item 2", "Item 3", "Item 4"];
 * }
 * ```
 */
let FooterTemplateDirective = class FooterTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
FooterTemplateDirective = __decorate([
    Directive({
        selector: '[kendoDropDownListFooterTemplate],[kendoComboBoxFooterTemplate],[kendoDropDownTreeFooterTemplate],[kendoMultiColumnComboBoxFooterTemplate],[kendoAutoCompleteFooterTemplate],[kendoMultiSelectFooterTemplate],[kendoMultiSelectTreeFooterTemplate]'
    }),
    __metadata("design:paramtypes", [TemplateRef])
], FooterTemplateDirective);

/**
 * Renders the group header content. To define the group template, nest an `<ng-template>` tag
 * with the `kendo<ComponentName>GroupTemplate` directive inside the component tag. The template context is
 * set to the current component. To get a reference to the current data item, use the `let-groupName` directive.
 *
 * - [Using `GroupTemplate` with the AutoComplete]({% slug templates_autocomplete %}#toc-group-template)
 * - [Using `GroupTemplate` with the ComboBox]({% slug templates_combobox %}#toc-group-template)
 * - [Using `GroupTemplate` with the MultiColumnComboBox]({% slug templates_multicolumncombobox %}#toc-group-template)
 * - [Using `GroupTemplate` with the DropDownList]({% slug templates_ddl %}#toc-group-template)
 * - [Using `GroupTemplate` with the MultiSelect]({% slug templates_multiselect %}#toc-group-template)
 *
 * @example
 * ```ts
 * import { groupBy } from '@progress/kendo-data-query';
 * _@Component({
 * selector: 'my-app',
 * template: `
 *  <kendo-combobox [data]="groupedData" textField="name" valueField="name">
 *    <ng-template kendoComboBoxGroupTemplate let-groupName>
 *      <span>Food type: {{groupName}} option</span>
 *    </ng-template>
 *  </kendo-combobox>
 * `
 * })
 * class AppComponent {
 *   public data = [
 *       { name: "Pork", category: "Food", subcategory: "Meat" },
 *       { name: "Pepper", category: "Food", subcategory: "Vegetables" },
 *       { name: "Beef", category: "Food", subcategory: "Meat" }
 *   ];
 *   public groupedData = groupBy(this.data, [{field: "subcategory"}]);
 * }
 * ```
 */
let GroupTemplateDirective = class GroupTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
GroupTemplateDirective = __decorate([
    Directive({
        selector: '[kendoDropDownListGroupTemplate],[kendoComboBoxGroupTemplate],[kendoMultiColumnComboBoxGroupTemplate],[kendoAutoCompleteGroupTemplate],[kendoMultiSelectGroupTemplate]'
    }),
    __metadata("design:paramtypes", [TemplateRef])
], GroupTemplateDirective);

/**
 * Renders the fixed group header content. To define the fixed group template, nest an `<ng-template>` tag
 * with the `kendo<ComponentName>FixedGroupTemplate` directive inside the component tag. The template context is
 * set to the current component. To get a reference to the current data item, use the `let-groupName` directive.
 *
 * - [Using `FixedGroupTemplate` with the AutoComplete]({% slug templates_autocomplete %}#toc-fixed-group-template)
 * - [Using `FixedGroupTemplate` with the ComboBox]({% slug templates_combobox %}#toc-fixed-group-template)
 * - [Using `FixedGroupTemplate` with the MultiColumnComboBox]({% slug templates_multicolumncombobox %}#toc-fixed-group-template)
 * - [Using `FixedGroupTemplate` with the DropDownList]({% slug templates_ddl %}#toc-fixed-group-template)
 * - [Using `FixedGroupTemplate` with the MultiSelect]({% slug templates_multiselect %}#toc-fixed-group-template)
 *
 * @example
 * ```ts
 * import { groupBy } from '@progress/kendo-data-query';
 * _@Component({
 * selector: 'my-app',
 * template: `
 *  <kendo-combobox [data]="groupedData" textField="name" valueField="name">
 *    <ng-template kendoComboBoxFixedGroupTemplate let-groupName>
 *      <span>Food type: {{groupName}} option</span>
 *    </ng-template>
 *  </kendo-combobox>
 * `
 * })
 * class AppComponent {
 *   public data = [
 *       { name: "Pork", category: "Food", subcategory: "Meat" },
 *       { name: "Pepper", category: "Food", subcategory: "Vegetables" },
 *       { name: "Beef", category: "Food", subcategory: "Meat" }
 *   ];
 *   public groupedData = groupBy(this.data, [{field: "subcategory"}]);
 * }
 * ```
 */
let FixedGroupTemplateDirective = class FixedGroupTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
FixedGroupTemplateDirective = __decorate([
    Directive({
        selector: '[kendoDropDownListFixedGroupTemplate],[kendoComboBoxFixedGroupTemplate],[kendoMultiColumnComboBoxFixedGroupTemplate],[kendoAutoCompleteFixedGroupTemplate],[kendoMultiSelectFixedGroupTemplate]'
    }),
    __metadata("design:paramtypes", [TemplateRef])
], FixedGroupTemplateDirective);

/**
 * @hidden
 */
let SelectionService = class SelectionService {
    /**
     * @hidden
     */
    constructor() {
        this.onSelect = new EventEmitter();
        this.onChange = new EventEmitter();
        this.onFocus = new EventEmitter();
        this.total = 0;
        this.selectedIndices = [];
    }
    getTotal() {
        return this.total;
    }
    isSelected(index) {
        return isPresent(this.selectedIndices.find(current => current === index));
    }
    isFocused(index) {
        return index === this.focused;
    }
    focus(index) {
        if (this.isFocused(index)) {
            return;
        }
        this.focused = index;
        this.onFocus.emit(index);
    }
    select(index) {
        if (this.isSelected(index)) {
            return;
        }
        this.selectedIndices = [index];
        this.focused = index;
        this.onSelect.emit({
            indices: [index],
            newSelection: isPresent(index)
        });
    }
    add(index) {
        if (this.isSelected(index)) {
            return;
        }
        this.selectedIndices.push(index);
        this.focused = index;
        this.onChange.emit({
            added: index,
            indices: this.selectedIndices.slice()
        });
    }
    unselect(index) {
        if (!this.isSelected(index)) {
            return;
        }
        const position = this.selectedIndices.indexOf(index);
        this.selectedIndices.splice(position, 1);
        this.focused = index;
        this.onChange.emit({
            indices: this.selectedIndices.slice(),
            removed: index
        });
    }
    change(index) {
        const newSelection = isPresent(index) && !this.isSelected(index);
        this.selectedIndices = [index];
        this.focused = index;
        this.onChange.emit({
            indices: [index],
            newSelection: newSelection
        });
    }
    resetSelection(index) {
        this.selectedIndices = index instanceof Array ? index : [index];
        this.focused = this.selectedIndices[this.selectedIndices.length - 1];
    }
    get selected() {
        return this.selectedIndices.slice();
    }
    get focused() {
        return this.focusedIndex;
    }
    set focused(index) {
        if (this.focusedIndex !== index) {
            this.focusedIndex = index;
            this.onFocus.emit(index);
        }
    }
};
SelectionService = __decorate([
    Injectable()
], SelectionService);

/**
 * @hidden
 */
var NavigationAction;
(function (NavigationAction) {
    NavigationAction[NavigationAction["Undefined"] = 0] = "Undefined";
    NavigationAction[NavigationAction["Open"] = 1] = "Open";
    NavigationAction[NavigationAction["Close"] = 2] = "Close";
    NavigationAction[NavigationAction["Enter"] = 3] = "Enter";
    NavigationAction[NavigationAction["Tab"] = 4] = "Tab";
    NavigationAction[NavigationAction["Esc"] = 5] = "Esc";
    NavigationAction[NavigationAction["Delete"] = 6] = "Delete";
    NavigationAction[NavigationAction["Backspace"] = 7] = "Backspace";
    NavigationAction[NavigationAction["Home"] = 8] = "Home";
    NavigationAction[NavigationAction["End"] = 9] = "End";
    NavigationAction[NavigationAction["Up"] = 10] = "Up";
    NavigationAction[NavigationAction["Down"] = 11] = "Down";
    NavigationAction[NavigationAction["Left"] = 12] = "Left";
    NavigationAction[NavigationAction["Right"] = 13] = "Right";
})(NavigationAction || (NavigationAction = {}));

/**
 * @hidden
 */
let DataService = class DataService {
    /**
     * @hidden
     */
    constructor() {
        this.grouped = false;
        this.groupIndices = [];
    }
    set data(data) {
        this._data = data;
        this.grouped = this.isGrouped(data);
        if (this.grouped) {
            this.groupIndices = this.getGroupIndices(data);
            this._flatData = this.flatten(data);
        }
    }
    get data() {
        if (this.grouped) {
            return this._flatData;
        }
        return this._data;
    }
    /**
     * @hidden
     * Used to get the actual items count, i.e. excluding the header items in case of grouping.
     */
    get itemsCount() {
        if (!isPresent(this.data) || this.data.length === 0) {
            return 0;
        }
        const items = this.grouped ? this._flatData.filter(item => !item.header) : this.data;
        return items.length;
    }
    /**
     * @hidden
     * Used to determine if the component received grouped data.
     */
    isGrouped(data) {
        // GroupResult { aggregates: AggregateResult, field: string, items: object[], value: any }
        // https://www.telerik.com/kendo-angular-ui/components/dataquery/api/GroupResult/
        return (isPresent(data) && data.length !== 0) && isPresent(data[0]) && hasProps(data[0], ['aggregates', 'field', 'items', 'value']);
    }
    /**
     * @hidden
     * Used to calculate the last item index of each group.
     */
    getGroupIndices(data) {
        let groupIndices = [];
        for (let i = 0; i <= data.length - 1; i++) {
            groupIndices[i] = (groupIndices[i - 1] || 0) + data[i].items.length;
        }
        return groupIndices;
    }
    /**
     * @hidden
     * Used to get a flat array containing all items matching certain criteria.
     */
    filter(predicate) {
        let result = [];
        if (this.isGrouped(this.data)) {
            for (let i = 0; i <= this.groupIndices.length - 1; i++) {
                const matches = this.data[i].items.filter(predicate);
                if (matches) {
                    result = result.concat(matches);
                }
            }
        }
        else {
            result = this.data.filter(predicate);
        }
        return result;
    }
    /**
     * @hidden
     * Used to get the index of a given data item.
     */
    indexOf(item, startFrom = 0) {
        let predicate = (element) => {
            return element === item;
        };
        if (this.grouped) {
            predicate = (element) => {
                return element.value === item;
            };
        }
        return this.findIndex(predicate, startFrom);
    }
    /**
     * @hidden
     * Used to get the index of a data item based on an expression.
     */
    findIndex(predicate, startFrom = 0) {
        let index = -1;
        if (this.grouped) {
            const data = this._flatData.filter(item => !item.header && item.offsetIndex >= startFrom);
            index = data.findIndex(predicate);
            index = data[index] ? data[index].offsetIndex : -1;
        }
        else {
            const data = this.data.slice(startFrom);
            const itemIndex = data.findIndex(predicate);
            index = itemIndex !== -1 ? itemIndex + startFrom : -1;
        }
        return index;
    }
    /**
     * @hidden
     * Used to get the closest group header prior to an item index.
     */
    closestGroup(index) {
        for (let i = index; i >= 0; i--) {
            if (this._flatData[i].header) {
                return this._flatData[i];
            }
        }
    }
    /**
     * @hidden
     * Used to get the first item matching the criteria.
     */
    find(predicate) {
        const index = this.findIndex(predicate);
        return this.itemAt(index);
    }
    /**
     * @hidden
     * Used to get the true index in a flattened data array.
     */
    flatIndex(index) {
        if (this.itemsCount === 0) {
            return -1;
        }
        if (this.grouped) {
            const match = this._flatData.find((item) => !item.header && item.offsetIndex === index);
            if (match) {
                return match.index;
            }
        }
        else {
            return index;
        }
        return -1;
    }
    /**
     * @hidden
     * Used to get the item at the provided index.
     */
    itemAt(index) {
        let dataItem;
        if (this.itemsCount === 0) {
            return dataItem;
        }
        if (this.grouped) {
            const match = this._flatData.find((item) => !item.header && item.offsetIndex === index);
            if (match) {
                dataItem = match.value;
            }
        }
        else {
            dataItem = this.data[index];
        }
        return dataItem;
    }
    /**
     * @hidden
     * Used to get the group at the provided index.
     */
    groupAt(index) {
        if (this.itemsCount === 0 || !this.isGrouped) {
            return;
        }
        return this._flatData.find((item) => item.header && item.index === index);
    }
    /**
     * @hidden
     * Used to get the field by which the data is grouped.
     */
    groupField() {
        if (this.itemsCount === 0 || !this.isGrouped) {
            return null;
        }
        return this._data[0].field;
    }
    /**
     * @hidden
     * Used to get the group to which a dataItem belongs.
     */
    itemGroup(item) {
        if (!item || this.itemsCount === 0 || !this.isGrouped) {
            return;
        }
        const fieldName = this.groupField();
        if (fieldName) {
            return getter$1(item, fieldName);
        }
    }
    flatten(data, group = undefined, offset = 0, groupIndex = 0) {
        let flat = [];
        if (isPresent(group)) {
            flat.push({
                header: true,
                index: groupIndex + offset,
                offsetIndex: groupIndex,
                value: group
            });
        }
        for (let i = 0; i < data.length; i++) {
            let result = [];
            if (data[i].items) {
                result = this.flatten(data[i].items, data[i].value, offset, i);
                offset = offset + data[i].items.length;
            }
            else {
                result.push({
                    header: false,
                    index: groupIndex + offset + i + 1,
                    offsetIndex: offset + i,
                    value: data[i]
                });
            }
            flat = flat.concat(result);
        }
        return flat;
    }
};
DataService = __decorate([
    Injectable()
], DataService);

/**
 * @hidden
 */
let DisabledItemsService = class DisabledItemsService {
    constructor(dataService) {
        this.dataService = dataService;
        this.itemDisabled = null;
    }
    isIndexDisabled(index) {
        if (this.itemDisabled) {
            const item = this.dataService.itemAt(index);
            if (isPresent(item)) {
                return this.itemDisabled({ dataItem: item, index });
            }
            else if (isPresent(this.defaultItem)) {
                return this.itemDisabled({ dataItem: this.defaultItem, index: -1 });
            }
        }
    }
    isItemDisabled(item) {
        if (this.itemDisabled) {
            const index = this.dataService.indexOf(item);
            if (index !== -1) {
                return this.itemDisabled({ dataItem: item, index });
            }
            else if (isPresent(this.defaultItem)) {
                return this.itemDisabled({ dataItem: this.defaultItem, index: -1 });
            }
        }
    }
};
DisabledItemsService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [DataService])
], DisabledItemsService);

const MIN_INDEX = 0;
/**
 * @hidden
 */
class NavigationEvent {
    /**
     * The index of the item to which the user navigated.
     */
    constructor(index, originalEvent) {
        this.index = index;
        this.originalEvent = originalEvent;
    }
}
/**
 * @hidden
 */
let NavigationService = class NavigationService {
    constructor(disabledItemsService, selectionService) {
        this.disabledItemsService = disabledItemsService;
        this.selectionService = selectionService;
        this.open = new EventEmitter();
        this.close = new EventEmitter();
        this.enter = new EventEmitter();
        this.tab = new EventEmitter();
        this.esc = new EventEmitter();
        this.up = new EventEmitter();
        this.right = new EventEmitter();
        this.down = new EventEmitter();
        this.left = new EventEmitter();
        this.delete = new EventEmitter();
        this.backspace = new EventEmitter();
        this.home = new EventEmitter();
        this.end = new EventEmitter();
    }
    process(args) {
        const keyCode = args.originalEvent.keyCode;
        const altKey = args.originalEvent.altKey;
        let index;
        let action = NavigationAction.Undefined;
        if (altKey && keyCode === Keys.ArrowDown) {
            action = NavigationAction.Open;
        }
        else if (altKey && keyCode === Keys.ArrowUp) {
            action = NavigationAction.Close;
        }
        else if (keyCode === Keys.Enter) {
            action = NavigationAction.Enter;
        }
        else if (keyCode === Keys.Escape) {
            action = NavigationAction.Esc;
        }
        else if (keyCode === Keys.Tab) {
            action = NavigationAction.Tab;
        }
        else if (keyCode === Keys.ArrowUp) {
            index = this.next({ current: args.current, start: args.min, end: args.max, step: -1 });
            action = NavigationAction.Up;
        }
        else if (keyCode === Keys.ArrowLeft) {
            index = this.next({ current: args.current, start: args.min, end: args.max, step: -1 });
            action = NavigationAction.Left;
        }
        else if (keyCode === Keys.ArrowDown) {
            index = this.next({ current: args.current, start: args.min, end: args.max, step: 1 });
            action = NavigationAction.Down;
        }
        else if (keyCode === Keys.ArrowRight) {
            index = this.next({ current: args.current, start: args.min, end: args.max, step: 1 });
            action = NavigationAction.Right;
        }
        else if (keyCode === Keys.Home) {
            index = this.isDisabled(MIN_INDEX) ? args.current : MIN_INDEX;
            action = NavigationAction.Home;
        }
        else if (keyCode === Keys.End) {
            index = this.isDisabled(args.max) ? args.current : args.max;
            action = NavigationAction.End;
        }
        else if (keyCode === Keys.Delete) {
            action = NavigationAction.Delete;
        }
        else if (keyCode === Keys.Backspace) {
            action = NavigationAction.Backspace;
        }
        const eventData = new NavigationEvent(index, args.originalEvent);
        if (action !== NavigationAction.Undefined) {
            this[NavigationAction[action].toLowerCase()].emit(eventData);
        }
        return action;
    }
    next(args) {
        const { current, start, end, step } = args;
        const nextIndex = !isPresent(current) ? start : this.clampIndex(current + step, start, end);
        const firstFocusableIndex = this.firstFocusableIndex(nextIndex, start, end, step);
        if (isPresent(firstFocusableIndex)) {
            return firstFocusableIndex;
        }
        if (this.selectionService.isSelected(current) && current >= start) {
            return current;
        }
        const inversedStep = -1 * step;
        return this.firstFocusableIndex(nextIndex, start, end, inversedStep);
    }
    clampIndex(index, min, max) {
        if (!isPresent(index) || index < min) {
            return min;
        }
        if (index > max) {
            return max;
        }
        return index;
    }
    firstFocusableIndex(startIndex, min, max, step) {
        while (min <= startIndex && startIndex <= max) {
            if (!this.isDisabled(startIndex)) {
                return startIndex;
            }
            startIndex += step;
        }
        return undefined;
    }
    isDisabled(index) {
        if (this.disabledItemsService) {
            return this.disabledItemsService.isIndexDisabled(index);
        }
    }
};
NavigationService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [DisabledItemsService,
        SelectionService])
], NavigationService);

/**
 * Renders content when no data is available. To define the no-data template, nest a `<ng-template>` tag
 * with the `kendo<ComponentName>NoDataTemplate` directive inside the component tag.
 *
 * - [Using `NoDataTemplate` with the AutoComplete]({% slug templates_autocomplete %}#toc-no-data-template)
 * - [Using `NoDataTemplate` with the ComboBox]({% slug templates_combobox %}#toc-no-data-template)
 * - [Using `NoDataTemplate` with the MultiColumnComboBox]({% slug templates_multicolumncombobox %}#toc-no-data-template)
 * - [Using `NoDataTemplate` with the DropDownList]({% slug templates_ddl %}#toc-no-data-template)
 * - [Using `NoDataTemplate` with the DropDownTree]({% slug templates_ddt %}#toc-no-data-template)
 * - [Using `NoDataTemplate` with the MultiSelect]({% slug templates_multiselect %}#toc-no-data-template)
 *
 * @example
 * ```ts
 * _@Component({
 * selector: 'my-app',
 * template: `
 *  <kendo-combobox [data]="listItems">
 *    <ng-template kendoComboBoxNoDataTemplate>
 *      <h4>No data!</h4>
 *    </ng-template>
 *  </kendo-combobox>
 * `
 * })
 * class AppComponent {
 *   public listItems: Array<string> = [];
 * }
 * ```
 */
let NoDataTemplateDirective = class NoDataTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
NoDataTemplateDirective = __decorate([
    Directive({
        selector: '[kendoDropDownListNoDataTemplate],[kendoDropDownTreeNoDataTemplate],[kendoComboBoxNoDataTemplate],[kendoMultiColumnComboBoxNoDataTemplate],[kendoAutoCompleteNoDataTemplate],[kendoMultiSelectNoDataTemplate],[kendoMultiSelectTreeNoDataTemplate]'
    }),
    __metadata("design:paramtypes", [TemplateRef])
], NoDataTemplateDirective);

/**
 * @hidden
 */
class PreventableEvent {
    constructor() {
        this.prevented = false;
    }
    /**
     * Prevents the default action for a specified event.
     * In this way, the source component suppresses the built-in behavior that follows the event.
     */
    preventDefault() {
        this.prevented = true;
    }
    /**
     * If the event is prevented by any of its subscribers, returns `true`.
     *
     * @returns `true` if the default action was prevented. Otherwise, returns `false`.
     */
    isDefaultPrevented() {
        return this.prevented;
    }
}

/**
 * Defines the mandatory properties of the `kendoDropDownFilter` directive
 * so that `kendoDropDownFilter` can be used with any of the DropDowns components
 * which implement the `FilterableDropDownComponentBase` class.
 *
 * @hidden
 */
class FilterableComponent {
}

/**
 * @hidden
 */
let ListItemDirective = class ListItemDirective {
    constructor(element) {
        this.element = element;
    }
};
ListItemDirective = __decorate([
    Directive({
        selector: '"li[role=option], li[role=group]"' // tslint:disable-line
    }),
    __metadata("design:paramtypes", [ElementRef])
], ListItemDirective);

/**
 * @hidden
 */
let ListComponent = class ListComponent {
    /* tslint:disable:member-ordering */
    constructor(dataService, wrapper, selectionService, disabledItemsService, cdr, zone, renderer) {
        this.dataService = dataService;
        this.wrapper = wrapper;
        this.selectionService = selectionService;
        this.disabledItemsService = disabledItemsService;
        this.cdr = cdr;
        this.zone = zone;
        this.renderer = renderer;
        this.selected = [];
        this.focused = -1;
        this.show = true;
        this.multipleSelection = false;
        this.type = 'list';
        this.checkboxes = { enabled: false };
        this.rounded = 'medium';
        this.onClick = new EventEmitter();
        this.pageChange = new EventEmitter();
        this.listResize = new EventEmitter();
        this.startFrom = 0;
        this.lastLoaded = 0;
        this.lastScrollTop = 0;
        this.scrollToFocused = false;
        this._size = 'medium';
        this.selectSubscription = merge(this.selectionService.onSelect.pipe(map((args) => args.indices[0])), this.selectionService.onFocus)
            .pipe(
        // handle only the very last onSelect/onFocus emission
        switchMap(event => this.zone.onStable.pipe(take(1), map(() => event))))
            .subscribe(this.scrollToItem.bind(this));
        this.prepareClasses();
    }
    set data(data) {
        this._data = data[0] && data[0].header ? data.slice(0) : data;
    }
    get data() {
        return this._data;
    }
    set size(size) {
        if (this.type === 'list') {
            this.renderer.removeClass(this.wrapper.nativeElement, getSizeClass('list', this.size));
            if (size) {
                this.renderer.addClass(this.wrapper.nativeElement, getSizeClass('list', size));
            }
            this._size = size;
        }
    }
    get size() {
        return this._size;
    }
    get pageSize() {
        if (this.virtual.pageSize) {
            return this.virtual.pageSize;
        }
        let size = Math.round(this.height / this.virtual.itemHeight);
        return size;
    }
    get scrollHeight() {
        return (this.dataService.grouped ? this.virtual.total - 1 : this.virtual.total) * this.virtual.itemHeight;
    }
    get overflowY() {
        if (isPresent(this.virtual)) {
            const overflow = this.hasVirtualScrollbar() ? 'scroll' : 'hidden';
            return overflow;
        }
    }
    /**
     * @hidden
     */
    get checkboxClasses() {
        return `${this.size ? getSizeClass('checkbox', this.size) : ''} ${this.rounded ? getRoundedClass(this.rounded) : ''}`;
    }
    ngOnChanges(changes) {
        if (isChanged('data', changes, false)) {
            if (this.lastLoaded <= 0) {
                this.lastLoaded = this.data.length - 1;
                this.scrollToFocused = !changes.data.isFirstChange();
            }
            this.setOverflow();
        }
        if (isChanged('virtual', changes, false)) {
            this.setOverflow();
        }
        if (isChanged('type', changes, false)) {
            this.prepareClasses();
        }
    }
    ngAfterViewInit() {
        this.setComponentClasses();
        this.zone.runOutsideAngular(() => {
            this.scrollSubscription = fromEvent(this.content.nativeElement, "scroll").pipe(auditTime(100), tap(this.prefetchData.bind(this)), tap(this.findCurrentGroup.bind(this))).subscribe(() => {
                this.lastScrollTop = this.content.nativeElement.scrollTop;
            });
        });
        this.setOverflow();
    }
    ngAfterViewChecked() {
        if (this.virtual) {
            this.positionItems();
        }
        if (this.items && this.scrollToFocused) {
            this.scrollToFocused = false;
            const scrollTarget = this.items.length && this.selectionService.focused === -1 ? 0 : this.selectionService.focused;
            this.scrollToItem(scrollTarget);
        }
        if (this.dataService.grouped) {
            this.findCurrentGroup();
        }
    }
    ngOnDestroy() {
        this.selectSubscription.unsubscribe();
        if (this.scrollSubscription) {
            this.scrollSubscription.unsubscribe();
        }
    }
    onCheckedChange(e, index) {
        const isChecked = e.target['checked'];
        if (isChecked && !this.selectionService.isSelected(index)) {
            this.selectionService.add(index);
        }
        if (!isChecked && this.selectionService.isSelected(index)) {
            this.selectionService.unselect(index);
        }
    }
    prepareClasses() {
        if (this.type === 'list') {
            this.listContentClass = 'k-list-content';
            this.listClass = 'k-list-ul';
            this.listItemClass = 'k-list-item';
            this.listVirtualClass = 'k-virtual-list';
            this.listGroupStickyHeaderClass = 'k-list-group-sticky-header';
            this.listGroupStickyHeaderTextClass = 'k-list-header-text';
            this.listGroupItemClass = 'k-list-group-item';
            this.listGroupItemTextClass = 'k-list-item-text';
        }
        else {
            this.listContentClass = 'k-table-body k-table-scroller';
            this.listClass = 'k-table k-table-list';
            this.listItemClass = 'k-table-row';
            this.listVirtualClass = 'k-virtual-table';
            this.listGroupStickyHeaderClass = 'k-table-group-sticky-header';
            this.listGroupStickyHeaderTextClass = 'k-table-th';
            this.listGroupItemClass = 'k-table-group-row';
            this.listGroupItemTextClass = 'k-table-th';
        }
    }
    isChecked(index) {
        const normalizedIndex = this.virtual ? index + this.virtual.skip : index;
        return this.selectionService.isSelected(normalizedIndex);
    }
    firstVisibleItem() {
        const content = this.content.nativeElement;
        const rect = content.getBoundingClientRect();
        // IE9 hack
        const disabled = Array.prototype.slice.call(content.querySelectorAll(".k-disabled"));
        // This is a workaround for finding elements with pointer-events: none;
        disabled.forEach((el) => this.renderer.setStyle(el, "pointer-events", "auto"));
        const item = document.elementFromPoint(rect.left + 1, rect.top + 1);
        disabled.forEach((el) => this.renderer.setStyle(el, "pointer-events", "none"));
        // return the closest `li` item to cover the custom template scenario
        return closest(item, 'li');
    }
    findCurrentGroup() {
        if (!this.dataService.grouped) {
            this.currentGroup = undefined;
            return;
        }
        const item = this.firstVisibleItem();
        if (item) {
            let index;
            if (item.getAttribute("role") === "group") {
                index = parseInt(item.getAttribute("group-index"), 10);
                this.currentGroup = this.dataService.groupAt(index).value;
            }
            else {
                index = parseInt(item.getAttribute("index"), 10);
                this.currentGroup = this.dataService.itemGroup(this.dataService.itemAt(index));
            }
        }
        else {
            this.currentGroup = undefined;
        }
        this.cdr.detectChanges();
    }
    prefetchData() {
        if (!this.virtual) {
            return;
        }
        const visibleItems = Math.trunc(this.content.nativeElement.clientHeight / this.virtual.itemHeight);
        const offsetY = this.content.nativeElement.scrollTop;
        const start = Math.trunc(offsetY / this.virtual.itemHeight);
        const down = offsetY > this.lastScrollTop;
        const nextPage = (start + visibleItems >= this.lastLoaded) && this.lastLoaded < this.virtual.total - 1;
        const leftOver = this.pageSize - (this.lastLoaded - this.startFrom);
        const prevPage = this.lastLoaded - this.pageSize + visibleItems >= start - leftOver;
        if (down && nextPage) {
            this.changePage(start);
        }
        if (!down && prevPage) {
            this.changePage(start - this.pageSize + visibleItems + 1);
        }
    }
    changePage(start) {
        this.zone.run(() => {
            let end = this.pageSize + start;
            if (end > this.virtual.total) {
                start--;
                end = this.virtual.total;
            }
            if (start < 0) {
                start = 0;
            }
            this.startFrom = start;
            this.lastLoaded = end;
            this.pageChange.emit({ skip: start, take: this.pageSize });
        });
    }
    index(groupIndex, itemIndex) {
        return groupIndex > 0 ? (this.dataService.groupIndices[groupIndex - 1] + itemIndex) : itemIndex;
    }
    getText(dataItem) {
        return getter$1(dataItem, this.textField);
    }
    getValue(dataItem) {
        return getter$1(dataItem, this.valueField);
    }
    isDisabled(index) {
        if (isPresent(this.virtual)) {
            index += this.virtual.skip;
        }
        return this.disabledItemsService.isIndexDisabled(index);
    }
    isAltRow(index) {
        return this.type === 'dropdowngrid' && index % 2 !== 0;
    }
    scrollToItem(index) {
        let flatIndex = index;
        if (this.dataService.grouped) {
            // takes into account the group header items
            flatIndex = this.dataService.flatIndex(index);
            /* The first group header item is not rendered in the list (see template), so subtract 1 when calulating the flat index.
               With virtualization enabled, the first group header could be in a previous page, in which case don't subtract anything. */
            const groupHeaderOffset = this.firstGroupHeaderInTargetedPage(flatIndex) ? -1 : 0;
            flatIndex += groupHeaderOffset;
        }
        if (this.virtual && flatIndex > -1) {
            this.scrollToIndex(flatIndex);
            return;
        }
        const items = this.items.toArray();
        if (isPresent(items[flatIndex]) && flatIndex !== -1) {
            this.scroll(items[flatIndex].element);
        }
    }
    scrollToIndex(index) {
        let content = this.content.nativeElement;
        let contentScrollTop = content.scrollTop;
        const itemOffsetTop = index * this.virtual.itemHeight;
        const itemOffsetHeight = this.virtual.itemHeight;
        const contentOffsetHeight = content.clientHeight;
        const bottomDistance = itemOffsetTop + itemOffsetHeight;
        if (contentScrollTop > itemOffsetTop) {
            contentScrollTop = itemOffsetTop;
        }
        else if (bottomDistance > (contentScrollTop + contentOffsetHeight)) {
            contentScrollTop = (bottomDistance - contentOffsetHeight);
        }
        content.scrollTop = contentScrollTop;
    }
    scroll(item) {
        if (!item) {
            return;
        }
        const nativeElement = item.nativeElement;
        let content = this.content.nativeElement, itemOffsetTop = nativeElement.offsetTop, itemOffsetHeight = nativeElement.offsetHeight, contentScrollTop = content.scrollTop, contentOffsetHeight = content.clientHeight, bottomDistance = itemOffsetTop + itemOffsetHeight;
        if (contentScrollTop > itemOffsetTop) {
            contentScrollTop = itemOffsetTop;
        }
        else if (bottomDistance > (contentScrollTop + contentOffsetHeight)) {
            contentScrollTop = (bottomDistance - contentOffsetHeight);
        }
        content.scrollTop = contentScrollTop;
    }
    /**
     * Indicates whether a scrollbar is currently rendered in the list.
     */
    hasScrollbar() {
        if (!(isPresent(this.items) && this.items.length && isPresent(this.list) && isPresent(this.content))) {
            return false;
        }
        const hasVirtualScroll = isPresent(this.virtual) && this.hasVirtualScrollbar();
        return hasVirtualScroll || this.list.nativeElement.scrollHeight > this.content.nativeElement.offsetHeight;
    }
    /**
     * Sets the list's content overflow (hides/shows scrollbar)
     */
    setOverflow() {
        if (this.virtual) {
            const overflow = this.hasVirtualScrollbar() ? 'scroll' : 'hidden';
            this.renderer.setStyle(this.content.nativeElement, 'overflow-y', overflow);
        }
    }
    /**
     * Indicates whether the scrollbar should be visible in virtual mode.
     */
    hasVirtualScrollbar() {
        const contentOffsetHeight = this.content.nativeElement.offsetHeight;
        const virtualOffsetHeight = this.virtualContainer && this.virtualContainer.nativeElement.offsetHeight;
        return this.virtualContainer && virtualOffsetHeight > contentOffsetHeight;
    }
    positionItems() {
        this.items.forEach((item, index) => {
            const offsetY = (index + this.startFrom) * this.virtual.itemHeight;
            this.renderer.setStyle(item.element.nativeElement, "transform", `translateY(${offsetY}px`);
        });
    }
    /**
     * Indicates whether the first group header from the data set is in the targeted virtual page.
     */
    firstGroupHeaderInTargetedPage(itemIndex) {
        if (!isPresent(this.virtual)) {
            return true;
        }
        return this.virtual.skip === 0 && (this.virtual.pageSize > itemIndex);
    }
    setComponentClasses() {
        if (this.type === 'list') {
            this.renderer.addClass(this.wrapper.nativeElement, 'k-list');
            if (this.size) {
                this.renderer.addClass(this.wrapper.nativeElement, getSizeClass('list', this.size));
            }
        }
        if (isPresent(this.virtual)) {
            this.renderer.addClass(this.wrapper.nativeElement, this.listVirtualClass);
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", Array)
], ListComponent.prototype, "selected", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], ListComponent.prototype, "focused", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ListComponent.prototype, "textField", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ListComponent.prototype, "valueField", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], ListComponent.prototype, "height", void 0);
__decorate([
    Input(),
    __metadata("design:type", ItemTemplateDirective)
], ListComponent.prototype, "template", void 0);
__decorate([
    Input(),
    __metadata("design:type", GroupTemplateDirective)
], ListComponent.prototype, "groupTemplate", void 0);
__decorate([
    Input(),
    __metadata("design:type", FixedGroupTemplateDirective)
], ListComponent.prototype, "fixedGroupTemplate", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ListComponent.prototype, "show", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ListComponent.prototype, "id", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ListComponent.prototype, "optionPrefix", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ListComponent.prototype, "multipleSelection", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], ListComponent.prototype, "virtual", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ListComponent.prototype, "type", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], ListComponent.prototype, "checkboxes", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [Array])
], ListComponent.prototype, "data", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], ListComponent.prototype, "size", null);
__decorate([
    Input(),
    __metadata("design:type", String)
], ListComponent.prototype, "rounded", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ListComponent.prototype, "onClick", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ListComponent.prototype, "pageChange", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ListComponent.prototype, "listResize", void 0);
__decorate([
    ViewChildren(ListItemDirective),
    __metadata("design:type", QueryList)
], ListComponent.prototype, "items", void 0);
__decorate([
    ViewChild('content', { static: true }),
    __metadata("design:type", ElementRef)
], ListComponent.prototype, "content", void 0);
__decorate([
    ViewChild('list', { static: true }),
    __metadata("design:type", ElementRef)
], ListComponent.prototype, "list", void 0);
__decorate([
    ViewChild('virtualContainer', { static: false }),
    __metadata("design:type", ElementRef)
], ListComponent.prototype, "virtualContainer", void 0);
ListComponent = __decorate([
    Component({
        selector: 'kendo-list',
        template: `
    <div *ngIf="dataService.grouped"
        [class]="listGroupStickyHeaderClass"
        [ngStyle]="{
            'height.px': virtual?.itemHeight,
            'minHeight.px' : virtual?.itemHeight,
            'boxSizing' : virtual ? 'border-box' : 'inherit'}"
        >
        <ng-template *ngIf="fixedGroupTemplate"
            [templateContext]="{
                templateRef: fixedGroupTemplate.templateRef,
                $implicit: currentGroup
            }">
        </ng-template>
        <ng-template [ngIf]="!fixedGroupTemplate"><span [class]="listGroupStickyHeaderTextClass">{{ currentGroup }}</span></ng-template>
    </div>
    <div #content
        [class]="listContentClass"
        [style.overscrollBehavior]="'none'"
        [style.maxHeight.px]="height"
        unselectable="on">
    <ul #list
        role="listbox"
        [class]="listClass"
        [attr.id]="id"
        [attr.aria-hidden]="!show">
         <ng-template *ngIf="!dataService.grouped && show" ngFor let-dataItem let-itemIndex="index" [ngForOf]="data">
            <li
                role="option"
                kendoDropDownsSelectable
                [checkboxes]="checkboxes"
                [height]="virtual?.itemHeight"
                [index]="itemIndex + startFrom"
                [multipleSelection]="multipleSelection"
                [attr.id]="optionPrefix + '-' + getValue(dataItem)"
                [attr.tabIndex]="-1"
                [class]="listItemClass"
                [ngClass]="{
                    'k-disabled': isDisabled(itemIndex),
                    'k-table-alt-row': isAltRow(itemIndex)
                }"
            >
                <input
                    *ngIf="checkboxes.enabled"
                    type="checkbox"
                    class="k-checkbox"
                    [ngClass]="checkboxClasses"
                    (change)="onCheckedChange($event, itemIndex)"
                    [checked]="isChecked(itemIndex)"
                />
                <ng-template *ngIf="template"
                    [templateContext]="{
                        templateRef: template.templateRef,
                        $implicit: dataItem
                    }">
                </ng-template>
                <ng-template [ngIf]="!template"><span class="k-list-item-text">{{ getText(dataItem) }}</span></ng-template>
            </li>
         </ng-template>
         <ng-template *ngIf="dataService.grouped" ngFor let-dataItem let-itemIndex="index" [ngForOf]="data">
            <li
                *ngIf="dataItem.header && dataItem.index > 0"
                role="group"
                [class]="listGroupItemClass"
                [class.k-table-alt-row]="isAltRow(itemIndex - 1)"
                [ngStyle]="{
                    'height.px': virtual?.itemHeight,
                    'minHeight.px' : virtual?.itemHeight,
                    'boxSizing' : virtual ? 'border-box' : 'inherit'}"
                [attr.group-index]="dataItem.index"
                [attr.id]="optionPrefix + '-' + getValue(dataItem.value)"
                [attr.tabIndex]="-1">
                    <span [class]="listGroupItemTextClass">
                        <ng-template *ngIf="groupTemplate"
                            [templateContext]="{
                                templateRef: groupTemplate.templateRef,
                                $implicit: dataItem.value
                        }">
                        </ng-template>
                        <ng-template [ngIf]="!groupTemplate">{{ dataItem.value }}</ng-template>
                    </span>
            </li>
            <li
                *ngIf="!dataItem.header"
                role="option"
                kendoDropDownsSelectable
                [height]="virtual?.itemHeight"
                [index]="dataItem.offsetIndex"
                [multipleSelection]="multipleSelection"
                [attr.absolute-index]="dataItem.index"
                [attr.id]="optionPrefix + '-' + getValue(dataItem.value)"
                [attr.tabIndex]="-1"
                [class]="listItemClass"
                [ngClass]="{
                    'k-disabled': isDisabled(dataItem.offsetIndex),
                    'k-table-alt-row': isAltRow(itemIndex - 1)
                }"
            >
                <ng-template *ngIf="template"
                    [templateContext]="{
                        templateRef: template.templateRef,
                        $implicit: dataItem.value
                    }">
                </ng-template>
                <ng-template [ngIf]="!template"><span class="k-list-item-text">{{ getText(dataItem.value) }}</span></ng-template>
            </li>
        </ng-template>
        <kendo-resize-sensor
            *ngIf="!virtual"
            (resize)="listResize.emit()"
        >
        </kendo-resize-sensor>
    </ul>
    <div *ngIf="virtual" #virtualContainer class="k-height-container" role="presentation">
        <div [style.height.px]="scrollHeight">
            <kendo-resize-sensor (resize)="listResize.emit()"></kendo-resize-sensor>
        </div>
    </div>
    </div>
  `
    }),
    __metadata("design:paramtypes", [DataService,
        ElementRef,
        SelectionService,
        DisabledItemsService,
        ChangeDetectorRef,
        NgZone,
        Renderer2])
], ListComponent);

/**
 * @hidden
 */
const DEFAULTS = {
    pageSize: 50,
    itemHeight: 28
};
/**
 * @hidden
 */
const normalizeVirtualizationSettings = (settings, defaultOverrides) => {
    const defaults = Object.assign({}, DEFAULTS, defaultOverrides);
    if (settings === true) {
        return defaults;
    }
    if (!settings) {
        return null;
    }
    return Object.assign({ pageSize: DEFAULTS.pageSize }, settings);
};

/**
 * @hidden
 */
const TOUCH_ENABLED = new InjectionToken('dropdowns-touch-enabled');

/* tslint:disable:member-ordering */
var AutoCompleteComponent_1;
const NO_VALUE = "";
/**
 * @hidden
 */
const AUTOCOMPLETE_VALUE_ACCESSOR = {
    multi: true,
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AutoCompleteComponent)
};
/**
 * Represents the [Kendo UI AutoComplete component for Angular]({% slug overview_autocomplete %}).
 *
 * @example
 * ```ts
 * _@Component({
 * selector: 'my-app',
 * template: `
 *  <kendo-autocomplete
 *      [data]="listItems"
 *      [placeholder]="placeholder"
 *  >
 * `
 * })
 * class AppComponent {
 *   public placeholder: string = 'Type "it" for suggestions';
 *   public listItems: Array<string> = ["Item 1", "Item 2", "Item 3", "Item 4"];
 * }
 * ```
 */
let AutoCompleteComponent = AutoCompleteComponent_1 = class AutoCompleteComponent {
    constructor(localization, dataService, popupService, selectionService, navigationService, disabledItemsService, _zone, cdr, renderer, hostElement, touchEnabled$$1) {
        this.localization = localization;
        this.dataService = dataService;
        this.popupService = popupService;
        this.selectionService = selectionService;
        this.navigationService = navigationService;
        this.disabledItemsService = disabledItemsService;
        this._zone = _zone;
        this.cdr = cdr;
        this.renderer = renderer;
        this.hostElement = hostElement;
        this.touchEnabled = touchEnabled$$1;
        /**
         * Defines whether the first match from the suggestions list will be automatically focused.
         * By default, `highlightFirst` is set to `true`.
         */
        this.highlightFirst = true;
        /**
         * @hidden
         */
        this.focusableId = `k-${guid$1()}`;
        /**
         * The hint which is displayed when the component is empty.
         */
        this.placeholder = "";
        /**
         * Sets the height of the suggestions list. By default, `listHeight` is 200px.
         *
         * > The `listHeight` property affects only the list of suggestions and not the whole popup container.
         * > To set the height of the popup container, use `popupSettings.height`.
         */
        this.listHeight = 200;
        /**
         * @hidden
         *
         * If set to `true`, renders a button on hovering over the component.
         * Clicking this button resets the value of the component to `undefined` and triggers the `change` event.
         */
        this.clearButton = true;
        /**
         * Sets the disabled state of the component.
         */
        this.disabled = false;
        /**
         * Sets the read-only state of the component.
         */
        this.readonly = false;
        /**
         * Specifies the [`tabindex`](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex) of the component.
         */
        this.tabindex = 0;
        /**
         * Enables the [filtering]({% slug filtering_autocomplete %}) functionality.
         * If set to `true`, the component emits the `filterChange` event.
         */
        this.filterable = false;
        /**
         * Fires each time the value is changed&mdash;
         * when the component is blurred or the value is cleared through the **Clear** button
         * ([see example]({% slug overview_autocomplete %}#toc-events)).
         * When the value of the component is programmatically changed to `ngModel` or `formControl`
         * through its API or form binding, the `valueChange` event is not triggered because it
         * might cause a mix-up with the built-in `valueChange` mechanisms of the `ngModel` or `formControl` bindings.
         */
        this.valueChange = new EventEmitter();
        /**
         * Fires each time the user types in the input field.
         * You can filter the source based on the passed filtration value
         * ([see example]({% slug overview_autocomplete %}#toc-events)).
         */
        this.filterChange = new EventEmitter();
        /**
         * Fires each time the popup is about to open.
         * This event is preventable. If you cancel it, the popup will remain closed.
         */
        this.open = new EventEmitter();
        /**
         * Fires after the popup has been opened.
         */
        this.opened = new EventEmitter();
        /**
         * Fires each time the popup is about to close.
         * This event is preventable. If you cancel it, the popup will remain open.
         */
        this.close = new EventEmitter();
        /**
         * Fires after the popup has been closed.
         */
        this.closed = new EventEmitter();
        /**
         * Fires each time the user focuses the AutoComplete.
         */
        this.onFocus = new EventEmitter();
        /**
         * Fires each time the AutoComplete gets blurred.
         */
        this.onBlur = new EventEmitter();
        this.widgetClasses = true;
        this.listBoxId = guid$1();
        this.optionPrefix = guid$1();
        this.onChangeCallback = noop;
        this.onTouchedCallback = noop;
        this.popupMouseDownHandler = (event) => event.preventDefault();
        this._popupSettings = { animate: true };
        this._open = false;
        this._value = "";
        this.valueChangeSubject = new Subject();
        this._isFocused = false;
        this._size = 'medium';
        this._rounded = 'medium';
        this._fillMode = 'solid';
        validatePackage(packageMetadata);
        this.direction = localization.rtl ? 'rtl' : 'ltr';
        this.wrapper = this.hostElement.nativeElement;
        this.data = [];
        this.subscribeEvents();
        this.subscribeTouchEvents();
        this.selectionService.resetSelection([-1]);
    }
    get width() {
        let wrapperOffsetWidth = 0;
        if (isDocumentAvailable()) {
            wrapperOffsetWidth = this.wrapper.offsetWidth;
        }
        const width = this.popupSettings.width || wrapperOffsetWidth;
        const minWidth = isNaN(wrapperOffsetWidth) ? wrapperOffsetWidth : `${wrapperOffsetWidth}px`;
        const maxWidth = isNaN(width) ? width : `${width}px`;
        return { min: minWidth, max: maxWidth };
    }
    get height() {
        const popupHeight = this.popupSettings.height;
        return isPresent(popupHeight) ? `${popupHeight}px` : 'auto';
    }
    get listContainerClasses() {
        const containerClasses = ['k-list-container', 'k-reset'];
        if (this.popupSettings.popupClass) {
            containerClasses.push(this.popupSettings.popupClass);
        }
        return containerClasses;
    }
    get suggestion() {
        if (!this.text || !this.suggestedText) {
            this.suggestedText = undefined;
            return;
        }
        const hasMatch = this.suggestedText.toLowerCase().startsWith(this.text.toLowerCase());
        const shouldSuggest = this.suggest && !this.backspacePressed;
        if (shouldSuggest && hasMatch) {
            return this.suggestedText;
        }
    }
    get appendTo() {
        const { appendTo } = this.popupSettings;
        if (!appendTo || appendTo === 'root') {
            return undefined;
        }
        return appendTo === 'component' ? this.container : appendTo;
    }
    get clearButtonVisiblity() {
        if (this.touchEnabled) {
            return 'visible';
        }
    }
    /**
     * Toggles the visibility of the popup.
     * If you use the `toggle` method to open or close the popup, the `open` and `close` events will not be fired.
     *
     * @param open - The state of the popup.
     */
    toggle(open) {
        Promise.resolve(null).then(() => {
            const shouldOpen = isPresent(open) ? open : !this._open;
            this._toggle(shouldOpen);
        });
    }
    /**
     * Returns the current open state of the popup.
     */
    get isOpen() {
        return this._open;
    }
    /**
     * @hidden
     */
    togglePopup(open) {
        const isDisabled = this.disabled || this.readonly;
        const sameState = this.isOpen === open;
        if (isDisabled || sameState) {
            return;
        }
        const isDefaultPrevented = this.triggerPopupEvents(open);
        if (!isDefaultPrevented) {
            this._toggle(open);
        }
    }
    get activeDescendant() {
        if (!this.isOpen || !isPresent(this.selectionService.focused) || this.selectionService.focused === -1) {
            return null;
        }
        const dataItem = this.dataService.itemAt(this.selectionService.focused);
        return this.optionPrefix + "-" + getter$1(dataItem, this.valueField);
    }
    get noDataLabel() {
        if (this.data.length === 0) {
            return this.messageFor('noDataText');
        }
    }
    /**
     * Sets the data of the AutoComplete.
     *
     * > The data has to be provided in an array-like list.
     */
    set data(data) {
        this.dataService.data = data || [];
        if (this.virtual) {
            this.virtual.skip = 0;
        }
        if (this.filterable) {
            this.selectionService.focused = this.isOpen && this.data.length && this.highlightFirst ? this.firstFocusableIndex(0) : -1;
        }
        if (this.suggest && this.dataService.itemsCount > 0) {
            this.suggestedText = getter$1(this.dataService.itemAt(0), this.valueField);
        }
    }
    get data() {
        const virtual = this.virtual;
        if (virtual) {
            const start = virtual.skip || 0;
            const end = start + virtual.pageSize;
            // Use length instead of itemsCount because of the grouping.
            virtual.total = this.dataService.data.length;
            return this.dataService.data.slice(start, end);
        }
        return this.dataService.data;
    }
    /**
     * Sets the value of the AutoComplete.
     */
    set value(newValue) {
        this.verifySettings(newValue);
        this._value = newValue || NO_VALUE;
        this.text = this.value;
        this.cdr.markForCheck();
    }
    get value() {
        return this._value || NO_VALUE;
    }
    /**
     * Configures the popup of the AutoComplete.
     *
     * The available options are:
     * - `animate: Boolean`&mdash;Controls the popup animation. By default, the open and close animations are enabled.
     * - `width: Number | String`&mdash;Sets the width of the popup container. By default, the width of the host element is used. If set to `auto`, the component automatically adjusts the width of the popup and no item labels are wrapped. The `auto` mode is not supported when virtual scrolling is enabled.
     * - `height: Number`&mdash;Sets the height of the popup container.
     * - `popupClass: String`&mdash;Specifies a list of CSS classes that are used to style the popup.
     * - `appendTo: "root" | "component" | ViewContainerRef`&mdash;Specifies the component to which the popup will be appended.
     */
    set popupSettings(settings) {
        this._popupSettings = Object.assign({ animate: true }, settings);
    }
    get popupSettings() {
        return this._popupSettings;
    }
    /**
     * Defines a Boolean function that is executed for each data item in the component
     * ([see examples]({% slug disableditems_autocomplete %})).
     * Determines whether the item will be disabled.
     */
    set itemDisabled(fn) {
        if (typeof fn !== 'function') {
            throw new Error(`itemDisabled must be a function, but received ${JSON.stringify(fn)}.`);
        }
        this.disabledItemsService.itemDisabled = fn;
    }
    /**
     * @hidden
     */
    set tabIndex(tabIndex) {
        this.tabindex = tabIndex;
    }
    get tabIndex() {
        return this.tabindex;
    }
    /**
     * Enables the [virtualization]({% slug virtualization_autocomplete %}) functionality.
     */
    set virtual(settings) {
        this._virtualSettings = normalizeVirtualizationSettings(settings);
    }
    get virtual() {
        return this._virtualSettings;
    }
    /**
     * Sets the size of the component.
     *
     * The possible values are:
     * * `'small'`
     * * `'medium'` (default)
     * * `'large'`
     * * `null`
     *
     */
    set size(size) {
        this.renderer.removeClass(this.wrapper, getSizeClass('input', this.size));
        if (size) {
            this.renderer.addClass(this.wrapper, getSizeClass('input', size));
        }
        this._size = size;
    }
    get size() {
        return this._size;
    }
    /**
     * Sets the border radius of the component.
     *
     * The possible values are:
     * * `'small'`
     * * `'medium'` (default)
     * * `'large'`
     * * `'full'`
     * * `null`
     *
     */
    set rounded(rounded) {
        this.renderer.removeClass(this.wrapper, getRoundedClass(this.rounded));
        if (rounded) {
            this.renderer.addClass(this.wrapper, getRoundedClass(rounded));
        }
        this._rounded = rounded;
    }
    get rounded() {
        return this._rounded;
    }
    /**
     * Sets the fillMode of the component.
     *
     * The possible values are:
     * * `'flat'`
     * * `'solid'` (default)
     * * `'outline'`
     * * `null`
     *
     */
    set fillMode(fillMode) {
        this.renderer.removeClass(this.wrapper, getFillModeClass('input', this.fillMode));
        if (fillMode) {
            this.renderer.addClass(this.wrapper, getFillModeClass('input', fillMode));
        }
        this._fillMode = fillMode;
    }
    get fillMode() {
        return this._fillMode;
    }
    get isFocused() {
        return this._isFocused;
    }
    set isFocused(isFocused) {
        this.renderer[isFocused ? 'addClass' : 'removeClass'](this.wrapper, "k-focus");
        this._isFocused = isFocused;
    }
    get isDisabled() {
        return this.disabled;
    }
    get isLoading() {
        return this.loading;
    }
    get dir() {
        return this.direction;
    }
    ngOnInit() {
        this.renderer.removeAttribute(this.wrapper, "tabindex");
        this.localizationChangeSubscription = this.localization
            .changes
            .subscribe(({ rtl }) => {
            this.direction = rtl ? 'rtl' : 'ltr';
            this.cdr.detectChanges();
        });
        this.setComponentClasses();
    }
    ngOnDestroy() {
        this.destroyPopup();
        this.unsubscribeEvents();
        if (this.localizationChangeSubscription) {
            this.localizationChangeSubscription.unsubscribe();
        }
    }
    ngOnChanges(changes) {
        const virtual = this.virtual;
        const requestInitialData = virtual && changes.data && changes.data.isFirstChange();
        if (requestInitialData) {
            this.pageChange({ skip: 0, take: virtual.pageSize });
        }
    }
    /**
     * Resets the value of the AutoComplete.
     * If you use the `reset` method to clear the value of the component,
     * the model will not update automatically and the `selectionChange` and `valueChange` events will not be fired.
     */
    reset() {
        this.value = NO_VALUE;
    }
    /**
     * @hidden
     */
    messageFor(key) {
        return this.localization.get(key);
    }
    /**
     * @hidden
     */
    clearValue(event) {
        event.stopImmediatePropagation();
        this.focus();
        this.change(NO_VALUE);
        if (this.filterable) {
            this.filterChange.emit('');
        }
        this.selectionService.resetSelection([]);
    }
    /**
     * @hidden
     */
    writeValue(value) {
        this.value = value;
    }
    /**
     * @hidden
     */
    registerOnChange(fn) {
        this.onChangeCallback = fn;
    }
    /**
     * @hidden
     */
    registerOnTouched(fn) {
        this.onTouchedCallback = fn;
    }
    /**
     * @hidden
     */
    setDisabledState(isDisabled) {
        this.disabled = isDisabled;
    }
    /**
     * Focuses a specific item of the AutoComplete based on a provided index.
     * If null or invalid index is provided the focus will be removed.
     */
    focusItemAt(index) {
        const isInRange = index >= 0 && index < this.data.length;
        if (isPresent(index) && isInRange && !this.disabledItemsService.isIndexDisabled(index)) {
            this.selectionService.focus(index);
        }
        else {
            this.selectionService.focus(-1);
        }
    }
    /**
     * Focuses the AutoComplete.
     */
    focus() {
        if (!this.disabled) {
            this.searchbar.focus();
        }
    }
    /**
     * Blurs the AutoComplete.
     */
    blur() {
        if (!this.disabled) {
            this.searchbar.blur();
        }
    }
    /**
     * @hidden
     */
    onResize() {
        if (this._open) {
            const popupWrapper = this.popupRef.popupElement;
            const { min, max } = this.width;
            popupWrapper.style.minWidth = min;
            popupWrapper.style.width = max;
        }
    }
    emitChange(value) {
        this.onChangeCallback(value);
        this.valueChange.emit(value);
    }
    verifySettings(newValue) {
        if (!isDevMode()) {
            return;
        }
        if (isPresent(newValue) && typeof newValue !== "string") {
            throw new Error("Expected value of type string. See https://www.telerik.com/kendo-angular-ui/components/dropdowns/autocomplete/value-binding/");
        }
    }
    search(text, startFrom = 0) {
        let index;
        if (text.length && this.dataService.itemsCount) {
            index = this.dataService.findIndex(this.findIndexPredicate(text), startFrom);
        }
        else {
            index = -1;
        }
        if (this.disabledItemsService.isIndexDisabled(index)) {
            if (index + 1 < this.dataService.itemsCount) {
                this.search(text, index + 1);
            }
            else {
                this.selectionService.focus(-1);
            }
        }
        else {
            this.selectionService.focus(index);
            if (this.suggest) {
                this.suggestedText = getter$1(this.dataService.itemAt(index), this.valueField);
            }
        }
    }
    navigate(index) {
        if (!this.isOpen) {
            return;
        }
        this.selectionService.focus(index);
    }
    /**
     * @hidden
     */
    handleNavigate(event) {
        const focused = isNaN(this.selectionService.focused) ? this.firstFocusableIndex(0) : this.selectionService.focused;
        if (this.disabled || this.readonly || isNaN(focused)) {
            return;
        }
        const action = this.navigationService.process({
            current: focused,
            max: this.dataService.itemsCount - 1,
            min: 0,
            originalEvent: event
        });
        if (action !== NavigationAction.Undefined &&
            action !== NavigationAction.Backspace &&
            action !== NavigationAction.Delete &&
            action !== NavigationAction.Home &&
            action !== NavigationAction.End &&
            action !== NavigationAction.Left &&
            action !== NavigationAction.Right &&
            ((action === NavigationAction.Enter && this.isOpen) || action !== NavigationAction.Enter)) {
            event.preventDefault();
        }
    }
    handleEnter(event) {
        const focused = this.selectionService.focused;
        let value;
        if (this.isOpen) {
            event.originalEvent.preventDefault();
        }
        if (focused >= 0) {
            value = getter$1(this.dataService.itemAt(focused), this.valueField);
        }
        else {
            const match = this.suggest && this.suggestedText && this.data.length &&
                getter$1(this.dataService.itemAt(0), this.valueField).toLowerCase() === this.searchbar.value.toLowerCase();
            if (this.isOpen && match) {
                value = this.suggestedText;
            }
            else {
                value = this.searchbar.value;
            }
        }
        this.change(value);
    }
    handleEscape() {
        this.togglePopup(false);
        this.selectionService.focused = -1;
        this.suggestedText = null;
    }
    /**
     * @hidden
     */
    searchBarChange(text) {
        const currentTextLength = isPresent(this.text) ? this.text.length : 0;
        this.backspacePressed = (text.length < currentTextLength) ? true : false;
        this.text = text;
        this.togglePopup(text.length > 0);
        if (!this.highlightFirst) {
            this.selectionService.focused = -1;
        }
        if (this.filterable) {
            this.filterChange.emit(text);
        }
        else if (this.highlightFirst) {
            this.search(text);
        }
    }
    /**
     * @hidden
     */
    handleFocus() {
        this.isFocused = true;
        if (hasObservers(this.onFocus)) {
            this._zone.run(() => {
                this.onFocus.emit();
            });
        }
    }
    /**
     * @hidden
     */
    handleBlur() {
        const focused = this.filterable ? this.selectionService.focused : -1;
        this.searchbar.input.nativeElement.scrollLeft = 0; // Firefox doesn't auto-scroll to the left on blur like other browsers
        let dataItem;
        let text;
        if (focused !== -1) {
            dataItem = this.dataService.itemAt(focused);
            text = getter$1(dataItem, this.valueField) || "";
        }
        else {
            text = this.searchbar.value;
        }
        const exactMatch = text === this.searchbar.value;
        const insensitiveMatch = text.toLowerCase() === this.searchbar.value.toLowerCase();
        if (!exactMatch && insensitiveMatch) {
            this.selectionService.resetSelection([]);
        }
        this.isFocused = false;
        const valueHasChanged = this.value !== this.text;
        const runInZone = hasObservers(this.onBlur) || hasObservers(this.close) || isUntouched(this.wrapper) || valueHasChanged;
        if (runInZone) {
            this._zone.run(() => {
                if (valueHasChanged) {
                    this.change(this.searchbar.value);
                }
                this.onBlur.emit();
                this.onTouchedCallback();
                this.togglePopup(false);
            });
        }
        else {
            this.togglePopup(false);
        }
    }
    /**
     * @hidden
     */
    pageChange(event) {
        const virtual = this.virtual;
        virtual.skip = event.skip;
    }
    change(value) {
        this.togglePopup(false);
        this.valueChangeSubject.next(value);
    }
    subscribeEvents() {
        if (!isDocumentAvailable()) {
            return;
        }
        this.valueChangeSubscription = this.valueChangeSubject
            .subscribe(value => {
            const hasChange = this.value !== value;
            this.value = value;
            this.text = value;
            // emit change after assigning `this.value` => allows the user to modify the component value on `valueChange`
            if (hasChange) {
                this.emitChange(value);
            }
        });
        this.changeSubscription = this.selectionService.onChange.subscribe(this.handleItemChange.bind(this));
        this.focusSubscription = this.selectionService.onFocus.subscribe(this.handleItemFocus.bind(this));
        this.navigationSubscription = merge(this.navigationService.up, this.navigationService.down).subscribe((event) => this.navigate(event.index));
        this.closeSubscription = this.navigationService.close.subscribe(() => this.togglePopup(false));
        this.enterSubscription = this.navigationService.enter.subscribe(this.handleEnter.bind(this));
        this.escSubscription = this.navigationService.esc.subscribe(this.handleEscape.bind(this));
    }
    subscribeTouchEvents() {
        if (!isDocumentAvailable() || !this.touchEnabled) {
            return;
        }
        this._zone.runOutsideAngular(() => 
        // Roll up AutoComplete on iOS when tapped outside
        this.touchstartDisposeHandler = this.renderer.listen(document, 'touchstart', (e) => {
            const target = e.target;
            if (this.isFocused && !inDropDown(this.hostElement, target, this.popupRef)) {
                this._zone.run(() => this.blur());
            }
        }));
    }
    unsubscribeEvents() {
        if (!isDocumentAvailable()) {
            return;
        }
        this.changeSubscription.unsubscribe();
        this.navigationSubscription.unsubscribe();
        this.closeSubscription.unsubscribe();
        this.enterSubscription.unsubscribe();
        this.escSubscription.unsubscribe();
        this.valueChangeSubscription.unsubscribe();
        this.focusSubscription.unsubscribe();
        if (this.touchstartDisposeHandler) {
            this.touchstartDisposeHandler();
        }
    }
    handleItemChange(event) {
        const index = event.indices.length ? event.indices[0] : undefined;
        this.selectionService.resetSelection([-1]);
        if (!isPresent(index)) {
            return;
        }
        let text = getter$1(this.dataService.itemAt(index), this.valueField);
        this.change(text);
    }
    handleItemFocus(_event) {
        const focused = this.selectionService.focused;
        const shouldSuggest = Boolean(this.suggest && this.data && this.data.length && focused >= 0);
        if (shouldSuggest) {
            this.suggestedText = getter$1(this.dataService.itemAt(focused), this.valueField);
        }
    }
    createPopup() {
        if (this.virtual) {
            this.virtual.skip = 0;
        }
        const horizontalAlign = this.direction === "rtl" ? "right" : "left";
        const anchorPosition = { horizontal: horizontalAlign, vertical: "bottom" };
        const popupPosition = { horizontal: horizontalAlign, vertical: "top" };
        this.popupRef = this.popupService.open({
            anchor: this.wrapper,
            animate: this.popupSettings.animate,
            appendTo: this.appendTo,
            content: this.popupTemplate,
            popupClass: this.listContainerClasses,
            positionMode: 'absolute',
            popupAlign: popupPosition,
            anchorAlign: anchorPosition
        });
        const popupWrapper = this.popupRef.popupElement;
        const { min, max } = this.width;
        popupWrapper.addEventListener('mousedown', this.popupMouseDownHandler);
        popupWrapper.style.minWidth = min;
        popupWrapper.style.width = max;
        popupWrapper.style.height = this.height;
        popupWrapper.setAttribute("dir", this.direction);
        this.popupRef.popupOpen.subscribe(() => {
            this.cdr.detectChanges();
            this.optionsList.scrollToItem(this.selectionService.focused);
            this.opened.emit();
        });
        this.popupRef.popupClose.subscribe(() => {
            this.closed.emit();
        });
        this.popupRef.popupAnchorViewportLeave.subscribe(() => this.togglePopup(false));
    }
    destroyPopup() {
        if (this.popupRef) {
            this.popupRef.popupElement
                .removeEventListener('mousedown', this.popupMouseDownHandler);
            this.popupRef.close();
            this.popupRef = null;
        }
    }
    _toggle(open) {
        this._open = open;
        this.destroyPopup();
        if (this._open) {
            this.createPopup();
        }
    }
    triggerPopupEvents(open) {
        const eventArgs = new PreventableEvent();
        if (open) {
            this.open.emit(eventArgs);
        }
        else {
            this.close.emit(eventArgs);
        }
        return eventArgs.isDefaultPrevented();
    }
    firstFocusableIndex(index) {
        const maxIndex = this.data.length - 1;
        if (this.disabledItemsService.isIndexDisabled(index)) {
            return (index < maxIndex) ? this.firstFocusableIndex(index + 1) : undefined;
        }
        else {
            return index;
        }
    }
    findIndexPredicate(text) {
        if (this.dataService.grouped) {
            return (item) => {
                let itemText = getter$1(item.value, this.valueField);
                itemText = !isPresent(itemText) ? "" : itemText.toString().toLowerCase();
                return itemText.startsWith(text.toLowerCase());
            };
        }
        else {
            return (item) => {
                let itemText = getter$1(item, this.valueField);
                itemText = !isPresent(itemText) ? "" : itemText.toString().toLowerCase();
                return itemText.startsWith(text.toLowerCase());
            };
        }
    }
    setComponentClasses() {
        if (this.size) {
            this.renderer.addClass(this.wrapper, getSizeClass('input', this.size));
        }
        if (this.rounded) {
            this.renderer.addClass(this.wrapper, getRoundedClass(this.rounded));
        }
        if (this.fillMode) {
            this.renderer.addClass(this.wrapper, getFillModeClass('input', this.fillMode));
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], AutoCompleteComponent.prototype, "highlightFirst", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], AutoCompleteComponent.prototype, "focusableId", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], AutoCompleteComponent.prototype, "data", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], AutoCompleteComponent.prototype, "value", null);
__decorate([
    Input(),
    __metadata("design:type", String)
], AutoCompleteComponent.prototype, "valueField", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], AutoCompleteComponent.prototype, "placeholder", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], AutoCompleteComponent.prototype, "popupSettings", null);
__decorate([
    Input(),
    __metadata("design:type", Number)
], AutoCompleteComponent.prototype, "listHeight", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], AutoCompleteComponent.prototype, "loading", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], AutoCompleteComponent.prototype, "clearButton", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], AutoCompleteComponent.prototype, "suggest", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], AutoCompleteComponent.prototype, "disabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Function])
], AutoCompleteComponent.prototype, "itemDisabled", null);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], AutoCompleteComponent.prototype, "readonly", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], AutoCompleteComponent.prototype, "tabindex", void 0);
__decorate([
    Input("tabIndex"),
    __metadata("design:type", Number),
    __metadata("design:paramtypes", [Number])
], AutoCompleteComponent.prototype, "tabIndex", null);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], AutoCompleteComponent.prototype, "filterable", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], AutoCompleteComponent.prototype, "virtual", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], AutoCompleteComponent.prototype, "size", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], AutoCompleteComponent.prototype, "rounded", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], AutoCompleteComponent.prototype, "fillMode", null);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], AutoCompleteComponent.prototype, "valueChange", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], AutoCompleteComponent.prototype, "filterChange", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], AutoCompleteComponent.prototype, "open", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], AutoCompleteComponent.prototype, "opened", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], AutoCompleteComponent.prototype, "close", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], AutoCompleteComponent.prototype, "closed", void 0);
__decorate([
    Output('focus'),
    __metadata("design:type", EventEmitter)
], AutoCompleteComponent.prototype, "onFocus", void 0);
__decorate([
    Output('blur'),
    __metadata("design:type", EventEmitter)
], AutoCompleteComponent.prototype, "onBlur", void 0);
__decorate([
    ContentChild(ItemTemplateDirective, { static: false }),
    __metadata("design:type", ItemTemplateDirective)
], AutoCompleteComponent.prototype, "template", void 0);
__decorate([
    ContentChild(HeaderTemplateDirective, { static: false }),
    __metadata("design:type", HeaderTemplateDirective)
], AutoCompleteComponent.prototype, "headerTemplate", void 0);
__decorate([
    ContentChild(FooterTemplateDirective, { static: false }),
    __metadata("design:type", FooterTemplateDirective)
], AutoCompleteComponent.prototype, "footerTemplate", void 0);
__decorate([
    ContentChild(NoDataTemplateDirective, { static: false }),
    __metadata("design:type", NoDataTemplateDirective)
], AutoCompleteComponent.prototype, "noDataTemplate", void 0);
__decorate([
    ContentChild(GroupTemplateDirective, { static: false }),
    __metadata("design:type", GroupTemplateDirective)
], AutoCompleteComponent.prototype, "groupTemplate", void 0);
__decorate([
    ContentChild(FixedGroupTemplateDirective, { static: false }),
    __metadata("design:type", FixedGroupTemplateDirective)
], AutoCompleteComponent.prototype, "fixedGroupTemplate", void 0);
__decorate([
    ViewChild('container', { read: ViewContainerRef, static: true }),
    __metadata("design:type", ViewContainerRef)
], AutoCompleteComponent.prototype, "container", void 0);
__decorate([
    ViewChild('popupTemplate', { static: true }),
    __metadata("design:type", TemplateRef)
], AutoCompleteComponent.prototype, "popupTemplate", void 0);
__decorate([
    ViewChild(SearchBarComponent, { static: true }),
    __metadata("design:type", SearchBarComponent)
], AutoCompleteComponent.prototype, "searchbar", void 0);
__decorate([
    ViewChild('optionsList', { static: false }),
    __metadata("design:type", ListComponent)
], AutoCompleteComponent.prototype, "optionsList", void 0);
__decorate([
    HostBinding('class.k-autocomplete'),
    HostBinding('class.k-input'),
    __metadata("design:type", Boolean)
], AutoCompleteComponent.prototype, "widgetClasses", void 0);
__decorate([
    HostBinding('class.k-disabled'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], AutoCompleteComponent.prototype, "isDisabled", null);
__decorate([
    HostBinding('class.k-loading'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], AutoCompleteComponent.prototype, "isLoading", null);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], AutoCompleteComponent.prototype, "dir", null);
AutoCompleteComponent = AutoCompleteComponent_1 = __decorate([
    Component({
        exportAs: 'kendoAutoComplete',
        providers: [
            AUTOCOMPLETE_VALUE_ACCESSOR,
            DataService,
            SelectionService,
            NavigationService,
            DisabledItemsService,
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.autocomplete'
            },
            {
                provide: FilterableComponent,
                useExisting: forwardRef(() => AutoCompleteComponent_1)
            },
            {
                provide: KendoInput,
                useExisting: forwardRef(() => AutoCompleteComponent_1)
            }
        ],
        selector: 'kendo-autocomplete',
        template: `
        <ng-container kendoAutoCompleteLocalizedMessages
            i18n-noDataText="kendo.autocomplete.noDataText|The text displayed in the popup when there are no items"
            noDataText="NO DATA FOUND"

            i18n-clearTitle="kendo.autocomplete.clearTitle|The title of the clear button"
            clearTitle="clear"
        >
        </ng-container>
        <kendo-searchbar #searchbar
            [role]="'combobox'"
            [id]="focusableId"
            [listId]="listBoxId"
            [activeDescendant]="activeDescendant"
            [noDataLabel]="noDataLabel"
            [userInput]="text"
            [suggestedText]="suggestion"
            [disabled]="disabled"
            [readonly]="readonly"
            [tabIndex]="tabIndex"
            [popupOpen]="isOpen"
            [placeholder]="placeholder"
            (onNavigate)="handleNavigate($event)"
            (valueChange)="searchBarChange($event)"
            (onBlur)="handleBlur()"
            (onFocus)="handleFocus()"
        ></kendo-searchbar>
        <span
            *ngIf="!loading && !readonly && (clearButton && text?.length)"
            class="k-clear-value"
            [style.visibility]="clearButtonVisiblity"
            [attr.title]="messageFor('clearTitle')"
            role="button"
            tabindex="-1"
            (click)="clearValue($event)"
            (mousedown)="$event.preventDefault()"
        >
            <span class="k-icon k-i-x"></span>
        </span>
        <span *ngIf="loading" class="k-icon k-i-loading"></span>
        <ng-template #popupTemplate>
            <!--header template-->
            <ng-template *ngIf="headerTemplate"
                [templateContext]="{
                    templateRef: headerTemplate.templateRef
                }">
            </ng-template>
            <!--list-->
            <kendo-list
                #optionsList
                [size]="size"
                [rounded]="rounded"
                [id]="listBoxId"
                [optionPrefix]="optionPrefix"
                [data]="data"
                [textField]="valueField"
                [valueField]="valueField"
                [template]="template"
                [groupTemplate]="groupTemplate"
                [fixedGroupTemplate]="fixedGroupTemplate"
                [height]="listHeight"
                [show]="isOpen"
                [virtual]="virtual"
                (pageChange)="pageChange($event)"
            >
            </kendo-list>
            <!--no-data template-->
            <div class="k-no-data" *ngIf="data.length === 0">
                <ng-template [ngIf]="noDataTemplate"
                    [templateContext]="{
                        templateRef: noDataTemplate?.templateRef
                    }">
                </ng-template>
                <ng-template [ngIf]="!noDataTemplate">
                    <div>{{ messageFor('noDataText') }}</div>
                </ng-template>
            </div>
            <!--footer template-->
            <ng-template *ngIf="footerTemplate"
                [templateContext]="{
                    templateRef: footerTemplate.templateRef
                }">
            </ng-template>
        </ng-template>
        <ng-template [ngIf]="isOpen">
            <kendo-resize-sensor (resize)="onResize()"></kendo-resize-sensor>
        </ng-template>
        <ng-container #container></ng-container>
  `
    }),
    __param(10, Optional()), __param(10, Inject(TOUCH_ENABLED)),
    __metadata("design:paramtypes", [LocalizationService,
        DataService,
        PopupService,
        SelectionService,
        NavigationService,
        DisabledItemsService,
        NgZone,
        ChangeDetectorRef,
        Renderer2,
        ElementRef, Boolean])
], AutoCompleteComponent);

/* tslint:disable:variable-name */
/**
 * @hidden
 */
const MultiselectMessages = {
    'array': 'Expected values of array type. See http://www.telerik.com/kendo-angular-ui/components/dropdowns/multiselect/#value-selection',
    'object': 'Expected values of Object type. See http://www.telerik.com/kendo-angular-ui/components/dropdowns/multiselect/#value-selection',
    'primitive': 'Expected values of primitive type. See http://www.telerik.com/kendo-angular-ui/components/dropdowns/multiselect/#value-selection',
    'textAndValue': 'Expected textField and valueField options to be set. See http://www.telerik.com/kendo-angular-ui/components/dropdowns/multiselect/#toc-bind-to-arrays-of-complex-data'
};
/**
 * @hidden
 */
const MultiSelectTreeMessages = {
    'array': 'Expected values of array type. See http://www.telerik.com/kendo-angular-ui/components/dropdowns/multiselecttree/#value-selection',
    'primitive': 'Expected values of primitive type. See http://www.telerik.com/kendo-angular-ui/components/dropdowns/multiselecttree/value-binding/#toc-primitive-values',
    'object': 'Expected values of type Object. See http://www.telerik.com/kendo-angular-ui/components/dropdowns/multiselecttree/value-binding/#toc-object-values',
    'dataItems': 'Expected dataItems of type Object[] to be set. See https://www.telerik.com/kendo-angular-ui/components/dropdowns/api/MultiSelectTreeComponent/#toc-dataitems',
    'dataItemsLength': 'Expected dataItems length to match the number of provided values. See https://www.telerik.com/kendo-angular-ui/components/dropdowns/api/MultiSelectTreeComponent/#toc-dataitems',
    'textAndValue': 'Expected textField and valueField options to be set. See https://www.telerik.com/kendo-angular-ui/components/dropdowns/multiselecttree/value-binding',
    'valueDepth': 'Expected valueDepth of type number[] to be set. See https://www.telerik.com/kendo-angular-ui-develop/components/dropdowns/api/MultiSelectTreeComponent/#toc-valuedepth',
    'valueDepthLength': 'Expected valueDepth length to match the number of provided values. See https://www.telerik.com/kendo-angular-ui-develop/components/dropdowns/api/MultiSelectTreeComponent/#toc-valuedepth'
};
/**
 * @hidden
 */
const ComboBoxMessages = {
    'object': 'Expected value of type Object. See http://www.telerik.com/kendo-angular-ui/components/dropdowns/combobox/#toc-value-selection',
    'primitive': 'Expected value of primitive type. See http://www.telerik.com/kendo-angular-ui/components/dropdowns/combobox/#toc-value-selection',
    'textAndValue': 'Expected textField and valueField options to be set. See http://www.telerik.com/kendo-angular-ui/components/dropdowns/combobox/#toc-bind-to-arrays-of-complex-data',
    'noItemHeight': 'Expected virtual.itemHeight of type number.'
};
/**
 * @hidden
 */
const MultiColumnComboBoxMessages = {
    'data': 'Provided data must consist only of objects. See https://www.telerik.com/kendo-angular-ui/components/dropdowns/multicolumncombobox/data-binding/',
    'textAndValue': 'Expected textField and valueField options to be set. See https://www.telerik.com/kendo-angular-ui/components/dropdowns/multicolumncombobox/data-binding/#toc-fields-configuration'
};
/**
 * @hidden
 */
const DropDownListMessages = {
    'defaultItem': 'defaultItem and data items must be of same type. See http://www.telerik.com/kendo-angular-ui/components/dropdowns/api/DropDownListComponent/#toc-defaultitem',
    'object': 'Expected value of type Object. See http://www.telerik.com/kendo-angular-ui/components/dropdowns/dropdownlist/#toc-value-selection',
    'primitive': 'Expected value of primitive type. See http://www.telerik.com/kendo-angular-ui/components/dropdowns/dropdownlist/#toc-value-selection',
    'textAndValue': 'Expected textField and valueField options to be set. See http://www.telerik.com/kendo-angular-ui/components/dropdowns/dropdownlist/#toc-bind-to-arrays-of-complex-data'
};
/**
 * @hidden
 */
const DropDownTreeMessages = {
    'primitive': 'Expected value of primitive type. See http://www.telerik.com/kendo-angular-ui/components/dropdowns/dropdowntree/value-binding/#toc-primitive-values',
    'object': 'Expected value of type Object. See http://www.telerik.com/kendo-angular-ui/components/dropdowns/dropdowntree/value-binding/#toc-object-values',
    'dataItem': 'Expected dataItem of type Object to be set. See https://www.telerik.com/kendo-angular-ui/components/dropdowns/api/DropDownTreeComponent/#toc-dataitem',
    'textAndValue': 'Expected textField and valueField options to be set. See https://www.telerik.com/kendo-angular-ui/components/dropdowns/dropdowntree/value-binding',
    'valueDepth': 'Expected valueDepth to be set. See https://www.telerik.com/kendo-angular-ui-develop/components/dropdowns/api/DropDownTreeComponent/#toc-valuedepth'
};

/* tslint:disable:member-ordering */
var ComboBoxComponent_1;
/**
 * @hidden
 */
const COMBOBOX_VALUE_ACCESSOR = {
    multi: true,
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ComboBoxComponent)
};
/**
 * Represents the [Kendo UI ComboBox component for Angular]({% slug overview_combobox %}).
 *
 * @example
 * ```ts
 * _@Component({
 * selector: 'my-app',
 * template: `
 *  <kendo-combobox [data]="listItems">
 *  </kendo-combobox>
 * `
 * })
 * class AppComponent {
 *   public listItems: Array<string> = ["Item 1", "Item 2", "Item 3", "Item 4"];
 * }
 * ```
 */
let ComboBoxComponent = ComboBoxComponent_1 = class ComboBoxComponent {
    constructor(wrapper, localization, popupService, selectionService, navigationService, disabledItemsService, dataService, zone, cdr, renderer, touchEnabled$$1) {
        this.wrapper = wrapper;
        this.localization = localization;
        this.popupService = popupService;
        this.selectionService = selectionService;
        this.navigationService = navigationService;
        this.disabledItemsService = disabledItemsService;
        this.dataService = dataService;
        this.zone = zone;
        this.cdr = cdr;
        this.renderer = renderer;
        this.touchEnabled = touchEnabled$$1;
        this.selected = [];
        /**
         * @hidden
         */
        this.focusableId = `k-${guid$1()}`;
        /**
         * Specifies whether the ComboBox allows user-defined values that are not present in the dataset
         * ([more information and examples]({% slug custom_values_combobox %})).
         * Defaults to `false`.
         */
        this.allowCustom = false;
        /**
         * A user-defined callback which returns normalized custom values.
         * Typically used when the data items are different from type `string`.
         * @param { Any } value - The custom value defined by the user.
         * @returns { Any }
         *
         * @example
         * ```ts
         * import { map } from 'rxjs/operators';
         *
         * _@Component({
         * selector: 'my-app',
         * template: `
         *   <kendo-combobox
         *       [allowCustom]="true"
         *       [data]="listItems"
         *       textField="text"
         *       valueField="value"
         *       [valueNormalizer]="valueNormalizer"
         *       (valueChange)="onValueChange($event)"
         *   >
         *   </kendo-combobox>
         * `
         * })
         *
         * class AppComponent {
         *   public listItems: Array<{ text: string, value: number }> = [
         *       { text: "Small", value: 1 },
         *       { text: "Medium", value: 2 },
         *       { text: "Large", value: 3 }
         *   ];
         *
         *   public onValueChange(value) {
         *       console.log("valueChange : ", value);
         *   }
         *
         *   public valueNormalizer = (text$: Observable<string>) => text$.pipe(map((text: string) => {
         *      return { ProductID: null, ProductName: text };
         *   }));
         *
         * }
         * ```
         */
        this.valueNormalizer = (text) => text.pipe(map((userInput) => userInput));
        /**
         * The hint that is displayed when the component is empty.
         *
         */
        this.placeholder = "";
        /**
         * Sets the height of the suggestions list. By default, `listHeight` is 200px.
         *
         * > The `listHeight` property affects only the list of suggestions and not the whole popup container.
         * > To set the height of the popup container, use `popupSettings.height`.
         */
        this.listHeight = 200;
        /**
         * Enables the auto-completion of the text based on the first data item.
         */
        this.suggest = false;
        /**
         * If set to `true`, renders a button on hovering over the component.
         * Clicking this button resets the value of the component to `undefined` and triggers the `change` event.
         */
        this.clearButton = true;
        /**
         * Sets the disabled state of the component.
         */
        this.disabled = false;
        /**
         * Sets the read-only state of the component.
         */
        this.readonly = false;
        /**
         * Specifies the [`tabindex`](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex) of the component.
         */
        this.tabindex = 0;
        /**
         * Enables the [filtering]({% slug filtering_combobox %}) functionality.
         * If set to `true`, the component emits the `filterChange` event.
         */
        this.filterable = false;
        /**
         * Fires each time the value is changed&mdash;
         * when the component is blurred or the value is cleared through the **Clear** button
         * ([see example]({% slug overview_combobox %}#toc-events)).
         * When the value of the component is programmatically changed to `ngModel` or `formControl`
         * through its API or form binding, the `valueChange` event is not triggered because it
         * might cause a mix-up with the built-in `valueChange` mechanisms of the `ngModel` or `formControl` bindings.
         */
        this.valueChange = new EventEmitter();
        /**
         * Fires each time an item selection is changed
         * ([see example]({% slug overview_combobox %}#toc-events)).
         */
        this.selectionChange = new EventEmitter();
        /**
         * Fires each time the user types in the input field.
         * You can filter the source based on the passed filtration value
         * ([see example]({% slug overview_combobox %}#toc-events)).
         */
        this.filterChange = new EventEmitter();
        /**
         * Fires each time the popup is about to open.
         * This event is preventable. If you cancel it, the popup will remain closed.
         */
        this.open = new EventEmitter();
        /**
         * Fires after the popup has been opened.
         */
        this.opened = new EventEmitter();
        /**
         * Fires each time the popup is about to close.
         * This event is preventable. If you cancel it, the popup will remain open.
         */
        this.close = new EventEmitter();
        /**
         * Fires after the popup has been closed.
         */
        this.closed = new EventEmitter();
        /**
         * Fires each time the user focuses the ComboBox.
         */
        this.onFocus = new EventEmitter();
        /**
         * Fires each time the ComboBox gets blurred.
         */
        this.onBlur = new EventEmitter();
        this.widgetClasses = true;
        this._isFocused = false;
        this.listBoxId = guid$1();
        this.optionPrefix = guid$1();
        this.onChangeCallback = (_) => { };
        this.onTouchedCallback = (_) => { };
        /**
         * Used for the default virtualization settings config.
         */
        this.defaultVirtualItemHeight = 28;
        /**
         * Used for the default virtualization settings config.
         */
        this.defaultVirtualPageSize = 50;
        this._filtering = false;
        this._text = '';
        this.filterText = '';
        this._open = false;
        this._popupSettings = { animate: true };
        this.popupMouseDownHandler = (event) => event.preventDefault();
        this.customValueSubject = new Subject();
        this.valueSubject = new Subject();
        this.clearValueSubject = new Subject();
        this.subs = new Subscription();
        this._size = 'medium';
        this._rounded = 'medium';
        this._fillMode = 'solid';
        validatePackage(packageMetadata);
        this.direction = localization.rtl ? 'rtl' : 'ltr';
        this.data = [];
    }
    set text(text) {
        this._text = isPresent(text) ? text.toString() : "";
    }
    get text() {
        return this._text;
    }
    /**
     * @hidden
     */
    togglePopup(open) {
        const isDisabled = this.disabled || this.readonly;
        const sameState = this.isOpen === open;
        if (isDisabled || sameState) {
            return;
        }
        const isDefaultPrevented = this.triggerPopupEvents(open);
        if (!isDefaultPrevented) {
            this._toggle(open);
        }
    }
    get activeDescendant() {
        if (!this.isOpen || !isPresent(this.selectionService.focused) || this.selectionService.focused === -1) {
            return null;
        }
        const dataItem = this.dataService.itemAt(this.selectionService.focused);
        return this.optionPrefix + "-" + (dataItem ? getter$1(dataItem, this.valueField) : "");
    }
    get noDataLabel() {
        if (this.data.length === 0) {
            return this.messageFor('noDataText');
        }
    }
    get appendTo() {
        const { appendTo } = this.popupSettings;
        if (!appendTo || appendTo === 'root') {
            return undefined;
        }
        return appendTo === 'component' ? this.container : appendTo;
    }
    /**
     * Sets the data of the ComboBox.
     *
     * > The data has to be provided in an array-like list.
     */
    set data(data) {
        this.dataService.data = data || [];
        if (this.virtual) {
            this.virtual.skip = 0;
        }
        this.setState();
        if (this._filtering) {
            const queryAndDataPresent = this.text.length > 0 && this.dataService.itemsCount > 0;
            const index = queryAndDataPresent ? this.firstFocusableIndex(0) : -1;
            this.selectionService.focused = index;
        }
        if (this.suggest && this.dataService.itemsCount && this.text) {
            this.suggestedText = getter$1(this.dataService.itemAt(0), this.textField);
        }
    }
    get data() {
        const virtual = this.virtual;
        if (virtual) {
            const start = virtual.skip || 0;
            const end = start + virtual.pageSize;
            // Use length instead of itemsCount because of the grouping.
            virtual.total = this.dataService.data.length;
            return this.dataService.data.slice(start, end);
        }
        return this.dataService.data;
    }
    /**
     * Sets the value of the ComboBox.
     * It can either be of the primitive (string, numbers) or of the complex (objects) type.
     * To define the type, use the `valuePrimitive` option.
     *
     * > All selected values which are not present in the dataset are considered custom values.
     * > When the `Enter` key is pressed or the component loses focus, custom values get dismissed unless `allowCustom` is set to `true`.
     */
    set value(newValue) {
        this._value = newValue;
        this.setState();
        this.cdr.markForCheck();
    }
    get value() {
        return this._value;
    }
    /**
     * Specifies the type of the selected value.
     * If set to `true`, the selected value has to be of the primitive type
     * ([more information and example]({% slug valuebinding_combobox %}#toc-primitive-values-from-object-fields)).
     */
    set valuePrimitive(isPrimitive) {
        this._valuePrimitive = isPrimitive;
    }
    get valuePrimitive() {
        if (!isPresent(this._valuePrimitive)) {
            return !isPresent(this.valueField);
        }
        return this._valuePrimitive;
    }
    /**
     * Configures the popup of the ComboBox.
     *
     * The available options are:
     * - `animate: Boolean`&mdash;Controls the popup animation. By default, the open and close animations are enabled.
     * - `width: Number | String`&mdash;Sets the width of the popup container. By default, the width of the host element is used. If set to `auto`, the component automatically adjusts the width of the popup and no item labels are wrapped. The `auto` mode is not supported when virtual scrolling is enabled.
     * - `height: Number`&mdash;Sets the height of the popup container.
     * - `popupClass: String`&mdash;Specifies a list of CSS classes that are used to style the popup.
     * - `appendTo: "root" | "component" | ViewContainerRef`&mdash;Specifies the component to which the popup will be appended.
     */
    set popupSettings(settings) {
        this._popupSettings = Object.assign({ animate: true }, settings);
    }
    get popupSettings() {
        return this._popupSettings;
    }
    /**
     * Defines a Boolean function that is executed for each data item in the component
     * ([see examples]({% slug disableditems_combobox %})). Determines whether the item will be disabled.
     */
    set itemDisabled(fn) {
        if (typeof fn !== 'function') {
            throw new Error(`itemDisabled must be a function, but received ${JSON.stringify(fn)}.`);
        }
        this.disabledItemsService.itemDisabled = fn;
    }
    /**
     * @hidden
     */
    set tabIndex(tabIndex) {
        this.tabindex = tabIndex;
    }
    get tabIndex() {
        return this.tabindex;
    }
    /**
     * Enables the [virtualization]({% slug virtualization_combobox %}) functionality.
     */
    set virtual(settings) {
        this._virtualSettings = normalizeVirtualizationSettings(settings, {
            itemHeight: this.defaultVirtualItemHeight,
            pageSize: this.defaultVirtualPageSize
        });
    }
    get virtual() {
        return this._virtualSettings;
    }
    /**
     * Sets the size of the component.
     *
     * The possible values are:
     * * `'small'`
     * * `'medium'` (default)
     * * `'large'`
     * * `null`
     *
     */
    set size(size) {
        this.renderer.removeClass(this.wrapper.nativeElement, getSizeClass('input', this.size));
        if (size) {
            this.renderer.addClass(this.wrapper.nativeElement, getSizeClass('input', size));
        }
        this._size = size;
    }
    get size() {
        return this._size;
    }
    /**
     * Sets the border radius of the component.
     *
     * The possible values are:
     * * `'small'`
     * * `'medium'` (default)
     * * `'large'`
     * * `'full'`
     * * `null`
     *
     */
    set rounded(rounded) {
        this.renderer.removeClass(this.wrapper.nativeElement, getRoundedClass(this.rounded));
        if (rounded) {
            this.renderer.addClass(this.wrapper.nativeElement, getRoundedClass(rounded));
        }
        this._rounded = rounded;
    }
    get rounded() {
        return this._rounded;
    }
    /**
     * Sets the fillMode of the component.
     *
     * The possible values are:
     * * `'flat'`
     * * `'solid'` (default)
     * * `'outline'`
     * * `null`
     *
     */
    set fillMode(fillMode) {
        this.renderer.removeClass(this.wrapper.nativeElement, getFillModeClass('input', this.fillMode));
        if (fillMode) {
            this.renderer.addClass(this.wrapper.nativeElement, getFillModeClass('input', fillMode));
        }
        this._fillMode = fillMode;
    }
    get fillMode() {
        return this._fillMode;
    }
    get isDisabled() {
        return this.disabled;
    }
    get isLoading() {
        return this.loading;
    }
    get dir() {
        return this.direction;
    }
    get isFocused() {
        return this._isFocused;
    }
    set isFocused(value) {
        this.renderer[value ? 'addClass' : 'removeClass'](this.wrapper.nativeElement, "k-focus");
        this._isFocused = value;
    }
    get clearButtonVisiblity() {
        if (this.touchEnabled) {
            return 'visible';
        }
    }
    get popupWidth() {
        let wrapperOffsetWidth = 0;
        if (isDocumentAvailable()) {
            wrapperOffsetWidth = this.wrapper.nativeElement.offsetWidth;
        }
        const width = this.popupSettings.width || wrapperOffsetWidth;
        const minWidth = isNaN(wrapperOffsetWidth) ? wrapperOffsetWidth : `${wrapperOffsetWidth}px`;
        const maxWidth = isNaN(width) ? width : `${width}px`;
        return { min: minWidth, max: maxWidth };
    }
    get popupHeight() {
        const popupHeight = this.popupSettings.height;
        return isPresent(popupHeight) ? `${popupHeight}px` : 'auto';
    }
    ngOnInit() {
        this.renderer.removeAttribute(this.wrapper.nativeElement, 'tabindex');
        this.attachStreams();
        this.createValueStream();
        this.subscribeTouchEvents();
        this.attachSelectClickHandler();
        this.setComponentClasses();
    }
    createValueStream() {
        const valueStream = this.valueSubject.pipe(filter((candidate) => {
            const current = getter$1(this.value, this.valueField);
            const newValue = getter$1(candidate, this.valueField);
            let newText = getter$1(candidate, this.textField);
            if (!isPresent(this.value) && !isPresent(newValue)) {
                return false;
            }
            if (isPresent(newText)) {
                newText = newText.toString();
            }
            if (current === newValue && this.text === newText) {
                this.clearFilter();
                return false;
            }
            else {
                return true;
            }
        }), map((candidate) => {
            const newValue = getter$1(candidate, this.valueField);
            const newText = getter$1(candidate, this.textField);
            return {
                dataItem: candidate,
                text: newText,
                value: this.valuePrimitive ? newValue : candidate
            };
        }));
        const customValueStreams = partition(() => this.allowCustom)(this.customValueSubject.pipe(throttleTime(300)));
        const allowCustomValueStream = customValueStreams[0].pipe(tap(() => {
            this.loading = true;
            this.disabled = true;
            this.cdr.detectChanges();
        }), filter(() => {
            const hasChange = this.text !== getter$1(this.value, this.valueField);
            this.loading = hasChange;
            this.disabled = hasChange;
            if (!hasChange) {
                this.clearFilter();
            }
            return hasChange;
        }), this.valueNormalizer, map((normalizedValue) => {
            return {
                custom: true,
                dataItem: normalizedValue,
                text: this.text,
                value: normalizedValue
            };
        }));
        const disableCustomValueStream = customValueStreams[1].pipe(map(() => {
            return {
                custom: true,
                dataItem: undefined,
                text: undefined,
                value: undefined
            };
        }));
        const clearValueStream = this.clearValueSubject.pipe(map(() => ({
            dataItem: undefined,
            text: undefined,
            value: undefined
        })));
        if (this.valueSubscription) {
            this.valueSubscription.unsubscribe();
        }
        const merged = merge(valueStream, allowCustomValueStream, disableCustomValueStream, clearValueStream);
        this.valueSubscription = merged.pipe(catchError(() => {
            const selectionChanged = getter$1(this.dataItem, this.valueField) !== undefined;
            this.dataItem = undefined;
            this.value = undefined;
            this.text = undefined;
            this.loading = false;
            this.disabled = false;
            if (selectionChanged) {
                this.selectionChange.emit(undefined);
            }
            this.emitValueChange();
            this.createValueStream();
            return of(null);
        }))
            .subscribe((state) => {
            const selectionChanged = getter$1(this.dataItem, this.valueField) !== getter$1(state.dataItem, this.valueField);
            this.dataItem = state.dataItem;
            this.value = state.value;
            this.text = state.text;
            this.loading = false;
            this.disabled = false;
            this.clearFilter();
            if (state.custom) {
                this.selectionService.focused = -1;
            }
            if (selectionChanged) {
                const selectionArgs = state.custom ? undefined : this.dataItem;
                this.selectionChange.emit(selectionArgs);
            }
            this.emitValueChange();
        });
    }
    attachStreams() {
        if (!isDocumentAvailable()) {
            return;
        }
        this.subs.add(this.localization
            .changes.subscribe(({ rtl }) => {
            this.direction = rtl ? 'rtl' : 'ltr';
            this.cdr.detectChanges();
        }));
        this.subs.add(merge(this.navigationService.up, this.navigationService.down, this.navigationService.home, this.navigationService.end)
            .pipe(filter((event) => isPresent(event.index)))
            .subscribe((event) => this.navigate(event.index)));
        this.subs.add(this.navigationService.open.subscribe(this.handleNavigationOpen.bind(this)));
        this.subs.add(this.navigationService.close.subscribe(() => this.togglePopup(false)));
        this.subs.add(this.navigationService.esc.subscribe(this.handleEscape.bind(this)));
        this.subs.add(this.navigationService.enter.pipe(tap((event) => {
            if (this.isOpen) {
                event.originalEvent.preventDefault();
            }
        }))
            .subscribe(this.handleEnter.bind(this)));
        this.subs.add(merge(this.selectionService.onChange, this.selectionService.onSelect.pipe(filter(_ => !this.isOpen)))
            .pipe(tap(_ => {
            this._filtering = false;
            this.togglePopup(false);
        }), map((event) => this.dataService.itemAt(event.indices[0])))
            .subscribe(dataItem => {
            this.change(dataItem);
        }));
        this.subs.add(this.selectionService.onSelect.pipe(filter(_ => this.isOpen), tap(_ => this._filtering = false), map((event) => this.dataService.itemAt(event.indices[0])))
            .subscribe(dataItem => {
            const selectionChanged = getter$1(dataItem, this.valueField) !== getter$1(this.dataItem, this.valueField);
            this.updateState({ dataItem });
            if (selectionChanged) {
                this.selectionChange.emit(dataItem);
            }
        }));
    }
    ngOnDestroy() {
        this.destroyPopup();
        this.subs.unsubscribe();
        if (isPresent(this.valueSubscription)) {
            this.valueSubscription.unsubscribe();
        }
        if (this.touchstartDisposeHandler) {
            this.touchstartDisposeHandler();
        }
        if (this.selectClickDisposeHandler) {
            this.selectClickDisposeHandler();
        }
    }
    ngOnChanges(changes) {
        const virtual = this.virtual;
        const requestInitialData = virtual && changes.data && changes.data.isFirstChange();
        if (requestInitialData) {
            this.pageChange({ skip: 0, take: virtual.pageSize });
        }
        if (isChanged('valueNormalizer', changes)) {
            this.createValueStream();
        }
        if (anyChanged(['textField', 'valueField', 'valuePrimitive'], changes, false)) {
            this.setState();
        }
    }
    ngAfterContentChecked() {
        this.verifySettings();
    }
    /**
     * Focuses a specific item of the ComboBox based on a provided index.
     * If null or invalid index is provided the focus will be removed.
     */
    focusItemAt(index) {
        const isInRange = index >= 0 && index < this.data.length;
        if (isPresent(index) && isInRange && !this.disabledItemsService.isIndexDisabled(index)) {
            this.selectionService.focus(index);
        }
        else {
            this.selectionService.focus(-1);
        }
    }
    /**
     * Focuses the ComboBox.
     */
    focus() {
        if (!this.disabled) {
            this.searchbar.focus();
        }
    }
    /**
     * Blurs the ComboBox.
     */
    blur() {
        if (!this.disabled) {
            this.searchbar.blur();
        }
    }
    /**
     * Toggles the visibility of the popup. If you use the `toggle` method to open or close the popup,
     * the `open` and `close` events will not be fired.
     *
     * @param open - The state of the popup.
     */
    toggle(open) {
        Promise.resolve(null).then(() => {
            const shouldOpen = isPresent(open) ? open : !this._open;
            this._toggle(shouldOpen);
            this.cdr.markForCheck();
        });
    }
    /**
     * Returns the current open state of the popup.
     */
    get isOpen() {
        return this._open;
    }
    /**
     * Resets the value of the ComboBox.
     * If you use the `reset` method to clear the value of the component,
     * the model will not update automatically and the `selectionChange` and `valueChange` events will not be fired.
     */
    reset() {
        this.value = undefined;
        this.clearState();
        this.resetSelection();
    }
    /**
     * @hidden
     *
     * Used by the TextBoxContainer to determine if the floating label
     * should be rendered in the input when the component is not focused.
     */
    isEmpty() {
        const textEmpty = !isPresent(this.text) || isEmptyString(this.text);
        const valueEmpty = !isPresent(this.value) || isEmptyString(this.value);
        return textEmpty && valueEmpty;
    }
    /**
     * @hidden
     */
    messageFor(key) {
        return this.localization.get(key);
    }
    /**
     * @hidden
     */
    clearValue(event) {
        event.stopImmediatePropagation();
        this.focus();
        this._filtering = true;
        this._previousDataItem = undefined;
        this.selectionService.resetSelection([]);
        this.clearValueSubject.next();
        this._filtering = false;
    }
    /**
     * @hidden
     */
    writeValue(value) {
        this.value = value === null ? undefined : value;
    }
    /**
     * @hidden
     */
    registerOnChange(fn) {
        this.onChangeCallback = fn;
    }
    /**
     * @hidden
     */
    registerOnTouched(fn) {
        this.onTouchedCallback = fn;
    }
    /**
     * @hidden
     */
    setDisabledState(isDisabled) {
        this.disabled = isDisabled;
    }
    /**
     * @hidden
     */
    get buttonClasses() {
        return this.loading ? 'k-i-loading' : this.iconClass || 'k-i-arrow-s';
    }
    /**
     * @hidden
     */
    get selectButtonClasses() {
        return `${this.size ? getSizeClass('button', this.size) : ''} ${this.fillMode ? 'k-button-' + this.fillMode : ''} ${this.fillMode ? 'k-button-' + this.fillMode + '-base' : ''}
        `;
    }
    /**
     * @hidden
     */
    onResize() {
        if (this.isOpen) {
            const popupWrapper = this.popupRef.popupElement;
            const { min, max } = this.popupWidth;
            popupWrapper.style.minWidth = min;
            popupWrapper.style.width = max;
        }
    }
    verifySettings() {
        if (!isDevMode()) {
            return;
        }
        if (this.valuePrimitive === true && isPresent(this.value) && typeof this.value === "object") {
            throw new Error(ComboBoxMessages.primitive);
        }
        if (this.valuePrimitive === false && isPresent(this.value) && typeof this.value !== "object") {
            throw new Error(ComboBoxMessages.object);
        }
        const valueOrText = !isPresent(this.valueField) !== !isPresent(this.textField);
        if (valueOrText) {
            throw new Error(ComboBoxMessages.textAndValue);
        }
        if (this.virtual && isNaN(this.virtual.itemHeight)) {
            throw new Error(ComboBoxMessages.noItemHeight);
        }
    }
    setState() {
        // Filtering in process, do nothing.
        if (this._filtering) {
            return;
        }
        const value = this.value;
        const valueField = this.valueField;
        const resolved = this.findDataItem({ valueField, value });
        if (isPresent(resolved.index) && resolved.index !== -1) {
            this.updateState({ dataItem: resolved.dataItem, confirm: true });
            this.resetSelection(resolved.index);
        }
        else if (isPresent(value) && this.allowCustom) {
            this.updateState({ dataItem: value });
            this.resetSelection(-1);
        }
        else if (this._previousDataItem && this.value) {
            this.updateState({ dataItem: this._previousDataItem });
            this.resetSelection();
        }
        else {
            this.clearState();
            this.resetSelection(-1);
        }
    }
    updateState({ dataItem, confirm = false }) {
        this.dataItem = dataItem;
        this.text = getter$1(dataItem, this.textField);
        if (confirm) {
            this._previousDataItem = dataItem;
        }
    }
    clearState() {
        this.text = undefined;
        this.dataItem = undefined;
    }
    resetSelection(index) {
        const clear = !isPresent(index) || index < 0;
        this.selectionService.resetSelection(clear ? [] : [index]);
        this.selectionService.focused = index;
    }
    firstFocusableIndex(index) {
        const maxIndex = this.data.length - 1;
        if (this.disabledItemsService.isIndexDisabled(index)) {
            return (index < maxIndex) ? this.firstFocusableIndex(index + 1) : undefined;
        }
        else {
            return index;
        }
    }
    findIndexPredicate(text) {
        if (this.dataService.grouped) {
            return (item) => {
                let itemText = getter$1(item.value, this.textField);
                itemText = !isPresent(itemText) ? "" : itemText.toString().toLowerCase();
                return itemText.startsWith(text.toLowerCase());
            };
        }
        else {
            return (item) => {
                let itemText = getter$1(item, this.textField);
                itemText = !isPresent(itemText) ? "" : itemText.toString().toLowerCase();
                return itemText.startsWith(text.toLowerCase());
            };
        }
    }
    findDataItem({ valueField, value }) {
        const result = {
            dataItem: null,
            index: -1
        };
        const comparer = (element) => {
            const dataItem = this.dataService.grouped ? element.value : element;
            return getter$1(dataItem, valueField) === getter$1(value, valueField);
        };
        const index = this.dataService.findIndex(comparer);
        result.dataItem = this.dataService.itemAt(index);
        result.index = index;
        return result;
    }
    search(text, startFrom = 0) {
        let index;
        if (text.length && this.dataService.itemsCount) {
            index = this.dataService.findIndex(this.findIndexPredicate(text), startFrom);
        }
        else {
            index = -1;
        }
        if (this.disabledItemsService.isIndexDisabled(index)) {
            if (index + 1 < this.dataService.itemsCount) {
                this.search(text, index + 1);
            }
            else {
                this.selectionService.focus(-1);
            }
        }
        else {
            this.selectionService.focus(index);
            if (this.suggest) {
                this.suggestedText = getter$1(this.dataService.itemAt(index), this.textField);
            }
        }
    }
    /**
     * @hidden
     */
    getSuggestion() {
        const hasSelected = !!this.selectionService.selected.length;
        const shouldSuggest = this.suggest && !this.backspacePressed && this.suggestedText && this.text;
        if (!hasSelected && shouldSuggest && this.suggestedText.toLowerCase().startsWith(this.text.toLowerCase())) {
            return this.suggestedText;
        }
        else {
            this.suggestedText = undefined;
        }
    }
    navigate(index) {
        if (this.dataService.itemsCount === 0) {
            return;
        }
        this.text = getter$1(this.dataService.itemAt(index), this.textField);
        this.selectionService.select(index);
    }
    /**
     * @hidden
     */
    handleNavigate(event) {
        const hasSelected = isPresent(this.selectionService.selected[0]);
        const focused = isNaN(this.selectionService.focused) ? this.firstFocusableIndex(0) : this.selectionService.focused;
        let offset = 0;
        if (this.disabled || this.readonly) {
            return;
        }
        if (event.keyCode === Keys.Home || event.keyCode === Keys.End) {
            return;
        }
        if (!hasSelected) {
            if (event.keyCode === Keys.ArrowDown) {
                offset = -1;
            }
            else if (event.keyCode === Keys.ArrowUp) {
                offset = 1;
            }
        }
        const action = this.navigationService.process({
            current: offset + focused,
            max: this.dataService.itemsCount - 1,
            min: 0,
            originalEvent: event
        });
        if (action !== NavigationAction.Undefined &&
            action !== NavigationAction.Left &&
            action !== NavigationAction.Right &&
            action !== NavigationAction.Backspace &&
            action !== NavigationAction.Delete &&
            ((action === NavigationAction.Enter && this.isOpen) || action !== NavigationAction.Enter)) {
            event.preventDefault();
        }
    }
    handleEnter() {
        const text = this.text;
        const focused = this.selectionService.focused;
        const hasFocused = isPresent(focused) && focused !== -1;
        const previousText = getter$1(this._previousDataItem, this.textField) || "";
        const focusedItemText = getter$1(this.dataService.itemAt(focused), this.textField);
        const textHasChanged = text !== previousText;
        this.togglePopup(false);
        this._filtering = false;
        if (this.allowCustom && textHasChanged) {
            if (text === focusedItemText || this.useSuggestion()) {
                this.selectionService.change(focused);
            }
            else {
                this.change(text, true);
            }
        }
        if (!this.allowCustom) {
            if (hasFocused) {
                this.selectionService.change(focused);
            }
            else if (textHasChanged) {
                this.change(text, true);
            }
        }
    }
    /**
     * @hidden
     */
    handleBlur() {
        this._filtering = false;
        this.searchbar.input.nativeElement.scrollLeft = 0; // Firefox doesn't auto-scroll to the left on blur like other browsers
        this.isFocused = false;
        const unresolvedSelection = getter$1(this.dataItem, this.valueField) !== getter$1(this.value, this.valueField);
        const currentText = this.searchbar.value;
        const textHasChanged = currentText !== (getter$1(this.dataItem, this.textField) || '');
        const valueHasChanged = unresolvedSelection || textHasChanged;
        const runInZone = valueHasChanged || hasObservers(this.onBlur) || hasObservers(this.close) || isUntouched(this.wrapper.nativeElement);
        if (runInZone) {
            this.zone.run(() => {
                if (valueHasChanged) {
                    const lowerCaseMatch = isPresent(this.focusedItemText) && this.focusedItemText.toLowerCase() === currentText.toLowerCase();
                    if (lowerCaseMatch || unresolvedSelection) {
                        this.selectionService.change(this.selectionService.focused);
                    }
                    else {
                        this.change(currentText, true);
                    }
                }
                this.onBlur.emit();
                this.onTouchedCallback();
                this.togglePopup(false);
            });
        }
        else {
            this.togglePopup(false);
        }
    }
    /**
     * @hidden
     */
    handleEscape() {
        this.togglePopup(false);
        // clear the focus only if the focused item is not selected
        const hasSelected = this.selectionService.selected.length > 0;
        if (!hasSelected) {
            this.suggestedText = null;
            this.selectionService.focused = -1;
        }
    }
    /**
     * @hidden
     */
    handleNavigationOpen() {
        this.restoreItemFocus();
        this.togglePopup(true);
    }
    /**
     * @hidden
     */
    searchBarChange(text) {
        const currentTextLength = this.text ? this.text.length : 0;
        this.backspacePressed = (text.length < currentTextLength) ? true : false;
        this.text = text;
        // Reset the selection prior to filter. If a match is present, it will be resolved. If a match is not present, it is not needed.
        this.selectionService.resetSelection([]);
        this.togglePopup(true);
        this._filtering = true;
        if (this.filterable && this.filterText !== text) {
            this.filterText = text;
            this.filterChange.emit(text);
        }
        else {
            this.search(text);
        }
    }
    /**
     * @hidden
     */
    handleFocus() {
        this.isFocused = true;
        if (hasObservers(this.onFocus)) {
            this.zone.run(() => this.onFocus.emit());
        }
    }
    /**
     * @hidden
     */
    pageChange(event) {
        const virtual = this.virtual;
        virtual.skip = event.skip;
    }
    change(candidate, isCustom = false) {
        if (isCustom) {
            this.customValueSubject.next(candidate);
        }
        else {
            this.valueSubject.next(candidate);
        }
    }
    emitValueChange() {
        this.onChangeCallback(this.value);
        this.valueChange.emit(this.value);
        this._previousDataItem = this.dataItem;
    }
    /**
     * @hidden
     */
    selectClick() {
        if (!this.touchEnabled) {
            this.searchbar.focus();
        }
        if (!this.isOpen) {
            this.restoreItemFocus();
        }
        this.togglePopup(!this.isOpen);
    }
    get listContainerClasses() {
        return ['k-list-container', 'k-reset'].concat(this.popupSettings.popupClass || []);
    }
    /**
     * @hidden
     */
    preventEventDefault(event) {
        event.preventDefault();
    }
    get focusedItemText() {
        const focused = this.selectionService.focused;
        if (!isPresent(focused) || focused === -1) {
            return null;
        }
        const itemText = getter$1(this.dataService.itemAt(focused), this.textField);
        return !isPresent(itemText) ? "" : itemText.toString();
    }
    /**
     * Focuses the first match when there's text in the input field, but no focused item.
     */
    restoreItemFocus() {
        const hasFocus = isPresent(this.selectionService.focused) && this.selectionService.focused > -1;
        if (!hasFocus && this.text && this.dataService.itemsCount) {
            if (this.filterable) {
                this.selectionService.focused = this.firstFocusableIndex(0);
            }
            else {
                this.search(this.text);
            }
        }
    }
    useSuggestion() {
        if (!(this.suggest && isPresent(this.searchbar.value))) {
            return false;
        }
        const focusedDataItem = this.dataService.itemAt(this.selectionService.focused);
        const focusedItemText = getter$1(focusedDataItem, this.textField);
        if (!isPresent(focusedItemText)) {
            return false;
        }
        return this.searchbar.value.toLowerCase() === focusedItemText.toLowerCase();
    }
    destroyPopup() {
        if (this.popupRef) {
            this.popupRef.popupElement
                .removeEventListener('mousedown', this.popupMouseDownHandler);
            this.popupRef.close();
            this.popupRef = null;
        }
    }
    createPopup() {
        if (this.virtual) {
            this.virtual.skip = 0;
        }
        const horizontalAlign = this.direction === "rtl" ? "right" : "left";
        const anchorPosition = { horizontal: horizontalAlign, vertical: "bottom" };
        const popupPosition = { horizontal: horizontalAlign, vertical: "top" };
        this.popupRef = this.popupService.open({
            anchor: this.wrapper,
            animate: this.popupSettings.animate,
            appendTo: this.appendTo,
            content: this.popupTemplate,
            popupClass: this.listContainerClasses,
            positionMode: 'absolute',
            anchorAlign: anchorPosition,
            popupAlign: popupPosition
        });
        const popupWrapper = this.popupRef.popupElement;
        const { min, max } = this.popupWidth;
        popupWrapper.addEventListener('mousedown', this.popupMouseDownHandler);
        popupWrapper.style.minWidth = min;
        popupWrapper.style.width = max;
        popupWrapper.style.height = this.popupHeight;
        popupWrapper.setAttribute("dir", this.direction);
        this.popupRef.popupOpen.subscribe(() => {
            this.cdr.detectChanges();
            this.optionsList.scrollToItem(this.selectionService.focused);
            this.opened.emit();
        });
        this.popupRef.popupClose.subscribe(() => {
            this.closed.emit();
        });
        this.popupRef.popupAnchorViewportLeave.subscribe(() => this.togglePopup(false));
    }
    _toggle(open) {
        this._open = open;
        this.destroyPopup();
        if (this._open) {
            this.createPopup();
        }
    }
    triggerPopupEvents(open) {
        const eventArgs = new PreventableEvent();
        if (open) {
            this.open.emit(eventArgs);
        }
        else {
            this.close.emit(eventArgs);
        }
        return eventArgs.isDefaultPrevented();
    }
    clearFilter() {
        if (!(this.filterable && this.filterText)) {
            return;
        }
        this.filterText = '';
        this.filterChange.emit(this.filterText);
    }
    subscribeTouchEvents() {
        if (!isDocumentAvailable() || !this.touchEnabled) {
            return;
        }
        this.zone.runOutsideAngular(() => 
        // Roll up ComboBox on iOS when tapped outside
        this.touchstartDisposeHandler = this.renderer.listen(document, 'touchstart', (e) => {
            const target = e.target;
            const isInDropDown = inDropDown(this.wrapper, target, this.popupRef);
            if (this.isFocused && !isInDropDown) {
                // Close popup and mobile keyboard if searchbar is focused
                this.zone.run(() => this.blur());
            }
            else if (this.isOpen && !isInDropDown) {
                // Close popup if the popup is opened via the select click
                this.zone.run(() => this.togglePopup(false));
            }
        }));
    }
    attachSelectClickHandler() {
        const selectElement = this.select.nativeElement;
        const event = pointers ? 'pointerdown' : 'click';
        this.selectClickDisposeHandler = this.renderer.listen(selectElement, event, this.selectClick.bind(this));
    }
    setComponentClasses() {
        if (this.size) {
            this.renderer.addClass(this.wrapper.nativeElement, getSizeClass('input', this.size));
        }
        if (this.rounded) {
            this.renderer.addClass(this.wrapper.nativeElement, getRoundedClass(this.rounded));
        }
        if (this.fillMode) {
            this.renderer.addClass(this.wrapper.nativeElement, getFillModeClass('input', this.fillMode));
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], ComboBoxComponent.prototype, "focusableId", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ComboBoxComponent.prototype, "allowCustom", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], ComboBoxComponent.prototype, "data", null);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], ComboBoxComponent.prototype, "value", null);
__decorate([
    Input(),
    __metadata("design:type", String)
], ComboBoxComponent.prototype, "textField", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ComboBoxComponent.prototype, "valueField", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], ComboBoxComponent.prototype, "valuePrimitive", null);
__decorate([
    Input(),
    __metadata("design:type", Function)
], ComboBoxComponent.prototype, "valueNormalizer", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ComboBoxComponent.prototype, "placeholder", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], ComboBoxComponent.prototype, "popupSettings", null);
__decorate([
    Input(),
    __metadata("design:type", Number)
], ComboBoxComponent.prototype, "listHeight", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ComboBoxComponent.prototype, "iconClass", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ComboBoxComponent.prototype, "loading", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ComboBoxComponent.prototype, "suggest", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ComboBoxComponent.prototype, "clearButton", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ComboBoxComponent.prototype, "disabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Function])
], ComboBoxComponent.prototype, "itemDisabled", null);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ComboBoxComponent.prototype, "readonly", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], ComboBoxComponent.prototype, "tabindex", void 0);
__decorate([
    Input("tabIndex"),
    __metadata("design:type", Number),
    __metadata("design:paramtypes", [Number])
], ComboBoxComponent.prototype, "tabIndex", null);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ComboBoxComponent.prototype, "filterable", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], ComboBoxComponent.prototype, "virtual", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], ComboBoxComponent.prototype, "size", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], ComboBoxComponent.prototype, "rounded", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], ComboBoxComponent.prototype, "fillMode", null);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ComboBoxComponent.prototype, "valueChange", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ComboBoxComponent.prototype, "selectionChange", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ComboBoxComponent.prototype, "filterChange", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ComboBoxComponent.prototype, "open", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ComboBoxComponent.prototype, "opened", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ComboBoxComponent.prototype, "close", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ComboBoxComponent.prototype, "closed", void 0);
__decorate([
    Output('focus'),
    __metadata("design:type", EventEmitter)
], ComboBoxComponent.prototype, "onFocus", void 0);
__decorate([
    Output('blur'),
    __metadata("design:type", EventEmitter)
], ComboBoxComponent.prototype, "onBlur", void 0);
__decorate([
    ContentChild(ItemTemplateDirective, { static: false }),
    __metadata("design:type", ItemTemplateDirective)
], ComboBoxComponent.prototype, "template", void 0);
__decorate([
    ContentChild(HeaderTemplateDirective, { static: false }),
    __metadata("design:type", HeaderTemplateDirective)
], ComboBoxComponent.prototype, "headerTemplate", void 0);
__decorate([
    ContentChild(FooterTemplateDirective, { static: false }),
    __metadata("design:type", FooterTemplateDirective)
], ComboBoxComponent.prototype, "footerTemplate", void 0);
__decorate([
    ContentChild(NoDataTemplateDirective, { static: false }),
    __metadata("design:type", NoDataTemplateDirective)
], ComboBoxComponent.prototype, "noDataTemplate", void 0);
__decorate([
    ContentChild(GroupTemplateDirective, { static: false }),
    __metadata("design:type", GroupTemplateDirective)
], ComboBoxComponent.prototype, "groupTemplate", void 0);
__decorate([
    ContentChild(FixedGroupTemplateDirective, { static: false }),
    __metadata("design:type", FixedGroupTemplateDirective)
], ComboBoxComponent.prototype, "fixedGroupTemplate", void 0);
__decorate([
    ViewChild('container', { read: ViewContainerRef, static: true }),
    __metadata("design:type", ViewContainerRef)
], ComboBoxComponent.prototype, "container", void 0);
__decorate([
    ViewChild('popupTemplate', { static: true }),
    __metadata("design:type", TemplateRef)
], ComboBoxComponent.prototype, "popupTemplate", void 0);
__decorate([
    ViewChild(SearchBarComponent, { static: true }),
    __metadata("design:type", SearchBarComponent)
], ComboBoxComponent.prototype, "searchbar", void 0);
__decorate([
    ViewChild('optionsList', { static: false }),
    __metadata("design:type", ListComponent)
], ComboBoxComponent.prototype, "optionsList", void 0);
__decorate([
    ViewChild('select', { static: true }),
    __metadata("design:type", ElementRef)
], ComboBoxComponent.prototype, "select", void 0);
__decorate([
    HostBinding('class.k-combobox'),
    HostBinding('class.k-input'),
    __metadata("design:type", Boolean)
], ComboBoxComponent.prototype, "widgetClasses", void 0);
__decorate([
    HostBinding('class.k-disabled'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ComboBoxComponent.prototype, "isDisabled", null);
__decorate([
    HostBinding('class.k-loading'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ComboBoxComponent.prototype, "isLoading", null);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], ComboBoxComponent.prototype, "dir", null);
ComboBoxComponent = ComboBoxComponent_1 = __decorate([
    Component({
        exportAs: 'kendoComboBox',
        providers: [
            COMBOBOX_VALUE_ACCESSOR,
            DataService,
            SelectionService,
            NavigationService,
            DisabledItemsService,
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.combobox'
            },
            {
                provide: FilterableComponent, useExisting: forwardRef(() => ComboBoxComponent_1)
            },
            {
                provide: KendoInput, useExisting: forwardRef(() => ComboBoxComponent_1)
            }
        ],
        selector: 'kendo-combobox',
        template: `
        <ng-container kendoComboBoxLocalizedMessages
            i18n-noDataText="kendo.combobox.noDataText|The text displayed in the popup when there are no items"
            noDataText="NO DATA FOUND"

            i18n-clearTitle="kendo.combobox.clearTitle|The title of the clear button"
            clearTitle="clear"

            i18n-selectButtonText="kendo.combobox.selectButtonText|The text set as aria-label on the select button"
            selectButtonText="Select"
        >
        </ng-container>
        <kendo-searchbar #searchbar
            [role]="'combobox'"
            [id]="focusableId"
            [listId]="listBoxId"
            [activeDescendant]="activeDescendant"
            [noDataLabel]="noDataLabel"
            [userInput]="text"
            [suggestedText]="getSuggestion()"
            [disabled]="disabled"
            [readonly]="readonly"
            [tabIndex]="tabIndex"
            [popupOpen]="isOpen"
            [placeholder]="placeholder"
            (onNavigate)="handleNavigate($event)"
            (valueChange)="searchBarChange($event)"
            (onBlur)="handleBlur()"
            (onFocus)="handleFocus()"
        ></kendo-searchbar>
        <span
            *ngIf="clearButton && !loading && !disabled && !readonly && text?.length"
            class="k-clear-value"
            [style.visibility]="clearButtonVisiblity"
            aria-hidden="true"
            [attr.title]="messageFor('clearTitle')"
            (click)="clearValue($event)"
            [kendoEventsOutsideAngular]="{
                mousedown: preventEventDefault
            }"
        >
            <span class="k-icon k-i-x"></span>
        </span>
        <button
            #select
            unselectable="on"
            type="button"
            class="k-input-button k-button k-icon-button"
            [ngClass]="selectButtonClasses"
            [attr.aria-label]="messageFor('selectButtonText')"
            [kendoEventsOutsideAngular]="{
                mousedown: preventEventDefault
            }"
        >
            <span class="k-button-icon k-icon" [ngClass]="buttonClasses"></span>
        </button>
        <ng-template #popupTemplate>
            <!--header template-->
            <ng-template *ngIf="headerTemplate"
                [templateContext]="{
                    templateRef: headerTemplate.templateRef
                }">
            </ng-template>
            <!--list-->
            <kendo-list
                #optionsList
                [size]="size"
                [rounded]="rounded"
                [id]="listBoxId"
                [optionPrefix]="optionPrefix"
                [data]="data"
                [textField]="textField"
                [valueField]="valueField"
                [template]="template"
                [groupTemplate]="groupTemplate"
                [fixedGroupTemplate]="fixedGroupTemplate"
                [height]="listHeight"
                [show]="isOpen"
                [virtual]="virtual"
                (pageChange)="pageChange($event)"
            >
            </kendo-list>
            <!--no-data template-->
            <div class="k-no-data" *ngIf="data.length === 0">
                <ng-template [ngIf]="noDataTemplate"
                    [templateContext]="{
                        templateRef: noDataTemplate ? noDataTemplate.templateRef : undefined
                    }">
                </ng-template>
                <ng-template [ngIf]="!noDataTemplate">
                    <div>{{ messageFor('noDataText') }}</div>
                </ng-template>
            </div>
            <!--footer template-->
            <ng-template *ngIf="footerTemplate"
                [templateContext]="{
                    templateRef: footerTemplate.templateRef
                }">
            </ng-template>
        </ng-template>
        <ng-template [ngIf]="isOpen">
            <kendo-resize-sensor (resize)="onResize()"></kendo-resize-sensor>
        </ng-template>
        <ng-container #container></ng-container>
  `
    }),
    __param(10, Optional()), __param(10, Inject(TOUCH_ENABLED)),
    __metadata("design:paramtypes", [ElementRef,
        LocalizationService,
        PopupService,
        SelectionService,
        NavigationService,
        DisabledItemsService,
        DataService,
        NgZone,
        ChangeDetectorRef,
        Renderer2, Boolean])
], ComboBoxComponent);

/**
 * Renders the selected value of the dropdown. To define the header template, nest an `<ng-template>` tag
 * with the `kendo<ComponentName>ValueTemplate` directive inside the component tag.
 *
 * The template context is set to the current component.
 * To get a reference to the current data item, use the `let-dataItem` directive.
 *
 * > The `ValueTemplate` directive can only be used with the DropDownList and DropDownTree components.
 *
 * - [Using `ValueTemplate` with the DropDownList]({% slug templates_ddl %}#toc-value-template)
 * - [Using `ValueTemplate` with the DropDownTree]({% slug templates_ddt %}#toc-value-template)
 *
 * @example
 * ```ts
 * _@Component({
 * selector: 'my-app',
 * template: `
 *  <kendo-dropdownlist [data]="listItems">
 *    <ng-template kendoDropDownListValueTemplate let-dataItem>
 *      <span>{{dataItem}} option</span>
 *    </ng-template>
 *  </kendo-dropdownlist>
 * `
 * })
 * class AppComponent {
 *   public listItems: Array<string> = ["Item 1", "Item 2", "Item 3", "Item 4"];
 * }
 * ```
 */
let ValueTemplateDirective = class ValueTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
ValueTemplateDirective = __decorate([
    Directive({
        selector: '[kendoDropDownListValueTemplate],[kendoDropDownTreeValueTemplate]'
    }),
    __metadata("design:paramtypes", [TemplateRef])
], ValueTemplateDirective);

/* tslint:disable:member-ordering */
var DropDownListComponent_1;
/**
 * @hidden
 */
const DROPDOWNLIST_VALUE_ACCESSOR = {
    multi: true,
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DropDownListComponent)
};
/**
 * Represents the [Kendo UI DropDownList component for Angular]({% slug overview_ddl %}).
 *
 * @example
 * ```ts
 * _@Component({
 * selector: 'my-app',
 * template: `
 *  <kendo-dropdownlist [data]="listItems">
 *  </kendo-dropdownlist>
 * `
 * })
 * class AppComponent {
 *   public listItems: Array<string> = ["Item 1", "Item 2", "Item 3", "Item 4"];
 * }
 * ```
 */
let DropDownListComponent = DropDownListComponent_1 = class DropDownListComponent {
    constructor(wrapper, localization, popupService, selectionService, navigationService, disabledItemsService, dataService, _zone, renderer, cdr, touchEnabled$$1) {
        this.wrapper = wrapper;
        this.localization = localization;
        this.popupService = popupService;
        this.selectionService = selectionService;
        this.navigationService = navigationService;
        this.disabledItemsService = disabledItemsService;
        this.dataService = dataService;
        this._zone = _zone;
        this.renderer = renderer;
        this.cdr = cdr;
        this.touchEnabled = touchEnabled$$1;
        /**
         * Sets the height of the options list. By default, `listHeight` is 200px.
         *
         * > The `listHeight` property affects only the list of options and not the whole popup container.
         * > To set the height of the popup container, use `popupSettings.height`.
         */
        this.listHeight = 200;
        /**
         * Sets the disabled state of the component.
         */
        this.disabled = false;
        /**
         * Sets the read-only state of the component.
         */
        this.readonly = false;
        /**
         * Enables the [filtering]({% slug filtering_ddl %}) functionality of the DropDownList.
         */
        this.filterable = false;
        /**
         * Enables a case-insensitive search. When filtration is disabled, use this option.
         */
        this.ignoreCase = true;
        /**
         * Sets the delay before an item search is performed. When filtration is disabled, use this option.
         */
        this.delay = 500;
        /**
         * Specifies the [`tabindex`](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex) of the component.
         */
        this.tabindex = 0;
        /**
         * Fires each time the value is changed ([see example]({% slug overview_ddl %}#toc-events)).
         */
        this.valueChange = new EventEmitter();
        /**
         * Fires each time the user types in the input field
         * ([see example]({% slug overview_ddl %}#toc-events)).
         * You can filter the source based on the passed filtration value.
         * When the value of the component is programmatically changed to `ngModel` or `formControl`
         * through its API or form binding, the `valueChange` event is not triggered because it
         * might cause a mix-up with the built-in `valueChange` mechanisms of the `ngModel` or `formControl` bindings.
         */
        this.filterChange = new EventEmitter();
        /**
         * Fires each time the item selection is changed
         * ([see example]({% slug overview_ddl %}#toc-events)).
         */
        this.selectionChange = new EventEmitter();
        /**
         * Fires each time the popup is about to open
         * ([see example]({% slug openstate_ddl %}#toc-preventing-opening-and-closing)).
         * This event is preventable. If you cancel it, the popup will remain closed.
         */
        this.open = new EventEmitter();
        /**
         * Fires after the popup has been opened.
         */
        this.opened = new EventEmitter();
        /**
         * Fires each time the popup is about to close
         * ([see example]({% slug openstate_ddl %}#toc-preventing-opening-and-closing)).
         * This event is preventable. If you cancel it, the popup will remain open.
         */
        this.close = new EventEmitter();
        /**
         * Fires after the popup has been closed.
         */
        this.closed = new EventEmitter();
        /**
         * Fires each time the user focuses the DropDownList.
         */
        this.onFocus = new EventEmitter();
        /**
         * Fires each time the DropDownList gets blurred.
         */
        this.onBlur = new EventEmitter();
        this.hostClasses = true;
        /**
         * @hidden
         */
        this.focusableId = `k-${guid$1()}`;
        this.role = 'listbox';
        this.groupIndices = [];
        this.listBoxId = guid$1();
        this.optionPrefix = guid$1();
        this.filterText = "";
        this._isFocused = false;
        this.onTouchedCallback = (_) => { };
        this.onChangeCallback = (_) => { };
        this.word = "";
        this.last = "";
        this.filterFocused = new EventEmitter();
        this.filterBlurred = new EventEmitter();
        this.hostElementFocused = new EventEmitter();
        this.hostElementBlurred = new EventEmitter();
        this.selectionSubscription = new Subscription();
        this._open = false;
        this._popupSettings = { animate: true };
        this._size = 'medium';
        this._rounded = 'medium';
        this._fillMode = 'solid';
        validatePackage(packageMetadata);
        this.direction = localization.rtl ? 'rtl' : 'ltr';
        this.data = [];
        this.subscribeEvents();
        this.subscribeTouchEvents();
        this.subscribeFocusEvents();
        this.popupMouseDownHandler = this.onMouseDown.bind(this);
    }
    get width() {
        const wrapperWidth = isDocumentAvailable() ? this.wrapper.nativeElement.offsetWidth : 0;
        const width = this.popupSettings.width || wrapperWidth;
        const minWidth = isNaN(wrapperWidth) ? wrapperWidth : `${wrapperWidth}px`;
        const maxWidth = isNaN(width) ? width : `${width}px`;
        return { min: minWidth, max: maxWidth };
    }
    get height() {
        const popupHeight = this.popupSettings.height;
        return isPresent(popupHeight) ? `${popupHeight}px` : 'auto';
    }
    get widgetTabIndex() {
        if (this.disabled) {
            return undefined;
        }
        const providedTabIndex = Number(this.tabIndex);
        const defaultTabIndex = 0;
        return !isNaN(providedTabIndex) ? providedTabIndex : defaultTabIndex;
    }
    get ariaExpanded() {
        return this.isOpen;
    }
    get ariaOwns() {
        if (!this.isOpen) {
            return;
        }
        return this.listBoxId;
    }
    get ariaActivedescendant() {
        if (!isPresent(this.dataItem) || !this.isOpen) {
            return;
        }
        return this.optionPrefix + "-" + getter$1(this.dataItem, this.valueField);
    }
    get noDataLabel() {
        if (this.dataService.itemsCount === 0) {
            return this.messageFor('noDataText');
        }
    }
    get appendTo() {
        const { appendTo } = this.popupSettings;
        if (!appendTo || appendTo === 'root') {
            return undefined;
        }
        return appendTo === 'component' ? this.container : appendTo;
    }
    /**
     * Sets the data of the DropDownList.
     *
     * > The data has to be provided in an array-like list.
     */
    set data(data) {
        this.dataService.data = data || [];
        if (this.virtual) {
            this.virtual.skip = 0;
        }
        this.setState();
    }
    get data() {
        const virtual = this.virtual;
        if (virtual) {
            const start = virtual.skip || 0;
            const end = start + virtual.pageSize;
            // Use length instead of itemsCount because of the grouping.
            virtual.total = this.dataService.data.length;
            return this.dataService.data.slice(start, end);
        }
        return this.dataService.data;
    }
    /**
     * Sets the value of the DropDownList.
     * It can either be of the primitive (string, numbers) or of the complex (objects) type.
     * To define the type, use the `valuePrimitive` option.
     *
     * > All selected values which are not present in the source are ignored.
     */
    set value(newValue) {
        if (!isPresent(newValue)) {
            this._previousDataItem = undefined;
        }
        this._value = newValue;
        this.setState();
        this.cdr.markForCheck();
    }
    get value() {
        return this._value;
    }
    /**
     * Configures the popup of the DropDownList.
     *
     * The available options are:
     * - `animate: Boolean`&mdash;Controls the popup animation. By default, the open and close animations are enabled.
     * - `width: Number | String`&mdash;Sets the width of the popup container. By default, the width of the host element is used. If set to `auto`, the component automatically adjusts the width of the popup and no item labels are wrapped. The `auto` mode is not supported when virtual scrolling is enabled.
     * - `height: Number`&mdash;Sets the height of the popup container.
     * - `popupClass: String`&mdash;Specifies a list of CSS classes that are used to style the popup.
     * - `appendTo: "root" | "component" | ViewContainerRef`&mdash;Specifies the component to which the popup will be appended.
     */
    set popupSettings(settings) {
        this._popupSettings = Object.assign({ animate: true }, settings);
    }
    get popupSettings() {
        return this._popupSettings;
    }
    /**
     * Defines a Boolean function that is executed for each data item in the component
     * ([see examples]({% slug disableditems_ddl %})). Determines whether the item will be disabled.
     */
    set itemDisabled(fn) {
        if (typeof fn !== 'function') {
            throw new Error(`itemDisabled must be a function, but received ${JSON.stringify(fn)}.`);
        }
        this.disabledItemsService.itemDisabled = fn;
    }
    /**
     * Enables the [virtualization]({% slug virtualization_ddl %}) functionality.
     */
    set virtual(settings) {
        this._virtualSettings = normalizeVirtualizationSettings(settings);
    }
    get virtual() {
        return this._virtualSettings;
    }
    /**
     * Specifies the type of the selected value
     * ([more information and example]({% slug valuebinding_ddl %}#toc-primitive-values-from-object-fields)).
     * If set to `true`, the selected value has to be of a primitive value.
     */
    set valuePrimitive(isPrimitive) {
        this._valuePrimitive = isPrimitive;
    }
    get valuePrimitive() {
        if (!isPresent(this._valuePrimitive)) {
            return !isPresent(this.valueField);
        }
        return this._valuePrimitive;
    }
    /**
     * @hidden
     */
    set tabIndex(tabIndex) {
        this.tabindex = tabIndex;
    }
    get tabIndex() {
        return this.tabindex;
    }
    /**
     * Sets the size of the component.
     *
     * The possible values are:
     * * `'small'`
     * * `'medium'` (default)
     * * `'large'`
     * * `null`
     *
     */
    set size(size) {
        this.renderer.removeClass(this.wrapper.nativeElement, getSizeClass('picker', this.size));
        if (size) {
            this.renderer.addClass(this.wrapper.nativeElement, getSizeClass('picker', size));
        }
        this._size = size;
    }
    get size() {
        return this._size;
    }
    /**
     * Sets the border radius of the component.
     *
     * The possible values are:
     * * `'small'`
     * * `'medium'` (default)
     * * `'large'`
     * * `'full'`
     * * `null`
     *
     */
    set rounded(rounded) {
        this.renderer.removeClass(this.wrapper.nativeElement, getRoundedClass(this.rounded));
        if (rounded) {
            this.renderer.addClass(this.wrapper.nativeElement, getRoundedClass(rounded));
        }
        this._rounded = rounded;
    }
    get rounded() {
        return this._rounded;
    }
    /**
     * Sets the fillMode of the component.
     *
     * The possible values are:
     * * `'flat'`
     * * `'solid'` (default)
     * * `'outline'`
     * * `null`
     *
     */
    set fillMode(fillMode) {
        this.renderer.removeClass(this.wrapper.nativeElement, getFillModeClass('picker', this.fillMode));
        if (fillMode) {
            this.renderer.addClass(this.wrapper.nativeElement, getFillModeClass('picker', fillMode));
        }
        this._fillMode = fillMode;
    }
    get fillMode() {
        return this._fillMode;
    }
    /**
     * @hidden
     */
    blurComponent(event) {
        if (event.target !== this.wrapper.nativeElement) {
            return;
        }
        event.stopImmediatePropagation();
        this.hostElementBlurred.emit();
    }
    /**
     * @hidden
     */
    blurFilterInput() {
        this.filterBlurred.emit();
    }
    /**
     * @hidden
     */
    focusComponent(event) {
        if (event.target !== this.wrapper.nativeElement) {
            return;
        }
        event.stopImmediatePropagation();
        this.hostElementFocused.emit();
        if (!this.isFocused) {
            this.isFocused = true;
            if (hasObservers(this.onFocus)) {
                this._zone.run(() => {
                    this.onFocus.emit();
                });
            }
        }
    }
    /**
     * @hidden
     */
    onResize() {
        if (this._open) {
            const popupWrapper = this.popupRef.popupElement;
            const { min, max } = this.width;
            popupWrapper.style.minWidth = min;
            popupWrapper.style.width = max;
        }
    }
    get isDisabled() {
        return this.disabled;
    }
    get isLoading() {
        return this.loading;
    }
    get dir() {
        return this.direction;
    }
    get hostTabIndex() {
        return this.widgetTabIndex;
    }
    get isReadonly() {
        return this.readonly;
    }
    get isAriaExpanded() {
        return this.ariaExpanded;
    }
    get hostAriaOwns() {
        return this.ariaOwns;
    }
    get hostAriaActivedescendant() {
        return this.ariaActivedescendant;
    }
    get hostNoDataLabel() {
        return this.noDataLabel;
    }
    /**
     * @hidden
     */
    keydown(event) {
        const firstIndex = isPresent(this.defaultItem) ? -1 : 0;
        let focused = isNaN(this.selectionService.focused) ? this.firstFocusableIndex(firstIndex) : this.selectionService.focused;
        let offset = 0;
        if (this.disabled || this.readonly) {
            return;
        }
        const isHomeEnd = event.keyCode === Keys.Home || event.keyCode === Keys.End;
        const isFilterFocused = this.filterable && this.isFocused && this.isOpen;
        if (isFilterFocused && isHomeEnd) {
            return;
        }
        const hasSelected = isPresent(this.selectionService.selected[0]);
        const focusedItemNotSelected = isPresent(this.selectionService.focused) && !this.selectionService.isSelected(this.selectionService.focused);
        if (!hasSelected || focusedItemNotSelected) {
            if (event.keyCode === Keys.ArrowDown || event.keyCode === Keys.ArrowRight) {
                offset = -1;
            }
            else if (event.keyCode === Keys.ArrowUp || event.keyCode === Keys.ArrowLeft) {
                offset = 1;
            }
        }
        const eventData = event;
        const action = this.navigationService.process({
            current: focused + offset,
            max: this.dataService.itemsCount - 1,
            min: this.defaultItem ? -1 : 0,
            originalEvent: eventData
        });
        const leftRightKeys = (action === NavigationAction.Left) || (action === NavigationAction.Right);
        if (action !== NavigationAction.Undefined &&
            action !== NavigationAction.Tab &&
            action !== NavigationAction.Backspace &&
            action !== NavigationAction.Delete &&
            !(leftRightKeys && this.filterable) &&
            action !== NavigationAction.Enter //enter when popup is opened is handled before `handleEnter`
        ) {
            eventData.preventDefault();
        }
    }
    /**
     * @hidden
     */
    keypress(event) {
        if (this.disabled || this.readonly || this.filterable) {
            return;
        }
        this.onKeyPress(event);
    }
    /**
     * @hidden
     */
    click(event) {
        event.preventDefault();
        this.focus();
        this.togglePopup(!this.isOpen);
    }
    set isFocused(isFocused) {
        this.renderer[isFocused ? 'addClass' : 'removeClass'](this.wrapper.nativeElement, 'k-focus');
        this._isFocused = isFocused;
    }
    get isFocused() {
        return this._isFocused;
    }
    ngOnInit() {
        this.renderer.removeAttribute(this.wrapper.nativeElement, "tabindex");
        this.localizationChangesSubscription = this.localization
            .changes.subscribe(({ rtl }) => {
            this.direction = rtl ? 'rtl' : 'ltr';
            this.cdr.detectChanges();
        });
        this.assignAriaDescribedBy();
        this.setComponentClasses();
    }
    /**
     * @hidden
     * Used by the TextBoxContainer to determine if the component is empty.
     */
    isEmpty() {
        const value = this.value;
        return !(value === 0 || value === false || value || this.defaultItem);
    }
    /**
     * @hidden
     */
    onFilterFocus() {
        this.filterFocused.emit();
    }
    /**
     * @hidden
     */
    ngOnDestroy() {
        this.destroyPopup();
        this.unsubscribeEvents();
        if (this.localizationChangesSubscription) {
            this.localizationChangesSubscription.unsubscribe();
        }
    }
    /**
     * @hidden
     */
    ngOnChanges(changes) {
        const virtual = this.virtual;
        const requestInitialData = virtual && changes.data && changes.data.isFirstChange();
        if (requestInitialData) {
            this.pageChange({ skip: 0, take: virtual.pageSize });
        }
        if (isChanged('defaultItem', changes, false)) {
            this.disabledItemsService.defaultItem = this.defaultItem;
        }
        if (anyChanged(['textField', 'valueField', 'valuePrimitive', 'defaultItem', 'itemDisabled'], changes, false)) {
            this.setState();
        }
    }
    /**
     * @hidden
     */
    ngAfterContentChecked() {
        this.verifySettings();
    }
    /**
     * Focuses a specific item of the DropDownList based on a provided index.
     * If there is a default item it is positioned at index -1.
     * If null or invalid index is provided the focus will be removed.
     */
    focusItemAt(index) {
        const minIndex = isPresent(this.defaultItem) ? -1 : 0;
        const isInRange = minIndex <= index && index < this.data.length;
        if (isPresent(index) && isInRange && !this.disabledItemsService.isIndexDisabled(index)) {
            this.selectionService.focus(index);
        }
        else {
            this.selectionService.focus(null);
        }
    }
    /**
     * Focuses the DropDownList.
     */
    focus() {
        if (!this.disabled) {
            this.wrapper.nativeElement.focus();
        }
    }
    /**
     * Blurs the DropDownList.
     */
    blur() {
        if (!this.disabled) {
            this.wrapper.nativeElement.blur();
        }
    }
    /**
     * Toggles the visibility of the popup
     * ([see example]({% slug openstate_ddl %}#toc-setting-the-initially-opened-component)).
     * If you use the `toggle` method to open or close the popup, the `open` and `close` events will not be fired.
     *
     * @param open - The state of the popup.
     */
    toggle(open) {
        // The Promise is required to open the popup on load.
        // Otherwise, the "Expression has changed..." type error will be thrown.
        Promise.resolve(null).then(() => {
            const shouldOpen = isPresent(open) ? open : !this._open;
            this._toggle(shouldOpen);
        });
    }
    _toggle(open) {
        this._open = open;
        this.destroyPopup();
        if (this._open) {
            this.createPopup();
        }
    }
    triggerPopupEvents(open) {
        const eventArgs = new PreventableEvent();
        if (open) {
            this.open.emit(eventArgs);
        }
        else {
            this.close.emit(eventArgs);
        }
        return eventArgs.isDefaultPrevented();
    }
    /**
     * @hidden
     */
    togglePopup(open) {
        const isDisabled = this.disabled || this.readonly;
        const sameState = this.isOpen === open;
        if (isDisabled || sameState) {
            return;
        }
        const isDefaultPrevented = this.triggerPopupEvents(open);
        if (!isDefaultPrevented) {
            if (!open && this.filterable && this.isFocused) {
                this.focus();
            }
            this._toggle(open);
        }
    }
    /**
     * Returns the current open state of the popup.
     */
    get isOpen() {
        return this._open;
    }
    /**
     * Resets the value of the DropDownList.
     * If you use the `reset` method to clear the value of the component,
     * the model will not update automatically and the `selectionChange` and `valueChange` events will not be fired.
     */
    reset() {
        this.value = undefined;
    }
    /**
     * @hidden
     */
    messageFor(key) {
        return this.localization.get(key);
    }
    /**
     * @hidden
     */
    writeValue(value) {
        this.value = value === null ? undefined : value;
    }
    /**
     * @hidden
     */
    registerOnChange(fn) {
        this.onChangeCallback = fn;
    }
    /**
     * @hidden
     */
    registerOnTouched(fn) {
        this.onTouchedCallback = fn;
    }
    /**
     * @hidden
     */
    setDisabledState(isDisabled) {
        this.disabled = isDisabled;
    }
    /**
     * @hidden
     */
    get buttonClasses() {
        return this.loading ? 'k-i-loading' : this.iconClass || 'k-i-arrow-s';
    }
    /**
     * @hidden
     */
    get selectButtonClasses() {
        return `${this.size ? getSizeClass('button', this.size) : ''} ${this.fillMode ? 'k-button-' + this.fillMode : ''} ${this.fillMode ? 'k-button-' + this.fillMode + '-base' : ''}`;
    }
    /**
     * @hidden
     */
    get filterInputClasses() {
        return `${this.size ? getSizeClass('input', this.size) : ''} ${this.fillMode ? 'k-input-' + this.fillMode : ''} ${this.rounded ? getRoundedClass(this.rounded) : ''}`;
    }
    /**
     * @hidden
     */
    get optionLabelSizeClass() {
        return `${this.size ? getSizeClass('list', this.size) : ''}`;
    }
    /**
     * @hidden
     */
    get listContainerClasses() {
        const containerClasses = ['k-list-container', 'k-reset'];
        if (this.popupSettings.popupClass) {
            containerClasses.push(this.popupSettings.popupClass);
        }
        return containerClasses;
    }
    /**
     * @hidden
     */
    get isDisabledDefaultItem() {
        return this.disabledItemsService.isItemDisabled(this.defaultItem);
    }
    /**
     * @hidden
     */
    getText() {
        return this.text;
    }
    /**
     * @hidden
     */
    getDefaultItemText() {
        return getter$1(this.defaultItem, this.textField);
    }
    createPopup() {
        if (this.virtual) {
            this.virtual.skip = 0;
        }
        const horizontalAlign = this.direction === "rtl" ? "right" : "left";
        const anchorPosition = { horizontal: horizontalAlign, vertical: "bottom" };
        const popupPosition = { horizontal: horizontalAlign, vertical: "top" };
        this.popupRef = this.popupService.open({
            anchor: this.wrapper,
            anchorAlign: anchorPosition,
            animate: this.popupSettings.animate,
            appendTo: this.appendTo,
            content: this.popupTemplate,
            popupAlign: popupPosition,
            popupClass: this.listContainerClasses,
            positionMode: 'absolute'
        });
        const popupWrapper = this.popupRef.popupElement;
        const { min, max } = this.width;
        popupWrapper.addEventListener('mousedown', this.popupMouseDownHandler);
        popupWrapper.style.minWidth = min;
        popupWrapper.style.width = max;
        popupWrapper.style.height = this.height;
        popupWrapper.setAttribute("dir", this.direction);
        this.popupRef.popupOpen.subscribe(() => {
            this.cdr.detectChanges();
            this.optionsList.scrollToItem(this.selectionService.focused);
            this.opened.emit();
        });
        this.popupRef.popupClose.subscribe(() => {
            this.closed.emit();
        });
        if (!this.filterable) {
            this.popupRef.popupAnchorViewportLeave.subscribe(() => this.togglePopup(false));
        }
    }
    destroyPopup() {
        if (this.popupRef) {
            this.popupRef.popupElement
                .removeEventListener('mousedown', this.popupMouseDownHandler);
            this.popupRef.close();
            this.popupRef = null;
        }
    }
    updateState({ dataItem, confirm = false }) {
        this.dataItem = dataItem;
        this.text = getter$1(dataItem, this.textField);
        if (confirm) {
            this._previousDataItem = dataItem;
        }
    }
    clearState() {
        this.text = undefined;
        this.dataItem = undefined;
    }
    resetSelection(index) {
        const clear = !isPresent(index);
        this.selectionService.resetSelection(clear ? [] : [index]);
        this.selectionService.focused = clear ? this.firstFocusableIndex(0) : index;
    }
    onSelectionChange({ dataItem }) {
        this.updateState({ dataItem });
        this.selectionChange.emit(dataItem);
        // reassigning the value label ID as aria-deascibedby forces firefox/nvda, forefox/jaws to read
        // the new value when the popup is closed and the value is changed with the arrow keys (up/down)
        this.assignAriaDescribedBy();
    }
    subscribeEvents() {
        if (!isDocumentAvailable()) {
            return;
        }
        // Item selection when the popup is open.
        this.selectionSubscription.add(this.selectionService.onSelect.pipe(filter(_ => this.isOpen), map(this.itemFromEvent.bind(this)))
            .subscribe(this.onSelectionChange.bind(this)));
        // Item selection when the popup is closed | clicked | enter, and so on.
        this.selectionSubscription.add(merge(this.selectionService.onSelect.pipe(filter(_ => !this.isOpen)), this.selectionService.onChange).pipe(map(this.itemFromEvent.bind(this)), tap(_ => this.togglePopup(false)))
            .subscribe(({ dataItem, value: newValue, newSelection }) => {
            if (newSelection) {
                this.onSelectionChange({ dataItem });
            }
            const shouldUsePrevious = !isPresent(dataItem) && this._previousDataItem;
            const shouldUseNewValue = newValue !== getter$1(this.value, this.valueField);
            if (shouldUsePrevious) {
                this.updateState({ dataItem: this._previousDataItem });
                this.resetSelection();
            }
            else if (shouldUseNewValue) {
                this.value = this.valuePrimitive ? newValue : dataItem;
                this._previousDataItem = dataItem;
                this.emitChange(this.value);
            }
            this.clearFilter();
        }));
        this.navigationSubscription = merge(this.navigationService.up, this.navigationService.down, this.navigationService.left.pipe(skipWhile(() => this.filterable)), this.navigationService.right.pipe(skipWhile(() => this.filterable)), this.navigationService.home, this.navigationService.end)
            .pipe(filter((event) => !isNaN(event.index)))
            .subscribe((event) => this.selectionService.select(event.index));
        this.openSubscription = this.navigationService.open.subscribe(() => this.togglePopup(true));
        this.closeSubscription = this.navigationService.close.subscribe(() => {
            this.togglePopup(false);
            this.focus();
        });
        this.enterSubscription = this.navigationService.enter
            .pipe(tap((event) => event.originalEvent.preventDefault()))
            .subscribe(this.handleEnter.bind(this));
        this.escSubscription = this.navigationService.esc
            .subscribe(this.handleEscape.bind(this));
        this.filterBlurredSubscription = this.filterBlurred.pipe(concatMap(() => interval(10).pipe(take(1), takeUntil(this.hostElementFocused))))
            .subscribe(() => {
            this.hostElementBlurred.emit();
        });
        this._zone.runOutsideAngular(() => {
            this.componentBlurredSubscription =
                merge(this.hostElementBlurred.pipe(concatMap(() => interval(10).pipe(take(1), takeUntil(this.filterFocused)))), this.navigationService.tab).pipe(tap(event => event instanceof NavigationEvent && this.focus()), filter(() => this.isFocused))
                    .subscribe(() => this.componentBlur());
        });
    }
    subscribeTouchEvents() {
        if (!isDocumentAvailable() || !this.touchEnabled) {
            return;
        }
        this._zone.runOutsideAngular(() => 
        // Roll up DropDownList on iOS when tapped outside
        this.touchstartDisposeHandler = this.renderer.listen(document, 'touchstart', (e) => {
            const target = e.target;
            if (this.isFocused && !inDropDown(this.wrapper, target, this.popupRef)) {
                this._zone.run(() => {
                    if (this.filterFocused) {
                        // Close popup if filter is focused
                        this.togglePopup(false);
                    }
                    this.blur();
                });
            }
        }));
    }
    subscribeFocusEvents() {
        if (isDocumentAvailable()) {
            this.focusComponent = this.focusComponent.bind(this);
            this.blurComponent = this.blurComponent.bind(this);
            this._zone.runOutsideAngular(() => {
                const useCapture = true;
                document.addEventListener('focus', this.focusComponent, useCapture);
                document.addEventListener('blur', this.blurComponent, useCapture);
            });
        }
    }
    unSubscribeFocusEvents() {
        if (isDocumentAvailable()) {
            const useCapture = true;
            document.removeEventListener('focus', this.focusComponent, useCapture);
            document.removeEventListener('blur', this.blurComponent, useCapture);
        }
    }
    unsubscribeEvents() {
        if (!isDocumentAvailable()) {
            return;
        }
        this.navigationSubscription.unsubscribe();
        this.openSubscription.unsubscribe();
        this.closeSubscription.unsubscribe();
        this.enterSubscription.unsubscribe();
        this.escSubscription.unsubscribe();
        this.componentBlurredSubscription.unsubscribe();
        this.filterBlurredSubscription.unsubscribe();
        this.unSubscribeFocusEvents();
        if (this.touchstartDisposeHandler) {
            this.touchstartDisposeHandler();
        }
        if (this.selectionSubscription) {
            this.selectionSubscription.unsubscribe();
        }
    }
    itemFromEvent(event) {
        const index = event.indices[0];
        let dataItem = this.dataService.itemAt(index);
        dataItem = isPresent(dataItem) ? dataItem : this.currentOrDefault(index);
        const value = getter$1(dataItem, this.valueField);
        const newSelection = event.newSelection;
        return {
            dataItem,
            index,
            newSelection,
            value
        };
    }
    currentOrDefault(selectedIndex) {
        const defaultItemIndex = -1;
        if (isPresent(this.dataItem) && selectedIndex !== defaultItemIndex) {
            return this.dataItem;
        }
        else {
            return this.defaultItem;
        }
    }
    firstFocusableIndex(index) {
        const maxIndex = this.dataService.itemsCount - 1;
        if (this.disabledItemsService.isIndexDisabled(index)) {
            return (index < maxIndex) ? this.firstFocusableIndex(index + 1) : undefined;
        }
        else {
            return index;
        }
    }
    handleEnter() {
        if (this.isOpen) {
            this.selectionService.change(this.selectionService.focused);
            this.focus();
        }
        else {
            this.togglePopup(true);
        }
    }
    handleEscape() {
        if (isPresent(this.selectionService.selected[0])) {
            this.selectionService.change(this.selectionService.selected[0]);
        }
        else {
            this.togglePopup(false);
            this.clearFilter();
        }
        this.focus();
    }
    clearFilter() {
        if (!(this.filterable && this.filterText)) {
            return;
        }
        this.filterText = "";
        this.cdr.markForCheck();
        this.filterChange.emit(this.filterText);
    }
    verifySettings() {
        if (!isDevMode()) {
            return;
        }
        if (this.defaultItem && this.valueField && typeof this.defaultItem !== "object") {
            throw new Error(DropDownListMessages.defaultItem);
        }
        if (this.valuePrimitive === true && isPresent(this.value) && typeof this.value === "object") {
            throw new Error(DropDownListMessages.primitive);
        }
        if (this.valuePrimitive === false && isPresent(this.value) && typeof this.value !== "object") {
            throw new Error(DropDownListMessages.object);
        }
        const valueOrText = !isPresent(this.valueField) !== !isPresent(this.textField);
        if (valueOrText) {
            throw new Error(DropDownListMessages.textAndValue);
        }
    }
    componentBlur() {
        this.isFocused = false;
        const selectionPresent = isPresent(this.selectionService.selected[0]);
        const valueHasChanged = selectionPresent && getter$1(this.value, this.valueField) !== getter$1(this.dataService.itemAt(this.selectionService.selected[0]), this.valueField);
        if (valueHasChanged ||
            hasObservers(this.close) ||
            hasObservers(this.onBlur) ||
            hasObservers(this.filterChange) ||
            isUntouched(this.wrapper.nativeElement)) {
            this._zone.run(() => {
                if (valueHasChanged) {
                    this.selectionService.change(this.selectionService.selected[0]);
                }
                this.togglePopup(false);
                this.clearFilter();
                this.onBlur.emit();
                this.onTouchedCallback();
            });
        }
        else {
            this.togglePopup(false);
        }
    }
    /**
     * @hidden
     */
    onMouseDown(event) {
        const tagName = event.target.tagName.toLowerCase();
        if (tagName !== "input") {
            event.preventDefault();
        }
    }
    onKeyPress(event) {
        if (event.which === 0 || event.keyCode === Keys.Enter) {
            return;
        }
        let character = String.fromCharCode(event.charCode || event.keyCode);
        if (this.ignoreCase) {
            character = character.toLowerCase();
        }
        if (character === " ") {
            event.preventDefault();
        }
        this.word += character;
        this.last = character;
        this.search();
    }
    search() {
        clearTimeout(this.typingTimeout);
        if (!this.filterable) {
            this.typingTimeout = setTimeout(() => { this.word = ""; }, this.delay);
            this.selectNext();
        }
    }
    selectNext() {
        let data = this.dataService
            .filter((item) => isPresent(item) && !item.header && !this.disabledItemsService.isItemDisabled(item))
            .map((item) => {
            if (this.dataService.grouped) {
                return { item: item.value, itemIndex: item.offsetIndex };
            }
            return { item: item, itemIndex: this.dataService.indexOf(item) };
        });
        const isInLoop = sameCharsOnly(this.word, this.last);
        let dataLength = data.length;
        let hasSelected = !isNaN(this.selectionService.selected[0]);
        let startIndex = !hasSelected ? 0 : this.selectionService.selected[0];
        let text, index, defaultItem;
        if (this.defaultItem && !this.disabledItemsService.isItemDisabled(this.defaultItem)) {
            defaultItem = { item: this.defaultItem, itemIndex: -1 };
            dataLength += 1;
            startIndex += 1;
        }
        startIndex += isInLoop && hasSelected ? 1 : 0;
        data = shuffleData(data, startIndex, defaultItem);
        index = 0;
        for (; index < dataLength; index++) {
            text = getter$1(data[index].item, this.textField);
            const loopMatch = Boolean(isInLoop && matchText(text, this.last, this.ignoreCase));
            const nextMatch = Boolean(matchText(text, this.word, this.ignoreCase));
            if (loopMatch || nextMatch) {
                index = data[index].itemIndex;
                break;
            }
        }
        if (index !== dataLength) {
            this.navigate(index);
        }
    }
    emitChange(value) {
        this.onChangeCallback(value);
        this.valueChange.emit(value);
    }
    navigate(index) {
        this.selectionService.select(index);
    }
    findDataItem({ valueField, value }) {
        const result = {
            dataItem: null,
            index: -1
        };
        const prop = dataItem => getter$1(dataItem, valueField);
        let comparer;
        if (this.dataService.grouped) {
            comparer = (element) => {
                return prop(element.value) === prop(value);
            };
        }
        else {
            comparer = (element) => {
                return prop(element) === prop(value);
            };
        }
        const index = this.dataService.findIndex(comparer);
        result.dataItem = this.dataService.itemAt(index);
        result.index = index;
        return result;
    }
    setState() {
        const value = this.value;
        const valueField = this.valueField;
        const textField = this.textField;
        const primitive = this.valuePrimitive;
        if (this.defaultItem) {
            const defaultValue = getter$1(this.defaultItem, valueField);
            const currentValue = getter$1(value, valueField);
            if (!isPresent(value) || (currentValue === defaultValue)) {
                this.updateState({ dataItem: this.defaultItem, confirm: true });
                this.resetSelection(-1);
                if (this.filterable && this.filterText && this.dataService.itemsCount) {
                    this.selectionService.focused = this.firstFocusableIndex(0);
                }
                return;
            }
        }
        const resolved = this.findDataItem({ valueField, value });
        // The data and value are of same shape,
        // for example, value: 'foo', data: ['foo', 'bar']
        // or value: { value: 1, text: 'foo' }, data: [{ value: 1, text: 'foo' }].
        const ofSameType = !(primitive && textField);
        if (resolved.dataItem) {
            this.updateState({ dataItem: resolved.dataItem, confirm: true });
            this.resetSelection(resolved.index);
        }
        else if (isPresent(value) && ofSameType) {
            this.updateState({ dataItem: value });
            this.resetSelection();
        }
        else if (this._previousDataItem) {
            this.updateState({ dataItem: this._previousDataItem });
            this.resetSelection();
        }
        else {
            this.clearState();
            this.resetSelection();
        }
    }
    /**
     * @hidden
     */
    handleFilter(event) {
        this.filterChange.emit(event.target.value);
    }
    /**
     * @hidden
     */
    pageChange(event) {
        const virtual = this.virtual;
        virtual.skip = event.skip;
    }
    assignAriaDescribedBy() {
        const currentValue = this.wrapper.nativeElement.getAttribute('aria-describedby') || '';
        const trimmed = currentValue.replace(this.valueLabelId, '').trim();
        // reset the value label ID to force readers to read the new value
        this.valueLabelId = guid$1();
        // add to the current value - don't replace it
        const newValue = `${this.valueLabelId} ${trimmed}`.trim();
        this.renderer.setAttribute(this.wrapper.nativeElement, 'aria-describedby', newValue);
    }
    setComponentClasses() {
        if (this.size) {
            this.renderer.addClass(this.wrapper.nativeElement, getSizeClass('picker', this.size));
        }
        if (this.rounded) {
            this.renderer.addClass(this.wrapper.nativeElement, getRoundedClass(this.rounded));
        }
        if (this.fillMode) {
            this.renderer.addClass(this.wrapper.nativeElement, getFillModeClass('picker', this.fillMode));
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], DropDownListComponent.prototype, "iconClass", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], DropDownListComponent.prototype, "loading", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], DropDownListComponent.prototype, "data", null);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], DropDownListComponent.prototype, "value", null);
__decorate([
    Input(),
    __metadata("design:type", String)
], DropDownListComponent.prototype, "textField", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], DropDownListComponent.prototype, "valueField", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], DropDownListComponent.prototype, "popupSettings", null);
__decorate([
    Input(),
    __metadata("design:type", Number)
], DropDownListComponent.prototype, "listHeight", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], DropDownListComponent.prototype, "defaultItem", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], DropDownListComponent.prototype, "disabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Function])
], DropDownListComponent.prototype, "itemDisabled", null);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], DropDownListComponent.prototype, "readonly", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], DropDownListComponent.prototype, "filterable", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], DropDownListComponent.prototype, "virtual", null);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], DropDownListComponent.prototype, "ignoreCase", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], DropDownListComponent.prototype, "delay", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], DropDownListComponent.prototype, "valuePrimitive", null);
__decorate([
    Input(),
    __metadata("design:type", Number)
], DropDownListComponent.prototype, "tabindex", void 0);
__decorate([
    Input("tabIndex"),
    __metadata("design:type", Number),
    __metadata("design:paramtypes", [Number])
], DropDownListComponent.prototype, "tabIndex", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], DropDownListComponent.prototype, "size", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], DropDownListComponent.prototype, "rounded", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], DropDownListComponent.prototype, "fillMode", null);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], DropDownListComponent.prototype, "valueChange", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], DropDownListComponent.prototype, "filterChange", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], DropDownListComponent.prototype, "selectionChange", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], DropDownListComponent.prototype, "open", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], DropDownListComponent.prototype, "opened", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], DropDownListComponent.prototype, "close", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], DropDownListComponent.prototype, "closed", void 0);
__decorate([
    Output('focus'),
    __metadata("design:type", EventEmitter)
], DropDownListComponent.prototype, "onFocus", void 0);
__decorate([
    Output('blur'),
    __metadata("design:type", EventEmitter)
], DropDownListComponent.prototype, "onBlur", void 0);
__decorate([
    ContentChild(ItemTemplateDirective, { static: false }),
    __metadata("design:type", ItemTemplateDirective)
], DropDownListComponent.prototype, "itemTemplate", void 0);
__decorate([
    ContentChild(GroupTemplateDirective, { static: false }),
    __metadata("design:type", GroupTemplateDirective)
], DropDownListComponent.prototype, "groupTemplate", void 0);
__decorate([
    ContentChild(FixedGroupTemplateDirective, { static: false }),
    __metadata("design:type", FixedGroupTemplateDirective)
], DropDownListComponent.prototype, "fixedGroupTemplate", void 0);
__decorate([
    ContentChild(ValueTemplateDirective, { static: false }),
    __metadata("design:type", ValueTemplateDirective)
], DropDownListComponent.prototype, "valueTemplate", void 0);
__decorate([
    ContentChild(HeaderTemplateDirective, { static: false }),
    __metadata("design:type", HeaderTemplateDirective)
], DropDownListComponent.prototype, "headerTemplate", void 0);
__decorate([
    ContentChild(FooterTemplateDirective, { static: false }),
    __metadata("design:type", FooterTemplateDirective)
], DropDownListComponent.prototype, "footerTemplate", void 0);
__decorate([
    ContentChild(NoDataTemplateDirective, { static: false }),
    __metadata("design:type", NoDataTemplateDirective)
], DropDownListComponent.prototype, "noDataTemplate", void 0);
__decorate([
    ViewChild('container', { read: ViewContainerRef, static: true }),
    __metadata("design:type", ViewContainerRef)
], DropDownListComponent.prototype, "container", void 0);
__decorate([
    ViewChild('popupTemplate', { static: true }),
    __metadata("design:type", TemplateRef)
], DropDownListComponent.prototype, "popupTemplate", void 0);
__decorate([
    ViewChild('optionsList', { static: false }),
    __metadata("design:type", ListComponent)
], DropDownListComponent.prototype, "optionsList", void 0);
__decorate([
    HostBinding('class.k-dropdownlist'),
    HostBinding('class.k-picker'),
    __metadata("design:type", Boolean)
], DropDownListComponent.prototype, "hostClasses", void 0);
__decorate([
    HostBinding('class.k-disabled'),
    HostBinding('attr.aria-disabled'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], DropDownListComponent.prototype, "isDisabled", null);
__decorate([
    HostBinding('class.k-loading'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], DropDownListComponent.prototype, "isLoading", null);
__decorate([
    Input('id'),
    HostBinding('attr.id'),
    __metadata("design:type", String)
], DropDownListComponent.prototype, "focusableId", void 0);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], DropDownListComponent.prototype, "dir", null);
__decorate([
    HostBinding('attr.tabindex'),
    __metadata("design:type", Number),
    __metadata("design:paramtypes", [])
], DropDownListComponent.prototype, "hostTabIndex", null);
__decorate([
    HostBinding('attr.readonly'),
    HostBinding('attr.aria-readonly'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], DropDownListComponent.prototype, "isReadonly", null);
__decorate([
    HostBinding('attr.role'),
    HostBinding('attr.aria-haspopup'),
    __metadata("design:type", String)
], DropDownListComponent.prototype, "role", void 0);
__decorate([
    HostBinding('attr.aria-expanded'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], DropDownListComponent.prototype, "isAriaExpanded", null);
__decorate([
    HostBinding('attr.aria-owns'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], DropDownListComponent.prototype, "hostAriaOwns", null);
__decorate([
    HostBinding('attr.aria-activedescendant'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], DropDownListComponent.prototype, "hostAriaActivedescendant", null);
__decorate([
    HostBinding('attr.aria-label'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], DropDownListComponent.prototype, "hostNoDataLabel", null);
__decorate([
    HostListener('keydown', ['$event']),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], DropDownListComponent.prototype, "keydown", null);
__decorate([
    HostListener('keypress', ['$event']),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], DropDownListComponent.prototype, "keypress", null);
__decorate([
    HostListener('click', ['$event']),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], DropDownListComponent.prototype, "click", null);
DropDownListComponent = DropDownListComponent_1 = __decorate([
    Component({
        exportAs: 'kendoDropDownList',
        providers: [
            DROPDOWNLIST_VALUE_ACCESSOR,
            DataService,
            SelectionService,
            NavigationService,
            DisabledItemsService,
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.dropdownlist'
            },
            {
                provide: FilterableComponent, useExisting: forwardRef(() => DropDownListComponent_1)
            },
            {
                provide: KendoInput, useExisting: forwardRef(() => DropDownListComponent_1)
            }
        ],
        selector: 'kendo-dropdownlist',
        template: `
        <ng-container kendoDropDownListLocalizedMessages
            i18n-noDataText="kendo.dropdownlist.noDataText|The text displayed in the popup when there are no items"
            noDataText="NO DATA FOUND"

            i18n-selectButtonText="kendo.dropdownlist.selectButtonText|The text set as aria-label on the select button"
            selectButtonText="Select"
        >
        </ng-container>
        <span class="k-input-inner" unselectable="on" role="option" [id]="valueLabelId">
            <span class="k-input-value-text">
                <ng-template *ngIf="valueTemplate"
                    [templateContext]="{
                        templateRef: valueTemplate.templateRef,
                        $implicit: dataItem
                    }">
                </ng-template>
                <ng-template [ngIf]="!valueTemplate">{{ getText() }}</ng-template>
            </span>
        </span>
        <button
            tabindex="-1"
            unselectable="on"
            type="button"
            class="k-input-button k-button k-icon-button"
            [ngClass]="selectButtonClasses"
            [attr.aria-label]="messageFor('selectButtonText')"
            [kendoEventsOutsideAngular]="{
                mousedown: onMouseDown
            }"
        >
            <span
                class="k-button-icon k-icon"
                unselectable="on"
                [ngClass]="buttonClasses"
            ></span>
        </button>
        <ng-template #popupTemplate>
            <!--filterable-->

            <ng-template [ngIf]="filterable">
                <div class="k-list-filter" (click)="$event.stopImmediatePropagation()">
                    <span
                        class="k-searchbox k-input"
                        [ngClass]="filterInputClasses"
                    >
                        <span class="k-input-icon k-icon k-i-search" unselectable="on"></span>
                        <input
                            [attr.aria-owns]="ariaOwns"
                            [attr.aria-activedescendant]="ariaActivedescendant"
                            [attr.aria-label]="noDataLabel"
                            tabindex="-1"
                            [filterInput]="isFocused && !touchEnabled"
                            [dir]="direction"
                            [(ngModel)]="filterText"
                            class="k-input-inner"
                            (keydown)="keydown($event)"
                            (input)="handleFilter($event)"
                            (focus)="onFilterFocus()"
                            (blur)="blurFilterInput()" />
                    </span>
                </div>
            </ng-template>
            <!--default item-->
            <ng-template [ngIf]="defaultItem && !itemTemplate">
                <div
                    class="k-list"
                    [ngClass]="optionLabelSizeClass"
                >
                    <div class="k-list-optionlabel" [ngClass]="{ 'k-disabled': isDisabledDefaultItem }" kendoDropDownsSelectable [index]="-1">
                        {{ getDefaultItemText() }}
                    </div>
                </div>
            </ng-template>
            <ng-template [ngIf]="defaultItem && itemTemplate">
                <div
                    class="k-list"
                    [ngClass]="optionLabelSizeClass"
                >
                    <div class="k-list-optionlabel" [ngClass]="{ 'k-disabled': isDisabledDefaultItem }" kendoDropDownsSelectable [index]="-1">
                        <ng-template
                            [templateContext]="{
                                templateRef: itemTemplate.templateRef,
                                $implicit: defaultItem
                            }">
                        </ng-template>
                    </div>
                </div>
            </ng-template>
            <!--header template-->
            <ng-template *ngIf="headerTemplate"
                [templateContext]="{
                    templateRef: headerTemplate.templateRef
                }">
            </ng-template>
            <!--list-->
            <kendo-list
                #optionsList
                [size]="size"
                [rounded]="rounded"
                [id]="listBoxId"
                [optionPrefix]="optionPrefix"
                [data]="data"
                [textField]="textField"
                [valueField]="valueField"
                [template]="itemTemplate"
                [groupTemplate]="groupTemplate"
                [fixedGroupTemplate]="fixedGroupTemplate"
                [height]="listHeight"
                [show]="isOpen"
                [virtual]="virtual"
                (pageChange)="pageChange($event)"
                >
            </kendo-list>
            <!--no-data template-->
            <div class="k-no-data" *ngIf="data.length === 0">
                <ng-template [ngIf]="noDataTemplate"
                    [templateContext]="{
                        templateRef: noDataTemplate ? noDataTemplate.templateRef : undefined
                    }">
                </ng-template>
                <ng-template [ngIf]="!noDataTemplate">
                    <div>{{ messageFor('noDataText') }}</div>
                </ng-template>
            </div>
            <!--footer template-->
            <ng-template *ngIf="footerTemplate"
                [templateContext]="{
                    templateRef: footerTemplate.templateRef
                }">
            </ng-template>
        </ng-template>
        <ng-template [ngIf]="isOpen">
            <kendo-resize-sensor (resize)="onResize()"></kendo-resize-sensor>
        </ng-template>
        <ng-container #container></ng-container>
  `
    }),
    __param(10, Optional()), __param(10, Inject(TOUCH_ENABLED)),
    __metadata("design:paramtypes", [ElementRef,
        LocalizationService,
        PopupService,
        SelectionService,
        NavigationService,
        DisabledItemsService,
        DataService,
        NgZone,
        Renderer2,
        ChangeDetectorRef, Boolean])
], DropDownListComponent);

/**
 * Renders the content of the custom list item in the MultiSelect
 * ([see example]({% slug templates_multiselect %}#toc-customizing-the-item-content)).
 * The template context is set to the current component.
 * To get a reference to the current text that is typed by the
 * user, use the `let-customItem` directive.
 *
 * > The `CustomItemTemplate` directive can only be used with the MultiSelect component.
 *
 * @example
 * ```ts
 * _@Component({
 * selector: 'my-app',
 * template: `
 *  <kendo-multiselect [data]="listItems" [allowCustom]="true">
 *    <ng-template kendoMultiSelectCustomItemTemplate let-customItem>
 *      <span>New Item: {{customItem}}</span>
 *    </ng-template>
 *  </kendo-multiselect>
 * `
 * })
 * class AppComponent {
 *   public listItems: Array<string> = ["Item 1", "Item 2", "Item 3", "Item 4"];
 * }
 * ```
 *
 */
let CustomItemTemplateDirective = class CustomItemTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
CustomItemTemplateDirective = __decorate([
    Directive({
        selector: '[kendoMultiSelectCustomItemTemplate]'
    }),
    __metadata("design:paramtypes", [TemplateRef])
], CustomItemTemplateDirective);

/**
 * Renders the selected tag value of the MultiSelect
 * ([see example]({% slug templates_multiselect %}#toc-tag-template)).
 * The template context is set to the current component.
 * To get a reference to the current data item, use the `let-dataItem` directive.
 *
 * > The `TagTemplate` directive can only be used with the MultiSelect and MultiSelectTree components.
 *
 * @example
 * ```ts
 * _@Component({
 * selector: 'my-app',
 * template: `
 *  <kendo-multiselect [data]="items">
 *    <ng-template kendoMultiSelectTagTemplate let-dataItem>
 *      <span>{{dataItem}} option</span>
 *    </ng-template>
 *  </kendo-multiselect>
 * `
 * })
 * class AppComponent {
 *   public items: Array<string> = ["Item 1", "Item 2", "Item 3", "Item 4"];
 * }
 * ```
 */
let TagTemplateDirective = class TagTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
TagTemplateDirective = __decorate([
    Directive({
        selector: '[kendoMultiSelectTagTemplate],[kendoMultiSelectTreeTagTemplate]'
    }),
    __metadata("design:paramtypes", [TemplateRef])
], TagTemplateDirective);

/**
 * Renders the grouped tag values in the MultiSelect
 * ([see example]({% slug summarytagmode_multiselect %})).
 * The template context is set to the current component.
 * To get a reference to the current grouped
 * data items collection, use the `let-dataItems` directive.
 *
 * > The `GroupTagTemplate` directive can only be used with the MultiSelect and MultiSelectTree components.
 *
 * @example
 * ```ts
 * _@Component({
 * selector: 'my-app',
 * template: `
 *  <kendo-multiselect kendoMultiSelectSummaryTag [data]="items">
 *    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
 *      <span>{{dataItems.length}} item(s) selected</span>
 *    </ng-template>
 *  </kendo-multiselect>
 * `
 * })
 * class AppComponent {
 *   public items: Array<string> = ["Item 1", "Item 2", "Item 3", "Item 4"];
 * }
 * ```
 */
let GroupTagTemplateDirective = class GroupTagTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
GroupTagTemplateDirective = __decorate([
    Directive({
        selector: '[kendoMultiSelectGroupTagTemplate],[kendoMultiSelectTreeGroupTagTemplate]'
    }),
    __metadata("design:paramtypes", [TemplateRef])
], GroupTagTemplateDirective);

/**
 * Arguments for the `removeTag` event. The `removeTag` event fires when a tag is about
 * to the removed. If you cancel the event, the removal is prevented.
 */
class RemoveTagEvent extends PreventableEvent {
    /**
     * Constructs the event arguments for the `remove` event.
     * @param dataItem - The data item or an array of data items that will be removed.
     */
    constructor(dataItem) {
        super();
        this.dataItem = dataItem;
    }
}

/**
 * @hidden
 */
const normalizeCheckboxesSettings = (settings) => {
    if (isObject(settings)) {
        const defaultSettings = { enabled: true, checkOnClick: true };
        return Object.assign({}, defaultSettings, settings);
    }
    return { enabled: Boolean(settings), checkOnClick: true };
};

/* tslint:disable:member-ordering */
var MultiSelectComponent_1;
const MULTISELECT_VALUE_ACCESSOR = {
    multi: true,
    provide: NG_VALUE_ACCESSOR,
    // tslint:disable-next-line:no-use-before-declare
    useExisting: forwardRef(() => MultiSelectComponent)
};
/**
 * Represents the [Kendo UI MultiSelect component for Angular]({% slug overview_multiselect %}).
 *
 * @example
 * ```ts
 * _@Component({
 * selector: 'my-app',
 * template: `
 *  <kendo-multiselect [data]="listItems">
 *  </kendo-multiselect>
 * `
 * })
 * class AppComponent {
 *   public listItems: Array<string> = ["Item 1", "Item 2", "Item 3", "Item 4"];
 * }
 * ```
 */
let MultiSelectComponent = MultiSelectComponent_1 = class MultiSelectComponent {
    constructor(wrapper, localization, popupService, dataService, selectionService, navigationService, disabledItemsService, cdr, differs, renderer, _zone, touchEnabled$$1) {
        this.wrapper = wrapper;
        this.localization = localization;
        this.popupService = popupService;
        this.dataService = dataService;
        this.selectionService = selectionService;
        this.navigationService = navigationService;
        this.disabledItemsService = disabledItemsService;
        this.cdr = cdr;
        this.differs = differs;
        this.renderer = renderer;
        this._zone = _zone;
        this.touchEnabled = touchEnabled$$1;
        this.listBoxId = guid$1();
        this.tagListId = guid$1();
        this.tagPrefix = "tag-" + guid$1();
        this.optionPrefix = "option-" + guid$1();
        this.focusedTagIndex = undefined;
        /**
         * @hidden
         */
        this.focusableId = `k-${guid$1()}`;
        /**
         * Determines whether to close the options list of the MultiSelect after the item selection is finished
         * ([see example]({% slug openstate_multiselect %}#toc-keeping-the-options-list-open-while-on-focus)).
         * @default true
         */
        this.autoClose = true;
        /**
         * Specifies the [`tabindex`](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex) of the component.
         */
        this.tabindex = 0;
        /**
         * Sets the disabled state of the component.
         */
        this.disabled = false;
        /**
         * Sets the read-only state of the component.
         */
        this.readonly = false;
        /**
         * Enables the [filtering]({% slug filtering_multiselect %}) functionality of the MultiSelect.
         */
        this.filterable = false;
        /**
         * Sets the height of the suggestions list. By default, `listHeight` is 200px.
         *
         * > The `listHeight` property affects only the list of suggestions and not the whole popup container.
         * > To set the height of the popup container, use `popupSettings.height`.
         */
        this.listHeight = 200;
        /**
         * If set to `true`, renders a button on hovering over the component.
         * Clicking this button resets the value of the component to an empty array and triggers the `change` event.
         */
        this.clearButton = true;
        /**
         * A user-defined callback function which receives an array of selected data items and maps them to an array of tags
         * ([see examples]({% slug summarytagmode_multiselect %}#toc-rendering-of-tags)).
         *
         * @param { Any[] } dataItems - The selected data items from the list.
         * @returns { Any[] } - The tags that will be rendered by the component.
         */
        this.tagMapper = (tags) => tags || [];
        /**
         * Specifies whether the MultiSelect allows user-defined values that are not present in the dataset
         * ([more information and examples]({% slug custom_values_multiselect %})).
         * Defaults to `false`.
         */
        this.allowCustom = false;
        /**
         * A user-defined callback function which returns normalized custom values.
         * Typically used when the data items are different from type `string`.
         *
         * @param { Any } value - The custom value that is defined by the user.
         * @returns { Any }
         *
         * @example
         * ```ts
         * import { map } from 'rxjs/operators';
         *
         * _@Component({
         * selector: 'my-app',
         * template: `
         *   <kendo-multiselect
         *       [allowCustom]="true"
         *       [data]="listItems"
         *       textField="text"
         *       valueField="value"
         *       [valueNormalizer]="valueNormalizer"
         *       (valueChange)="onValueChange($event)"
         *   >
         *   </kendo-multiselect>
         * `
         * })
         *
         * class AppComponent {
         *   public listItems: Array<{ text: string, value: number }> = [
         *       { text: "Small", value: 1 },
         *       { text: "Medium", value: 2 },
         *       { text: "Large", value: 3 }
         *   ];
         *
         *   public onValueChange(value) {
         *       console.log("valueChange : ", value);
         *   }
         *
         *   public valueNormalizer = (text$: Observable<string>) => text$.pipe(map((text: string) => {
         *      return {
         *         value: Math.floor(Math.random() * (1000 - 100) + 1000), //generate unique valueField
         *         text: text };
         *   }));
         *
         * }
         * ```
         */
        this.valueNormalizer = (text) => text.pipe(map((userInput) => {
            const comparer = (item) => typeof item === 'string' && userInput.toLowerCase() === item.toLowerCase();
            const matchingValue = this.value.find(comparer);
            if (matchingValue) {
                return matchingValue;
            }
            const matchingItem = this.dataService.find(comparer);
            return matchingItem ? matchingItem : userInput;
        }));
        /**
         * Fires each time the user types in the input field.
         * You can filter the source based on the passed filtration value.
         */
        this.filterChange = new EventEmitter();
        /**
         * Fires each time the value is changed&mdash;
         * when the component is blurred or the value is cleared through the **Clear** button
         * ([see example]({% slug overview_multiselect %}#toc-events)).
         * When the value of the component is programmatically changed to `ngModel` or `formControl`
         * through its API or form binding, the `valueChange` event is not triggered because it
         * might cause a mix-up with the built-in `valueChange` mechanisms of the `ngModel` or `formControl` bindings.
         */
        this.valueChange = new EventEmitter();
        /**
         * Fires each time the popup is about to open
         * ([see example]({% slug openstate_multiselect %}#toc-preventing-opening-and-closing)).
         * This event is preventable. If you cancel it, the popup will remain closed.
         */
        this.open = new EventEmitter();
        /**
         * Fires after the popup has been opened.
         */
        this.opened = new EventEmitter();
        /**
         * Fires each time the popup is about to close
         * ([see example]({% slug openstate_multiselect %}#toc-preventing-opening-and-closing)).
         * This event is preventable. If you cancel it, the popup will remain open.
         */
        this.close = new EventEmitter();
        /**
         * Fires after the popup has been closed.
         */
        this.closed = new EventEmitter();
        /**
         * Fires each time the user focuses the MultiSelect.
         */
        this.onFocus = new EventEmitter(); //tslint:disable-line:no-output-rename
        /**
         * Fires each time the MultiSelect gets blurred.
         */
        this.onBlur = new EventEmitter(); //tslint:disable-line:no-output-rename
        /**
         * Fires each time a tag is about to be removed.
         * This event is preventable. If you cancel it, the tag will not be removed.
         */
        this.removeTag = new EventEmitter();
        this.hostClasses = true;
        this.role = 'combobox';
        this.initialized = false;
        this._size = 'medium';
        this._rounded = 'medium';
        this._fillMode = 'solid';
        this.onChangeCallback = (_) => { };
        this.onTouchedCallback = (_) => { };
        this._placeholder = '';
        this._open = false;
        this._value = [];
        this._popupSettings = { animate: true };
        this._checkboxes = { enabled: false };
        this._isFocused = false;
        this.selectedDataItems = [];
        this.customValueSubject = new Subject();
        this.observableSubscriptions = new Subscription();
        validatePackage(packageMetadata);
        this.popupMouseDownHandler = this.onMouseDown.bind(this);
        this.data = [];
        this.direction = this.localization.rtl ? 'rtl' : 'ltr';
        this.subscribeEvents();
        this.subscribeTouchEvents();
    }
    /**
     * Focuses a specific item of the MultiSelect based on a provided index.
     * If there is a custom item it is positioned at index -1.
     * If null or invalid index is provided the focus will be removed.
     */
    focusItemAt(index) {
        const minIndex = this.allowCustom ? -1 : 0;
        const isInRange = minIndex <= 0 && index < this.data.length;
        if (isPresent(index) && isInRange && !this.disabledItemsService.isIndexDisabled(index)) {
            this.selectionService.focus(index);
        }
        else {
            this.selectionService.focus(null);
        }
    }
    /**
     * Focuses the MultiSelect.
     */
    focus() {
        if (!this.disabled) {
            this.searchbar.focus();
        }
    }
    /**
     * @hidden
     */
    onSearchBarFocus() {
        if (!this.isFocused) {
            this.isFocused = true;
            if (hasObservers(this.onFocus)) {
                this._zone.run(() => {
                    this.onFocus.emit();
                });
            }
        }
    }
    /**
     * Blurs the MultiSelect.
     */
    blur() {
        if (!this.disabled) {
            this.searchbar.blur();
        }
    }
    /**
     * @hidden
     */
    onSearchBarBlur() {
        if (!this.isFocused) {
            return;
        }
        this.isFocused = false;
        if (hasObservers(this.onBlur) ||
            hasObservers(this.filterChange) ||
            hasObservers(this.close) ||
            isUntouched(this.wrapper.nativeElement)) {
            this._zone.run(() => {
                this.closePopup();
                if (!(this.isOpen && this.allowCustom)) {
                    this.clearFilter();
                }
                this.onBlur.emit();
                this.onTouchedCallback();
            });
        }
        else {
            if (!this.allowCustom) {
                this.clearFilter();
            }
            this.closePopup();
        }
    }
    /**
     * @hidden
     */
    onMouseDown(event) {
        event.preventDefault();
    }
    /**
     * @hidden
     */
    onResize() {
        if (this._open) {
            const popupWrapper = this.popupRef.popupElement;
            const { min, max } = this.width;
            popupWrapper.style.minWidth = min;
            popupWrapper.style.width = max;
        }
    }
    get appendTo() {
        const { appendTo } = this.popupSettings;
        if (!appendTo || appendTo === 'root') {
            return undefined;
        }
        return appendTo === 'component' ? this.container : appendTo;
    }
    /**
     * Sets the data of the MultiSelect.
     *
     * > The data has to be provided in an array-like list of items.
     */
    set data(data) {
        this.dataService.data = data || [];
        if (this.virtual) {
            this.virtual.skip = 0;
        }
        if (this.initialized) {
            this.setState(this.value);
        }
    }
    get data() {
        const virtual = this.virtual;
        if (virtual) {
            const start = virtual.skip || 0;
            const end = start + virtual.pageSize;
            //Use length instead of itemsCount because of grouping
            virtual.total = this.dataService.data.length;
            return this.dataService.data.slice(start, end);
        }
        return this.dataService.data;
    }
    /**
     * Sets the value of the MultiSelect. It can be either of the primitive (string, numbers) or of the complex (objects) type.
     * To define the type, use the `valuePrimitive` option.
     *
     * > All selected values which are not present in the source are ignored.
     */
    set value(values) {
        this._value = values ? values : [];
        if (!this.differ && this.value) {
            this.differ = this.differs.find(this.value).create();
        }
        this.valueChangeDetected = true;
        if (this.initialized) {
            this.setState(this.value);
        }
    }
    get value() {
        return this._value;
    }
    /**
     * @hidden
     */
    set tabIndex(tabIndex) {
        this.tabindex = tabIndex;
    }
    get tabIndex() {
        return this.tabindex;
    }
    /**
     * Sets the size of the component.
     *
     * The possible values are:
     * * `'small'`
     * * `'medium'` (default)
     * * `'large'`
     * * `null`
     *
     */
    set size(size) {
        this.renderer.removeClass(this.wrapper.nativeElement, getSizeClass('input', this.size));
        if (size) {
            this.renderer.addClass(this.wrapper.nativeElement, getSizeClass('input', size));
        }
        this._size = size;
    }
    get size() {
        return this._size;
    }
    /**
     * Sets the border radius of the component.
     *
     * The possible values are:
     * * `'small'`
     * * `'medium'` (default)
     * * `'large'`
     * * `'full'`
     * * `null`
     *
     */
    set rounded(rounded) {
        this.renderer.removeClass(this.wrapper.nativeElement, getRoundedClass(this.rounded));
        if (rounded) {
            this.renderer.addClass(this.wrapper.nativeElement, getRoundedClass(rounded));
        }
        this._rounded = rounded;
    }
    get rounded() {
        return this._rounded;
    }
    /**
     * Sets the fillMode of the component.
     *
     * The possible values are:
     * * `'flat'`
     * * `'solid'` (default)
     * * `'outline'`
     * * `null`
     *
     */
    set fillMode(fillMode) {
        this.renderer.removeClass(this.wrapper.nativeElement, getFillModeClass('input', this.fillMode));
        if (fillMode) {
            this.renderer.addClass(this.wrapper.nativeElement, getFillModeClass('input', fillMode));
        }
        this._fillMode = fillMode;
    }
    get fillMode() {
        return this._fillMode;
    }
    /**
     * The hint which is displayed when the component is empty.
     * When the values are selected, it disappears.
     */
    set placeholder(text) {
        this._placeholder = text || '';
    }
    get placeholder() {
        return this.selectedDataItems.length ? '' : this._placeholder;
    }
    /**
     * Defines a Boolean function that is executed for each data item in the component
     * ([see examples]({% slug disableditems_multiselect %})). Determines whether the item will be disabled.
     */
    set itemDisabled(fn) {
        if (typeof fn !== 'function') {
            throw new Error(`itemDisabled must be a function, but received ${JSON.stringify(fn)}.`);
        }
        this.disabledItemsService.itemDisabled = fn;
    }
    /**
     * Specifies whether checkboxes will be rendered before each item in the popup list.
     */
    set checkboxes(settings) {
        this._checkboxes = normalizeCheckboxesSettings(settings);
    }
    get checkboxes() {
        return this._checkboxes;
    }
    /**
     * Enables the [virtualization]({% slug virtualization_multiselect %}) functionality.
     */
    set virtual(settings) {
        this._virtualSettings = normalizeVirtualizationSettings(settings);
    }
    get virtual() {
        return this._virtualSettings;
    }
    /**
     * Configures the popup of the MultiSelect.
     *
     * The available options are:
     * - `animate: Boolean`&mdash;Controls the popup animation. By default, the open and close animations are enabled.
     * - `width: Number | String`&mdash;Sets the width of the popup container. By default, the width of the host element is used. If set to `auto`, the component automatically adjusts the width of the popup and no item labels are wrapped. The `auto` mode is not supported when virtual scrolling is enabled.
     * - `height: Number`&mdash;Sets the height of the popup container.
     * - `popupClass: String`&mdash;Specifies a list of CSS classes that are used to style the popup.
     * - `appendTo: "root" | "component" | ViewContainerRef`&mdash;Specifies the component to which the popup will be appended.
     */
    set popupSettings(settings) {
        this._popupSettings = Object.assign({ animate: true }, settings);
    }
    get popupSettings() {
        return this._popupSettings;
    }
    /**
     * Specifies the type of the selected value.
     * If set to `true`, the selected value has to be of the primitive type
     * ([more information and example]({% slug valuebinding_multiselect %}#toc-primitive-values-from-object-fields)).
     */
    set valuePrimitive(isPrimitive) {
        this._valuePrimitive = isPrimitive;
    }
    get valuePrimitive() {
        if (!isPresent(this._valuePrimitive)) {
            return !isPresent(this.valueField);
        }
        return this._valuePrimitive;
    }
    get dir() {
        return this.direction;
    }
    get disabledClass() {
        return this.disabled;
    }
    get isLoading() {
        return this.loading;
    }
    get isAriaExpanded() {
        return this.isOpen;
    }
    /**
     * @hidden
     */
    hostMousedown(event) {
        const inputElement = this.searchbar.input.nativeElement;
        if (event.button === 0) {
            if (this.isFocused && this.isOpen && event.target === inputElement) {
                return;
            }
            if (!this.touchEnabled || (this.touchEnabled && event.target.tagName !== 'SPAN')) {
                this.searchbar.focus();
            }
            this.togglePopup(!this.isOpen);
            event.preventDefault();
        }
    }
    get listContainerClasses() {
        const containerClasses = ['k-list-container', 'k-reset'];
        if (this.popupSettings.popupClass) {
            containerClasses.push(this.popupSettings.popupClass);
        }
        return containerClasses;
    }
    /**
     * @hidden
     */
    get customItemSizeClass() {
        return `${this.size ? getSizeClass('list', this.size) : ''}`;
    }
    get width() {
        let wrapperOffsetWidth = 0;
        if (isDocumentAvailable()) {
            wrapperOffsetWidth = this.wrapper.nativeElement.offsetWidth;
        }
        const width = this.popupSettings.width || wrapperOffsetWidth;
        const minWidth = isNaN(wrapperOffsetWidth) ? wrapperOffsetWidth : `${wrapperOffsetWidth}px`;
        const maxWidth = isNaN(width) ? width : `${width}px`;
        return { min: minWidth, max: maxWidth };
    }
    get height() {
        const popupHeight = this.popupSettings.height;
        return isPresent(popupHeight) ? `${popupHeight}px` : 'auto';
    }
    get activeDescendant() {
        const focusedTagIndex = this.focusedTagIndex;
        const focusedListIndex = this.selectionService.focused;
        let prefix;
        let item;
        if (isPresent(focusedTagIndex) && !this.isOpen) {
            item = this.tags[focusedTagIndex];
            prefix = this.tagPrefix;
        }
        else if (isPresent(focusedListIndex) && focusedListIndex !== -1 && this.isOpen) {
            item = this.dataService.itemAt(focusedListIndex);
            prefix = this.optionPrefix;
        }
        else {
            return null;
        }
        return prefix + "-" + getter$1(item, this.valueField);
    }
    get noDataLabel() {
        if (this.dataService.itemsCount === 0) {
            return this.messageFor('noDataText');
        }
    }
    get clearButtonVisiblity() {
        if (this.touchEnabled) {
            return 'visible';
        }
    }
    /**
     * @hidden
     */
    verifySettings() {
        if (!isDevMode() || this.value.length === 0) {
            return;
        }
        if (!isArray(this.value)) {
            throw new Error(MultiselectMessages.array);
        }
        if (this.valuePrimitive === true && isObjectArray(this.value)) {
            throw new Error(MultiselectMessages.primitive);
        }
        if (this.valuePrimitive === false && !isObjectArray(this.value)) {
            throw new Error(MultiselectMessages.object);
        }
        const valueOrText = !isPresent(this.valueField) !== !isPresent(this.textField);
        if (valueOrText) {
            throw new Error(MultiselectMessages.textAndValue);
        }
    }
    /**
     * @hidden
     */
    change(event) {
        const isCustomItem = (isPresent(event.added) || isPresent(event.removed)) && (event.added === -1 || event.removed === -1);
        if (isCustomItem) {
            this.addCustomValue(this.text);
            return; // The change is emited asynchronosly.
        }
        // Existing items.
        if (isPresent(event.added)) {
            const dataItem = this.dataService.itemAt(event.added);
            const newItem = (this.valuePrimitive && isPresent(dataItem) && isPresent(dataItem[this.valueField])) ? dataItem[this.valueField] : dataItem;
            this.value = [...this.value, newItem];
        }
        if (isPresent(event.removed)) {
            const dataItem = this.dataService.itemAt(event.removed);
            const filter$$1 = (item) => getter$1(item, this.valueField) !== getter$1(dataItem, this.valueField);
            this.value = this.value.filter(filter$$1);
            this.selectionService.focused = event.removed;
            this.cdr.detectChanges();
        }
        this.emitValueChange();
    }
    /**
     * @hidden
     */
    setState(value) {
        let data = this.dataService.data;
        if (this.dataService.grouped) {
            data = data.filter(item => !item.header).map(item => item.value);
        }
        const selection = selectedIndices(this.value, data, this.valueField);
        this.selectionService.resetSelection(selection);
        if (this.disabledItemsService.isIndexDisabled(this.selectionService.focused)) {
            this.selectionService.focused = this.firstFocusableIndex(0);
        }
        if (this.isOpen && this.selectionService.focused === undefined) {
            if (this.dataService.itemsCount > 0) {
                this.selectionService.focused = this.firstFocusableIndex(0);
            }
            else if (this.allowCustom) {
                this.selectionService.focused = -1;
            }
        }
        if (this.valuePrimitive && !this.valueField) {
            this.selectedDataItems = value.slice();
        }
        if (isObjectArray(value) || this.valuePrimitive && this.valueField) {
            this.selectedDataItems = resolveAllValues(value, data, this.valueField);
        }
        if (this.selectedDataItems.length < value.length) {
            this.selectedDataItems = value
                .map(current => {
                const dataItem = this.selectedDataItems.find(item => getter$1(item, this.valueField) === getter$1(current, this.valueField));
                return isPresent(dataItem) ? dataItem : this.resolveDataItemFromTags(current);
            })
                .filter(dataItem => isPresent(dataItem));
        }
        this.tags = this.tagMapper(this.selectedDataItems.slice(0));
        this.disabledIndices = this.disabledItemsMapper();
        this.cdr.markForCheck();
    }
    /**
     * @hidden
     */
    handleFilter(text) {
        this.text = text;
        if (text && !this.isOpen) {
            this.openPopup();
        }
        if (this.filterable) {
            this.filterChange.emit(text);
        }
        else {
            this.searchTextAndFocus(text);
        }
        this.searchbar.setInputSize();
    }
    /**
     * @hidden
     */
    pageChange(event) {
        const virtual = this.virtual;
        virtual.skip = event.skip;
    }
    /**
     * @hidden
     */
    clearFilter() {
        if (this.filterable && this.text) {
            this.filterChange.emit("");
        }
        this.text = "";
        /* Clearing the value from the input as the setInputSize calculation will be incorrect otherwise.
         Calling cdr.detectChanges to clear the input value as a result of property binding
         causes JAWS to read outdated tag values in IE upon tag selection for some reason. */
        this.searchbar.input.nativeElement.value = "";
        this.searchbar.setInputSize();
    }
    /**
     * @hidden
     */
    handleNavigate(event) {
        const navigateInput = this.text && event.keyCode !== Keys.ArrowDown && event.keyCode !== Keys.ArrowUp;
        const selectValue = this.text && event.keyCode === Keys.Enter || event.keyCode === Keys.Escape;
        const deleteTag = !this.text && event.keyCode === Keys.Backspace && this.tags.length > 0;
        if (deleteTag) {
            this.handleBackspace();
            return;
        }
        if (this.disabled || navigateInput && !selectValue) {
            return;
        }
        const eventData = event;
        const focused = isNaN(this.selectionService.focused) ? -1 : this.selectionService.focused;
        const action = this.navigationService.process({
            current: focused,
            max: this.dataService.itemsCount - 1,
            min: this.allowCustom && this.text ? -1 : 0,
            open: this.isOpen,
            originalEvent: eventData
        });
        if (action !== NavigationAction.Undefined &&
            ((action === NavigationAction.Enter && this.isOpen) || action !== NavigationAction.Enter)) {
            event.preventDefault();
        }
    }
    /**
     * @hidden
     */
    handleRemoveTag({ tag }) {
        const eventArgs = new RemoveTagEvent(tag);
        if (this.disabled || this.readonly) {
            return;
        }
        this.focus();
        this.removeTag.emit(eventArgs);
        if (eventArgs.isDefaultPrevented()) {
            return;
        }
        if (tag instanceof Array) {
            this.removeGroupTag(tag);
        }
        else {
            this.removeSingleTag(tag);
        }
        this.cdr.detectChanges();
    }
    /**
     * @hidden
     */
    clearAll(event) {
        event.stopImmediatePropagation();
        event.preventDefault();
        this.focus();
        this.clearFilter();
        const selected = this.selectionService.selected;
        this.value = this.value.filter((_item, index) => this.disabledItemsService.isIndexDisabled(selected[index]));
        this.emitValueChange();
    }
    /**
     * @hidden
     */
    addCustomValue(text) {
        this.customValueSubject.next(text);
    }
    ngAfterContentChecked() {
        this.verifySettings();
    }
    ngDoCheck() {
        const valueChanges = this.differ && this.differ.diff(this.value);
        if (valueChanges && !this.valueChangeDetected) {
            this.setState(this.value);
        }
        this.valueChangeDetected = false;
    }
    ngOnInit() {
        this.renderer.removeAttribute(this.wrapper.nativeElement, "tabindex");
        this.createCustomValueStream();
        this.localizationChangeSubscription = this.localization
            .changes.subscribe(({ rtl }) => {
            this.direction = rtl ? 'rtl' : 'ltr';
            this.cdr.markForCheck();
        });
        this.setState(this.value);
        this.setComponentClasses();
        this.initialized = true;
    }
    ngOnChanges(changes) {
        const virtual = this.virtual;
        const requestInitialData = virtual && changes.data && changes.data.isFirstChange();
        if (requestInitialData) {
            this.pageChange({ skip: 0, take: virtual.pageSize });
        }
        if (isChanged('valueNormalizer', changes)) {
            this.createCustomValueStream();
        }
        if (anyChanged(['textField', 'valueField', 'valuePrimitive'], changes)) {
            this.setState(this.value);
        }
    }
    ngAfterViewInit() {
        this.searchbar.setInputSize();
    }
    ngOnDestroy() {
        this._toggle(false);
        this.unsubscribeEvents();
    }
    /**
     * Toggles the visibility of the popup
     * ([see example]({% slug openstate_multiselect %}#toc-setting-the-initially-opened-component)).
     * If you use the `toggle` method to open or close the popup, the respective `open` and `close` events will not be fired.
     *
     * @param open - The state of the popup.
     */
    toggle(open) {
        // The Promise is required for opening the popup on load.
        // Otherwise, the "Expression has changed..." type error will be thrown.
        Promise.resolve(null).then(() => {
            const shouldOpen = isPresent(open) ? open : !this._open;
            this._toggle(shouldOpen);
            this.cdr.markForCheck();
        });
    }
    /**
     * Returns the current open state of the popup.
     */
    get isOpen() {
        return this._open;
    }
    /**
     * Resets the value of the MultiSelect.
     * If you use the `reset` method to clear the value of the component,
     * the model will not update automatically and the `selectionChange` and `valueChange` events will not be fired.
     */
    reset() {
        this.text = "";
        this.value = [];
    }
    /**
     * @hidden
     */
    messageFor(key) {
        return this.localization.get(key);
    }
    // NG MODEL BINDINGS
    /**
     * @hidden
     */
    writeValue(value) {
        this.value = value || [];
    }
    /**
     * @hidden
     */
    registerOnChange(fn) {
        this.onChangeCallback = fn;
    }
    /**
     * @hidden
     */
    registerOnTouched(fn) {
        this.onTouchedCallback = fn;
    }
    /**
     * @hidden
     */
    setDisabledState(isDisabled) {
        this.disabled = isDisabled;
    }
    /**
     * @hidden
     */
    onTagMapperChange() {
        this.tags = this.tagMapper(this.selectedDataItems.slice(0));
        this.cdr.markForCheck();
    }
    set isFocused(isFocused) {
        this.renderer[isFocused ? 'addClass' : 'removeClass'](this.wrapper.nativeElement, 'k-focus');
        this._isFocused = isFocused;
    }
    get isFocused() {
        return this._isFocused;
    }
    subscribeEvents() {
        if (!isDocumentAvailable()) {
            return;
        }
        const isOpen = () => this.isOpen;
        const isClosed = () => !this.isOpen;
        const isTagFocused = () => !this.isOpen && this.focusedTagIndex !== undefined;
        [
            this.selectionService.onChange.subscribe(this.handleItemChange.bind(this)),
            this.navigationService.esc.subscribe(this.closePopup.bind(this)),
            this.navigationService.enter.pipe(filter(isOpen)).subscribe(this.handleEnter.bind(this)),
            this.navigationService.open.subscribe(this.openPopup.bind(this)),
            this.navigationService.close.subscribe(this.handleClose.bind(this)),
            this.navigationService.up.pipe(filter(isOpen)).subscribe((event) => this.handleUp(event.index)),
            this.navigationService.home.pipe(filter(() => isClosed)).subscribe(this.handleHome.bind(this)),
            this.navigationService.end.pipe(filter(() => isClosed)).subscribe(this.handleEnd.bind(this)),
            this.navigationService.backspace.pipe(filter(isTagFocused)).subscribe(this.handleBackspace.bind(this)),
            this.navigationService.delete.pipe(filter(isTagFocused)).subscribe(this.handleDelete.bind(this)),
            this.navigationService.left.subscribe(this.direction === 'rtl' ? this.handleRightKey.bind(this) : this.handleLeftKey.bind(this)),
            this.navigationService.right.subscribe(this.direction === 'rtl' ? this.handleLeftKey.bind(this) : this.handleRightKey.bind(this)),
            this.navigationService.down.subscribe((event) => this.handleDownKey(event.index))
        ].forEach(s => this.observableSubscriptions.add(s));
    }
    subscribeTouchEvents() {
        if (!isDocumentAvailable() || !this.touchEnabled) {
            return;
        }
        this._zone.runOutsideAngular(() => 
        // Roll up MultiSelect on iOS when tapped outside
        this.touchstartDisposeHandler = this.renderer.listen(document, 'touchstart', (e) => {
            const target = e.target;
            if ((this.isFocused || this.isOpen) && !inDropDown(this.wrapper, target, this.popupRef)) {
                this._zone.run(() => {
                    this.blur();
                    if (this.isOpen) {
                        this.togglePopup(false);
                    }
                });
            }
        }));
    }
    unsubscribeEvents() {
        if (!isDocumentAvailable()) {
            return;
        }
        this.observableSubscriptions.unsubscribe();
        if (this.customValueSubscription) {
            this.customValueSubscription.unsubscribe();
        }
        if (this.localizationChangeSubscription) {
            this.localizationChangeSubscription.unsubscribe();
        }
        if (this.touchstartDisposeHandler) {
            this.touchstartDisposeHandler();
        }
    }
    removeGroupTag(dataItems) {
        let data = this.dataService.data;
        if (this.dataService.grouped) {
            data = data.filter(item => !item.header).map(item => item.value);
        }
        const dataItemValues = new Set(dataItems.map(item => getter$1(item, this.valueField)));
        this.value = this.value.filter(value => {
            const index = selectedIndices([value], data, this.valueField)[0];
            const isDataItemDisabled = this.disabledItemsService.isIndexDisabled(index);
            return !dataItemValues.has(getter$1(value, this.valueField)) || isDataItemDisabled;
        });
        this.emitValueChange();
    }
    removeSingleTag(dataItem) {
        let data = this.dataService.data;
        if (this.dataService.grouped) {
            data = data.filter(item => !item.header).map(item => item.value);
        }
        const index = selectedIndices([dataItem], data, this.valueField)[0];
        if (this.disabledItemsService.isIndexDisabled(index)) {
            return;
        }
        if (isNumber(index)) {
            this.selectionService.unselect(index);
            this.selectionService.focused = index;
            this.togglePopup(false);
        }
        else { // the deleted item is not present in the source
            const filter$$1 = item => getter$1(item, this.valueField) !== getter$1(dataItem, this.valueField);
            this.value = this.value.filter(filter$$1);
            this.emitValueChange();
        }
    }
    /**
     * @hidden
     *
     * Determines which of the provided tags should be disabled and stores their position indices
     */
    disabledItemsMapper() {
        const { selected } = this.selectionService;
        return new Set(this.selectedDataItems.reduce((indices, _item, index) => {
            if (this.disabledItemsService.isIndexDisabled(selected[index])) {
                indices.push(index);
            }
            return indices;
        }, []));
    }
    createCustomValueStream() {
        if (this.customValueSubscription) {
            this.customValueSubscription.unsubscribe();
        }
        this.customValueSubscription = this.customValueSubject.pipe(tap(() => {
            this.loading = true;
            this.disabled = true;
            this.cdr.detectChanges();
        }), this.valueNormalizer, catchError(() => {
            this.loading = false;
            this.disabled = false;
            if (this.autoClose) {
                this.togglePopup(false);
            }
            if (this.autoClose || !this.filterable) {
                this.clearFilter();
            }
            this.nextTick(() => {
                this.searchbar.focus();
            });
            this.createCustomValueStream();
            return of(null);
        }))
            .subscribe((normalizedValue) => {
            this.loading = false;
            this.disabled = false;
            if (isPresent(normalizedValue)) { // if valueNormalizer returns `null` or `undefined` custom value is discarded
                const newValue = this.valuePrimitive ? getter$1(normalizedValue, this.valueField) : normalizedValue;
                const itemIndex = this.dataService.indexOf(newValue);
                const customItem = itemIndex === -1;
                if (this.value.indexOf(newValue) === -1) {
                    this.tags = this.tagMapper([...this.selectedDataItems, normalizedValue]);
                    if (!customItem) {
                        this.selectionService.add(itemIndex);
                    }
                    else {
                        this.value = [...this.value, newValue];
                    }
                }
                else {
                    if (!customItem && this.selectionService.isSelected(itemIndex)) {
                        this.selectionService.unselect(itemIndex);
                        this.selectionService.focused = itemIndex;
                    }
                    else {
                        this.value = this.value.filter(item => getter$1(item, this.valueField) !== newValue);
                    }
                }
                this.emitValueChange();
            }
            if (this.autoClose) {
                this.togglePopup(false);
            }
            if (this.autoClose || !this.filterable) {
                this.clearFilter();
            }
            this.nextTick(() => {
                this.searchbar.focus();
            });
        });
    }
    handleItemChange(event) {
        this.change(event);
        if (this.autoClose) {
            this.togglePopup(false);
        }
        if (this.autoClose || !this.filterable) {
            this.clearFilter();
        }
    }
    handleEnter(event) {
        const service = this.selectionService;
        const focusedIndex = this.selectionService.focused;
        if (this.isOpen) {
            event.originalEvent.preventDefault();
        }
        if (focusedIndex === -1) {
            if (this.allowCustom && this.text) {
                this.addCustomValue(this.text);
            }
            return; // Clear filter & close are done at customValueSubscription due to race conditions.
        }
        if (service.isSelected(focusedIndex)) {
            service.unselect(focusedIndex);
            service.focused = focusedIndex;
        }
        else {
            service.add(focusedIndex);
        }
        if (this.autoClose) {
            this.togglePopup(false);
        }
        if (this.autoClose || !this.filterable) {
            this.clearFilter();
        }
    }
    handleClose() {
        this.closePopup();
        this.searchbar.focus();
    }
    handleEnd() {
        this.focusedTagIndex = this.tags.length - 1;
    }
    handleHome() {
        this.focusedTagIndex = 0;
    }
    handleUp(index) {
        this.selectionService.focused = index;
    }
    handleBackspace() {
        if (this.focusedTagIndex !== undefined) {
            this.handleDelete();
        }
        else {
            this.handleRemoveTag({ tag: this.tags[this.tags.length - 1] });
            this.searchbar.focus();
        }
    }
    handleDelete() {
        this.handleRemoveTag({ tag: this.tags[this.focusedTagIndex] });
        if (this.focusedTagIndex === this.tags.length) {
            this.focusedTagIndex = undefined;
        }
    }
    handleLeftKey() {
        if (this.focusedTagIndex === undefined || this.focusedTagIndex < 0) {
            this.focusedTagIndex = this.tags.length - 1;
        }
        else if (this.focusedTagIndex !== 0) {
            this.focusedTagIndex--;
        }
    }
    handleDownKey(index) {
        if (this.isOpen) {
            this.selectionService.focused = index || this.firstFocusableIndex(0);
        }
        else {
            this.openPopup();
        }
    }
    handleRightKey() {
        const last = this.tags.length - 1;
        if (this.focusedTagIndex === last) {
            this.focusedTagIndex = undefined;
        }
        else if (this.focusedTagIndex < last) {
            this.focusedTagIndex++;
        }
    }
    findIndex(text, startsFrom = 0) {
        let itemText;
        text = text.toLowerCase();
        let index = this.dataService.findIndex(item => {
            if (this.dataService.grouped) {
                itemText = getter$1(item.value, this.textField);
            }
            else {
                itemText = getter$1(item, this.textField);
            }
            itemText = !isPresent(itemText) ? "" : itemText.toString().toLowerCase();
            return text && itemText.startsWith(text);
        }, startsFrom);
        if (this.disabledItemsService.isIndexDisabled(index)) {
            return (index + 1 > this.dataService.itemsCount) ? -1 : this.findIndex(text, index + 1);
        }
        else {
            return index;
        }
    }
    searchTextAndFocus(text) {
        const index = this.findIndex(text);
        this.selectionService.focused = index;
    }
    closePopup() {
        this.togglePopup(false);
        this.focusedTagIndex = undefined;
    }
    openPopup() {
        this.togglePopup(true);
        this.focusedTagIndex = undefined;
    }
    togglePopup(open) {
        const isDisabled = this.disabled || this.readonly;
        const sameState = this.isOpen === open;
        if (isDisabled || sameState) {
            return;
        }
        const isDefaultPrevented = this.triggerPopupEvents(open);
        if (!isDefaultPrevented) {
            this._toggle(open);
        }
    }
    triggerPopupEvents(open) {
        const eventArgs = new PreventableEvent();
        if (open) {
            this.open.emit(eventArgs);
        }
        else {
            this.close.emit(eventArgs);
        }
        return eventArgs.isDefaultPrevented();
    }
    _toggle(open) {
        this._open = open;
        this.destroyPopup();
        if (this._open) {
            this.createPopup();
        }
    }
    destroyPopup() {
        if (this.popupRef) {
            this.popupRef.popupElement
                .removeEventListener('mousedown', this.popupMouseDownHandler);
            this.popupRef.close();
            this.popupRef = null;
        }
    }
    createPopup() {
        if (this.virtual) {
            this.virtual.skip = 0;
        }
        const horizontalAlign = this.direction === "rtl" ? "right" : "left";
        const anchorPosition = { horizontal: horizontalAlign, vertical: "bottom" };
        const popupPosition = { horizontal: horizontalAlign, vertical: "top" };
        this.popupRef = this.popupService.open({
            anchor: this.wrapper,
            anchorAlign: anchorPosition,
            animate: this.popupSettings.animate,
            appendTo: this.appendTo,
            content: this.popupTemplate,
            popupAlign: popupPosition,
            popupClass: this.listContainerClasses,
            positionMode: 'absolute'
        });
        const popupWrapper = this.popupRef.popupElement;
        const { min, max } = this.width;
        popupWrapper.addEventListener('mousedown', this.popupMouseDownHandler);
        popupWrapper.style.minWidth = min;
        popupWrapper.style.width = max;
        popupWrapper.style.height = this.height;
        popupWrapper.setAttribute("dir", this.direction);
        this.popupRef.popupOpen.subscribe(() => {
            this.cdr.detectChanges();
            this.optionsList.scrollToItem(this.selectionService.focused);
            this.opened.emit();
        });
        this.popupRef.popupClose.subscribe(() => {
            this.closed.emit();
        });
        this.popupRef.popupAnchorViewportLeave.subscribe(() => {
            this.togglePopup(false);
        });
    }
    emitValueChange() {
        this.onChangeCallback(this.value);
        this.valueChange.emit(this.value);
    }
    resolveDataItemFromTags(value) {
        if (!(this.tags && this.tags.length && isPresent(value))) {
            return undefined;
        }
        // Flattening the tags array in case of a summary tag occurrence.
        const tags = this.tags.reduce((acc, tag) => {
            const items = isArray(tag) ? tag : [tag];
            acc.push(...items);
            return acc;
        }, []);
        return tags.find(tag => getter$1(tag, this.valueField) === getter$1(value, this.valueField));
    }
    firstFocusableIndex(index) {
        const maxIndex = this.dataService.itemsCount;
        if (this.disabledItemsService.isIndexDisabled(index)) {
            const nextIndex = index + 1;
            return (nextIndex < maxIndex) ? this.firstFocusableIndex(nextIndex) : undefined;
        }
        else {
            return index;
        }
    }
    nextTick(f) {
        this._zone.runOutsideAngular(() => {
            // Use `setTimeout` instead of a resolved promise
            // because the latter does not wait long enough.
            setTimeout(() => this._zone.run(f));
        });
    }
    setComponentClasses() {
        if (this.size) {
            this.renderer.addClass(this.wrapper.nativeElement, getSizeClass('input', this.size));
        }
        if (this.rounded) {
            this.renderer.addClass(this.wrapper.nativeElement, getRoundedClass(this.rounded));
        }
        if (this.fillMode) {
            this.renderer.addClass(this.wrapper.nativeElement, getFillModeClass('input', this.fillMode));
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], MultiSelectComponent.prototype, "focusableId", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], MultiSelectComponent.prototype, "autoClose", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], MultiSelectComponent.prototype, "loading", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [Array])
], MultiSelectComponent.prototype, "data", null);
__decorate([
    Input(),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [Array])
], MultiSelectComponent.prototype, "value", null);
__decorate([
    Input(),
    __metadata("design:type", String)
], MultiSelectComponent.prototype, "valueField", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], MultiSelectComponent.prototype, "textField", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], MultiSelectComponent.prototype, "tabindex", void 0);
__decorate([
    Input("tabIndex"),
    __metadata("design:type", Number),
    __metadata("design:paramtypes", [Number])
], MultiSelectComponent.prototype, "tabIndex", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], MultiSelectComponent.prototype, "size", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], MultiSelectComponent.prototype, "rounded", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], MultiSelectComponent.prototype, "fillMode", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], MultiSelectComponent.prototype, "placeholder", null);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], MultiSelectComponent.prototype, "disabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Function])
], MultiSelectComponent.prototype, "itemDisabled", null);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], MultiSelectComponent.prototype, "checkboxes", null);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], MultiSelectComponent.prototype, "readonly", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], MultiSelectComponent.prototype, "filterable", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], MultiSelectComponent.prototype, "virtual", null);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], MultiSelectComponent.prototype, "popupSettings", null);
__decorate([
    Input(),
    __metadata("design:type", Number)
], MultiSelectComponent.prototype, "listHeight", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], MultiSelectComponent.prototype, "valuePrimitive", null);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], MultiSelectComponent.prototype, "clearButton", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], MultiSelectComponent.prototype, "tagMapper", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], MultiSelectComponent.prototype, "allowCustom", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function)
], MultiSelectComponent.prototype, "valueNormalizer", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], MultiSelectComponent.prototype, "filterChange", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], MultiSelectComponent.prototype, "valueChange", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], MultiSelectComponent.prototype, "open", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], MultiSelectComponent.prototype, "opened", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], MultiSelectComponent.prototype, "close", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], MultiSelectComponent.prototype, "closed", void 0);
__decorate([
    Output('focus'),
    __metadata("design:type", EventEmitter)
], MultiSelectComponent.prototype, "onFocus", void 0);
__decorate([
    Output('blur'),
    __metadata("design:type", EventEmitter)
], MultiSelectComponent.prototype, "onBlur", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], MultiSelectComponent.prototype, "removeTag", void 0);
__decorate([
    ViewChild('container', { read: ViewContainerRef, static: true }),
    __metadata("design:type", ViewContainerRef)
], MultiSelectComponent.prototype, "container", void 0);
__decorate([
    ViewChild(SearchBarComponent, { static: true }),
    __metadata("design:type", SearchBarComponent)
], MultiSelectComponent.prototype, "searchbar", void 0);
__decorate([
    ViewChild('popupTemplate', { static: true }),
    __metadata("design:type", TemplateRef)
], MultiSelectComponent.prototype, "popupTemplate", void 0);
__decorate([
    ViewChild('optionsList', { static: false }),
    __metadata("design:type", ListComponent)
], MultiSelectComponent.prototype, "optionsList", void 0);
__decorate([
    ContentChild(ItemTemplateDirective, { static: false }),
    __metadata("design:type", ItemTemplateDirective)
], MultiSelectComponent.prototype, "template", void 0);
__decorate([
    ContentChild(CustomItemTemplateDirective, { static: false }),
    __metadata("design:type", CustomItemTemplateDirective)
], MultiSelectComponent.prototype, "customItemTemplate", void 0);
__decorate([
    ContentChild(GroupTemplateDirective, { static: false }),
    __metadata("design:type", GroupTemplateDirective)
], MultiSelectComponent.prototype, "groupTemplate", void 0);
__decorate([
    ContentChild(FixedGroupTemplateDirective, { static: false }),
    __metadata("design:type", FixedGroupTemplateDirective)
], MultiSelectComponent.prototype, "fixedGroupTemplate", void 0);
__decorate([
    ContentChild(HeaderTemplateDirective, { static: false }),
    __metadata("design:type", HeaderTemplateDirective)
], MultiSelectComponent.prototype, "headerTemplate", void 0);
__decorate([
    ContentChild(FooterTemplateDirective, { static: false }),
    __metadata("design:type", FooterTemplateDirective)
], MultiSelectComponent.prototype, "footerTemplate", void 0);
__decorate([
    ContentChild(TagTemplateDirective, { static: false }),
    __metadata("design:type", TagTemplateDirective)
], MultiSelectComponent.prototype, "tagTemplate", void 0);
__decorate([
    ContentChild(GroupTagTemplateDirective, { static: false }),
    __metadata("design:type", GroupTagTemplateDirective)
], MultiSelectComponent.prototype, "groupTagTemplate", void 0);
__decorate([
    ContentChild(NoDataTemplateDirective, { static: false }),
    __metadata("design:type", NoDataTemplateDirective)
], MultiSelectComponent.prototype, "noDataTemplate", void 0);
__decorate([
    HostBinding('class.k-multiselect'),
    HostBinding('class.k-input'),
    __metadata("design:type", Boolean)
], MultiSelectComponent.prototype, "hostClasses", void 0);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], MultiSelectComponent.prototype, "dir", null);
__decorate([
    HostBinding('class.k-disabled'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], MultiSelectComponent.prototype, "disabledClass", null);
__decorate([
    HostBinding('class.k-loading'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], MultiSelectComponent.prototype, "isLoading", null);
__decorate([
    HostBinding('attr.role'),
    __metadata("design:type", String)
], MultiSelectComponent.prototype, "role", void 0);
__decorate([
    HostBinding('attr.aria-expanded'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], MultiSelectComponent.prototype, "isAriaExpanded", null);
__decorate([
    HostListener('mousedown', ['$event']),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], MultiSelectComponent.prototype, "hostMousedown", null);
MultiSelectComponent = MultiSelectComponent_1 = __decorate([
    Component({
        exportAs: 'kendoMultiSelect',
        providers: [
            MULTISELECT_VALUE_ACCESSOR,
            DataService,
            SelectionService,
            NavigationService,
            DisabledItemsService,
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.multiselect'
            },
            {
                provide: FilterableComponent, useExisting: forwardRef(() => MultiSelectComponent_1)
            },
            {
                provide: KendoInput, useExisting: forwardRef(() => MultiSelectComponent_1)
            }
        ],
        selector: 'kendo-multiselect',
        template: `
        <ng-container kendoMultiSelectLocalizedMessages
            i18n-noDataText="kendo.multiselect.noDataText|The text displayed in the popup when there are no items"
            noDataText="NO DATA FOUND"

            i18n-clearTitle="kendo.multiselect.clearTitle|The title of the clear button"
            clearTitle="clear"
        >
        </ng-container>
        <kendo-taglist
            [size]="size"
            [rounded]="rounded"
            [fillMode]="fillMode"
            [id]="tagListId"
            [tags]="tags"
            [textField]="textField"
            [valueField]="valueField"
            [focused]="focusedTagIndex"
            [disabled]="disabled"
            [template]="tagTemplate"
            [groupTemplate]="groupTagTemplate"
            [tagPrefix]="tagPrefix"
            [disabledIndices]="disabledIndices"
            (removeTag)="handleRemoveTag($event)"
        >
            <kendo-searchbar
                #searchbar
                [id]="focusableId"
                [role]="'textbox'"
                [tagListId]="tagListId"
                [activeDescendant]="activeDescendant"
                [noDataLabel]="noDataLabel"
                [userInput]="text"
                [disabled]="disabled"
                [readonly]="readonly"
                [tabIndex]="tabIndex"
                [popupOpen]="isOpen"
                [placeholder]="placeholder"
                (onNavigate)="handleNavigate($event)"
                (valueChange)="handleFilter($event)"
                (onBlur)="onSearchBarBlur()"
                (onFocus)="onSearchBarFocus()"
            >
            </kendo-searchbar>
        </kendo-taglist>

        <span
            *ngIf="!loading && !readonly && clearButton && (tags?.length || text?.length)"
            class="k-clear-value"
            [style.visibility]="clearButtonVisiblity"
            [attr.title]="messageFor('clearTitle')"
            role="button"
            tabindex="-1"
            (mousedown)="clearAll($event)"
        >
            <span class="k-icon k-i-x"></span>
        </span>
        <span
            *ngIf="loading"
            class="k-icon k-i-loading"
        >
        </span>
        <ng-template #popupTemplate>
            <!--header template-->
            <ng-template *ngIf="headerTemplate"
                [templateContext]="{
                    templateRef: headerTemplate.templateRef
                }">
            </ng-template>
            <!--custom item template-->
            <div
                *ngIf="allowCustom && text"
                class="k-list"
                [ngClass]="customItemSizeClass"
            >
                <div class="k-list-item k-custom-item" kendoDropDownsSelectable [multipleSelection]="true" [index]="-1">
                    <ng-template *ngIf="customItemTemplate;else default_custom_item_template"
                        [templateContext]="{
                            templateRef: customItemTemplate.templateRef,
                            $implicit: text
                        }">
                    </ng-template>
                    <ng-template #default_custom_item_template>{{ text }}</ng-template>
                    <span class="k-icon k-i-plus" style="float: right"></span>
                </div>
            </div>
            <!--list-->
            <kendo-list
                #optionsList
                [size]="size"
                [rounded]="rounded"
                [id]="listBoxId"
                [optionPrefix]="optionPrefix"
                [data]="data"
                [textField]="textField"
                [valueField]="valueField"
                [height]="listHeight"
                [template]="template"
                [groupTemplate]="groupTemplate"
                [fixedGroupTemplate]="fixedGroupTemplate"
                [show]="isOpen"
                [multipleSelection]="true"
                [virtual]="virtual"
                (pageChange)="pageChange($event)"
                [checkboxes]="checkboxes"
                >
            </kendo-list>
            <!--no data template-->
            <div class="k-no-data" *ngIf="data.length === 0">
                <ng-template [ngIf]="noDataTemplate"
                    [templateContext]="{
                        templateRef: noDataTemplate ? noDataTemplate.templateRef : undefined
                    }">
                </ng-template>
                <ng-template [ngIf]="!noDataTemplate">
                    <div>{{ messageFor('noDataText') }}</div>
                </ng-template>
            </div>
            <!--footer template-->
            <ng-template *ngIf="footerTemplate"
                [templateContext]="{
                    templateRef: footerTemplate.templateRef
                }">
            </ng-template>
        </ng-template>
        <ng-template [ngIf]="isOpen">
            <kendo-resize-sensor (resize)="onResize()"></kendo-resize-sensor>
        </ng-template>
        <ng-container #container></ng-container>
  `
    }),
    __param(11, Optional()), __param(11, Inject(TOUCH_ENABLED)),
    __metadata("design:paramtypes", [ElementRef,
        LocalizationService,
        PopupService,
        DataService,
        SelectionService,
        NavigationService,
        DisabledItemsService,
        ChangeDetectorRef,
        KeyValueDiffers,
        Renderer2,
        NgZone, Boolean])
], MultiSelectComponent);

/**
 * Renders the column cell content of the MultiColumnComboBox. To define a column cell template, nest an `<ng-template>` tag
 * with the `kendoMultiColumnComboBoxColumnCellTemplate` directive inside the [`<kendo-combobox-column>`]({% slug api_dropdowns_comboboxcolumncomponent %}) tag
 * ([see example]({% slug templates_multicolumncombobox %})).
 *
 * The current [`column`]({% slug api_dropdowns_comboboxcolumncomponent %}) and data item are available as context variables:
 *
 * - `let-dataItem="dataItem"` (`any`) - The current data item. Also available as implicit context variable.
 * - `let-column="column"` ([`ColumnComponent`]({% slug api_dropdowns_comboboxcolumncomponent %})) - The current column configuration obejct.
 */
let ColumnCellTemplateDirective = class ColumnCellTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
ColumnCellTemplateDirective = __decorate([
    Directive({
        selector: '[kendoMultiColumnComboBoxColumnCellTemplate]'
    }),
    __metadata("design:paramtypes", [TemplateRef])
], ColumnCellTemplateDirective);

/**
 * Renders the column header content of the MultiColumnComboBox. To define a column header template, nest an `<ng-template>` tag
 * with the `kendoMultiColumnComboBoxColumnHeaderTemplate` directive inside the [`<kendo-combobox-column>`]({% slug api_dropdowns_comboboxcolumncomponent %}) tag
 * ([see example]({% slug templates_multicolumncombobox %})).
 *
 * The current [`column`]({% slug api_dropdowns_comboboxcolumncomponent %}) is available as implicit context variable.
 */
let ColumnHeaderTemplateDirective = class ColumnHeaderTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
ColumnHeaderTemplateDirective = __decorate([
    Directive({
        selector: '[kendoMultiColumnComboBoxColumnHeaderTemplate]'
    }),
    __metadata("design:paramtypes", [TemplateRef])
], ColumnHeaderTemplateDirective);

/**
 * Represents the column definition of the [MultiColumnComboBox]({% slug overview_multicolumncombobox %})
 * ([see example]({% slug columns_multicolumncombobox %})).
 */
let ComboBoxColumnComponent = class ComboBoxColumnComponent {
    /**
     * Represents the column definition of the [MultiColumnComboBox]({% slug overview_multicolumncombobox %})
     * ([see example]({% slug columns_multicolumncombobox %})).
     */
    constructor() {
        /**
         * Sets the visibility of the column.
         *
         * @default false
         */
        this.hidden = false;
        /**
         * @hidden
         */
        this.matchesMedia = true;
    }
};
__decorate([
    ContentChild(ColumnCellTemplateDirective, { static: false }),
    __metadata("design:type", ColumnCellTemplateDirective)
], ComboBoxColumnComponent.prototype, "cellTemplate", void 0);
__decorate([
    ContentChild(ColumnHeaderTemplateDirective, { static: false }),
    __metadata("design:type", ColumnHeaderTemplateDirective)
], ComboBoxColumnComponent.prototype, "headerTemplate", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ComboBoxColumnComponent.prototype, "field", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ComboBoxColumnComponent.prototype, "title", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], ComboBoxColumnComponent.prototype, "width", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ComboBoxColumnComponent.prototype, "hidden", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], ComboBoxColumnComponent.prototype, "style", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], ComboBoxColumnComponent.prototype, "headerStyle", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], ComboBoxColumnComponent.prototype, "class", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], ComboBoxColumnComponent.prototype, "headerClass", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ComboBoxColumnComponent.prototype, "media", void 0);
ComboBoxColumnComponent = __decorate([
    Component({
        selector: 'kendo-combobox-column',
        template: ''
    })
], ComboBoxColumnComponent);

/**
 * Persists the intially resolved scrollbar width value.
 */
let SCROLLBAR_WIDTH;
/**
 * @hidden
 *
 * Gets the default scrollbar width accoring to the current environment.
 */
const scrollbarWidth = () => {
    // calculate scrollbar width only once, then return the memoized value
    if (isNaN(SCROLLBAR_WIDTH)) {
        const div = document.createElement('div');
        div.style.cssText = 'overflow: scroll; overflow-x: hidden; zoom: 1; clear: both; display: block;';
        div.innerHTML = '&nbsp;';
        document.body.appendChild(div);
        SCROLLBAR_WIDTH = div.offsetWidth - div.scrollWidth;
        document.body.removeChild(div);
    }
    return SCROLLBAR_WIDTH;
};
/**
 * Checks if all columns have a valid user-defined width.
 */
const allColumnsWidthsSet = (columns) => {
    if (!isPresent(columns) || columns.length === 0) {
        return false;
    }
    return columns.toArray().every(column => !isNaN(column.width) && column.width > 0);
};
/**
 * @hidden
 *
 * Calculates the row width according to the passed columns width configuration.
 * Hidden columns and such that don't match the provided media query are ignored.
 * If some of the columns don't have a preset width or have an invalid width value, the function returns `null`.
 */
const getRowWidthFromColumnsMeta = (columns) => {
    if (!allColumnsWidthsSet(columns)) {
        return null;
    }
    const bordersWidth = 2;
    const initialRowWidht = scrollbarWidth() + bordersWidth;
    return columns.reduce((totalWidth, column) => {
        if (!column.hidden && column.matchesMedia) {
            totalWidth += parseInt(column.width, 10);
        }
        return totalWidth;
    }, initialRowWidht);
};

var MultiColumnComboBoxComponent_1;
/**
 * Represents the [Kendo UI MultiColumnComboBox component for Angular]({% slug overview_multicolumncombobox %}).
 */
let MultiColumnComboBoxComponent = MultiColumnComboBoxComponent_1 = class MultiColumnComboBoxComponent extends ComboBoxComponent {
    constructor(localization, popupService, selectionService, navigationService, disabledItemsService, dataService, zone, changeDetector, renderer, wrapper, touchEnabled$$1) {
        super(wrapper, localization, popupService, selectionService, navigationService, disabledItemsService, dataService, zone, changeDetector, renderer, touchEnabled$$1);
        /**
         * @hidden
         */
        this.hostClasses = true;
        this.removeWindowResizeListener = noop;
        // the row height in @progress/kendo-theme-default
        this.defaultVirtualItemHeight = 36;
        // use a smaller virtual page size as columns with multiple cells can cause poor performance
        this.defaultVirtualPageSize = 30;
    }
    /**
     * @hidden
     */
    get isDisabled() {
        return this.disabled;
    }
    /**
     * @hidden
     */
    set header(header) {
        // updates the header padding on initial render as the resize senzor doesn't kick in as early
        this.updateHeaderPadding(header && header.nativeElement);
    }
    get popupWidth() {
        const wrapperOffsetWidth = this.wrapper.nativeElement.offsetWidth;
        const min = `${wrapperOffsetWidth}px`;
        const width = this.popupSettings.width || getRowWidthFromColumnsMeta(this.columns) || wrapperOffsetWidth;
        const max = isNaN(width) ? width : `${width}px`;
        return { min, max };
    }
    /**
     * @hidden
     */
    get tableSizeClass() {
        return `${this.size ? getSizeClass('table', this.size) : ''}`;
    }
    /**
     * @hidden
     */
    get listContainerClasses() {
        return [
            'k-popup',
            'k-dropdowngrid-popup'
        ].concat(this.popupSettings.popupClass || []);
    }
    ngAfterViewInit() {
        this.updateColumnsMediaState();
        this.addWindowResizeListener();
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        this.removeWindowResizeListener();
    }
    /**
     * @hidden
     */
    textFrom(dataItem, field) {
        return getter$1(dataItem, field);
    }
    /**
     * @hidden
     *
     * Adds or removes a padding value at the end of the header container equal to the size of the scrollbar.
     * As when the items container has a scrollbar, the column headers and the cells are misaligned.
     * When the container has a scrollbar, the padding style is added, and when there is none - it is removed.
     */
    updateHeaderPadding(header) {
        if (!isPresent(header)) {
            return;
        }
        // the scrollbar is rendered on the left in rtl
        const headerPaddingPosition = this.localization.rtl ? 'padding-left' : 'padding-right';
        if (this.optionsList.hasScrollbar() && scrollbarWidth() > 0) {
            this.renderer.setStyle(header, headerPaddingPosition, `${scrollbarWidth()}px`);
        }
        else {
            this.renderer.removeStyle(header, headerPaddingPosition);
        }
    }
    verifySettings() {
        if (!isDevMode()) {
            return;
        }
        if (isPresent(this.data) && this.data.length > 0 && this.data.some(item => !isObject(item))) {
            throw new Error(MultiColumnComboBoxMessages.data);
        }
        if (!isPresent(this.valueField) || !isPresent(this.textField)) {
            throw new Error(MultiColumnComboBoxMessages.textAndValue);
        }
        super.verifySettings();
    }
    addWindowResizeListener() {
        if (!isDocumentAvailable()) {
            return;
        }
        this.zone.runOutsideAngular(() => this.removeWindowResizeListener = this.renderer.listen(window, 'resize', this.updateColumnsMediaState.bind(this)));
    }
    updateColumnsMediaState() {
        if (!(isPresent(this.columns) && isDocumentAvailable())) {
            return;
        }
        this.columns.forEach(column => {
            const matchesMedia = !column.media || window.matchMedia(column.media).matches;
            if (column.matchesMedia !== matchesMedia) {
                column.matchesMedia = matchesMedia;
                if (this.isOpen) {
                    // enter the zone only if the popup is actually open
                    // update its width in case it's dependent on the columns' width
                    this.zone.run(() => this.popupRef.popupElement.style.width = this.popupWidth.max);
                }
            }
        });
    }
};
__decorate([
    HostBinding('class.k-dropdowngrid'),
    __metadata("design:type", Boolean)
], MultiColumnComboBoxComponent.prototype, "hostClasses", void 0);
__decorate([
    HostBinding('class.k-disabled'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], MultiColumnComboBoxComponent.prototype, "isDisabled", null);
__decorate([
    ContentChildren(ComboBoxColumnComponent),
    __metadata("design:type", QueryList)
], MultiColumnComboBoxComponent.prototype, "columns", void 0);
__decorate([
    ViewChild('header', { static: false }),
    __metadata("design:type", ElementRef),
    __metadata("design:paramtypes", [ElementRef])
], MultiColumnComboBoxComponent.prototype, "header", null);
MultiColumnComboBoxComponent = MultiColumnComboBoxComponent_1 = __decorate([
    Component({
        providers: [
            SelectionService,
            DataService,
            NavigationService,
            DisabledItemsService,
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.multicolumncombobox'
            },
            {
                multi: true,
                provide: NG_VALUE_ACCESSOR,
                useExisting: forwardRef(() => MultiColumnComboBoxComponent_1)
            },
            {
                provide: KendoInput,
                useExisting: forwardRef(() => MultiColumnComboBoxComponent_1)
            },
            {
                provide: FilterableComponent,
                useExisting: forwardRef(() => MultiColumnComboBoxComponent_1)
            }
        ],
        selector: 'kendo-multicolumncombobox',
        template: `
        <ng-container
            kendoMultiColumnComboBoxLocalizedMessages

            i18n-noDataText="kendo.multicolumncombobox.noDataText|The text displayed in the popup when there are no items"
            noDataText="NO DATA FOUND"

            i18n-clearTitle="kendo.multicolumncombobox.clearTitle|The title of the clear button"
            clearTitle="clear"
        >
        </ng-container>
        <kendo-searchbar
            #searchbar
            [role]="'combobox'"
            [id]="focusableId"
            [listId]="listBoxId"
            [activeDescendant]="activeDescendant"
            [noDataLabel]="noDataLabel"
            [userInput]="text"
            [suggestedText]="getSuggestion()"
            [disabled]="disabled"
            [readonly]="readonly"
            [tabIndex]="tabIndex"
            [popupOpen]="isOpen"
            [placeholder]="placeholder"
            (onNavigate)="handleNavigate($event)"
            (valueChange)="searchBarChange($event)"
            (onBlur)="handleBlur()"
            (onFocus)="handleFocus()"
        >
        </kendo-searchbar>

        <span
            *ngIf="clearButton && !loading && !disabled && !readonly && text?.length"
            class="k-clear-value"
            [style.visibility]="clearButtonVisiblity"
            aria-hidden="true"
            [attr.title]="messageFor('clearTitle')"
            (click)="clearValue($event)"
            [kendoEventsOutsideAngular]="{
                mousedown: preventEventDefault
            }"
        >
            <span class="k-icon k-i-x"></span>
        </span>

        <button
            #select
            tabindex="-1"
            aria-hidden="true"
            unselectable="on"
            type="button"
            class="k-input-button k-button k-icon-button"
            [ngClass]="selectButtonClasses"
            [attr.aria-label]="messageFor('selectButtonText')"
            [kendoEventsOutsideAngular]="{
                mousedown: preventEventDefault
            }"
        >
            <span class="k-button-icon k-icon" [ngClass]="buttonClasses"></span>
        </button>

        <ng-template #popupTemplate>
            <!--user-defined header template -->
            <ng-template
                *ngIf="headerTemplate"
                [templateContext]="{
                    templateRef: headerTemplate?.templateRef
                }"
            >
            </ng-template>

            <!--data table-->
            <div class="k-data-table" [ngClass]="tableSizeClass">

                <!--grid header-->
                <div
                    #header
                    class="k-table-header"
                >
                    <div class="k-table-header-wrap">
                        <table class="k-table" role="presentation">
                            <colgroup>
                                <ng-container *ngFor="let column of columns">
                                    <col
                                        *ngIf="!column.hidden && column.matchesMedia"
                                        [style.width.px]="column.width"
                                    />
                                </ng-container>
                            </colgroup>
                            <thead class="k-table-thead">
                                <tr class="k-table-row">
                                    <ng-container *ngFor="let column of columns">
                                        <th
                                            *ngIf="!column.hidden && column.matchesMedia"
                                            class="k-table-th"
                                            [ngStyle]="column.headerStyle"
                                            [ngClass]="column.headerClass"
                                        >
                                            <ng-container *ngIf="!column.headerTemplate">
                                                {{ column.title || column.field }}
                                            </ng-container>
                                            <ng-template
                                                *ngIf="column.headerTemplate"
                                                [templateContext]="{
                                                    templateRef: column.headerTemplate?.templateRef,
                                                    $implicit: column,
                                                    column: column
                                                }"
                                            >
                                            </ng-template>
                                        </th>
                                    </ng-container>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>

                <!-- item template -->
                <ng-template #rowTemplate let-dataItem>
                    <ng-container *ngFor="let column of columns">
                        <span
                            *ngIf="!column.hidden && column.matchesMedia"
                            class="k-table-td"
                            [ngClass]="column.class"
                            [style.width.px]="column.width"
                            [ngStyle]="column.style"
                        >
                            <ng-container *ngIf="!column.cellTemplate">
                                {{ textFrom(dataItem, column.field) }}
                            </ng-container>
                            <ng-template
                                *ngIf="column.cellTemplate"
                                [templateContext]="{
                                    templateRef: column.cellTemplate?.templateRef,
                                    $implicit: dataItem,
                                    dataItem: dataItem,
                                    column: column
                                }"
                            >
                            </ng-template>
                        </span>
                    </ng-container>
                </ng-template>

                <!--list-->
                <kendo-list
                    #optionsList
                    [id]="listBoxId"
                    [optionPrefix]="optionPrefix"
                    [data]="data"
                    [textField]="textField"
                    [valueField]="valueField"
                    [template]="{ templateRef: rowTemplate }"
                    [groupTemplate]="groupTemplate"
                    [fixedGroupTemplate]="fixedGroupTemplate"
                    [height]="listHeight"
                    [show]="isOpen"
                    [virtual]="virtual"
                    [type]="'dropdowngrid'"
                    (pageChange)="pageChange($event)"
                    (listResize)="updateHeaderPadding(header)"
                >
                </kendo-list>

                <!--no-data template-->
                <div
                    class="k-no-data"
                    *ngIf="data.length === 0"
                >
                    <ng-template
                        [ngIf]="noDataTemplate"
                        [templateContext]="{
                            templateRef: noDataTemplate?.templateRef
                        }"
                    >
                    </ng-template>
                    <ng-template [ngIf]="!noDataTemplate">
                        <div>{{ messageFor('noDataText') }}</div>
                    </ng-template>
                </div>

                <!--user-defined footer template-->
                <ng-container *ngIf="footerTemplate">
                    <div class="k-table-footer">
                        <table class="k-table">
                            <tfoot class="k-table-tfoot">
                                <tr class="k-table-row">
                                    <td class="k-table-td">
                                        <ng-template
                                            [templateContext]="{
                                                templateRef: footerTemplate.templateRef
                                            }"
                                        >
                                        </ng-template>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </ng-container>

            </div>

        </ng-template>

        <kendo-resize-sensor
            *ngIf="isOpen"
            (resize)="onResize()"
        >
        </kendo-resize-sensor>

        <!-- when the popupSettings.appendTo value is set to 'component', this container is used -->
        <ng-container #container></ng-container>
    `
    }),
    __param(10, Inject(TOUCH_ENABLED)),
    __metadata("design:paramtypes", [LocalizationService,
        PopupService,
        SelectionService,
        NavigationService,
        DisabledItemsService,
        DataService,
        NgZone,
        ChangeDetectorRef,
        Renderer2,
        ElementRef, Boolean])
], MultiColumnComboBoxComponent);

/**
 * Renders the content of each node in the DropDownTree. To define a node template, nest an `<ng-template>` tag
 * with the `kendoDropDownTreeNodeTemplate` directive inside the `<kendo-dropdowntree>` tag.
 *
 * The current data item and hierarchical index are available as context variables:
 *
 * - `let-dataItem` (`any`) - The current data item. Available as implicit context variable.
 * - `let-index="index"` (`string`) - The current item hierarchical index.
 */
let NodeTemplateDirective = class NodeTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
NodeTemplateDirective = __decorate([
    Directive({
        selector: '[kendoDropDownTreeNodeTemplate], [kendoMultiSelectTreeNodeTemplate]'
    }),
    __metadata("design:paramtypes", [TemplateRef])
], NodeTemplateDirective);

var DropDownTreeComponent_1;
/* tslint:disable:member-ordering */
const DEFAULT_POPUP_SETTINGS = { animate: true };
const hasChildren = () => false;
const fetchChildren = () => of([]);
const itemDisabled = () => false;
const isNodeVisible = () => true;
/**
 * Represents the [Kendo UI DropDownTree component for Angular]({% slug overview_ddt %}).
 */
let DropDownTreeComponent = DropDownTreeComponent_1 = class DropDownTreeComponent {
    constructor(wrapper, popupService, navigationService, renderer, _zone, cdr, localization, touchEnabled$$1) {
        this.wrapper = wrapper;
        this.popupService = popupService;
        this.navigationService = navigationService;
        this.renderer = renderer;
        this._zone = _zone;
        this.cdr = cdr;
        this.localization = localization;
        this.touchEnabled = touchEnabled$$1;
        this.hostClasses = true;
        this.role = 'listbox';
        this.ariaHasPopup = 'tree';
        /**
         * Fires each time the popup is about to open
         * ([see example]({% slug openstate_ddt %})).
         * This event is preventable. If you cancel it, the popup will remain closed.
         */
        this.open = new EventEmitter();
        /**
         * Fires after the popup has been opened.
         */
        this.opened = new EventEmitter();
        /**
         * Fires each time the popup is about to close
         * ([see example]({% slug openstate_ddt %})).
         * This event is preventable. If you cancel it, the popup will remain open.
         */
        this.close = new EventEmitter();
        /**
         * Fires after the popup has been closed.
         */
        this.closed = new EventEmitter();
        /**
         * Fires when the user expands a node in the popup TreeView.
         */
        this.nodeExpand = new EventEmitter();
        /**
         * Fires when the user collapses a node in the popup TreeView.
         */
        this.nodeCollapse = new EventEmitter();
        /**
         * Fires each time the user focuses the DropDownTree.
         */
        this.onFocus = new EventEmitter();
        /**
         * Fires each time the DropDownTree gets blurred.
         */
        this.onBlur = new EventEmitter();
        /**
         * Fires each time the value is changed
         * ([see example]({% slug overview_ddt %}#toc-events)).
         */
        this.valueChange = new EventEmitter();
        /**
         * Fires when the value of the built-in filter input element changes.
         */
        this.filterChange = new EventEmitter();
        /**
         * If set to `true`, renders a button on hovering over the component.
         * Clicking this button resets the value of the component to `undefined` and triggers the `change` event.
         */
        this.clearButton = true;
        /**
         * A function which determines if a specific node has child nodes.
         */
        this.hasChildren = hasChildren;
        /**
         * A function which provides the child nodes for a given parent node.
         */
        this.fetchChildren = fetchChildren;
        /**
         * The hint which is displayed when the component is empty.
         */
        this.placeholder = "";
        /**
         * Sets the height of the options list. By default, `listHeight` is 200px.
         *
         * > The `listHeight` property affects only the list of options and not the whole popup container.
         * > To set the height of the popup container, use `popupSettings.height`.
         */
        this.listHeight = 200;
        /**
         * Sets the disabled state of the component.
         */
        this.disabled = false;
        /**
         * Sets the read-only state of the component.
         */
        this.readonly = false;
        /**
         * Specifies the type of the selected value
         * ([more information and example]({% slug valuebinding_ddt %}#toc-primitive-values)).
         * If set to `true`, the selected value has to be of a primitive value.
         */
        this.valuePrimitive = false;
        /**
         * A function that is executed for each data item and determines if a specific item is disabled.
         */
        this.itemDisabled = itemDisabled;
        /**
         * A callback which determines whether a tree node should be rendered as hidden. The utility .k-display-none class is used to hide the nodes.
         * Useful for custom filtering implementations.
         */
        this.isNodeVisible = isNodeVisible;
        /**
         * Indicates whether the child nodes will be fetched on node expand or will be initially prefetched.
         * @default true
         */
        this.loadOnDemand = true;
        /**
         * Renders the built-in input element for filtering the DropDownTree.
         * If set to `true`, the component emits the `filterChange` event, which can be used to [filter the DropDownTree manually]({% slug filtering_ddt %}#toc-manual-filtering).
         * A built-in filtering implementation is available to use with the [`kendoDropDownTreeHierarchyBinding`]({% slug api_dropdowns_dropdowntreehierarchybindingdirective %}) and [`kendoDropDownTreeFlatBinding`]({% slug api_dropdowns_dropdowntreeflatbindingdirective %}) directives.
         */
        this.filterable = false;
        /**
         * @hidden
         */
        this.filter = '';
        /**
         * @hidden
         *
         * Used by the kendo-label and kendo-floatinglabel to access and associate the focusable element with the provided label via aria-labelledby.
         */
        this.focusableId = `k-${guid()}`;
        /**
         * @hidden
         */
        this.selectedKeys = [];
        /**
         * @hidden
         */
        this.filterStateChange = new EventEmitter();
        /**
         * @hidden
         */
        this.allNodesHidden = false;
        /**
         * @hidden
         *
         * Used to associate the value label with the wrapper via aria-describedby.
         */
        this.valueLabelId = `k-${guid()}`;
        this._popupSettings = DEFAULT_POPUP_SETTINGS;
        this._tabindex = 0;
        this._isFocused = false;
        this._size = 'medium';
        this._rounded = 'medium';
        this._fillMode = 'solid';
        this.subscriptions = [];
        this.onTouchedCallback = noop;
        this.onChangeCallback = noop;
        this.direction = localization.rtl ? 'rtl' : 'ltr';
        this.subscribeEvents();
        this.subscribeTouchEvents();
        this.subscribeFocusEvents();
    }
    get isDisabled() {
        return this.disabled;
    }
    get isLoading() {
        return this.loading;
    }
    get id() {
        return this.focusableId;
    }
    get hostTabIndex() {
        return this.tabindex;
    }
    get isAriaExpanded() {
        return this.isOpen;
    }
    get isReadonly() {
        return this.readonly;
    }
    /**
     * @hidden
     */
    handleClick() {
        this.togglePopup(!this.isOpen);
    }
    /**
     * @hidden
     */
    handleKeydown(event) {
        if (this.disabled || this.readonly) {
            return;
        }
        const eventData = event;
        this.navigationService.process({
            originalEvent: eventData
        });
    }
    set treeview(treeview) {
        if (treeview) {
            if (this.isFocused && !this.filterable || this.touchEnabled) {
                treeview.focus();
            }
            // the treeview animations are initially disabled (we don't want expand animations during popup opening)
            // re-enables the animations for user interaction
            treeview.animate = true;
            this._treeview = treeview;
        }
    }
    get treeview() {
        return this._treeview;
    }
    /**
     * Sets the data of the DropDownTree.
     *
     * > The data has to be provided in an array-like list with objects.
     */
    set data(data) {
        this._nodes = data;
        this.setState();
    }
    get data() {
        return this._nodes;
    }
    /**
     * Sets the value of the DropDownTree.
     * It can either be of the primitive (string, numbers) or of the complex (objects) type.
     * To define the type, use the `valuePrimitive` option.
     *
     */
    set value(newValue) {
        this._value = newValue;
        this.setState();
    }
    get value() {
        return this._value;
    }
    /**
     * Configures the popup of the DropDownTree.
     *
     * The available options are:
     * - `animate: Boolean`&mdash;Controls the popup animation. By default, the open and close animations are enabled.
     * - `width: Number | String`&mdash;Sets the width of the popup container. By default, the width of the host element is used. If set to `auto`, the component automatically adjusts the width of the popup and no item labels are wrapped.
     * - `height: Number`&mdash;Sets the height of the popup container.
     * - `popupClass: String`&mdash;Specifies a list of CSS classes that are used to style the popup.
     * - `appendTo: "root" | "component" | ViewContainerRef`&mdash;Specifies the component to which the popup will be appended.
     */
    set popupSettings(settings) {
        this._popupSettings = Object.assign({}, DEFAULT_POPUP_SETTINGS, settings);
    }
    get popupSettings() {
        return this._popupSettings;
    }
    /**
     * Keeps the current `dataItem` object in order to resolve selection.
     * Needs to be provided when `value` is bound in and `valuePrimitive` is set to true.
     */
    set dataItem(item) {
        this._dataItem = item;
        this.setState();
    }
    get dataItem() {
        return this._dataItem ? this._dataItem : this.value;
    }
    /**
     * Specifies the [`tabindex`](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex) of the component.
     */
    set tabindex(value) {
        const providedTabIndex = Number(value);
        const defaultTabIndex = 0;
        this._tabindex = !isNaN(providedTabIndex) ? providedTabIndex : defaultTabIndex;
    }
    get tabindex() {
        return this.disabled ? -1 : this._tabindex;
    }
    /**
     * Sets the size of the component.
     *
     * The possible values are:
     * * `'small'`
     * * `'medium'` (default)
     * * `'large'`
     * * `null`
     *
     */
    set size(size) {
        this.renderer.removeClass(this.wrapper.nativeElement, getSizeClass('picker', this.size));
        if (size) {
            this.renderer.addClass(this.wrapper.nativeElement, getSizeClass('picker', size));
        }
        this._size = size;
    }
    get size() {
        return this._size;
    }
    /**
     * Sets the border radius of the component.
     *
     * The possible values are:
     * * `'small'`
     * * `'medium'` (default)
     * * `'large'`
     * * `'full'`
     * * `null`
     *
     */
    set rounded(rounded) {
        this.renderer.removeClass(this.wrapper.nativeElement, getRoundedClass(this.rounded));
        if (rounded) {
            this.renderer.addClass(this.wrapper.nativeElement, getRoundedClass(rounded));
        }
        this._rounded = rounded;
    }
    get rounded() {
        return this._rounded;
    }
    /**
     * Sets the fillMode of the component.
     *
     * The possible values are:
     * * `'flat'`
     * * `'solid'` (default)
     * * `'outline'`
     * * `null`
     *
     */
    set fillMode(fillMode) {
        this.renderer.removeClass(this.wrapper.nativeElement, getFillModeClass('picker', this.fillMode));
        if (fillMode) {
            this.renderer.addClass(this.wrapper.nativeElement, getFillModeClass('picker', fillMode));
        }
        this._fillMode = fillMode;
    }
    get fillMode() {
        return this._fillMode;
    }
    set isFocused(isFocused) {
        this.renderer[isFocused ? 'addClass' : 'removeClass'](this.wrapper.nativeElement, 'k-focus');
        this._isFocused = isFocused;
    }
    get isFocused() {
        return this._isFocused;
    }
    get width() {
        const wrapperWidth = this.wrapper.nativeElement.offsetWidth;
        const width = this.popupSettings.width || wrapperWidth;
        const minWidth = isNaN(wrapperWidth) ? wrapperWidth : `${wrapperWidth}px`;
        const maxWidth = isNaN(width) ? width : `${width}px`;
        return { min: minWidth, max: maxWidth };
    }
    get height() {
        const popupHeight = this.popupSettings.height;
        return isPresent(popupHeight) ? `${popupHeight}px` : 'auto';
    }
    /**
     * Returns the current open state of the popup.
     */
    get isOpen() {
        return isPresent(this.popupRef);
    }
    get clearButtonVisiblity() {
        if (this.touchEnabled) {
            return 'visible';
        }
    }
    /**
     * @hidden
     *
     * Alias for `isNodeExpanded`. Used for compatibility with the `ExpandableComponent` interface.
     * Required for the expand-directive.
     */
    set isExpanded(callback) {
        this.isNodeExpanded = callback;
    }
    get isExpanded() {
        return this.isNodeExpanded;
    }
    /**
     * @hidden
     *
     * Alias for `nodeExpand`. Used for compatibility with the `ExpandableComponent` interface.
     * Required for the expand-directive.
     */
    get expand() {
        return this.nodeExpand;
    }
    /**
     * @hidden
     *
     * Alias for `nodeCollapse`. Used for compatibility with the `ExpandableComponent` interface.
     * Required for the expand-directive.
     */
    get collapse() {
        return this.nodeCollapse;
    }
    /**
     * @hidden
     *
     * Alias for `data`. Used for compatibility with the `DataBoundComponent` interface.
     * Required for the data-binding directives.
     */
    set nodes(nodes) {
        this.data = nodes;
    }
    get nodes() {
        return this.data;
    }
    /**
     * @hidden
     *
     * Alias for `fetchChildren`. Used for compatibility with the `DataBoundComponent` interface.
     * Required for the data-binding directives
     */
    set children(callback) {
        this.fetchChildren = callback;
    }
    get children() {
        return this.fetchChildren;
    }
    /**
     * @hidden
     *
     * Alias for `isNodeVisible`. Used for compatibility with the `DataBoundComponent` interface.
     * The `DataBoundComponent` interface is used in the data-binding directives.
     */
    set isVisible(callback) {
        this.isNodeVisible = callback;
    }
    get isVisible() {
        return this.isNodeVisible;
    }
    ngOnInit() {
        this.renderer.removeAttribute(this.wrapper.nativeElement, 'tabindex');
        this.assignAriaDescribedBy();
        this.subscriptions.push(this.localization
            .changes.subscribe(({ rtl }) => {
            this.direction = rtl ? 'rtl' : 'ltr';
            this.cdr.markForCheck();
        }));
        this.setComponentClasses();
    }
    /**
     * @hidden
     */
    ngOnDestroy() {
        this.destroyPopup();
        this.unsubscribeEvents();
    }
    /**
     * @hidden
     */
    ngOnChanges(changes) {
        if (anyChanged(['textField', 'valueField', 'valuePrimitive'], changes, false)) {
            this.setState();
        }
    }
    /**
     * @hidden
     */
    ngAfterContentChecked() {
        this.verifySettings();
    }
    /**
     * @hidden
     *
     * Used by the kendo-floatinglabel component to determine if the floating label
     * should be rendered inside the input when the component is not focused.
     */
    isEmpty() {
        return !this.text && !this.placeholder;
    }
    /**
     * @hidden
     */
    togglePopup(open) {
        const isDisabled = this.disabled || this.readonly;
        const sameState = this.isOpen === open;
        if (isDisabled || sameState) {
            return;
        }
        const togglePrevented = this.triggerPopupEvents(open);
        if (!togglePrevented) {
            open ?
                this.createPopup() :
                this.destroyPopup();
        }
    }
    /**
     * @hidden
     */
    handleFocus(event) {
        if (event.target !== this.wrapper.nativeElement) {
            return;
        }
        event.stopImmediatePropagation();
        if (!this.isFocused) {
            this.isFocused = true;
            if (hasObservers(this.onFocus)) {
                this._zone.run(() => {
                    this.onFocus.emit();
                });
            }
        }
    }
    /**
     * @hidden
     */
    handleBlur(e) {
        const relatedTarget = e && e.relatedTarget;
        if (this.wrapper.nativeElement.contains(relatedTarget) ||
            (this.isOpen && this.popupRef.popupElement.contains(relatedTarget))) {
            return;
        }
        this.isFocused = false;
        if (hasObservers(this.onBlur) ||
            isUntouched(this.wrapper.nativeElement)) {
            this._zone.run(() => {
                this.togglePopup(false);
                this.onBlur.emit();
                this.onTouchedCallback();
            });
        }
        else {
            this.togglePopup(false);
        }
    }
    /**
     * Focuses a specific item of the DropDownTree based on a provided index in the format of `1_1`.
     * The targeted item should be expanded in order for it to be focused.
     * If null or invalid index is provided the focus will be set on the first item.
     */
    focusItemAt(index) {
        if (this.treeview) {
            const lookup = this.treeview.itemLookup(index);
            let isItemDisabled = !isPresent(lookup) || this.treeview.isDisabled(lookup.item.dataItem, lookup.item.index);
            if (!isItemDisabled) {
                this.treeview.focus(index);
            }
        }
    }
    /**
     * Focuses the DropDownTree.
     */
    focus() {
        if (!this.disabled) {
            this.wrapper.nativeElement.focus();
        }
    }
    /**
     * Blurs the DropDownTree.
     */
    blur() {
        if (!this.disabled) {
            this.wrapper.nativeElement.blur();
        }
    }
    /**
     * Resets the value of the DropDownTree.
     * If you use the `reset` method to clear the value of the component,
     * the model will not update automatically and the `valueChange` event will not be fired.
     */
    reset() {
        this.value = undefined;
        this.dataItem = undefined;
    }
    /**
     * Toggles the visibility of the popup
     * ([see example]({% slug openstate_ddt %})).
     * If you use the `toggle` method to open or close the popup, the `open` and `close` events will not be fired.
     *
     * @param open - The state of the popup.
     */
    toggle(open) {
        // The Promise is required to open the popup on load.
        // Otherwise, the "ViewContainerRef not found..." error will be thrown.
        Promise.resolve(null).then(() => {
            const shouldOpen = isPresent(open) ? open : !isPresent(this.popupRef);
            this.destroyPopup();
            if (shouldOpen) {
                this.createPopup();
            }
        });
    }
    /**
     * @hidden
     */
    get popupContainerClasses() {
        const containerClasses = ['k-popup-dropdowntree'];
        if (this.popupSettings.popupClass) {
            containerClasses.push(this.popupSettings.popupClass);
        }
        return containerClasses;
    }
    /**
     * @hidden
     */
    onSelectionChange({ dataItem, index }) {
        this.valueDepth = index.split('_').length - 1;
        const valueField = this.getField(this.valueField, dataItem);
        const newValue = this.valuePrimitive ?
            getter(valueField)(dataItem) :
            dataItem;
        const shouldUpdateValue = newValue !== this.value;
        if (shouldUpdateValue) {
            this.value = newValue;
            this.dataItem = dataItem;
            this.emitValueChange(this.value);
        }
        this.togglePopup(false);
        this.focus();
    }
    /**
     * @hidden
     */
    messageFor(key) {
        return this.localization.get(key);
    }
    /**
     * @hidden
     */
    clearValue(event) {
        event.stopImmediatePropagation();
        this.focus();
        this.value = undefined;
        this.dataItem = undefined;
        this.clearState();
        this.valueChange.emit(undefined);
        this.emitValueChange();
    }
    get appendTo() {
        const { appendTo } = this.popupSettings;
        if (!appendTo || appendTo === 'root') {
            return undefined;
        }
        return appendTo === 'component' ? this.container : appendTo;
    }
    /**
     * @hidden
     */
    preventEventDefault(event) {
        event.preventDefault();
    }
    /**
     * @hidden
     */
    writeValue(value) {
        // If the user resets the value by providing null/undefined we need to reset the `dataItem`
        // Because upon initialization of the component the `writeValue` is being called twice -
        // first time with `null` value regardless of sync/async value - an extra check is added to
        // distinguish between client reset and initial phanotm 'null' value
        if (!isPresent(value) && isPresent(this.value)) {
            this.dataItem = null;
        }
        this.value = value === null ? undefined : value;
    }
    /**
     * @hidden
     */
    registerOnChange(fn) {
        this.onChangeCallback = fn;
    }
    /**
     * @hidden
     */
    registerOnTouched(fn) {
        this.onTouchedCallback = fn;
    }
    /**
     * @hidden
     */
    setDisabledState(isDisabled) {
        this.disabled = isDisabled;
        this.cdr.markForCheck();
    }
    /**
     * @hidden
     */
    handleFilterInputChange(term) {
        this.filterChange.next(term);
        this.allNodesHidden = this.nodes.every((node, index) => !this.isVisible(node, String(index)));
    }
    /**
     * @hidden
     */
    get buttonClasses() {
        return this.loading ? 'k-i-loading' : 'k-i-arrow-s';
    }
    /**
     * @hidden
     */
    get selectButtonClasses() {
        return `${this.size ? getSizeClass('button', this.size) : ''} ${this.fillMode ? 'k-button-' + this.fillMode : ''} ${this.fillMode ? 'k-button-' + this.fillMode + '-base' : ''}`;
    }
    /**
     * @hidden
     */
    get filterInputClasses() {
        return `${this.size ? getSizeClass('input', this.size) : ''} ${this.fillMode ? 'k-input-' + this.fillMode : ''} ${this.rounded ? getRoundedClass(this.rounded) : ''}`;
    }
    verifySettings() {
        if (!isDevMode()) {
            return;
        }
        if (this.valuePrimitive === true && isPresent(this.value) && typeof this.value === "object") {
            throw new Error(DropDownTreeMessages.primitive);
        }
        if (this.valuePrimitive === true && isPresent(this.value) && typeof this.dataItem !== "object") {
            throw new Error(DropDownTreeMessages.dataItem);
        }
        if (this.valuePrimitive === false && isPresent(this.value) && typeof this.value !== "object") {
            throw new Error(DropDownTreeMessages.object);
        }
        if (!isPresent(this.valueField) || !isPresent(this.textField)) {
            throw new Error(DropDownTreeMessages.textAndValue);
        }
        if ((isArray(this.valueField) || isArray(this.textField)) && isPresent(this.value) && !isPresent(this.valueDepth)) {
            throw new Error(DropDownTreeMessages.valueDepth);
        }
    }
    emitValueChange(value) {
        this.onChangeCallback(value);
        this.valueChange.emit(value);
    }
    getText(textField, dataItem) {
        if (isPresent(dataItem) && isPresent(textField)) {
            const field = this.getField(textField, dataItem);
            return getter(field)(dataItem);
        }
        return null;
    }
    /**
     * @hidden
     *
     * Determines the `valueField` and `textField` for a specific level in the data set
     *  @param field - the field value (string | string[])
     *  @param value - current value
     */
    getField(field, value) {
        const fieldsCount = field.length - 1;
        if (typeof field === 'string') {
            // If the `valueField` | `textField` is the same for all levels
            return field;
        }
        else if (isPresent(this.valueDepth)) {
            // When `valueDepth` can be defined from the index on selectionChange or provided by the user
            return fieldsCount < this.valueDepth ? field[fieldsCount] : field[this.valueDepth];
        }
        else {
            // Fallback: Look to find a match of each field in the current data item
            // Side effect may occur if all of the listed fields are present in the data item
            return field.find(item => item in value);
        }
    }
    triggerPopupEvents(open) {
        const eventArgs = new PreventableEvent();
        if (open) {
            this.open.emit(eventArgs);
        }
        else {
            this.close.emit(eventArgs);
        }
        return eventArgs.isDefaultPrevented();
    }
    createPopup() {
        const horizontalAlign = this.direction === "rtl" ? "right" : "left";
        const anchorPosition = { horizontal: horizontalAlign, vertical: 'bottom' };
        const popupPosition = { horizontal: horizontalAlign, vertical: 'top' };
        this.popupRef = this.popupService.open({
            anchor: this.wrapper,
            appendTo: this.appendTo,
            anchorAlign: anchorPosition,
            content: this.popupTemplate,
            popupAlign: popupPosition,
            positionMode: 'absolute',
            popupClass: this.popupContainerClasses
        });
        const popupWrapper = this.popupRef.popupElement;
        const { min, max } = this.width;
        popupWrapper.style.minWidth = min;
        popupWrapper.style.width = max;
        popupWrapper.style.height = this.height;
        popupWrapper.setAttribute("dir", this.direction);
        this.popupRef.popupOpen.subscribe(() => {
            this.cdr.detectChanges();
            this.opened.emit();
        });
        this.popupRef.popupClose.subscribe(() => {
            this.closed.emit();
        });
    }
    destroyPopup() {
        if (this.popupRef) {
            this.popupRef.close();
            this.popupRef = null;
            if (this.filter !== "") {
                this.filter = "";
                this.allNodesHidden = false;
                if (hasObservers(this.filterChange)) {
                    this._zone.run(() => {
                        this.filterChange.emit("");
                    });
                }
            }
        }
    }
    handleEscape() {
        this.togglePopup(false);
        this.focus();
    }
    setState() {
        if (isPresent(this.value) && isPresent(this.dataItem) && isPresent(this.valueField)) {
            this.text = this.getText(this.textField, this.dataItem);
            const valueField = this.getField(this.valueField, this.dataItem);
            this.selectBy = valueField;
            this.selectedKeys = [getter(valueField)(this.dataItem)];
        }
        else {
            this.clearState();
        }
        this.cdr.markForCheck();
    }
    clearState() {
        this.text = undefined;
        this.selectedKeys = [];
    }
    subscribeEvents() {
        this.subscriptions.push(this.navigationService.open.subscribe(() => this.togglePopup(true)), this.navigationService.close.subscribe(() => {
            this.togglePopup(false);
            this.focus();
        }), this.navigationService.enter
            .pipe(tap((event) => event.originalEvent.preventDefault()))
            .subscribe(() => this.togglePopup(true)), this.navigationService.esc
            .subscribe(() => this.handleEscape()), this.navigationService.tab.subscribe(() => this.focus()), this.navigationService.down.subscribe((event) => {
            if (!this.treeview) {
                return;
            }
            event.originalEvent.preventDefault();
            if (!this.treeview.isActive) {
                this.treeview.focus();
            }
        }), this.navigationService.up.subscribe((event) => {
            if (!this.treeview) {
                return;
            }
            event.originalEvent.preventDefault();
            if (this.filterable && this.treeview['navigationService']['activeIndex'] === '0') {
                this.filterInput.nativeElement.focus();
            }
        }));
    }
    subscribeTouchEvents() {
        if (!isDocumentAvailable() || !this.touchEnabled) {
            return;
        }
        this._zone.runOutsideAngular(() => 
        // Roll up DropDownTree on iOS when tapped outside
        this.touchstartDisposeHandler = this.renderer.listen(document, 'touchstart', (e) => {
            const target = e.target;
            if (this.isFocused && !inDropDown(this.wrapper, target, this.popupRef)) {
                this._zone.run(() => {
                    if (this.isOpen) {
                        this.treeview.blur();
                    }
                    this.blur();
                });
            }
        }));
    }
    subscribeFocusEvents() {
        if (isDocumentAvailable()) {
            this.handleFocus = this.handleFocus.bind(this);
            this.handleDocumentBlur = this.handleDocumentBlur.bind(this);
            this._zone.runOutsideAngular(() => {
                const useCapture = true;
                document.addEventListener('focus', this.handleFocus, useCapture);
                document.addEventListener('blur', this.handleDocumentBlur, useCapture);
            });
        }
    }
    unSubscribeFocusEvents() {
        if (isDocumentAvailable()) {
            const useCapture = true;
            document.removeEventListener('focus', this.handleFocus, useCapture);
            document.removeEventListener('blur', this.handleDocumentBlur, useCapture);
        }
    }
    unsubscribeEvents() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.unSubscribeFocusEvents();
        if (this.touchstartDisposeHandler) {
            this.touchstartDisposeHandler();
        }
    }
    handleDocumentBlur(event) {
        if (event.target !== this.wrapper.nativeElement) {
            return;
        }
        event.stopImmediatePropagation();
        this.handleBlur(event);
    }
    assignAriaDescribedBy() {
        const currentValue = this.wrapper.nativeElement.getAttribute('aria-describedby') || '';
        // add to the current value - don't replace it (the aria-describedby is used by the FormField component as well)
        const newValue = `${this.valueLabelId} ${currentValue.trim()}`.trim();
        this.renderer.setAttribute(this.wrapper.nativeElement, 'aria-describedby', newValue);
    }
    setComponentClasses() {
        if (this.size) {
            this.renderer.addClass(this.wrapper.nativeElement, getSizeClass('picker', this.size));
        }
        if (this.rounded) {
            this.renderer.addClass(this.wrapper.nativeElement, getRoundedClass(this.rounded));
        }
        if (this.fillMode) {
            this.renderer.addClass(this.wrapper.nativeElement, getFillModeClass('picker', this.fillMode));
        }
    }
};
__decorate([
    HostBinding('class.k-dropdowntree'),
    HostBinding('class.k-picker'),
    __metadata("design:type", Boolean)
], DropDownTreeComponent.prototype, "hostClasses", void 0);
__decorate([
    HostBinding('class.k-disabled'),
    HostBinding('attr.aria-disabled'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], DropDownTreeComponent.prototype, "isDisabled", null);
__decorate([
    HostBinding('class.k-loading'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], DropDownTreeComponent.prototype, "isLoading", null);
__decorate([
    HostBinding('attr.id'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], DropDownTreeComponent.prototype, "id", null);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String)
], DropDownTreeComponent.prototype, "direction", void 0);
__decorate([
    HostBinding('attr.tabindex'),
    __metadata("design:type", Number),
    __metadata("design:paramtypes", [])
], DropDownTreeComponent.prototype, "hostTabIndex", null);
__decorate([
    HostBinding('attr.role'),
    __metadata("design:type", String)
], DropDownTreeComponent.prototype, "role", void 0);
__decorate([
    HostBinding('attr.aria-haspopup'),
    __metadata("design:type", String)
], DropDownTreeComponent.prototype, "ariaHasPopup", void 0);
__decorate([
    HostBinding('attr.aria-expanded'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], DropDownTreeComponent.prototype, "isAriaExpanded", null);
__decorate([
    HostBinding('attr.readonly'),
    HostBinding('attr.aria-readonly'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], DropDownTreeComponent.prototype, "isReadonly", null);
__decorate([
    HostListener('click'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], DropDownTreeComponent.prototype, "handleClick", null);
__decorate([
    HostListener('keydown', ['$event']),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], DropDownTreeComponent.prototype, "handleKeydown", null);
__decorate([
    ContentChild(NoDataTemplateDirective, { static: false }),
    __metadata("design:type", NoDataTemplateDirective)
], DropDownTreeComponent.prototype, "noDataTemplate", void 0);
__decorate([
    ContentChild(HeaderTemplateDirective, { static: false }),
    __metadata("design:type", HeaderTemplateDirective)
], DropDownTreeComponent.prototype, "headerTemplate", void 0);
__decorate([
    ContentChild(FooterTemplateDirective, { static: false }),
    __metadata("design:type", FooterTemplateDirective)
], DropDownTreeComponent.prototype, "footerTemplate", void 0);
__decorate([
    ContentChild(NodeTemplateDirective, { static: false }),
    __metadata("design:type", NodeTemplateDirective)
], DropDownTreeComponent.prototype, "nodeTemplate", void 0);
__decorate([
    ContentChild(ValueTemplateDirective, { static: false }),
    __metadata("design:type", ValueTemplateDirective)
], DropDownTreeComponent.prototype, "valueTemplate", void 0);
__decorate([
    ViewChild('popupTemplate', { static: true }),
    __metadata("design:type", TemplateRef)
], DropDownTreeComponent.prototype, "popupTemplate", void 0);
__decorate([
    ViewChild('container', { read: ViewContainerRef, static: true }),
    __metadata("design:type", ViewContainerRef)
], DropDownTreeComponent.prototype, "container", void 0);
__decorate([
    ViewChild('treeview', { static: false }),
    __metadata("design:type", TreeViewComponent),
    __metadata("design:paramtypes", [TreeViewComponent])
], DropDownTreeComponent.prototype, "treeview", null);
__decorate([
    ViewChild('filterInput', { static: false }),
    __metadata("design:type", ElementRef)
], DropDownTreeComponent.prototype, "filterInput", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], DropDownTreeComponent.prototype, "open", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], DropDownTreeComponent.prototype, "opened", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], DropDownTreeComponent.prototype, "close", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], DropDownTreeComponent.prototype, "closed", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], DropDownTreeComponent.prototype, "nodeExpand", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], DropDownTreeComponent.prototype, "nodeCollapse", void 0);
__decorate([
    Output('focus'),
    __metadata("design:type", EventEmitter)
], DropDownTreeComponent.prototype, "onFocus", void 0);
__decorate([
    Output('blur'),
    __metadata("design:type", EventEmitter)
], DropDownTreeComponent.prototype, "onBlur", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], DropDownTreeComponent.prototype, "valueChange", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], DropDownTreeComponent.prototype, "filterChange", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], DropDownTreeComponent.prototype, "loading", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], DropDownTreeComponent.prototype, "clearButton", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [Array])
], DropDownTreeComponent.prototype, "data", null);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], DropDownTreeComponent.prototype, "value", null);
__decorate([
    Input(),
    __metadata("design:type", Object)
], DropDownTreeComponent.prototype, "textField", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], DropDownTreeComponent.prototype, "valueField", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], DropDownTreeComponent.prototype, "valueDepth", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function)
], DropDownTreeComponent.prototype, "hasChildren", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function)
], DropDownTreeComponent.prototype, "fetchChildren", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], DropDownTreeComponent.prototype, "placeholder", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], DropDownTreeComponent.prototype, "popupSettings", null);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], DropDownTreeComponent.prototype, "dataItem", null);
__decorate([
    Input(),
    __metadata("design:type", Number)
], DropDownTreeComponent.prototype, "listHeight", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], DropDownTreeComponent.prototype, "disabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], DropDownTreeComponent.prototype, "readonly", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], DropDownTreeComponent.prototype, "valuePrimitive", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number),
    __metadata("design:paramtypes", [Number])
], DropDownTreeComponent.prototype, "tabindex", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], DropDownTreeComponent.prototype, "size", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], DropDownTreeComponent.prototype, "rounded", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], DropDownTreeComponent.prototype, "fillMode", null);
__decorate([
    Input(),
    __metadata("design:type", Function)
], DropDownTreeComponent.prototype, "itemDisabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function)
], DropDownTreeComponent.prototype, "isNodeExpanded", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function)
], DropDownTreeComponent.prototype, "isNodeVisible", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], DropDownTreeComponent.prototype, "loadOnDemand", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], DropDownTreeComponent.prototype, "filterable", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], DropDownTreeComponent.prototype, "filter", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], DropDownTreeComponent.prototype, "focusableId", void 0);
DropDownTreeComponent = DropDownTreeComponent_1 = __decorate([
    Component({
        exportAs: 'kendoDropDownTree',
        providers: [
            DataService,
            SelectionService,
            NavigationService,
            DisabledItemsService,
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.dropdowntree'
            },
            {
                multi: true,
                provide: NG_VALUE_ACCESSOR,
                useExisting: forwardRef(() => DropDownTreeComponent_1)
            },
            {
                provide: KendoInput,
                useExisting: forwardRef(() => DropDownTreeComponent_1)
            },
            {
                provide: DataBoundComponent,
                useExisting: forwardRef(() => DropDownTreeComponent_1)
            },
            {
                provide: ExpandableComponent,
                useExisting: forwardRef(() => DropDownTreeComponent_1)
            }
        ],
        selector: 'kendo-dropdowntree',
        template: `
        <ng-container kendoDropDownTreeLocalizedMessages
            i18n-noDataText="kendo.dropdowntree.noDataText|The text displayed in the popup when there are no items"
            noDataText="NO DATA FOUND"

            i18n-clearTitle="kendo.dropdowntree.clearTitle|The title of the clear button"
            clearTitle="clear"

            i18n-selectButtonText="kendo.dropdowntree.selectButtonText|The text set as aria-label on the select button"
            selectButtonText="Select"
        >
        </ng-container>
        <span
            [attr.id]="valueLabelId"
            class="k-input-inner"
            role="option"
        >
            <span class="k-input-value-text">
                <ng-template *ngIf="valueTemplate"
                    [templateContext]="{
                        templateRef: valueTemplate.templateRef,
                        $implicit: dataItem
                    }">
                </ng-template>
                <ng-template [ngIf]="!valueTemplate"> {{ text || placeholder }} </ng-template>
            </span>
        </span>
        <span
            *ngIf="!loading && !readonly && clearButton && text?.length && !disabled"
            class="k-clear-value"
            [style.visibility]="clearButtonVisiblity"
            aria-hidden="true"
            [attr.title]="messageFor('clearTitle')"
            (click)="clearValue($event)"
        >
            <span class="k-icon k-i-x"></span>
        </span>
        <button
            tabindex="-1"
            type="button"
            aria-hidden="true"
            class="k-input-button k-button k-icon-button"
            [ngClass]="selectButtonClasses"
            [attr.aria-label]="messageFor('selectButtonText')"
        >
            <span
                class="k-button-icon k-icon"
                [ngClass]="buttonClasses"
            ></span>
        </button>
        <ng-template #popupTemplate>
            <span
                *ngIf="filterable"
                class="k-list-filter"
            >
                <span
                    class="k-textbox k-input"
                    [ngClass]="filterInputClasses"
                >
                    <span class="k-input-prefix">
                        <span class="k-input-icon k-icon k-i-search"></span>
                    </span>
                    <input
                        #filterInput
                        (input)="handleFilterInputChange($event.target.value)"
                        [filterInput]="filterable && !touchEnabled"
                        (keydown.arrowdown)="handleKeydown($event)"
                        (keydown.alt.arrowup)="handleKeydown($event)"
                        [(ngModel)]="filter"
                        class="k-input-inner"
                        role="textbox"
                        aria-haspopup="true"
                        aria-expanded="false"
                        tabindex="0"
                        aria-disabled="false"
                        aria-readonly="false"
                        [kendoEventsOutsideAngular]="{
                            blur: handleBlur
                        }"
                        [scope]="this"
                    >
                </span>
            </span>
            <!--header template-->
            <ng-template
                *ngIf="headerTemplate"
                [templateContext]="{
                    templateRef: headerTemplate?.templateRef
                }">
            </ng-template>
            <kendo-treeview
                #treeview
                *ngIf="data.length !== 0 && !allNodesHidden"
                [size]="size"
                [nodes]="data"
                [style.maxHeight.px]="listHeight"
                [animate]="false"
                [(selectedKeys)]="selectedKeys"
                [selectBy]="selectBy"
                [textField]="textField"
                kendoTreeViewSelectable
                [children]="children"
                [hasChildren]="hasChildren"
                [loadOnDemand]="loadOnDemand"
                [isExpanded]="isNodeExpanded"
                [isDisabled]="itemDisabled"
                [nodeTemplate]="nodeTemplate"
                [filter]="filter"
                [isVisible]="isNodeVisible"
                (focusout)="handleBlur($event)"
                (keydown)="handleKeydown($event)"
                (selectionChange)="onSelectionChange($event)"
                (expand)="nodeExpand.emit($event)"
                (collapse)="nodeCollapse.emit($event)"
            >
            </kendo-treeview>
            <!--footer template-->
            <ng-template
                *ngIf="footerTemplate"
                [templateContext]="{
                    templateRef: footerTemplate?.templateRef
                }">
            </ng-template>
            <!--no-data template-->
            <div class="k-no-data" *ngIf="data.length === 0 || allNodesHidden">
                <ng-template [ngIf]="noDataTemplate"
                    [templateContext]="{
                        templateRef: noDataTemplate?.templateRef
                    }">
                </ng-template>
                <ng-template [ngIf]="!noDataTemplate">
                    <div>{{ messageFor('noDataText') }}</div>
                </ng-template>
            </div>
        </ng-template>
        <ng-container #container></ng-container>
    `,
        changeDetection: ChangeDetectionStrategy.OnPush
    }),
    __param(7, Optional()), __param(7, Inject(TOUCH_ENABLED)),
    __metadata("design:paramtypes", [ElementRef,
        PopupService,
        NavigationService,
        Renderer2,
        NgZone,
        ChangeDetectorRef,
        LocalizationService, Boolean])
], DropDownTreeComponent);

var MultiSelectTreeComponent_1;
/* tslint:disable:member-ordering */
const DEFAULT_POPUP_SETTINGS$1 = { animate: true };
const DEFAULT_CHECKABLE_SETTINGS = { checkChildren: true, checkOnClick: true };
const hasChildren$1 = () => false;
const fetchChildren$1 = () => of([]);
const itemDisabled$1 = () => false;
const isNodeVisible$1 = () => true;
/**
 * Represents the [Kendo UI MultiSelectTree component for Angular]({% slug overview_multiselecttree %}).
 */
let MultiSelectTreeComponent = MultiSelectTreeComponent_1 = class MultiSelectTreeComponent {
    constructor(wrapper, popupService, renderer, navigationService, _zone, localization, cdr, touchEnabled$$1) {
        this.wrapper = wrapper;
        this.popupService = popupService;
        this.renderer = renderer;
        this.navigationService = navigationService;
        this._zone = _zone;
        this.localization = localization;
        this.cdr = cdr;
        this.touchEnabled = touchEnabled$$1;
        this.hostClasses = true;
        this.role = 'combobox';
        this.ariaHasPopup = 'tree';
        /**
         * Sets the levels in the data set where the values can be found when `valueField` is an Array.
         * The field serves to correctly allocate a data item used when the MultiSelectTree is initialized with a value.
         */
        this.valueDepth = [];
        /**
         * The hint which is displayed when the component is empty.
         */
        this.placeholder = "";
        /**
         * Sets the height of the options list. By default, `listHeight` is 200px.
         *
         * > The `listHeight` property affects only the list of options and not the whole popup container.
         * > To set the height of the popup container, use `popupSettings.height`.
         */
        this.listHeight = 200;
        /**
         * Sets the disabled state of the component.
         */
        this.disabled = false;
        /**
         * Sets the read-only state of the component.
         */
        this.readonly = false;
        /**
         * Specifies the type of the selected value
         * ([more information and example]({% slug valuebinding_multiselecttree %}#toc-primitive-values)).
         * If set to `true`, the selected value has to be a primitive one.
         */
        this.valuePrimitive = false;
        /**
         * Indicates whether the child nodes will be fetched on node expand or will be initially prefetched.
         * @default false
         */
        this.loadOnDemand = false;
        /**
         * @hidden
         *
         * Used by the kendo-label and kendo-floatinglabel to access and associate the focusable element with the provided label via aria-labelledby.
         */
        this.focusableId = `k-${guid()}`;
        /**
         * If set to `true`, renders a button on hovering over the component.
         * Clicking this button resets the value of the component to `undefined` and triggers the `change` event.
         * @default true
         */
        this.clearButton = true;
        /**
         * Renders the built-in input element for filtering the MultiSelectTree.
         * If set to `true`, the component emits the `filterChange` event, which can be used to [filter the MultiSelectTree manually]({% slug filtering_multiselecttree %}#toc-manual-filtering).
         * A built-in filtering implementation is available to use with the [`kendoMultiSelectTreeHierarchyBinding`]({% slug api_dropdowns_multiselecttreehierarchybindingdirective %}) and [`kendoMultiSelectTreeFlatBinding`]({% slug api_dropdowns_multiselecttreeflatbindingdirective %}) directives.
         * @default false
         */
        this.filterable = false;
        /**
         * If `checkАll` is set to `true` and the checkboxes are enabled, a tri-state checkbox appears above the embedded treeview.
         * Clicking the checkbox checks or unchecks all enabled items of the treeview that are loaded.
         * @default false
         */
        this.checkAll = false;
        /**
         * A function which determines if a specific node has child nodes.
         */
        this.hasChildren = hasChildren$1;
        /**
         * A function which provides the child nodes for a given parent node.
         */
        this.fetchChildren = fetchChildren$1;
        /**
         * A callback which determines whether a tree node should be rendered as hidden. The utility .k-display-none class is used to hide the nodes.
         * Useful for custom filtering implementations.
         */
        this.isNodeVisible = isNodeVisible$1;
        /**
         * A function that is executed for each data item and determines if a specific item is disabled.
         */
        this.itemDisabled = itemDisabled$1;
        /**
         * A user-defined callback function which receives an array of selected data items and maps them to an array of tags.
         *
         * @param { Any[] } dataItems - The selected data items from the list.
         * @returns { Any[] } - The tags that will be rendered by the component.
         */
        this.tagMapper = (tags) => tags || [];
        /**
         * Fires each time the user focuses the MultiSelectTree.
         */
        this.onFocus = new EventEmitter();
        /**
         * Fires each time the MultiSelectTree gets blurred.
         */
        this.onBlur = new EventEmitter();
        /**
         * Fires each time the popup is about to open
         * ([see example]({% slug openstate_multiselecttree %})).
         * This event is preventable. If you cancel it, the popup will remain closed.
         */
        this.open = new EventEmitter();
        /**
         * Fires after the popup has been opened.
         */
        this.opened = new EventEmitter();
        /**
         * Fires each time the popup is about to close
         * ([see example]({% slug openstate_multiselecttree %})).
         * This event is preventable. If you cancel it, the popup will remain open.
         */
        this.close = new EventEmitter();
        /**
         * Fires after the popup has been closed.
         */
        this.closed = new EventEmitter();
        /**
         * Fires when the user expands a node in the popup TreeView.
         */
        this.nodeExpand = new EventEmitter();
        /**
         * Fires when the user collapses a node in the popup TreeView.
         */
        this.nodeCollapse = new EventEmitter();
        /**
         * Fires each time the value is changed
         * ([see example]({% slug overview_multiselecttree %}#toc-events)).
         */
        this.valueChange = new EventEmitter();
        /**
         * Fires each time a tag is about to be removed.
         * This event is preventable. If you cancel it, the tag will not be removed.
         */
        this.removeTag = new EventEmitter();
        /**
         * Fires when the value of the built-in filter input element changes.
         */
        this.filterChange = new EventEmitter();
        /**
         * @hidden
         */
        this.filterStateChange = new EventEmitter();
        /**
         * @hidden
         */
        this.checkedItems = [];
        /**
         * @hidden
         */
        this.checkBy = (item) => item;
        /**
         * @hidden
         */
        this.showAfter = 0;
        /**
         * @hidden
         */
        this.allNodesHidden = false;
        this.tagListId = guid();
        this.tagPrefix = "tag-" + guid();
        this.focusedTagIndex = undefined;
        this._value = [];
        this._tabindex = 0;
        this._popupSettings = DEFAULT_POPUP_SETTINGS$1;
        this._checkableSettings = DEFAULT_CHECKABLE_SETTINGS;
        this._isFocused = false;
        this._size = 'medium';
        this._rounded = 'medium';
        this._fillMode = 'solid';
        this.subscriptions = [];
        this.lastAction = 'check';
        this.onTouchedCallback = noop;
        this.onChangeCallback = noop;
        this.direction = localization.rtl ? 'rtl' : 'ltr';
        this.subscribeEvents();
        this.subscribeFocusEvents();
    }
    get isDisabled() {
        return this.disabled;
    }
    get isLoading() {
        return this.loading;
    }
    get id() {
        return this.focusableId;
    }
    get hostTabIndex() {
        return this.tabindex;
    }
    get isAriaExpanded() {
        return this.isOpen;
    }
    get isReadonly() {
        return this.readonly;
    }
    get ariaDescribedBy() {
        return this.tagListId;
    }
    get ariaActiveDescendant() {
        return this.focusedTagId;
    }
    /**
     * @hidden
     */
    handleClick() {
        this.togglePopup(!this.isOpen);
    }
    /**
     * @hidden
     */
    handleKeydown(event) {
        const deleteTag = this.isWrapperActive && event.keyCode === Keys.Backspace && this.tags.length > 0;
        if (deleteTag) {
            this.handleBackspace();
            return;
        }
        if (this.disabled || this.readonly) {
            return;
        }
        const eventData = event;
        const action = this.navigationService.process({
            originalEvent: eventData
        });
        if (action === NavigationAction.Open) {
            eventData.preventDefault();
        }
    }
    set treeview(treeview) {
        this._treeview = treeview;
        if (treeview) {
            // If filtering is enabled, focus the TreeView on mobile devices instead of the filter input
            if (this.isFocused && !this.filterable && !this.checkAll || this.touchEnabled) {
                treeview.focus();
            }
            /**
             * the treeview animations are initially disabled (we don't want expand animations during popup opening)
             * re-enables the animations for user interaction
             * The Promise is required to properly change the `animate` property when
             * the popup is appended to a container and opened upon initialization.
             * Otherwise, the "Expression has changed..." type error will be thrown.
             */
            Promise.resolve(null).then(() => this.treeview.animate = true);
        }
    }
    get treeview() {
        return this._treeview;
    }
    /**
     * Specifies the [`tabindex`](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex) of the component.
     */
    set tabindex(value) {
        const providedTabIndex = parseNumber(value);
        const defaultTabIndex = 0;
        this._tabindex = !isNaN(providedTabIndex) ? providedTabIndex : defaultTabIndex;
    }
    get tabindex() {
        return this.disabled ? -1 : this._tabindex;
    }
    /**
     * Sets the size of the component.
     *
     * The possible values are:
     * * `'small'`
     * * `'medium'` (default)
     * * `'large'`
     * * `null`
     *
     */
    set size(size) {
        this.renderer.removeClass(this.wrapper.nativeElement, getSizeClass('input', this.size));
        if (size) {
            this.renderer.addClass(this.wrapper.nativeElement, getSizeClass('input', size));
        }
        this._size = size;
    }
    get size() {
        return this._size;
    }
    /**
     * Sets the border radius of the component.
     *
     * The possible values are:
     * * `'small'`
     * * `'medium'` (default)
     * * `'large'`
     * * `'full'`
     * * `null`
     *
     */
    set rounded(rounded) {
        this.renderer.removeClass(this.wrapper.nativeElement, getRoundedClass(this.rounded));
        if (rounded) {
            this.renderer.addClass(this.wrapper.nativeElement, getRoundedClass(rounded));
        }
        this._rounded = rounded;
    }
    get rounded() {
        return this._rounded;
    }
    /**
     * Sets the fillMode of the component.
     *
     * The possible values are:
     * * `'flat'`
     * * `'solid'` (default)
     * * `'outline'`
     * * `null`
     *
     */
    set fillMode(fillMode) {
        this.renderer.removeClass(this.wrapper.nativeElement, getFillModeClass('input', this.fillMode));
        if (fillMode) {
            this.renderer.addClass(this.wrapper.nativeElement, getFillModeClass('input', fillMode));
        }
        this._fillMode = fillMode;
    }
    get fillMode() {
        return this._fillMode;
    }
    /**
     * Configures the popup of the MultiSelectTree.
     *
     * The available options are:
     * - `animate: Boolean`&mdash;Controls the popup animation. By default, the open and close animations are enabled.
     * - `width: Number | String`&mdash;Sets the width of the popup container. By default, the width of the host element is used. If set to `auto`, the component automatically adjusts the width of the popup and no item labels are wrapped.
     * - `height: Number`&mdash;Sets the height of the popup container.
     * - `popupClass: String`&mdash;Specifies a list of CSS classes that are used to style the popup.
     * - `appendTo: "root" | "component" | ViewContainerRef`&mdash;Specifies the component to which the popup will be appended.
     */
    set popupSettings(settings) {
        this._popupSettings = Object.assign({}, DEFAULT_POPUP_SETTINGS$1, settings);
        // `detectChanges` needed, otherwise upon value initialization and `appendTo` property
        // an error is thrown => ExpressionChangedAfterItHasBeenCheckedError
        this.cdr.detectChanges();
    }
    get popupSettings() {
        return this._popupSettings;
    }
    /**
     * Defines the checkable settings of the MultiSelecTree nodes.
     * If no value is provided, the default [`CheckableSettings`]({% slug api_dropdowns_multiselecttreecheckablesettings %}) are applied.
     */
    set checkableSettings(settings) {
        this._checkableSettings = Object.assign({}, DEFAULT_CHECKABLE_SETTINGS, settings);
    }
    get checkableSettings() {
        return this._checkableSettings;
    }
    /**
     * Sets the data of the MultiSelectTree.
     *
     * > The data has to be provided in an array-like list with objects.
     */
    set data(data) {
        this._nodes = data;
        this.setState();
    }
    get data() {
        return this._nodes;
    }
    /**
     * Sets the value of the MultiSelectTree.
     * It can either be of the primitive (string, numbers) or of the complex (objects) type.
     * To define the type, use the `valuePrimitive` option.
     *
     */
    set value(value) {
        this._value = value ? value : [];
        this.setState();
    }
    get value() {
        return this._value;
    }
    /**
     * Keeps the current `dataItems` object in order to resolve selection.
     * Needs to be provided when when programmatically setting a `value` and `valuePrimitive` is set to `true`.
     */
    set dataItems(items) {
        this._dataItems = (items || []).map((item, index) => {
            if (hasProps(item, ['dataItem', 'index', 'level'])) {
                return item;
            }
            return {
                dataItem: item,
                index: null,
                level: this.valueDepth[index] || 0
            };
        });
        this.setState();
    }
    get dataItems() {
        return this._dataItems || this.value.map((value, index) => ({
            dataItem: value,
            index: null,
            level: this.valueDepth[index] || 0
        }));
    }
    /**
     * @hidden
     */
    get focusedTagId() {
        if (!isPresent(this.focusedTagIndex) || this.isOpen) {
            return null;
        }
        const dataItem = this.tags[this.focusedTagIndex];
        return `${this.tagPrefix}-${valueFrom({ dataItem }, this.valueField)}`;
    }
    set isFocused(isFocused) {
        this.renderer[isFocused ? 'addClass' : 'removeClass'](this.wrapper.nativeElement, 'k-focus');
        this._isFocused = isFocused;
    }
    get isFocused() {
        return this._isFocused;
    }
    /**
     * Returns the current open state of the popup.
     */
    get isOpen() {
        return isPresent(this.popupRef);
    }
    get width() {
        const wrapperWidth = this.wrapper.nativeElement.offsetWidth;
        const width = this.popupSettings.width || wrapperWidth;
        const minWidth = isNaN(wrapperWidth) ? wrapperWidth : `${wrapperWidth}px`;
        const maxWidth = isNaN(width) ? width : `${width}px`;
        return { min: minWidth, max: maxWidth };
    }
    get height() {
        const popupHeight = this.popupSettings.height;
        return isPresent(popupHeight) ? `${popupHeight}px` : 'auto';
    }
    get appendTo() {
        const { appendTo } = this.popupSettings;
        if (!appendTo || appendTo === 'root') {
            return undefined;
        }
        return appendTo === 'component' ? this.container : appendTo;
    }
    /**
     * @hidden
     */
    get popupContainerClasses() {
        const containerClasses = ['k-popup-dropdowntree'];
        if (this.popupSettings.popupClass) {
            containerClasses.push(this.popupSettings.popupClass);
        }
        return containerClasses;
    }
    /**
     * @hidden
     *
     * Alias for `data`. Used for compatibility with the `DataBoundComponent` interface.
     * Required for the data-binding directives.
     */
    set nodes(nodes) {
        this.data = nodes;
    }
    get nodes() {
        return this.data;
    }
    /**
     * @hidden
     *
     * Alias for `fetchChildren`. Used for compatibility with the `DataBoundComponent` interface.
     * Required for the data-binding directives
     */
    set children(callback) {
        this.fetchChildren = callback;
    }
    get children() {
        return this.fetchChildren;
    }
    /**
     * @hidden
     *
     * Alias for `nodeExpand`. Used for compatibility with the `ExpandableComponent` interface.
     * Required for the expand-directive.
     */
    get expand() {
        return this.nodeExpand;
    }
    /**
     * @hidden
     *
     * Alias for `nodeCollapse`. Used for compatibility with the `ExpandableComponent` interface.
     * Required for the expand-directive.
     */
    get collapse() {
        return this.nodeCollapse;
    }
    /**
     * @hidden
     *
     * Alias for `isNodeExpanded`. Used for compatibility with the `ExpandableComponent` interface.
     * Required for the expand-directive.
     */
    set isExpanded(callback) {
        this.isNodeExpanded = callback;
    }
    get isExpanded() {
        return this.isNodeExpanded;
    }
    /**
     * @hidden
     *
     * Alias for `isNodeVisible`. Used for compatibility with the `DataBoundComponent` interface.
     * The `DataBoundComponent` interface is used in the data-binding directives.
     */
    set isVisible(callback) {
        this.isNodeVisible = callback;
    }
    get isVisible() {
        return this.isNodeVisible;
    }
    get isTagFocused() {
        return !this.isOpen && this.focusedTagIndex !== undefined;
    }
    get isTreeViewActive() {
        return this.treeview && this.treeview.isActive;
    }
    get isWrapperActive() {
        return document.activeElement === this.wrapper.nativeElement;
    }
    get isFilterActive() {
        return this.filterInput && document.activeElement === this.filterInput.nativeElement;
    }
    get isCheckAllActive() {
        return this.checkAllInput && document.activeElement === this.checkAllInput.nativeElement;
    }
    ngOnInit() {
        this.renderer.removeAttribute(this.wrapper.nativeElement, 'tabindex');
        this.renderer.setAttribute(this.wrapper.nativeElement, 'aria-expanded', String(this.isOpen));
        this.subscriptions.push(this.localization
            .changes.subscribe(({ rtl }) => {
            this.direction = rtl ? 'rtl' : 'ltr';
            this.cdr.markForCheck();
        }));
        this.setComponentClasses();
    }
    /**
     * @hidden
     */
    ngOnDestroy() {
        this.destroyPopup();
        this.unsubscribeEvents();
    }
    /**
     * @hidden
     */
    ngOnChanges(changes) {
        if (anyChanged(['textField', 'valueField', 'valuePrimitive'], changes, false)) {
            this.setState();
        }
        if (anyChanged(['valueDepth', 'value', 'dataItems'], changes, false)) {
            if (changes.value && !changes.dataItems && !this.valuePrimitive) {
                // Update the dataItems if the value is updated programmatically (non-primitive values only)
                // In the primitive case, the client should update the dataItems as well
                this.dataItems = this.value;
            }
            else {
                // Re-map the dataItems because `valueDepth` is not yet available when the check directive parses the items
                this.dataItems = this.dataItems.map((item, index) => (Object.assign({}, item, { level: this.valueDepth[index] || 0 })));
            }
        }
    }
    /**
     * @hidden
     */
    ngAfterContentChecked() {
        this.verifySettings();
    }
    /**
     * @hidden
     *
     * Used by the kendo-floatinglabel component to determine if the floating label
     * should be rendered inside the input when the component is not focused.
     */
    isEmpty() {
        return !Boolean(this.placeholder) && (!isPresent(this.value) || this.value.length === 0);
    }
    /**
     * Focuses the MultiSelectTree.
     */
    focus() {
        if (!this.disabled) {
            this.wrapper.nativeElement.focus();
        }
    }
    /**
     * Blurs the MultiSelectTree.
     */
    blur() {
        if (!this.disabled) {
            this.wrapper.nativeElement.blur();
        }
    }
    /**
     * Focuses a specific item of the MultiSelectTree based on a provided index in the format of `1_1`.
     * The targeted item should be expanded in order for it to be focused.
     * If null or invalid index is provided the focus will be set on the first item.
     */
    focusItemAt(index) {
        if (this.treeview) {
            const lookup = this.treeview.itemLookup(index);
            const isItemDisabled = !isPresent(lookup) || this.treeview.isDisabled(lookup.item.dataItem, lookup.item.index);
            if (!isItemDisabled) {
                this.treeview.focus(index);
            }
        }
    }
    /**
     * Resets the value of the MultiSelectTree.
     * If you use the `reset` method to clear the value of the component,
     * the model will not update automatically and the `valueChange` event will not be fired.
     */
    reset() {
        this.value = [];
        this.dataItems = [];
        this.valueDepth = [];
    }
    /**
     * Toggles the visibility of the popup
     * ([see example]({% slug openstate_multiselecttree %})).
     * If you use the `toggle` method to open or close the popup, the `open` and `close` events will not be fired.
     *
     * @param open - The state of the popup.
     */
    toggle(open) {
        // The Promise is required to open the popup on load.
        // Otherwise, the "ViewContainerRef not found..." error will be thrown.
        Promise.resolve(null).then(() => {
            const shouldOpen = isPresent(open) ? open : !isPresent(this.popupRef);
            this.destroyPopup();
            if (shouldOpen) {
                this.createPopup();
            }
        });
    }
    /**
     * @hidden
     */
    handleFocus(event) {
        if (event.target !== this.wrapper.nativeElement) {
            return;
        }
        event.stopImmediatePropagation();
        if (!this.isFocused) {
            this.isFocused = true;
            if (hasObservers(this.onFocus)) {
                this._zone.run(() => {
                    this.onFocus.emit();
                });
            }
            // Re-focus the treeview if `close` is prevented
            if (this.isOpen && this.treeview) {
                if (this.lastNodeOnFocus) {
                    this.lastNodeOnFocus.setAttribute('tabindex', '0');
                }
                this.treeview.focus();
            }
        }
    }
    /**
     * @hidden
     */
    handleBlur(e) {
        const relatedTarget = e && e.relatedTarget;
        if (this.wrapper.nativeElement.contains(relatedTarget) ||
            (this.isOpen && this.popupRef.popupElement.contains(relatedTarget))) {
            return;
        }
        this.isFocused = false;
        this.togglePopup(false);
        if (hasObservers(this.onBlur) ||
            isUntouched(this.wrapper.nativeElement)) {
            this._zone.run(() => {
                this.onBlur.emit();
                this.onTouchedCallback();
            });
        }
    }
    /**
     * @hidden
     */
    handleNodeClick(node) {
        if (!this.isFocused) {
            // Re-focus the MultiSelectTree when popup close is prevented and a node is clicked
            // On click the focus should be on the clicked element which is why we need to update the lastNodeOnFocus
            const parent = node.originalEvent.target.parentElement.parentElement;
            this.lastNodeOnFocus = parent;
            this.focus();
        }
    }
    /**
     * @hidden
     */
    togglePopup(open) {
        const isDisabled = this.disabled || this.readonly;
        const sameState = this.isOpen === open;
        this._zone.run(() => {
            this.focusedTagIndex = undefined;
        });
        if (isDisabled || sameState) {
            return;
        }
        const togglePrevented = this.triggerPopupEvents(open);
        if (!togglePrevented) {
            open ?
                this.createPopup() :
                this.destroyPopup();
        }
        else {
            this.removeTreeViewFromTabOrder();
        }
    }
    /**
     * @hidden
     */
    messageFor(key) {
        return this.localization.get(key);
    }
    /**
     * @hidden
     */
    handleCheckedItemsChange(items) {
        this.valueDepth = items.map(item => item.level);
        this.lastAction = items.length > this.dataItems.length ? 'check' : 'uncheck';
        this.dataItems = items.slice();
        this.updateValue(this.dataItems);
    }
    /**
     * @hidden
     */
    handleRemoveTag({ tag, index }) {
        if (this.disabled || this.readonly) {
            return;
        }
        const eventArgs = new RemoveTagEvent(tag);
        this.removeTag.emit(eventArgs);
        if (eventArgs.isDefaultPrevented()) {
            return;
        }
        // Remove tags based on their position index
        if (tag instanceof Array) {
            // Remove group tag
            this.dataItems = this.dataItems.filter((_item, i) => i < this.showAfter || this.disabledIndices.has(i));
            this.valueDepth = this.valueDepth.filter((_item, i) => i < this.showAfter || this.disabledIndices.has(i));
        }
        else {
            // Remove single tag
            this.dataItems = this.dataItems.filter((_item, i) => i !== index || this.disabledIndices.has(i));
            this.valueDepth = this.valueDepth.filter((_item, i) => i !== index || this.disabledIndices.has(i));
        }
        this.updateValue(this.dataItems);
        // focus the wrapper if the component is not focused - the floating label reacts to focus/blur
        if (!this.isFocused) {
            this.focus();
        }
    }
    /**
     * @hidden
     */
    handleTagMapperChange(showAfter) {
        this.showAfter = parseNumber(showAfter);
        this.setTags();
    }
    /**
     * @hidden
     */
    clearAll(event) {
        event.stopImmediatePropagation();
        event.preventDefault();
        this.focus();
        this.value = this.value.filter((_item, index) => this.disabledIndices.has(index));
        this.dataItems = this.dataItems.filter((_item, index) => this.disabledIndices.has(index));
        this.valueDepth = this.valueDepth.filter((_depth, index) => this.disabledIndices.has(index));
        this.emitValueChange(this.value);
    }
    /**
     * @hidden
     */
    writeValue(value) {
        this.value = value || [];
        // Update the dataItems if the value is updated programmatically (non-primitive values only)
        // In the primitive case, the client should update the dataItems as well
        if (!this.valuePrimitive) {
            this.dataItems = this.value;
        }
    }
    /**
     * @hidden
     */
    registerOnChange(fn) {
        this.onChangeCallback = fn;
    }
    /**
     * @hidden
     */
    registerOnTouched(fn) {
        this.onTouchedCallback = fn;
    }
    /**
     * @hidden
     */
    setDisabledState(isDisabled) {
        this.disabled = isDisabled;
        this.cdr.markForCheck();
    }
    /**
     * @hidden
     */
    handleFilterInputChange(term) {
        this.filterChange.next(term);
        this.allNodesHidden = this.nodes.every((node, index) => !this.isVisible(node, String(index)));
    }
    /**
     * @hidden
     */
    get filterInputClasses() {
        return `${this.size ? getSizeClass('input', this.size) : ''} ${this.fillMode ? 'k-input-' + this.fillMode : ''} ${this.rounded ? getRoundedClass(this.rounded) : ''}`;
    }
    /**
     * @hidden
     */
    get checkAllCheckboxClasses() {
        return `${this.size ? getSizeClass('checkbox', this.size) : ''}`;
    }
    /**
     * @hidden
     */
    toggleCheckAll() {
        this.checkAllInput.nativeElement.focus();
        this.checkAllInput.nativeElement.click();
    }
    verifySettings() {
        if (!isDevMode()) {
            return;
        }
        if (!isPresent(this.valueField) || !isPresent(this.textField)) {
            throw new Error(MultiSelectTreeMessages.textAndValue);
        }
        if (!isArray(this.value)) {
            throw new Error(MultiSelectTreeMessages.array);
        }
        if (this.value.length > 0) {
            if (this.valuePrimitive && this.value.some(item => isObject(item))) {
                throw new Error(MultiSelectTreeMessages.primitive);
            }
            const isEveryDataItemObject = this.dataItems.every(item => isObject(item.dataItem));
            if (this.valuePrimitive && !isArray(this.dataItems)) {
                throw new Error(MultiSelectTreeMessages.dataItems);
            }
            if (this.valuePrimitive && !isEveryDataItemObject) {
                throw new Error(MultiSelectTreeMessages.dataItems);
            }
            if (this.valuePrimitive && this.dataItems.length !== this.value.length) {
                throw new Error(MultiSelectTreeMessages.dataItemsLength);
            }
            if (!this.valuePrimitive && !isObjectArray(this.value)) {
                throw new Error(MultiSelectTreeMessages.object);
            }
            if ((isArray(this.valueField) || isArray(this.textField)) && !isArray(this.valueDepth)) {
                throw new Error(MultiSelectTreeMessages.valueDepth);
            }
            if ((isArray(this.valueField) || isArray(this.textField)) && this.valueDepth.length === 0) {
                throw new Error(MultiSelectTreeMessages.valueDepth);
            }
            if ((isArray(this.valueField) || isArray(this.textField)) && this.valueDepth.length !== this.value.length) {
                throw new Error(MultiSelectTreeMessages.valueDepthLength);
            }
        }
    }
    emitValueChange(value) {
        this.onChangeCallback(value);
        this.valueChange.emit(value);
    }
    triggerPopupEvents(open) {
        const eventArgs = new PreventableEvent();
        open ?
            this.open.emit(eventArgs) :
            this.close.emit(eventArgs);
        return eventArgs.isDefaultPrevented();
    }
    createPopup() {
        const horizontalAlign = this.direction === "rtl" ? "right" : "left";
        const anchorPosition = { horizontal: horizontalAlign, vertical: 'bottom' };
        const popupPosition = { horizontal: horizontalAlign, vertical: 'top' };
        this.popupRef = this.popupService.open({
            anchor: this.wrapper,
            appendTo: this.appendTo,
            anchorAlign: anchorPosition,
            content: this.popupTemplate,
            popupAlign: popupPosition,
            positionMode: 'absolute',
            popupClass: this.popupContainerClasses
        });
        const popupWrapper = this.popupRef.popupElement;
        const { min, max } = this.width;
        popupWrapper.style.minWidth = min;
        popupWrapper.style.width = max;
        popupWrapper.style.height = this.height;
        this.renderer.setAttribute(popupWrapper, 'dir', this.direction);
        this.renderer.setAttribute(this.wrapper.nativeElement, 'aria-expanded', 'true');
        this.popupRef.popupOpen.subscribe(() => {
            this.cdr.detectChanges();
            this.opened.emit();
        });
        this.popupRef.popupClose.subscribe(() => {
            this.closed.emit();
        });
    }
    destroyPopup() {
        if (this.popupRef) {
            this.popupRef.close();
            this.popupRef = null;
            this.renderer.setAttribute(this.wrapper.nativeElement, 'aria-expanded', 'false');
            if (this.filter !== "") {
                this.filter = "";
                this.allNodesHidden = false;
                if (hasObservers(this.filterChange)) {
                    this._zone.run(() => {
                        this.filterChange.emit("");
                    });
                }
            }
        }
    }
    subscribeEvents() {
        this.subscriptions.push(this.navigationService.open.subscribe(() => this.togglePopup(true)), this.navigationService.enter
            .pipe(tap((event) => event.originalEvent.preventDefault()))
            .subscribe(() => this.togglePopup(true)), merge(this.navigationService.close, this.navigationService.esc).subscribe(() => {
            this.focus();
            this.togglePopup(false);
        }), this.navigationService.tab.subscribe(this.handleTabKey.bind(this)), this.navigationService.up.subscribe(this.handleUpKey.bind(this)), this.navigationService.down.subscribe(this.handleDownKey.bind(this)), this.navigationService.left
            .pipe(filter(() => !this.isTreeViewActive))
            .subscribe(this.direction === 'rtl' ? this.handleRightKey.bind(this) : this.handleLeftKey.bind(this)), this.navigationService.right
            .pipe(filter(() => !this.isTreeViewActive))
            .subscribe(this.direction === 'rtl' ? this.handleLeftKey.bind(this) : this.handleRightKey.bind(this)), this.navigationService.home.pipe(filter(() => !this.isOpen)).subscribe(this.handleHome.bind(this)), this.navigationService.end.pipe(filter(() => !this.isOpen)).subscribe(this.handleEnd.bind(this)), this.navigationService.backspace.pipe(filter(() => this.isTagFocused)).subscribe(this.handleBackspace.bind(this)), this.navigationService.delete.pipe(filter(() => this.isTagFocused)).subscribe(this.handleDelete.bind(this)));
    }
    subscribeFocusEvents() {
        if (isDocumentAvailable()) {
            this.handleFocus = this.handleFocus.bind(this);
            this.handleDocumentBlur = this.handleDocumentBlur.bind(this);
            this._zone.runOutsideAngular(() => {
                const useCapture = true;
                document.addEventListener('focus', this.handleFocus, useCapture);
                document.addEventListener('blur', this.handleDocumentBlur, useCapture);
            });
        }
    }
    unSubscribeFocusEvents() {
        if (isDocumentAvailable()) {
            const useCapture = true;
            document.removeEventListener('focus', this.handleFocus, useCapture);
            document.removeEventListener('blur', this.handleDocumentBlur, useCapture);
        }
    }
    handleDocumentBlur(event) {
        if (event.target !== this.wrapper.nativeElement) {
            return;
        }
        event.stopImmediatePropagation();
        this.handleBlur(event);
    }
    handleTabKey() {
        this.focus();
        if (this.isOpen) {
            this.treeview.blur();
            this.removeTreeViewFromTabOrder();
        }
    }
    handleUpKey(event) {
        if (!this.treeview) {
            return;
        }
        event.originalEvent.preventDefault();
        // Prevent toggling the focus between the filterInput and the wrapper elements with `up` key
        if (this.isWrapperActive) {
            return;
        }
        const isFirstNodeActive = this.treeview['navigationService']['activeIndex'] === '0';
        // Current focus is on the filter input => should focus the wrapper
        if (this.filterable && this.isFilterActive) {
            this.focus();
            // Current focus is on the treeview first node => should focus the check all checkbox if enabled
        }
        else if (this.checkAll && !this.isCheckAllActive && isFirstNodeActive) {
            this.checkAllInput.nativeElement.focus();
            // Current focus is either on the check all checkbox or the treeview's first node
            // => should focus either the filter input (if enabled) or the wrapper
        }
        else if (this.isCheckAllActive || isFirstNodeActive) {
            this.filterable ?
                this.filterInput.nativeElement.focus() :
                this.focus();
        }
    }
    handleDownKey(event) {
        if (!this.treeview) {
            return;
        }
        event.originalEvent.preventDefault();
        // Current focus is on the wrapper => should focus the filter input
        if (this.filterable && this.isWrapperActive) {
            this.filterInput.nativeElement.focus();
            // Current focus is on the wrapper/filter input => should focus check all checkbox if enabled
        }
        else if (this.checkAll && (this.isWrapperActive || this.isFilterActive)) {
            this.checkAllInput.nativeElement.focus();
            // Should focus the treeview if filterable and check all are disabled
        }
        else if (!this.treeview.isActive) {
            this.treeview.focus();
        }
        this.focusedTagIndex = undefined;
    }
    handleRightKey(event) {
        event.originalEvent.preventDefault();
        const last = this.tags.length - 1;
        if (this.focusedTagIndex === last) {
            this.focusedTagIndex = undefined;
        }
        else if (this.focusedTagIndex < last) {
            this.focusedTagIndex++;
        }
        else if (!this.focusedTagIndex) {
            this.focusedTagIndex = 0;
        }
    }
    handleLeftKey(event) {
        event.originalEvent.preventDefault();
        if (this.focusedTagIndex === undefined || this.focusedTagIndex < 0) {
            this.focusedTagIndex = this.tags.length - 1;
        }
        else if (this.focusedTagIndex !== 0) {
            this.focusedTagIndex--;
        }
    }
    handleEnd(event) {
        event.originalEvent.preventDefault();
        this.focusedTagIndex = this.tags.length - 1;
    }
    handleHome(event) {
        event.originalEvent.preventDefault();
        this.focusedTagIndex = 0;
    }
    handleBackspace() {
        if (this.focusedTagIndex !== undefined) {
            this.handleDelete();
        }
        else {
            const tag = this.tags[this.tags.length - 1];
            const index = this.tags.length - 1;
            this.handleRemoveTag({ tag, index });
        }
    }
    handleDelete() {
        const tag = this.tags[this.focusedTagIndex];
        const index = this.focusedTagIndex;
        this.handleRemoveTag({ tag, index });
        if (this.focusedTagIndex === this.tags.length) {
            this.focusedTagIndex = undefined;
        }
    }
    unsubscribeEvents() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.unSubscribeFocusEvents();
    }
    /**
     * Remove the `TreeView` from the tab order, otherwise a focus loop between the page elements will occur
     * and the user will not be able to tab to the rest of the browser elements
     */
    removeTreeViewFromTabOrder() {
        const nodes = this.treeview.element.nativeElement.querySelectorAll('li');
        nodes.forEach(item => {
            if (item.getAttribute('tabindex') === '0') {
                this.lastNodeOnFocus = item;
                this.lastNodeOnFocus.setAttribute('tabindex', '-1');
            }
        });
    }
    setState() {
        if (isPresent(this.dataItems) && isPresent(this.valueField)) {
            this.setTags();
            this.checkedItems = this.dataItems.slice();
        }
        this.cdr.markForCheck();
    }
    setTags() {
        const source = this.dataItems.map(item => item.dataItem);
        this.tags = this.tagMapper(source);
        this.disabledIndices = this.disabledItemsMapper();
    }
    updateValue(value) {
        const newValue = this.valuePrimitive ?
            value.map(item => valueFrom(item, this.valueField)) :
            value.map(item => item.dataItem);
        this.value = newValue;
        this.emitValueChange(this.value);
    }
    /**
     * @hidden
     *
     * Determines which of the provided tags should be disabled and stores their position indices
     */
    disabledItemsMapper() {
        return new Set(this.dataItems.reduce((indices, item, index) => {
            if (this.itemDisabled(item.dataItem, item.index)) {
                indices.push(index);
            }
            return indices;
        }, []));
    }
    setComponentClasses() {
        if (this.size) {
            this.renderer.addClass(this.wrapper.nativeElement, getSizeClass('input', this.size));
        }
        if (this.rounded) {
            this.renderer.addClass(this.wrapper.nativeElement, getRoundedClass(this.rounded));
        }
        if (this.fillMode) {
            this.renderer.addClass(this.wrapper.nativeElement, getFillModeClass('input', this.fillMode));
        }
    }
};
__decorate([
    HostBinding('class.k-dropdowntree'),
    HostBinding('class.k-input'),
    __metadata("design:type", Boolean)
], MultiSelectTreeComponent.prototype, "hostClasses", void 0);
__decorate([
    HostBinding('class.k-disabled'),
    HostBinding('attr.aria-disabled'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], MultiSelectTreeComponent.prototype, "isDisabled", null);
__decorate([
    HostBinding('class.k-loading'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], MultiSelectTreeComponent.prototype, "isLoading", null);
__decorate([
    HostBinding('attr.id'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], MultiSelectTreeComponent.prototype, "id", null);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String)
], MultiSelectTreeComponent.prototype, "direction", void 0);
__decorate([
    HostBinding('attr.tabindex'),
    __metadata("design:type", Number),
    __metadata("design:paramtypes", [])
], MultiSelectTreeComponent.prototype, "hostTabIndex", null);
__decorate([
    HostBinding('attr.role'),
    __metadata("design:type", String)
], MultiSelectTreeComponent.prototype, "role", void 0);
__decorate([
    HostBinding('attr.aria-expanded'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], MultiSelectTreeComponent.prototype, "isAriaExpanded", null);
__decorate([
    HostBinding('attr.aria-haspopup'),
    __metadata("design:type", String)
], MultiSelectTreeComponent.prototype, "ariaHasPopup", void 0);
__decorate([
    HostBinding('attr.readonly'),
    HostBinding('attr.aria-readonly'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], MultiSelectTreeComponent.prototype, "isReadonly", null);
__decorate([
    HostBinding('attr.aria-describedby'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], MultiSelectTreeComponent.prototype, "ariaDescribedBy", null);
__decorate([
    HostBinding('attr.aria-activedescendant'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], MultiSelectTreeComponent.prototype, "ariaActiveDescendant", null);
__decorate([
    HostListener('click'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MultiSelectTreeComponent.prototype, "handleClick", null);
__decorate([
    HostListener('keydown', ['$event']),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], MultiSelectTreeComponent.prototype, "handleKeydown", null);
__decorate([
    ContentChild(HeaderTemplateDirective, { static: false }),
    __metadata("design:type", HeaderTemplateDirective)
], MultiSelectTreeComponent.prototype, "headerTemplate", void 0);
__decorate([
    ContentChild(FooterTemplateDirective, { static: false }),
    __metadata("design:type", FooterTemplateDirective)
], MultiSelectTreeComponent.prototype, "footerTemplate", void 0);
__decorate([
    ContentChild(NodeTemplateDirective, { static: false }),
    __metadata("design:type", NodeTemplateDirective)
], MultiSelectTreeComponent.prototype, "nodeTemplate", void 0);
__decorate([
    ContentChild(NoDataTemplateDirective, { static: false }),
    __metadata("design:type", NoDataTemplateDirective)
], MultiSelectTreeComponent.prototype, "noDataTemplate", void 0);
__decorate([
    ContentChild(TagTemplateDirective, { static: false }),
    __metadata("design:type", TagTemplateDirective)
], MultiSelectTreeComponent.prototype, "tagTemplate", void 0);
__decorate([
    ContentChild(GroupTagTemplateDirective, { static: false }),
    __metadata("design:type", GroupTagTemplateDirective)
], MultiSelectTreeComponent.prototype, "groupTagTemplate", void 0);
__decorate([
    ViewChild('popupTemplate', { static: true }),
    __metadata("design:type", TemplateRef)
], MultiSelectTreeComponent.prototype, "popupTemplate", void 0);
__decorate([
    ViewChild('container', { read: ViewContainerRef, static: true }),
    __metadata("design:type", ViewContainerRef)
], MultiSelectTreeComponent.prototype, "container", void 0);
__decorate([
    ViewChild('treeview', { static: false }),
    __metadata("design:type", TreeViewComponent),
    __metadata("design:paramtypes", [TreeViewComponent])
], MultiSelectTreeComponent.prototype, "treeview", null);
__decorate([
    ViewChild('filterInput', { static: false }),
    __metadata("design:type", ElementRef)
], MultiSelectTreeComponent.prototype, "filterInput", void 0);
__decorate([
    ViewChild('checkAllInput', { static: false }),
    __metadata("design:type", ElementRef)
], MultiSelectTreeComponent.prototype, "checkAllInput", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number),
    __metadata("design:paramtypes", [Number])
], MultiSelectTreeComponent.prototype, "tabindex", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], MultiSelectTreeComponent.prototype, "size", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], MultiSelectTreeComponent.prototype, "rounded", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], MultiSelectTreeComponent.prototype, "fillMode", null);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], MultiSelectTreeComponent.prototype, "popupSettings", null);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], MultiSelectTreeComponent.prototype, "checkableSettings", null);
__decorate([
    Input(),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [Array])
], MultiSelectTreeComponent.prototype, "data", null);
__decorate([
    Input(),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [Array])
], MultiSelectTreeComponent.prototype, "value", null);
__decorate([
    Input(),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [Array])
], MultiSelectTreeComponent.prototype, "dataItems", null);
__decorate([
    Input(),
    __metadata("design:type", Object)
], MultiSelectTreeComponent.prototype, "textField", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], MultiSelectTreeComponent.prototype, "valueField", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], MultiSelectTreeComponent.prototype, "valueDepth", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], MultiSelectTreeComponent.prototype, "loading", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], MultiSelectTreeComponent.prototype, "placeholder", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], MultiSelectTreeComponent.prototype, "listHeight", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], MultiSelectTreeComponent.prototype, "disabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], MultiSelectTreeComponent.prototype, "readonly", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], MultiSelectTreeComponent.prototype, "valuePrimitive", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], MultiSelectTreeComponent.prototype, "loadOnDemand", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], MultiSelectTreeComponent.prototype, "focusableId", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], MultiSelectTreeComponent.prototype, "clearButton", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], MultiSelectTreeComponent.prototype, "filterable", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], MultiSelectTreeComponent.prototype, "checkAll", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function)
], MultiSelectTreeComponent.prototype, "hasChildren", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function)
], MultiSelectTreeComponent.prototype, "fetchChildren", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function)
], MultiSelectTreeComponent.prototype, "isNodeExpanded", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function)
], MultiSelectTreeComponent.prototype, "isNodeVisible", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function)
], MultiSelectTreeComponent.prototype, "itemDisabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], MultiSelectTreeComponent.prototype, "tagMapper", void 0);
__decorate([
    Output('focus'),
    __metadata("design:type", EventEmitter)
], MultiSelectTreeComponent.prototype, "onFocus", void 0);
__decorate([
    Output('blur'),
    __metadata("design:type", EventEmitter)
], MultiSelectTreeComponent.prototype, "onBlur", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], MultiSelectTreeComponent.prototype, "open", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], MultiSelectTreeComponent.prototype, "opened", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], MultiSelectTreeComponent.prototype, "close", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], MultiSelectTreeComponent.prototype, "closed", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], MultiSelectTreeComponent.prototype, "nodeExpand", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], MultiSelectTreeComponent.prototype, "nodeCollapse", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], MultiSelectTreeComponent.prototype, "valueChange", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], MultiSelectTreeComponent.prototype, "removeTag", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], MultiSelectTreeComponent.prototype, "filterChange", void 0);
MultiSelectTreeComponent = MultiSelectTreeComponent_1 = __decorate([
    Component({
        exportAs: 'kendoMultiSelectTree',
        providers: [
            LocalizationService,
            NavigationService,
            DataService,
            DisabledItemsService,
            SelectionService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.multiselecttree'
            },
            {
                multi: true,
                provide: NG_VALUE_ACCESSOR,
                useExisting: forwardRef(() => MultiSelectTreeComponent_1)
            },
            {
                provide: DataBoundComponent,
                useExisting: forwardRef(() => MultiSelectTreeComponent_1)
            },
            {
                provide: ExpandableComponent,
                useExisting: forwardRef(() => MultiSelectTreeComponent_1)
            },
            {
                provide: KendoInput,
                useExisting: forwardRef(() => MultiSelectTreeComponent_1)
            }
        ],
        selector: 'kendo-multiselecttree',
        template: `
        <ng-container kendoMultiSelectTreeLocalizedMessages
            i18n-noDataText="kendo.multiselecttree.noDataText|The text displayed in the popup when there are no items"
            noDataText="NO DATA FOUND"

            i18n-clearTitle="kendo.multiselecttree.clearTitle|The title of the clear button"
            clearTitle="clear"

            i18n-checkAllText="kendo.multiselecttree.checkAllText|The text displayed for the check-all checkbox"
            checkAllText="Check all"
        >
        </ng-container>
        <kendo-taglist
            [size]="size"
            [rounded]="rounded"
            [fillMode]="fillMode"
            [id]="tagListId"
            [tags]="tags"
            [focused]="focusedTagIndex"
            [textField]="textField"
            [valueField]="valueField"
            [valueDepth]="valueDepth"
            [disabled]="disabled"
            [tagPrefix]="tagPrefix"
            [template]="tagTemplate"
            [groupTemplate]="groupTagTemplate"
            [disabledIndices]="disabledIndices"
            (removeTag)="handleRemoveTag($event)"
        >
            <span *ngIf="!tags || !tags.length"
                class="k-input-inner k-readonly"
            >
                <span class="k-input-value-text">{{ placeholder }}</span>
            </span>
        </kendo-taglist>
        <span
            *ngIf="!disabled && !loading && !readonly && clearButton && tags?.length"
            class="k-clear-value"
            [attr.title]="messageFor('clearTitle')"
            role="button"
            tabindex="-1"
            (click)="clearAll($event)"
        >
            <span class="k-icon k-i-x"></span>
        </span>
        <span
            *ngIf="loading"
            class="k-icon k-i-loading"
        >
        </span>
        <ng-template #popupTemplate>
            <span
                *ngIf="filterable"
                class="k-list-filter"
            >
                <span
                    class="k-textbox k-input"
                    [ngClass]="filterInputClasses"
                >
                    <span class="k-input-prefix">
                        <span class="k-input-icon k-icon k-i-search"></span>
                    </span>
                    <input
                        #filterInput
                        (input)="handleFilterInputChange($event.target.value)"
                        [filterInput]="filterable && !touchEnabled"
                        (keydown)="handleKeydown($event)"
                        [(ngModel)]="filter"
                        class="k-input-inner"
                        role="textbox"
                        aria-haspopup="true"
                        aria-expanded="false"
                        tabindex="0"
                        aria-disabled="false"
                        aria-readonly="false"
                        [kendoEventsOutsideAngular]="{
                            blur: handleBlur
                        }"
                        [scope]="this"
                    >
                </span>
            </span>
            <!--header template-->
            <ng-template
                *ngIf="headerTemplate"
                [templateContext]="{
                    templateRef: headerTemplate?.templateRef
                }">
            </ng-template>
            <div *ngIf="checkAll" class="k-check-all">
                <input
                    #checkAllInput
                    [checkAll]="!filterable && !touchEnabled"
                    type="checkbox"
                    class="k-checkbox"
                    [ngClass]="checkAllCheckboxClasses"
                    role="checkbox"
                    tabindex="0"
                    aria-disabled="false"
                    aria-readonly="false"
                    [treeview]="treeview"
                    [checkedItems]="checkedItems"
                    [valueField]="valueField"
                    [lastAction]="lastAction"
                    (checkedItemsChange)="handleCheckedItemsChange($event)"
                    (keydown)="handleKeydown($event)"
                    [kendoEventsOutsideAngular]="{
                        blur: handleBlur
                    }"
                    [scope]="this"
                >
                <span
                    class="k-checkbox-label"
                    (click)="toggleCheckAll()"
                    (mousedown)="$event.preventDefault()"
                >
                    {{ messageFor('checkAllText') }}
                </span>
            </div>
            <kendo-treeview
                #treeview
                [size]="size"
                [nodes]="data"
                [style.maxHeight.px]="listHeight"
                [animate]="false"
                kendoMultiSelectTreeCheckable
                [checkable]="checkableSettings"
                [checkedItems]="checkedItems"
                [valueField]="valueField"
                [textField]="textField"
                [children]="children"
                [hasChildren]="hasChildren"
                [isExpanded]="isNodeExpanded"
                [isDisabled]="itemDisabled"
                [nodeTemplate]="nodeTemplate"
                [loadOnDemand]="loadOnDemand"
                [filter]="filter"
                [isVisible]="isNodeVisible"
                (keydown)="handleKeydown($event)"
                (nodeClick)="handleNodeClick($event)"
                (expand)="nodeExpand.emit($event)"
                (collapse)="nodeCollapse.emit($event)"
                (checkedItemsChange)="handleCheckedItemsChange($event)"
                [kendoEventsOutsideAngular]="{
                    focusout: handleBlur
                }"
                [scope]="this"
            >
            </kendo-treeview>
            <!--footer template-->
            <ng-template
                *ngIf="footerTemplate"
                [templateContext]="{
                    templateRef: footerTemplate?.templateRef
                }">
            </ng-template>
            <!--no-data template-->
            <div class="k-no-data" *ngIf="data?.length === 0 || allNodesHidden">
                <ng-template [ngIf]="noDataTemplate"
                    [templateContext]="{
                        templateRef: noDataTemplate?.templateRef
                    }">
                </ng-template>
                <ng-template [ngIf]="!noDataTemplate">
                    <div>{{ messageFor('noDataText') }}</div>
                </ng-template>
            </div>
        </ng-template>
        <ng-container #container></ng-container>
    `
    }),
    __param(7, Optional()), __param(7, Inject(TOUCH_ENABLED)),
    __metadata("design:paramtypes", [ElementRef,
        PopupService,
        Renderer2,
        NavigationService,
        NgZone,
        LocalizationService,
        ChangeDetectorRef, Boolean])
], MultiSelectTreeComponent);

/**
 * A directive which encapsulates the retrieval of the child nodes when flat data is provided.
 */
let DropDownTreeFlatBindingDirective = class DropDownTreeFlatBindingDirective extends FlatDataBindingDirective {
    constructor(dropDownTree) {
        super(dropDownTree);
    }
};
__decorate([
    Input('kendoDropDownTreeFlatBinding'),
    __metadata("design:type", Array)
], DropDownTreeFlatBindingDirective.prototype, "nodes", void 0);
__decorate([
    Input('valueField'),
    __metadata("design:type", String)
], DropDownTreeFlatBindingDirective.prototype, "idField", void 0);
DropDownTreeFlatBindingDirective = __decorate([
    Directive({
        selector: '[kendoDropDownTreeFlatBinding]'
    }),
    __metadata("design:paramtypes", [DataBoundComponent])
], DropDownTreeFlatBindingDirective);

/**
 * A directive which encapsulates the retrieval of the child nodes when hierarchical data is provided.
 */
let DropDownTreeHierarchyBindingDirective = class DropDownTreeHierarchyBindingDirective extends HierarchyBindingDirective {
    constructor(dropDownTree) {
        super(dropDownTree);
        this.dropDownTree = dropDownTree;
    }
    /**
     * The nodes which will be displayed by the DropDownTree.
     */
    set data(nodes) {
        this.dropDownTree.nodes = nodes;
        this.nodes = nodes;
    }
};
__decorate([
    Input('kendoDropDownTreeHierarchyBinding'),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [Array])
], DropDownTreeHierarchyBindingDirective.prototype, "data", null);
DropDownTreeHierarchyBindingDirective = __decorate([
    Directive({
        selector: '[kendoDropDownTreeHierarchyBinding]'
    }),
    __metadata("design:paramtypes", [DataBoundComponent])
], DropDownTreeHierarchyBindingDirective);

/**
 * A directive which encapsulates the retrieval of the child nodes when flat data is provided.
 */
let MultiSelectTreeFlatBindingDirective = class MultiSelectTreeFlatBindingDirective extends FlatDataBindingDirective {
    constructor(multiSelectTree) {
        super(multiSelectTree);
    }
};
__decorate([
    Input('kendoMultiSelectTreeFlatBinding'),
    __metadata("design:type", Array)
], MultiSelectTreeFlatBindingDirective.prototype, "nodes", void 0);
__decorate([
    Input('valueField'),
    __metadata("design:type", String)
], MultiSelectTreeFlatBindingDirective.prototype, "idField", void 0);
MultiSelectTreeFlatBindingDirective = __decorate([
    Directive({
        selector: '[kendoMultiSelectTreeFlatBinding]'
    }),
    __metadata("design:paramtypes", [DataBoundComponent])
], MultiSelectTreeFlatBindingDirective);

/**
 * A directive which encapsulates the retrieval of the child nodes when hierarchical data is provided.
 */
let MultiSelectTreeHierarchyBindingDirective = class MultiSelectTreeHierarchyBindingDirective extends HierarchyBindingDirective {
    constructor(multiSelectTree) {
        super(multiSelectTree);
        this.multiSelectTree = multiSelectTree;
    }
    /**
     * The nodes which will be displayed by the MultiSelectTree.
     */
    set data(nodes) {
        this.multiSelectTree.nodes = nodes;
        this.nodes = nodes;
    }
};
__decorate([
    Input('kendoMultiSelectTreeHierarchyBinding'),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [Array])
], MultiSelectTreeHierarchyBindingDirective.prototype, "data", null);
MultiSelectTreeHierarchyBindingDirective = __decorate([
    Directive({
        selector: '[kendoMultiSelectTreeHierarchyBinding]'
    }),
    __metadata("design:paramtypes", [DataBoundComponent])
], MultiSelectTreeHierarchyBindingDirective);

/**
 * A directive which manages the expanded state of the popup TreeView.
 */
let DropDownTreesExpandDirective = class DropDownTreesExpandDirective extends ExpandDirective {
    constructor(dropDownTree) {
        super(dropDownTree);
    }
};
__decorate([
    Input('isNodeExpanded'),
    __metadata("design:type", Function)
], DropDownTreesExpandDirective.prototype, "isExpanded", void 0);
DropDownTreesExpandDirective = __decorate([
    Directive({
        selector: '[kendoDropDownTreeExpandable], [kendoMultiSelectTreeExpandable]'
    }),
    __metadata("design:paramtypes", [ExpandableComponent])
], DropDownTreesExpandDirective);

/**
 * @hidden
 */
let TagListComponent = class TagListComponent {
    constructor(renderer, hostElement) {
        this.renderer = renderer;
        this.hostElement = hostElement;
        this.rounded = 'medium';
        this.fillMode = 'solid';
        /**
         * A collection with the disabled tags' indices.
         */
        this.disabledIndices = new Set();
        this.removeTag = new EventEmitter();
        this.hostClasses = true;
        this._size = 'medium';
    }
    set size(size) {
        this.renderer.removeClass(this.hostElement.nativeElement, getSizeClass('chip-list', this.size));
        if (size) {
            this.renderer.addClass(this.hostElement.nativeElement, getSizeClass('chip-list', size));
        }
        this._size = size;
    }
    get size() {
        return this._size;
    }
    get hostId() {
        return this.id;
    }
    tagProp(tag, prop, index) {
        const propField = prop && this.getPropField(tag, prop, index);
        return getter$1(tag, propField);
    }
    isTagDisabled(tag, positionIndex) {
        if (this.isGroupTag(tag)) {
            /** The `positionIndex` is used to determine after how many single tags is the group tag displayed =>
             * => it is used to increment the indices inside the group tag so that we determine the actual position index
             * of each group tag item as they appear in the `value` (important to check against `disabledIndices`)
             * e.g. `disabledIndices = [0, 1]` && `tags = ['Small', ['Medium', 'Large']]` => without the incrementation with
             * `positionIndex`, the group tag would yield [0, 1] as indices, while it should yield [1, 2]
             */
            return tag.every((_tag, index) => this.disabledIndices.has(index + positionIndex));
        }
        return this.disabledIndices.has(positionIndex);
    }
    deleteTag(event, tag, index) {
        event.preventDefault();
        event.stopImmediatePropagation();
        if (!this.disabled && event.which === 1) {
            this.removeTag.emit({ tag, index });
        }
    }
    itemId(tag, index) {
        if (tag) { //because of custom values
            return this.tagPrefix + "-" + this.tagProp(tag, this.valueField, index);
        }
    }
    isGroupTag(tag) {
        return tag instanceof Array;
    }
    tagAriaHidden(index) {
        return isPresent(this.focused) && this.focused !== index;
    }
    getPropField(tag, prop, index) {
        // Needed for MultiSelectTree value binding (Heterogeneous Data)
        const fieldsCount = prop.length - 1;
        if (typeof prop === 'string') {
            return prop;
        }
        else if (this.valueDepth) {
            const depth = this.valueDepth[index];
            return fieldsCount < depth ? prop[fieldsCount] : prop[depth];
        }
        else {
            return prop.find(item => item in tag);
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", Array)
], TagListComponent.prototype, "tags", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], TagListComponent.prototype, "textField", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], TagListComponent.prototype, "valueField", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], TagListComponent.prototype, "valueDepth", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], TagListComponent.prototype, "focused", void 0);
__decorate([
    Input(),
    __metadata("design:type", TagTemplateDirective)
], TagListComponent.prototype, "template", void 0);
__decorate([
    Input(),
    __metadata("design:type", GroupTagTemplateDirective)
], TagListComponent.prototype, "groupTemplate", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], TagListComponent.prototype, "disabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], TagListComponent.prototype, "tagPrefix", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], TagListComponent.prototype, "id", void 0);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], TagListComponent.prototype, "size", null);
__decorate([
    Input(),
    __metadata("design:type", String)
], TagListComponent.prototype, "rounded", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], TagListComponent.prototype, "fillMode", void 0);
__decorate([
    Input(),
    __metadata("design:type", Set)
], TagListComponent.prototype, "disabledIndices", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], TagListComponent.prototype, "removeTag", void 0);
__decorate([
    HostBinding('class.k-input-values'),
    HostBinding('class.k-chip-list'),
    HostBinding('class.k-selection-multiple'),
    __metadata("design:type", Boolean)
], TagListComponent.prototype, "hostClasses", void 0);
__decorate([
    HostBinding('attr.id'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], TagListComponent.prototype, "hostId", null);
TagListComponent = __decorate([
    Component({
        selector: 'kendo-taglist',
        template: `
        <div
            *ngFor="let tag of tags; let index = index;"
            [attr.id]="itemId(tag, index)"
            [attr.aria-hidden]="tagAriaHidden(index)"
            class="k-chip"
            [ngClass]="{
                'k-focus': index === focused,
                'k-disabled': isTagDisabled(tag, index),
                'k-chip-sm': size === 'small',
                'k-chip-md': size === 'medium',
                'k-chip-lg': size === 'large',
                'k-rounded-sm': rounded === 'small',
                'k-rounded-md': rounded === 'medium',
                'k-rounded-lg': rounded === 'large',
                'k-rounded-full': rounded === 'full',
                'k-chip-solid k-chip-solid-base': fillMode === 'solid',
                'k-chip-flat k-chip-flat-base': fillMode === 'flat',
                'k-chip-outline k-chip-outline-base': fillMode === 'outline'
            }"
        >
            <span class="k-chip-content">
                <ng-template *ngIf="isGroupTag(tag); then groupTag else singleTag"></ng-template>
                <ng-template #groupTag>
                    <span class="k-chip-label k-text-ellipsis">
                        <ng-template *ngIf="groupTemplate"
                            [templateContext]="{
                            templateRef: groupTemplate.templateRef,
                            $implicit: tag
                        }">
                        </ng-template>
                        <ng-template [ngIf]="!groupTemplate">{{ tag.length }} {{ tag.length === 1 ? 'item' : 'items' }} selected</ng-template>
                    </span>
                </ng-template>
                <ng-template #singleTag>
                    <span class="k-chip-label k-text-ellipsis">
                        <ng-template *ngIf="template"
                            [templateContext]="{
                            templateRef: template.templateRef,
                            $implicit: tag
                        }">
                        </ng-template>
                        <ng-template [ngIf]="!template">{{ tagProp(tag, textField, index) }}</ng-template>
                    </span>
                </ng-template>
            </span>

            <span class="k-chip-actions">
                <span aria-label="delete" [attr.aria-hidden]="index !== focused" class="k-chip-action k-chip-remove-action">
                    <span class="k-icon k-i-x-circle" (mousedown)="deleteTag($event, tag, index)">
                    </span>
                </span>
            </span>
        </div>
        <ng-content></ng-content>
  `
    }),
    __metadata("design:paramtypes", [Renderer2,
        ElementRef])
], TagListComponent);

/**
 * @hidden
 */
class Messages extends ComponentMessages {
}
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "noDataText", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "clearTitle", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "checkAllText", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "selectButtonText", void 0);

var LocalizedMessagesDirective_1;
/**
 * @hidden
 */
let LocalizedMessagesDirective = LocalizedMessagesDirective_1 = class LocalizedMessagesDirective extends Messages {
    constructor(service) {
        super();
        this.service = service;
    }
};
LocalizedMessagesDirective = LocalizedMessagesDirective_1 = __decorate([
    Directive({
        providers: [
            {
                provide: Messages,
                useExisting: forwardRef(() => LocalizedMessagesDirective_1)
            }
        ],
        selector: `
    [kendoDropDownListLocalizedMessages],
    [kendoDropDownTreeLocalizedMessages],
    [kendoComboBoxLocalizedMessages],
    [kendoMultiColumnComboBoxLocalizedMessages],
    [kendoAutoCompleteLocalizedMessages],
    [kendoMultiSelectLocalizedMessages],
    [kendoMultiSelectTreeLocalizedMessages]
  `
    }),
    __metadata("design:paramtypes", [LocalizationService])
], LocalizedMessagesDirective);

var CustomMessagesComponent_1;
/**
 * Custom component messages override default component messages
 * ([see example]({% slug rtl_dropdowns %}#toc-messages)).
 */
let CustomMessagesComponent = CustomMessagesComponent_1 = class CustomMessagesComponent extends Messages {
    constructor(service) {
        super();
        this.service = service;
    }
    get override() {
        return true;
    }
};
CustomMessagesComponent = CustomMessagesComponent_1 = __decorate([
    Component({
        providers: [
            {
                provide: Messages,
                useExisting: forwardRef(() => CustomMessagesComponent_1)
            }
        ],
        selector: 'kendo-dropdownlist-messages,kendo-combobox-messages,kendo-multicolumncombobox-messages,kendo-autocomplete-messages,kendo-multiselect-messages,kendo-dropdowntree-messages,kendo-multiselecttree-messages',
        template: ``
    }),
    __metadata("design:paramtypes", [LocalizationService])
], CustomMessagesComponent);

const DEFAULT_FILTER_SETTINGS = {
    caseSensitive: false,
    operator: 'startsWith'
};
/**
 * Implements an event handler for the `filterChange` event of a DropDowns component
 * which performs simple data filtering.
 *
 * @example
 * ```ts
 * _@Component({
 * selector: 'my-app',
 * template: `
 *  <kendo-autocomplete
 *      [data]="data"
 *      kendoDropDownFilter
 *      placeholder="e.g. Andorra"
 *  >
 *  </kendo-autocomplete>
 * `
 * })
 * class AppComponent {
 *     public data: Array<string> = ["Albania", "Andorra", "Armenia", "Austria", "Azerbaijan"];
 * }
 * ```
 * > Currently, the built-in filtering does not work with [grouped data]({% slug api_kendo-data-query_groupby %}).
 */
let FilterDirective = class FilterDirective {
    constructor(component) {
        this.component = component;
        /**
         * @hidden
         *
         * Sets whether the filtering functionality is enabled on component init.
         */
        this.filterable = true;
        this._data = [];
    }
    /**
     * The initial data that will be used as a source array for the filtering operations.
     */
    set data(data) {
        this._data = data || [];
    }
    get data() {
        return this._data;
    }
    ngOnInit() {
        this.component.filterable = this.filterable;
        this.filterChangeSubscription = this.component.filterChange
            .subscribe(this.handleFilterChange.bind(this));
    }
    ngOnDestroy() {
        if (isPresent(this.filterChangeSubscription)) {
            this.filterChangeSubscription.unsubscribe();
        }
    }
    handleFilterChange(query) {
        this.component.data = this.data.filter(item => this.matchesAnyField(item, query));
    }
    matchesAnyField(item, query) {
        const normalizedQuery = this.normalizeValue(query);
        const { fields } = this.filterSettings;
        // if no filter fields are present, we are dealing with primitive data
        if (fields.length === 0) {
            return this.checkItem(item, normalizedQuery);
        }
        return fields.some(field => this.checkItem(getter$1(item, field), normalizedQuery));
    }
    checkItem(target, query) {
        target = this.normalizeValue(target);
        if (this.filterSettings.operator === 'contains') {
            return target.indexOf(query) !== -1;
        }
        else {
            return target.indexOf(query) === 0;
        }
    }
    normalizeValue(value) {
        const normalizedValue = isPresent(value) ? value.toString() : '';
        return this.filterSettings.caseSensitive ? normalizedValue : normalizedValue.toLowerCase();
    }
    getFilterFields(providedFields) {
        // ignore provided fields if the component deals with primitive data
        if (!this.component.textField && !this.component.valueField) {
            return [];
        }
        if (isArray(providedFields) && providedFields.length > 0) {
            return providedFields;
        }
        else {
            // the autocomplete uses `valueField` for text extraction
            const textField = this.component.textField || this.component.valueField;
            return [textField];
        }
    }
    get filterSettings() {
        const settings = this.rawSettings;
        const providedFields = isPresent(settings) && typeof settings === 'object' ? settings.fields : [];
        return Object.assign({}, DEFAULT_FILTER_SETTINGS, settings, { fields: this.getFilterFields(providedFields) });
    }
};
__decorate([
    Input(),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [Array])
], FilterDirective.prototype, "data", null);
__decorate([
    Input('kendoDropDownFilter'),
    __metadata("design:type", Object)
], FilterDirective.prototype, "rawSettings", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], FilterDirective.prototype, "filterable", void 0);
FilterDirective = __decorate([
    Directive({
        selector: '[kendoDropDownFilter]'
    }),
    __metadata("design:paramtypes", [FilterableComponent])
], FilterDirective);

/**
 * @hidden
 */
let FilterInputDirective = class FilterInputDirective {
    constructor(element, zone) {
        this.element = element;
        this.zone = zone;
    }
    ngOnChanges() {
        if (this.focused) {
            this.nextTick(() => this.element.nativeElement.focus());
        }
    }
    nextTick(fn) {
        this.zone.runOutsideAngular(() => setTimeout(fn));
    }
};
__decorate([
    Input('filterInput'),
    __metadata("design:type", Boolean)
], FilterInputDirective.prototype, "focused", void 0);
FilterInputDirective = __decorate([
    Directive({
        selector: '[filterInput]' // tslint:disable-line
    }),
    __metadata("design:paramtypes", [ElementRef,
        NgZone])
], FilterInputDirective);

const SHARED_DIRECTIVES = [
    HeaderTemplateDirective,
    FooterTemplateDirective,
    ItemTemplateDirective,
    GroupTemplateDirective,
    FixedGroupTemplateDirective,
    NoDataTemplateDirective,
    ValueTemplateDirective,
    TagTemplateDirective,
    GroupTagTemplateDirective,
    LocalizedMessagesDirective,
    CustomMessagesComponent,
    FilterDirective,
    FilterInputDirective
];
/**
 * @hidden
 *
 * The exported package module.
 *
 * The package exports:
 * - `ItemTemplateDirective`&mdash;The item template directive.
 * - `ValueTemplateDirective`&mdash;The value template directive.
 * - `HeaderTemplateDirective`&mdash;The header template directive.
 * - `FooterTemplateDirective`&mdash;The footer template directive.
 * - `NoDataTemplateDirective`&mdash;The noData template directive.
 * - `TagTemplateDirective`&mdash;The tag template directive.
 * - `SummaryTagTemplateDirective`&mdash;The summary tag template directive.
 */
let SharedDirectivesModule = class SharedDirectivesModule {
};
SharedDirectivesModule = __decorate([
    NgModule({
        declarations: [SHARED_DIRECTIVES],
        exports: [SHARED_DIRECTIVES]
    })
], SharedDirectivesModule);

/**
 * @hidden
 */
let SelectableDirective = class SelectableDirective {
    constructor(selectionService) {
        this.checkboxes = { enabled: false };
        // @HostBinding('attr.offset-index')
        // @Input() public offsetIndex: number;
        this.multipleSelection = false;
        this.selectionService = selectionService;
    }
    get focusedClassName() {
        return this.selectionService.isFocused(this.index);
    }
    get selectedClassName() {
        return !this.checkboxes.enabled && this.selectionService.isSelected(this.index);
    }
    onClick(event) {
        event.stopPropagation();
        if (this.checkboxes.enabled && !this.checkboxes.checkOnClick) {
            return;
        }
        if (this.multipleSelection) {
            if (this.selectionService.isSelected(this.index)) {
                this.selectionService.unselect(this.index);
            }
            else {
                this.selectionService.add(this.index);
            }
        }
        else {
            this.selectionService.change(this.index);
        }
    }
};
__decorate([
    HostBinding('attr.index'),
    Input(),
    __metadata("design:type", Number)
], SelectableDirective.prototype, "index", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], SelectableDirective.prototype, "checkboxes", void 0);
__decorate([
    HostBinding('style.height.px'),
    HostBinding('style.minHeight.px'),
    Input(),
    __metadata("design:type", Number)
], SelectableDirective.prototype, "height", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], SelectableDirective.prototype, "multipleSelection", void 0);
__decorate([
    HostBinding('class.k-focus'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], SelectableDirective.prototype, "focusedClassName", null);
__decorate([
    HostBinding('class.k-selected'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], SelectableDirective.prototype, "selectedClassName", null);
__decorate([
    HostListener('click', ['$event']),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], SelectableDirective.prototype, "onClick", null);
SelectableDirective = __decorate([
    Directive({
        selector: '[kendoDropDownsSelectable]'
    }),
    __metadata("design:paramtypes", [SelectionService])
], SelectableDirective);

/**
 * @hidden
 */
let TemplateContextDirective = class TemplateContextDirective {
    constructor(viewContainerRef) {
        this.viewContainerRef = viewContainerRef;
    }
    set templateContext(context) {
        if (this.insertedViewRef) {
            this.viewContainerRef.remove(this.viewContainerRef.indexOf(this.insertedViewRef));
            this.insertedViewRef = undefined;
        }
        if (context.templateRef) {
            this.insertedViewRef = this.viewContainerRef.createEmbeddedView(context.templateRef, context);
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], TemplateContextDirective.prototype, "templateContext", null);
TemplateContextDirective = __decorate([
    Directive({
        selector: '[templateContext]' // tslint:disable-line
    }),
    __metadata("design:paramtypes", [ViewContainerRef])
], TemplateContextDirective);

/**
 * @hidden
 *
 * A directive which manages the in-memory checked state of the MultiSelectTree nodes.
 */
class BaseCheckDirective {
    addItem(item) {
        if (this.isItemChecked(item)) {
            return;
        }
        const level = getHierarchicalItemLevel(item.index);
        const candidate = Object.assign({}, item, { level });
        this.checkedItems.push(candidate);
        this.checkedKeys.add(this.getKey(candidate));
    }
    removeItem(item) {
        if (!this.isItemChecked(item)) {
            return;
        }
        const level = getHierarchicalItemLevel(item.index);
        const candidate = Object.assign({}, item, { level });
        this.checkedItems = this.checkedItems
            .filter(item => valueFrom(item, this.valueField) !== valueFrom(candidate, this.valueField));
        this.checkedKeys.delete(this.getKey(candidate));
    }
    isItemChecked(item) {
        const level = item.index.split('_').length - 1;
        item.level = level;
        return this.checkedKeys.has(this.getKey(item));
    }
    updateItems() {
        this.checkedItems = this.checkedItems || [];
        this.checkedKeys = new Set(this.checkedItems.map(item => this.getKey(item)));
    }
    /**
     * Adds the item's depth to the item's value to allow duplicate values on different levels.
     *
     * @param item - The checked key.
     * @returns { string } - A string key consisting of the item's `valueField` value and its depth.
     */
    getKey(item) {
        if (isArray(this.valueField)) {
            return valueFrom(item, this.valueField) + '_' + item.level;
        }
        return valueFrom(item, this.valueField);
    }
}
__decorate([
    Input(),
    __metadata("design:type", Object)
], BaseCheckDirective.prototype, "valueField", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], BaseCheckDirective.prototype, "checkedItems", void 0);

/**
 * @hidden
 *
 * A directive which manages the in-memory checked state of the MultiSelectTree nodes.
 */
let CheckDirective = class CheckDirective extends BaseCheckDirective {
    constructor(treeView) {
        super();
        this.treeView = treeView;
        /**
         * Fires when the `checkedItems` collection was updated.
         */
        this.checkedItemsChange = new EventEmitter();
        /**
         * Holds a Set with just the checked item keys.
         *
         * Should be updated each time the `checkedItems` value or content is changed.
         * Can be used for efficient look-up of whether an item is checked or not (O(1) access time).
         */
        this.checkedKeys = new Set();
        this.subscriptions = new Subscription();
        this.subscriptions.add(this.treeView.checkedChange
            .subscribe(this.handleCheckedChange.bind(this)));
        this.treeView.isChecked = this.getCheckedState.bind(this);
    }
    ngOnChanges(changes) {
        if (isPresent(changes.checkable)) {
            this.toggleCheckOnClick();
        }
        if (isPresent(changes.checkedItems)) {
            this.updateItems();
        }
    }
    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.unsubscribeClick();
    }
    getCheckedState(dataItem, index) {
        if (this.isItemChecked({ dataItem, index })) {
            return 'checked';
        }
        else if (this.checkable.checkChildren && this.isItemIndeterminate(this.treeView.itemLookup(index))) {
            return 'indeterminate';
        }
        else {
            return 'none';
        }
    }
    handleCheckedChange(node) {
        this.checkNode(node);
        // parents should be checked if `checkChildren` is set to `true` (single config option for both)
        const checkParents = this.checkable.checkChildren;
        if (checkParents) {
            this.checkParents(node.parent);
        }
        this.checkedItemsChange.emit(this.checkedItems.slice());
    }
    toggleCheckOnClick() {
        this.unsubscribeClick();
        if (this.checkable.checkOnClick) {
            this.clickSubscription = this.treeView.nodeClick
                .pipe(filter(event => event.type === 'click'))
                .subscribe(event => {
                const lookup = this.treeView.itemLookup(event.item.index);
                this.handleCheckedChange(lookup);
            });
        }
    }
    unsubscribeClick() {
        if (this.clickSubscription) {
            this.clickSubscription.unsubscribe();
            this.clickSubscription = null;
        }
    }
    checkNode(lookup) {
        if (this.treeView.isDisabled(lookup.item.dataItem, lookup.item.index)) {
            return;
        }
        const target = lookup.item;
        const pendingCheck = [target];
        // TODO: extract in a separate `checkChildren` method?
        if (this.checkable.checkChildren) {
            const filter$$1 = (item) => this.treeView.isVisible(item.dataItem, item.index) &&
                !this.treeView.isDisabled(item.dataItem, item.index);
            fetchDescendentNodes(lookup, filter$$1)
                .forEach(lookup => pendingCheck.push(lookup.item));
        }
        const shouldCheck = !this.isItemChecked(target);
        pendingCheck.forEach(item => {
            if (shouldCheck) {
                this.addItem(item);
            }
            else {
                this.removeItem(item);
            }
        });
    }
    checkParents(parent) {
        let currentParent = parent;
        while (currentParent) {
            const allChildrenSelected = currentParent.children.every(item => this.isItemChecked(item));
            if (allChildrenSelected) {
                this.addItem(currentParent.item);
            }
            else {
                this.removeItem(currentParent.item);
            }
            currentParent = currentParent.parent;
        }
    }
    isItemIndeterminate(lookup) {
        const children = lookup.children;
        if (!Array.isArray(children) || children.length === 0) {
            return false;
        }
        let index = 0;
        let child = children[index];
        while (isPresent(child)) {
            if (this.isItemChecked(child.item) || this.isItemIndeterminate(child)) {
                return true;
            }
            index += 1;
            child = children[index];
        }
        return false;
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], CheckDirective.prototype, "checkable", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], CheckDirective.prototype, "valueField", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], CheckDirective.prototype, "checkedItems", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], CheckDirective.prototype, "checkedItemsChange", void 0);
CheckDirective = __decorate([
    Directive({
        selector: '[kendoMultiSelectTreeCheckable]'
    }),
    __metadata("design:paramtypes", [TreeViewComponent])
], CheckDirective);

/**
 * @hidden
 *
 * A directive which manages the in-memory checked state of the MultiSelectTree nodes.
 */
let CheckAllDirective = class CheckAllDirective extends BaseCheckDirective {
    constructor(element, zone, cdr, renderer) {
        super();
        this.element = element;
        this.zone = zone;
        this.cdr = cdr;
        this.renderer = renderer;
        /**
         * Fires when the `checkedItems` collection was updated.
         */
        this.checkedItemsChange = new EventEmitter();
        /**
         * Holds a Set with just the checked item keys.
         *
         * Should be updated each time the `checkedItems` value or content is changed.
         * Can be used for efficient look-up of whether an item is checked or not (O(1) access time).
         */
        this.checkedKeys = new Set();
    }
    handleChange(event) {
        // Need to store the current checkbox state at the moment of click
        this.currentCheckedState = event.checked;
        this.currentIndeterminateState = this.isIndeterminate;
        this.treeview.nodes.map((_value, index) => {
            const itemIndex = String(index);
            const itemLookup = this.treeview.itemLookup(itemIndex);
            this.checkNode(itemLookup);
        });
        this.checkedItemsChange.emit(this.checkedItems.slice());
    }
    get isIndeterminate() {
        const isIndeterminate = this.treeview.nodes.some((_node, index) => {
            const itemIndex = String(index);
            const itemLookup = this.treeview.itemLookup(itemIndex);
            return this.someChecked(itemLookup);
        });
        return this.isChecked ? false : isIndeterminate;
    }
    get isChecked() {
        const isChecked = this.treeview.nodes.every((_node, index) => {
            const itemIndex = String(index);
            const itemLookup = this.treeview.itemLookup(itemIndex);
            return this.allChecked(itemLookup);
        });
        return isChecked;
    }
    ngOnChanges(changes) {
        if (isPresent(changes.checkedItems)) {
            this.updateItems();
            this.renderer.setProperty(this.element.nativeElement, 'checked', this.isChecked);
            this.renderer.setProperty(this.element.nativeElement, 'indeterminate', this.isIndeterminate);
        }
    }
    ngOnInit() {
        if (this.focused) {
            this.nextTick(() => this.element.nativeElement.focus());
        }
    }
    nextTick(fn) {
        this.zone.runOutsideAngular(() => setTimeout(fn));
    }
    checkNode(itemLookup) {
        if (this.treeview.isDisabled(itemLookup.item.dataItem, itemLookup.item.index)) {
            return;
        }
        const pendingCheck = [];
        const filter$$1 = (item) => this.treeview.isVisible(item.dataItem, item.index) &&
            !this.treeview.isDisabled(item.dataItem, item.index);
        pendingCheck.push(itemLookup.item);
        fetchDescendentNodes(itemLookup, filter$$1)
            .forEach(lookup => pendingCheck.push(lookup.item));
        pendingCheck.forEach(item => {
            if (this.currentIndeterminateState) {
                this.lastAction === 'check' ?
                    this.addItem(item) :
                    this.removeItem(item);
                return;
            }
            this.currentCheckedState ?
                this.addItem(item) :
                this.removeItem(item);
        });
    }
    allChecked(lookup) {
        const children = lookup && lookup.children;
        if (!Array.isArray(children)) {
            return;
        }
        const childrenChecked = children.every(child => {
            if (child.children.length) {
                return this.isItemChecked(child.item) && this.allChecked(child);
            }
            return this.isItemChecked(child.item);
        });
        return childrenChecked && this.isItemChecked(lookup.item);
    }
    someChecked(lookup) {
        const children = lookup && lookup.children;
        if (!Array.isArray(children)) {
            return;
        }
        const childrenChecked = children.some(child => {
            if (child.children.length) {
                return this.isItemChecked(child.item) || this.someChecked(child);
            }
            return this.isItemChecked(child.item);
        });
        return childrenChecked || this.isItemChecked(lookup.item);
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], CheckAllDirective.prototype, "lastAction", void 0);
__decorate([
    Input(),
    __metadata("design:type", TreeViewComponent)
], CheckAllDirective.prototype, "treeview", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], CheckAllDirective.prototype, "checkedItems", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], CheckAllDirective.prototype, "valueField", void 0);
__decorate([
    Input('checkAll'),
    __metadata("design:type", Boolean)
], CheckAllDirective.prototype, "focused", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], CheckAllDirective.prototype, "checkedItemsChange", void 0);
__decorate([
    HostListener('change', ['$event.target']),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], CheckAllDirective.prototype, "handleChange", null);
CheckAllDirective = __decorate([
    Directive({
        selector: '[checkAll]' // tslint:disable-line
    }),
    __metadata("design:paramtypes", [ElementRef, NgZone, ChangeDetectorRef, Renderer2])
], CheckAllDirective);

const INTERNAL_DIRECTIVES = [
    ListComponent,
    ListItemDirective,
    SelectableDirective,
    SearchBarComponent,
    TemplateContextDirective,
    TagListComponent,
    CheckDirective,
    CheckAllDirective
];
/**
 * @hidden
 */
let SharedModule = class SharedModule {
};
SharedModule = __decorate([
    NgModule({
        declarations: [INTERNAL_DIRECTIVES],
        exports: [INTERNAL_DIRECTIVES, CommonModule, FormsModule, PopupModule, ResizeSensorModule, SharedDirectivesModule, EventsModule],
        imports: [CommonModule, FormsModule, PopupModule, ResizeSensorModule, SharedDirectivesModule, EventsModule]
    })
], SharedModule);

/**
 * A directive which configures the MultiSelectTree to show one single summary tag for all selected data items.
 * When a number is provided, the summary tag is displayed after the given amount of data items are selected
 * ([more information and examples]({% slug api_dropdowns_multiselecttreesummarytagdirective %})).
 *
 * @example
 * ```ts-no-run
 * <kendo-multiselecttree kendoMultiSelectTreeSummaryTag [data]="data"></kendo-multiselecttree>
 * ```
 *
 * @example
 * ```ts-no-run
 * <kendo-multiselecttree [kendoMultiSelectTreeSummaryTag]="2" [data]="data"></kendo-multiselecttree>
 * ```
 */
let MultiSelectTreeSummaryTagDirective = class MultiSelectTreeSummaryTagDirective {
    constructor(multiSelectTreeComponent) {
        this.multiSelectTreeComponent = multiSelectTreeComponent;
        /**
         * A numeric value that indicates the number of selected data items after which the summary tag will appear.
         */
        this.showAfter = 0;
        this.createTagMapper();
    }
    ngOnChanges(changes) {
        if (isPresent(changes.showAfter)) {
            this.createTagMapper();
            this.multiSelectTreeComponent.handleTagMapperChange(this.showAfter);
        }
    }
    createTagMapper() {
        const showAfter = parseNumber(this.showAfter);
        this.multiSelectTreeComponent.tagMapper = (tags) => {
            if (tags.length > showAfter) {
                // tags provided in an array are rendered as a single group tag
                return [...tags.slice(0, showAfter), tags.slice(showAfter)];
            }
            else {
                return tags;
            }
        };
    }
};
__decorate([
    Input('kendoMultiSelectTreeSummaryTag'),
    __metadata("design:type", Object)
], MultiSelectTreeSummaryTagDirective.prototype, "showAfter", void 0);
MultiSelectTreeSummaryTagDirective = __decorate([
    Directive({
        selector: '[kendoMultiSelectTreeSummaryTag]'
    }),
    __metadata("design:paramtypes", [MultiSelectTreeComponent])
], MultiSelectTreeSummaryTagDirective);

const DROPDOWNTREE_DIRECTIVES = [
    DropDownTreeComponent,
    MultiSelectTreeComponent,
    DropDownTreeFlatBindingDirective,
    DropDownTreeHierarchyBindingDirective,
    MultiSelectTreeFlatBindingDirective,
    MultiSelectTreeHierarchyBindingDirective,
    DropDownTreesExpandDirective,
    NodeTemplateDirective,
    MultiSelectTreeSummaryTagDirective
];
/**
 * @hidden
 */
let DropDownTreesModule = class DropDownTreesModule {
};
DropDownTreesModule = __decorate([
    NgModule({
        declarations: [DROPDOWNTREE_DIRECTIVES],
        exports: [DROPDOWNTREE_DIRECTIVES, SharedDirectivesModule],
        imports: [SharedModule, TreeViewModule]
    })
], DropDownTreesModule);

/**
 * A directive which configures the MultiSelect to show one single summary tag for all selected data items.
 * When a number is provided, the summary tag is displayed after the given amount of data items are selected
 * ([more information and examples]({% slug summarytagmode_multiselect %})).
 *
 * @example
 * ```ts-no-run
 * <kendo-multiselect kendoMultiSelectSummaryTag [data]="data"></kendo-multiselect>
 * ```
 *
 * @example
 * ```ts-no-run
 * <kendo-multiselect [kendoMultiSelectSummaryTag]="2" [data]="data"></kendo-multiselect>
 * ```
 */
let SummaryTagDirective = class SummaryTagDirective {
    constructor(multiSelectComponent) {
        this.multiSelectComponent = multiSelectComponent;
        /**
         * A numeric value that indicates the number of selected data items after which the summary tag will appear.
         */
        this.showAfter = 0;
        this.createTagMapper();
    }
    ngOnChanges(changes) {
        if (isPresent(changes.showAfter)) {
            this.createTagMapper();
            this.multiSelectComponent.onTagMapperChange();
        }
    }
    createTagMapper() {
        const showAfter = parseNumber(this.showAfter);
        this.multiSelectComponent.tagMapper = (tags) => {
            if (tags.length > showAfter) {
                let result;
                result = tags.slice(0, showAfter);
                result.push(tags.slice(showAfter, tags.length));
                return result;
            }
            else {
                return tags;
            }
        };
    }
};
__decorate([
    Input('kendoMultiSelectSummaryTag'),
    __metadata("design:type", Object)
], SummaryTagDirective.prototype, "showAfter", void 0);
SummaryTagDirective = __decorate([
    Directive({
        selector: '[kendoMultiSelectSummaryTag]'
    }),
    __metadata("design:paramtypes", [MultiSelectComponent])
], SummaryTagDirective);

const AUTOCOMPLETE_DIRECTIVES = [
    AutoCompleteComponent
];
/**
 * @hidden
 *
 * The exported package module.
 *
 * The package exports:
 * - `AutoCompleteComponent`&mdash;The AutoComplete component class.
 * - `ItemTemplateDirective`&mdash;The item template directive.
 * - `HeaderTemplateDirective`&mdash;The header template directive.
 * - `FooterTemplateDirective`&mdash;The footer template directive.
 */
let AutoCompleteModule = class AutoCompleteModule {
};
AutoCompleteModule = __decorate([
    NgModule({
        declarations: [AUTOCOMPLETE_DIRECTIVES],
        exports: [AUTOCOMPLETE_DIRECTIVES, SharedDirectivesModule],
        imports: [SharedModule]
    })
], AutoCompleteModule);

const COMBOBOX_DIRECTIVES = [
    ComboBoxComponent,
    MultiColumnComboBoxComponent,
    ComboBoxColumnComponent,
    ColumnHeaderTemplateDirective,
    ColumnCellTemplateDirective
];
const ɵ0$4 = touchEnabled;
/**
 * @hidden
 *
 * The exported package module.
 *
 * The package exports:
 * - `ComboBoxComponent`&mdash;The ComboBox component class.
 * - `MultiColumnComboBoxComponent`&mdash;The MultiColumnComboBox component class.
 * - `ItemTemplateDirective`&mdash;The item template directive.
 * - `HeaderTemplateDirective`&mdash;The header template directive.
 * - `FooterTemplateDirective`&mdash;The footer template directive.
 * - `ColumnHeaderTemplateDirective`&mdash;The column header template directive.
 * - `ColumnCellTemplateDirective`&mdash;The column cell template directive.
 */
let ComboBoxModule = class ComboBoxModule {
};
ComboBoxModule = __decorate([
    NgModule({
        declarations: [COMBOBOX_DIRECTIVES],
        exports: [COMBOBOX_DIRECTIVES, SharedDirectivesModule],
        imports: [SharedModule],
        providers: [{ provide: TOUCH_ENABLED, useValue: ɵ0$4 }]
    })
], ComboBoxModule);

const DROPDOWNLIST_DIRECTIVES = [
    DropDownListComponent
];
/**
 * @hidden
 *
 * The exported package module.
 *
 * The package exports:
 * - `DropDownListComponent`&mdash;The DropDownList component class.
 * - `ItemTemplateDirective`&mdash;The item template directive.
 * - `ValueTemplateDirective`&mdash;The value template directive.
 * - `HeaderTemplateDirective`&mdash;The header template directive.
 * - `FooterTemplateDirective`&mdash;The footer template directive.
 */
let DropDownListModule = class DropDownListModule {
};
DropDownListModule = __decorate([
    NgModule({
        declarations: [DROPDOWNLIST_DIRECTIVES],
        exports: [DROPDOWNLIST_DIRECTIVES, SharedDirectivesModule],
        imports: [SharedModule]
    })
], DropDownListModule);

const MULTISELECT_DIRECTIVES = [
    MultiSelectComponent,
    SummaryTagDirective,
    CustomItemTemplateDirective
];
const ɵ0$5 = touchEnabled;
/**
 * @hidden
 *
 * The exported package module.
 *
 * The package exports:
 * - `MultiSelectComponent`&mdash;The MultiSelect component class.
 * - `SummaryTagDirective`&mdash;The MultiSelect summary tag directive.
 * - `ItemTemplateDirective`&mdash;The item template directive.
 * - `CustomItemTemplateDirective`&mdash;The custom item template directive.
 * - `TagTemplateDirective`&mdash;The tag template directive.
 * - `SummaryTagTemplateDirective`&mdash;The summary tag template directive.
 * - `HeaderTemplateDirective`&mdash;The header template directive.
 * - `FooterTemplateDirective`&mdash;The footer template directive.
 * - `NoDataTemplateDirective`&mdash;The no-data template directive.
 */
let MultiSelectModule = class MultiSelectModule {
};
MultiSelectModule = __decorate([
    NgModule({
        declarations: [MULTISELECT_DIRECTIVES],
        exports: [MULTISELECT_DIRECTIVES, SharedDirectivesModule],
        imports: [SharedModule],
        providers: [{ provide: TOUCH_ENABLED, useValue: ɵ0$5 }]
    })
], MultiSelectModule);

/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the Dropdowns components.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Dropdowns module
 * import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare the app component
 *     imports:      [BrowserModule, DropDownsModule], // import the Dropdowns module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
let DropDownsModule = class DropDownsModule {
};
DropDownsModule = __decorate([
    NgModule({
        exports: [AutoCompleteModule, ComboBoxModule, DropDownListModule, MultiSelectModule, DropDownTreesModule]
    })
], DropDownsModule);

/**
 * Generated bundle index. Do not edit.
 */

export { AUTOCOMPLETE_VALUE_ACCESSOR, COMBOBOX_VALUE_ACCESSOR, TOUCH_ENABLED, DataService, DisabledItemsService, FilterInputDirective, FilterableComponent, ListItemDirective, CustomMessagesComponent, LocalizedMessagesDirective, Messages, NavigationService, SearchBarComponent, SelectionService, TagListComponent, NoDataTemplateDirective, TagTemplateDirective, DROPDOWNLIST_VALUE_ACCESSOR, BaseCheckDirective, CheckAllDirective, CheckDirective, MultiSelectTreeSummaryTagDirective, AutoCompleteComponent, ComboBoxComponent, DropDownListComponent, MultiSelectComponent, MultiColumnComboBoxComponent, ComboBoxColumnComponent, ColumnCellTemplateDirective, ColumnHeaderTemplateDirective, DropDownTreeComponent, MultiSelectTreeComponent, DropDownTreeFlatBindingDirective, DropDownTreeHierarchyBindingDirective, MultiSelectTreeFlatBindingDirective, MultiSelectTreeHierarchyBindingDirective, DropDownTreesExpandDirective, DropDownTreesModule, NodeTemplateDirective, ItemTemplateDirective, GroupTemplateDirective, FixedGroupTemplateDirective, CustomItemTemplateDirective, HeaderTemplateDirective, FooterTemplateDirective, ValueTemplateDirective, TemplateContextDirective, GroupTagTemplateDirective, SelectableDirective, SummaryTagDirective, FilterDirective, DropDownsModule, MultiSelectModule, SharedModule, AutoCompleteModule, ComboBoxModule, DropDownListModule, SharedDirectivesModule, ListComponent, PreventableEvent, RemoveTagEvent };
