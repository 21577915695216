/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import { __decorate, __metadata } from 'tslib';
import { Injectable, EventEmitter, isDevMode, HostBinding, Input, Output, Component, ChangeDetectorRef, Directive, ElementRef, Renderer2, forwardRef, NgModule } from '@angular/core';
import { LocalizationService, ComponentMessages, L10N_PREFIX } from '@progress/kendo-angular-l10n';
import { validatePackage } from '@progress/kendo-licensing';
import { CommonModule } from '@angular/common';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';

/**
 * @hidden
 */
let FilterService = class FilterService {
    /**
     * @hidden
     */
    constructor() {
        this.value = { filters: [], logic: 'or' };
        this.filters = [];
        this.isEditorDisabled = false;
    }
    addFilterGroup(item) {
        let filterGroup = { logic: 'or', filters: [] };
        item.filters.push(filterGroup);
    }
    addFilterExpression(item) {
        let filterExpression = { operator: 'eq', value: null, field: null };
        item.filters.push(filterExpression);
    }
    remove(item, positionIndex, parentItem) {
        if (!parentItem) {
            parentItem = this.value;
        }
        if (item === parentItem) {
            parentItem.filters = [];
            return;
        }
        let index = parentItem.filters.indexOf(item);
        if (index >= 0 && index === positionIndex) {
            parentItem.filters = parentItem.filters.filter((i) => i !== item);
            return;
        }
        parentItem.filters.forEach((filter) => filter.filters && this.remove(item, positionIndex, filter));
    }
};
FilterService = __decorate([
    Injectable()
], FilterService);

/**
 * @hidden
 */
const nullOperators = ["isnull", "isnotnull", "isempty", "isnotempty"];
/**
 * @hidden
 */
const isArray = (value) => Array.isArray(value);
/**
 * @hidden
 */
const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
};
/**
 * @hidden
 */
const defaultStringOperators = {
    "filterEqOperator": "eq",
    "filterNotEqOperator": "neq",
    "filterContainsOperator": "contains",
    "filterNotContainsOperator": "doesnotcontain",
    "filterStartsWithOperator": "startswith",
    "filterEndsWithOperator": "endswith",
    "filterIsNullOperator": "isnull",
    "filterIsNotNullOperator": "isnotnull",
    "filterIsEmptyOperator": "isempty",
    "filterIsNotEmptyOperator": "isnotempty"
};
/**
 * @hidden
 */
const defaultNumericOperators = {
    "filterEqOperator": "eq",
    "filterNotEqOperator": "neq",
    "filterGteOperator": "gte",
    "filterGtOperator": "gt",
    "filterLteOperator": "lte",
    "filterLtOperator": "lt",
    "filterIsNullOperator": "isnull",
    "filterIsNotNullOperator": "isnotnull"
};
/**
 * @hidden
 */
const defaultDateOperators = {
    "filterEqOperator": "eq",
    "filterNotEqOperator": "neq",
    "filterAfterOrEqualOperator": "gte",
    "filterAfterOperator": "gt",
    "filterBeforeOrEqualOperator": "lte",
    "filterBeforeOperator": "lt",
    "filterIsNullOperator": "isnull",
    "filterIsNotNullOperator": "isnotnull"
};
/**
 * @hidden
 */
const defaultOperators = {
    string: defaultStringOperators,
    number: defaultNumericOperators,
    date: defaultDateOperators
};
/**
 * @hidden
 */
const logicOperators = {
    "filterAndLogic": 'and',
    "filterOrLogic": 'or'
};
/**
 * @hidden
 */
const isFilterEditor = (editorType) => {
    const supportedEditorTypes = ['string', 'number', 'boolean', 'date'];
    return supportedEditorTypes.indexOf(editorType) >= 0;
};
/**
 * @hidden
 */
const localizeOperators = operators => localization => Object.keys(operators).map(key => ({
    text: localization.get(key),
    value: operators[key]
}));

/**
 * @hidden
 */
const packageMetadata = {
    name: '@progress/kendo-angular-filter',
    productName: 'Kendo UI for Angular',
    productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
    publishDate: 1646919397,
    version: '',
    licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/?utm_medium=product&utm_source=kendoangular&utm_campaign=kendo-ui-angular-purchase-license-keys-warning'
};

/**
 * Represents the [Kendo UI Filter component for Angular]({% slug overview_filter %}).
 * The Filter component can be used to apply any user defined filter criteria.
 * @example
 * ```
 *   @Component({
 *       selector: 'my-app',
 *       template: `
 *          <kendo-filter [filters]="filters" (valueChange)="onValueChange($event)"></kendo-filter>
 *       `
 *   })
 *   export class AppComponent {
 *
 *      public filters: FilterExpression[] = [
 *          {
 *              name: 'country',
 *              label: 'Country',
 *              filter: 'string',
 *              operators: ['eq', 'neq'],
 *          },
 *          {
 *              name: 'budget',
 *              filter: 'number'
 *          }
 *      ];
 *
 *      onValueChange(e: CompositeFilterDescriptor){
 *          console.log(e)
 *      }
 *  }
 * ```
 */
let FilterComponent = class FilterComponent {
    constructor(filterService, localization, cdr) {
        this.filterService = filterService;
        this.localization = localization;
        this.cdr = cdr;
        /**
         * Fires every time the Filter component value is updated.
         * That is each time a Filter Group or Filter Expression is added, removed, or updated.
         */
        this.valueChange = new EventEmitter();
        validatePackage(packageMetadata);
        this.direction = localization.rtl ? 'rtl' : 'ltr';
    }
    /**
     * Specifies the available user-defined filters. At least one filter should be provided.
     */
    set filters(_filters) {
        if (isDevMode() && (!isArray(_filters) || _filters.length === 0)) {
            throw new Error(`Pass at least one user-defined filter through the [filters] input property. See http://www.telerik.com/kendo-angular-ui/components/filter/#data-binding`);
        }
        this.filterService.filters = _filters.map(filterExpression => {
            let clonedFilter = Object.assign({}, filterExpression);
            if (!clonedFilter.title) {
                clonedFilter.title = clonedFilter.field;
            }
            return clonedFilter;
        });
    }
    get filters() {
        return this.filterService.filters;
    }
    /**
     * Sets the initial `value` of the Filter component.
     */
    set value(value) {
        const clonedValue = JSON.parse(JSON.stringify(value));
        this.normalizeValue(clonedValue);
        this.filterService.value = clonedValue;
    }
    get value() {
        return this.filterService.value;
    }
    ngOnInit() {
        if (this.filters.length === 0) {
            throw new Error(`Pass at least one user-defined filter through the [filters] input property. See http://www.telerik.com/kendo-angular-ui/components/filter/#data-binding`);
        }
        this.localizationSubscription = this.localization.changes.subscribe(({ rtl }) => {
            this.direction = rtl ? 'rtl' : 'ltr';
            this.cdr.detectChanges();
        });
    }
    ngOnDestroy() {
        if (this.localizationSubscription) {
            this.localizationSubscription.unsubscribe();
        }
    }
    /**
     * @hidden
     */
    getCurrentFilter() {
        return this.value;
    }
    /**
     * @hidden
     */
    onValueChange() {
        this.valueChange.emit(this.filterService.value);
    }
    normalizeFilter(filterDescriptor) {
        const foundFilter = this.filterService.filters.find((filter) => filter.field === filterDescriptor.field);
        if (isDevMode() && !foundFilter) {
            throw new Error(`There is no user-defined filter with '${filterDescriptor.field}' field provided through the [filters] input property.`);
        }
        if (isDevMode() && foundFilter.editor === 'boolean' && (!filterDescriptor.value && filterDescriptor.value !== false)) {
            console.warn(`Provide a value for the boolean '${filterDescriptor.field}' user-defined filter as the operator is always set to 'eq'.`);
        }
        if (filterDescriptor.operator && foundFilter.operators && !foundFilter.operators.some(operator => operator === filterDescriptor.operator)) {
            throw new Error(`The user-defined filter with field '${filterDescriptor.field}' is missing the '${filterDescriptor.operator}' operator.`);
        }
        if (foundFilter.editor === 'boolean') {
            filterDescriptor.operator = 'eq';
        }
        if (foundFilter.editor === 'date' && filterDescriptor.value) {
            filterDescriptor.value = new Date(filterDescriptor.value);
        }
        if (!filterDescriptor.value && filterDescriptor.value !== false) {
            filterDescriptor.value = null;
        }
    }
    normalizeValue(item) {
        item.filters.forEach((item) => {
            if (item.filters) {
                this.normalizeValue(item);
            }
            else {
                this.normalizeFilter(item);
            }
        });
    }
};
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String)
], FilterComponent.prototype, "direction", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [Array])
], FilterComponent.prototype, "filters", null);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], FilterComponent.prototype, "value", null);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], FilterComponent.prototype, "valueChange", void 0);
FilterComponent = __decorate([
    Component({
        selector: 'kendo-filter',
        template: `
        <ng-container kendoFilterLocalizedMessages
            i18n-editorDateTodayText="kendo.filter.editorDateTodayText|The text of the Today button of the Date editor"
            editorDateTodayText="Today"

            i18n-editorDateToggleText="kendo.filter.editorDateToggleText|The title of the Toggle button of the Date editor."
            editorDateToggleText="Toggle calendar"

            i18n-editorNumericDecrement="kendo.filter.editorNumericDecrement|The title of the Decrement button of the Numeric editor"
            editorNumericDecrement="Decrement"

            i18n-editorNumericIncrement="kendo.filter.editorNumericIncrement|The title of the Increment button of the Numeric editor"
            editorNumericIncrement="Increment"

            i18n-filterExpressionOperators="kendo.filter.filterExpressionOperators|The text of the Filter Expression Operators drop down"
            filterExpressionOperators="Operators"

            i18n-filterExpressionFilters="kendo.filter.filterExpressionFilters|The text of the Filter Expression filters drop down"
            filterExpressionFilters="Fields"

            i18n-remove="kendo.filter.remove|The text of the Remove button"
            remove="Remove"

            i18n-addFilter="kendo.filter.addFilter|The text of the Add Filter button"
            addFilter="Add Filter"

            i18n-addGroup="kendo.filter.addGroup|The text of the Add Group button"
            addGroup="Add Group"

            i18n-filterAndLogic="kendo.filter.filterAndLogic|The text of the And filter logic"
            filterAndLogic="And"

            i18n-filterOrLogic="kendo.filter.filterOrLogic|The text of the Or filter logic"
            filterOrLogic="Or"

            i18n-filterEqOperator="kendo.filter.filterEqOperator|The text of the equal filter operator"
            filterEqOperator="Is equal to"

            i18n-filterNotEqOperator="kendo.filter.filterNotEqOperator|The text of the not equal filter operator"
            filterNotEqOperator="Is not equal to"

            i18n-filterIsNullOperator="kendo.filter.filterIsNullOperator|The text of the is null filter operator"
            filterIsNullOperator="Is null"

            i18n-filterIsNotNullOperator="kendo.filter.filterIsNotNullOperator|The text of the is not null filter operator"
            filterIsNotNullOperator="Is not null"

            i18n-filterIsEmptyOperator="kendo.filter.filterIsEmptyOperator|The text of the is empty filter operator"
            filterIsEmptyOperator="Is empty"

            i18n-filterIsNotEmptyOperator="kendo.filter.filterIsNotEmptyOperator|The text of the is not empty filter operator"
            filterIsNotEmptyOperator="Is not empty"

            i18n-filterStartsWithOperator="kendo.filter.filterStartsWithOperator|The text of the starts with filter operator"
            filterStartsWithOperator="Starts with"

            i18n-filterContainsOperator="kendo.filter.filterContainsOperator|The text of the contains filter operator"
            filterContainsOperator="Contains"

            i18n-filterNotContainsOperator="kendo.filter.filterNotContainsOperator|The text of the does not contain filter operator"
            filterNotContainsOperator="Does not contain"

            i18n-filterEndsWithOperator="kendo.filter.filterEndsWithOperator|The text of the ends with filter operator"
            filterEndsWithOperator="Ends with"

            i18n-filterGteOperator="kendo.filter.filterGteOperator|The text of the greater than or equal filter operator"
            filterGteOperator="Is greater than or equal to"

            i18n-filterGtOperator="kendo.filter.filterGtOperator|The text of the greater than filter operator"
            filterGtOperator="Is greater than"

            i18n-filterLteOperator="kendo.filter.filterLteOperator|The text of the less than or equal filter operator"
            filterLteOperator="Is less than or equal to"

            i18n-filterLtOperator="kendo.filter.filterLtOperator|The text of the less than filter operator"
            filterLtOperator="Is less than"

            i18n-filterIsTrue="kendo.filter.filterIsTrue|The text of the IsTrue boolean filter option"
            filterIsTrue="Is True"

            i18n-filterIsFalse="kendo.filter.filterIsFalse|The text of the IsFalse boolean filter option"
            filterIsFalse="Is False"

            i18n-filterBooleanAll="kendo.filter.filterBooleanAll|The text of the (All) boolean filter option"
            filterBooleanAll="(All)"

            i18n-filterAfterOrEqualOperator="kendo.filter.filterAfterOrEqualOperator|The text of the after or equal date filter operator"
            filterAfterOrEqualOperator="Is after or equal to"

            i18n-filterAfterOperator="kendo.filter.filterAfterOperator|The text of the after date filter operator"
            filterAfterOperator="Is after"

            i18n-filterBeforeOperator="kendo.filter.filterBeforeOperator|The text of the before date filter operator"
            filterBeforeOperator="Is before"

            i18n-filterBeforeOrEqualOperator="kendo.filter.filterBeforeOrEqualOperator|The text of the before or equal date filter operator"
            filterBeforeOrEqualOperator="Is before or equal to"

            i18n-filterFieldAriaLabel="kendo.filter.filterFieldAriaLabel|The text of the filter field aria label"
            filterFieldAriaLabel="field"

            i18n-filterOperatorAriaLabel="kendo.filter.filterOperatorAriaLabel|The text of the filter operator aria label"
            filterOperatorAriaLabel="operator"

            i18n-filterValueAriaLabel="kendo.filter.filterValueAriaLabel|The text of the filter value aria label"
            filterValueAriaLabel="value"

            i18n-filterAriaLabel="kendo.filter.filterAriaLabel|The text of the filter row aria label"
            filterAriaLabel="filter"

            i18n-filterToolbarAriaLabel="kendo.filter.filterToolbarAriaLabel|The text of the filter toolbar aria label"
            filterToolbarAriaLabel="filter settings"
            >
        </ng-container>
        <div class="k-widget k-filter" [attr.dir]="direction">
            <ul class='k-filter-container'>
                <li class='k-filter-group-main'>
                    <kendo-filter-group
                        [currentItem]="getCurrentFilter()"
                        (valueChange)="onValueChange()"
                        >
                    </kendo-filter-group>
                </li>
            </ul>
        </div>
`,
        providers: [FilterService]
    }),
    __metadata("design:paramtypes", [FilterService,
        LocalizationService,
        ChangeDetectorRef])
], FilterComponent);

/**
 * @hidden
 */
let AriaLabelValueDirective = class AriaLabelValueDirective {
    constructor(hostElement, renderer) {
        this.hostElement = hostElement;
        this.renderer = renderer;
    }
    ngOnChanges() {
        const target = this.hostElement.nativeElement.querySelector('input') || this.hostElement.nativeElement;
        this.renderer.setAttribute(target, 'aria-label', this.ariaLabel);
    }
};
__decorate([
    Input('kendoAriaLabelValue'),
    __metadata("design:type", String)
], AriaLabelValueDirective.prototype, "ariaLabel", void 0);
AriaLabelValueDirective = __decorate([
    Directive({ selector: '[kendoAriaLabelValue]' }),
    __metadata("design:paramtypes", [ElementRef,
        Renderer2])
], AriaLabelValueDirective);

/**
 * @hidden
 */
let FilterBooleanEditorComponent = class FilterBooleanEditorComponent {
    constructor(localization, cdr) {
        this.localization = localization;
        this.cdr = cdr;
        this.valueChange = new EventEmitter();
        this.items = this.getValueItems();
        this.defaultItem = this.getDefaultItem();
    }
    ngOnInit() {
        this.localizationSubscription = this.localization.changes.subscribe(() => {
            this.defaultItem = this.getDefaultItem();
            this.items = this.getValueItems();
            this.cdr.detectChanges();
        });
    }
    getDefaultItem() {
        return { text: this.localization.get("filterBooleanAll"), value: null };
    }
    getValueItems() {
        return [
            { text: this.localization.get("filterIsTrue"), value: true },
            { text: this.localization.get("filterIsFalse"), value: false }
        ];
    }
    ngOnDestroy() {
        if (this.localizationSubscription) {
            this.localizationSubscription.unsubscribe();
        }
    }
    messageFor(key) {
        return this.localization.get(key);
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], FilterBooleanEditorComponent.prototype, "currentItem", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], FilterBooleanEditorComponent.prototype, "valueChange", void 0);
FilterBooleanEditorComponent = __decorate([
    Component({
        selector: 'kendo-filter-boolean-editor',
        template: `
    <kendo-dropdownlist
        [kendoAriaLabelValue]="messageFor('filterValueAriaLabel')"
        class="k-filter-toolbar-item k-filter-value"
        [(value)]="currentItem.value"
        (valueChange)="valueChange.emit()"
        [data]="items"
        [defaultItem]="defaultItem"
        [valuePrimitive]="true"
        textField="text"
        valueField="value"
        >
    </kendo-dropdownlist>
  `
    }),
    __metadata("design:paramtypes", [LocalizationService, ChangeDetectorRef])
], FilterBooleanEditorComponent);

/**
 * @hidden
 */
let FilterDateEditorComponent = class FilterDateEditorComponent {
    constructor(localization, filterService) {
        this.localization = localization;
        this.filterService = filterService;
        this.valueChange = new EventEmitter();
    }
    messageFor(key) {
        return this.localization.get(key);
    }
    isDisabled() {
        const isDisabled = this.filterService.isEditorDisabled;
        if (isDisabled) {
            this.currentItem.value = null;
        }
        return isDisabled;
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], FilterDateEditorComponent.prototype, "currentItem", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], FilterDateEditorComponent.prototype, "valueChange", void 0);
FilterDateEditorComponent = __decorate([
    Component({
        selector: 'kendo-filter-date-editor',
        template: `
        <kendo-datepicker
            [kendoAriaLabelValue]="messageFor('filterValueAriaLabel')"
            class="k-filter-toolbar-item k-filter-value"
            [(value)]="currentItem.value"
            (valueChange)="valueChange.emit()"
            [disabled]="isDisabled()">
            <kendo-datepicker-messages
                [toggle]="messageFor('editorDateToggleText')"
                [today]="messageFor('editorDateTodayText')">
            </kendo-datepicker-messages>
        </kendo-datepicker>
  `
    }),
    __metadata("design:paramtypes", [LocalizationService, FilterService])
], FilterDateEditorComponent);

/**
 * @hidden
 */
let FilterNumericEditorComponent = class FilterNumericEditorComponent {
    constructor(localization, filterService) {
        this.localization = localization;
        this.filterService = filterService;
        this.valueChange = new EventEmitter();
    }
    messageFor(key) {
        return this.localization.get(key);
    }
    isDisabled() {
        const isDisabled = this.filterService.isEditorDisabled;
        if (isDisabled) {
            this.currentItem.value = null;
        }
        return isDisabled;
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], FilterNumericEditorComponent.prototype, "currentItem", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], FilterNumericEditorComponent.prototype, "valueChange", void 0);
FilterNumericEditorComponent = __decorate([
    Component({
        selector: 'kendo-filter-numeric-editor',
        template: `
        <kendo-numerictextbox
            [kendoAriaLabelValue]="messageFor('filterValueAriaLabel')"
            class="k-filter-toolbar-item k-filter-value"
            [(value)]="currentItem.value"
            (valueChange)="valueChange.emit()"
            [disabled]="isDisabled()">
            <kendo-numerictextbox-messages
                [increment]="messageFor('editorNumericIncrement')"
                [decrement]="messageFor('editorNumericDecrement')">
            </kendo-numerictextbox-messages>
        </kendo-numerictextbox>
  `
    }),
    __metadata("design:paramtypes", [LocalizationService, FilterService])
], FilterNumericEditorComponent);

/**
 * @hidden
 */
let FilterTextEditorComponent = class FilterTextEditorComponent {
    constructor(localization, filterService) {
        this.localization = localization;
        this.filterService = filterService;
        this.valueChange = new EventEmitter();
    }
    isDisabled() {
        const isDisabled = this.filterService.isEditorDisabled;
        if (isDisabled) {
            this.currentItem.value = null;
        }
        return isDisabled;
    }
    messageFor(key) {
        return this.localization.get(key);
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], FilterTextEditorComponent.prototype, "currentItem", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], FilterTextEditorComponent.prototype, "valueChange", void 0);
FilterTextEditorComponent = __decorate([
    Component({
        selector: 'kendo-filter-text-editor',
        template: `
        <kendo-textbox
            [kendoAriaLabelValue]="messageFor('filterValueAriaLabel')"
            class="k-filter-toolbar-item k-filter-value"
            [(value)]="currentItem.value"
            (valueChange)="valueChange.emit()"
            [disabled]="isDisabled()">
        </kendo-textbox>
  `
    }),
    __metadata("design:paramtypes", [LocalizationService, FilterService])
], FilterTextEditorComponent);

/**
 * @hidden
 */
let FilterExpressionOperatorsComponent = class FilterExpressionOperatorsComponent {
    constructor(filterService, localization) {
        this.filterService = filterService;
        this.localization = localization;
        this.valueChange = new EventEmitter();
        this.operators = [];
    }
    messageFor(key) {
        return this.localization.get(key);
    }
    operatorValueChange(value) {
        this.valueChange.emit();
        this.filterService.isEditorDisabled = nullOperators.indexOf(value) >= 0;
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], FilterExpressionOperatorsComponent.prototype, "currentItem", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], FilterExpressionOperatorsComponent.prototype, "valueChange", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], FilterExpressionOperatorsComponent.prototype, "operators", void 0);
FilterExpressionOperatorsComponent = __decorate([
    Component({
        selector: 'kendo-filter-expression-operators',
        template: `
        <kendo-dropdownlist
            [kendoAriaLabelValue]="messageFor('filterOperatorAriaLabel')"
            [data]="operators"
            [title]="messageFor('filterExpressionOperators')"
            [(value)]="currentItem.operator"
            (valueChange)="operatorValueChange($event)"
            [valuePrimitive]="true"
            textField="text"
            valueField="value"
            >
        </kendo-dropdownlist>
  `
    }),
    __metadata("design:paramtypes", [FilterService, LocalizationService])
], FilterExpressionOperatorsComponent);

/**
 * @hidden
 */
let FilterExpressionComponent = class FilterExpressionComponent {
    constructor(filterService, localization, cdr) {
        this.filterService = filterService;
        this.localization = localization;
        this.cdr = cdr;
        this.valueChange = new EventEmitter();
        this.operators = [];
        this.filters = [];
        this.isBoolean = false;
        this.isEditorDisabled = false;
    }
    ngOnInit() {
        this.filters = this.filterService.filters;
        const foundFilter = this.getFilterExpressionByField(this.currentItem.field);
        if (this.currentItem.field) {
            this.setOperators(foundFilter);
        }
        const defaultFilter = this.getFilterExpressionByField(this.filterService.filters[0].field);
        if (!this.currentItem.field) {
            this.currentItem.field = this.filterService.filters[0].field;
            this.setOperators(defaultFilter);
        }
        this.localizationSubscription = this.localization.changes.subscribe(() => {
            this.setOperators(foundFilter || defaultFilter);
            this.cdr.detectChanges();
        });
    }
    ngOnDestroy() {
        if (this.localizationSubscription) {
            this.localizationSubscription.unsubscribe();
        }
    }
    normalizeOperators(filterEditor, operators) {
        let result = [];
        for (let j = 0; j < operators.length; j++) {
            if (isFilterEditor(filterEditor)) {
                result.push({
                    value: operators[j],
                    text: this.localization.get(getKeyByValue(defaultOperators[filterEditor], operators[j]))
                });
            }
        }
        return result;
    }
    messageFor(key) {
        return this.localization.get(key);
    }
    getFilterExpressionByField(name) {
        const foundFilter = this.filterService.filters.find(filter => filter.field === name);
        if (foundFilter) {
            return foundFilter;
        }
        return null;
    }
    filterValueChange(value) {
        this.currentItem.value = null;
        this.currentItem.field = value;
        const foundFilter = this.getFilterExpressionByField(this.currentItem.field);
        this.setOperators(foundFilter);
        this.valueChange.emit();
    }
    getDefaultOperators(operatorsType) {
        switch (operatorsType) {
            case 'string':
                return localizeOperators(defaultStringOperators)(this.localization);
            case 'number':
                return localizeOperators(defaultNumericOperators)(this.localization);
            case 'date':
                return localizeOperators(defaultDateOperators)(this.localization);
            default:
                break;
        }
    }
    getEditorType() {
        let item = this.filterService.filters.find((filterExpression) => filterExpression.field === this.currentItem.field);
        return item.editor;
    }
    removeFilterExpression() {
        this.filterService.remove(this.currentItem, this.index);
        this.valueChange.emit();
    }
    setOperators(filter) {
        this.isBoolean = filter.editor === 'boolean';
        if (this.isBoolean) {
            return;
        }
        if (filter.operators) {
            const localizedOperators = this.normalizeOperators(filter.editor, filter.operators);
            this.operators = localizedOperators;
            if (!this.currentItem.operator) {
                this.currentItem.operator = localizedOperators[0].value;
            }
        }
        else {
            this.operators = this.getDefaultOperators(filter.editor);
            if (!this.currentItem.operator) {
                this.currentItem.operator = this.operators[0].value;
            }
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", Number)
], FilterExpressionComponent.prototype, "index", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], FilterExpressionComponent.prototype, "currentItem", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], FilterExpressionComponent.prototype, "valueChange", void 0);
FilterExpressionComponent = __decorate([
    Component({
        selector: 'kendo-filter-expression',
        template: `
        <div class="k-filter-toolbar" role="group" [attr.aria-label]="messageFor('filterAriaLabel')">
            <div class="k-toolbar">
                <div class="k-filter-toolbar-item k-filter-field">
                    <kendo-dropdownlist
                        [kendoAriaLabelValue]="messageFor('filterFieldAriaLabel')"
                        [title]="messageFor('filterExpressionFilters')"
                        [data]="filters"
                        textField="title"
                        valueField="field"
                        [value]="currentItem.field"
                        [valuePrimitive]="true"
                        (valueChange)="filterValueChange($event)">
                    </kendo-dropdownlist>
                </div>
                <div *ngIf="!isBoolean" class="k-filter-toolbar-item k-filter-operator">
                    <kendo-filter-expression-operators
                        [currentItem]="currentItem"
                        [operators]="operators"
                        (valueChange)="valueChange.emit();">
                    </kendo-filter-expression-operators>
                </div>

                <ng-container [ngSwitch]="getEditorType()">
                    <kendo-filter-text-editor *ngSwitchCase="'string'" [currentItem]="currentItem" (valueChange)="valueChange.emit()"></kendo-filter-text-editor>
                    <kendo-filter-numeric-editor *ngSwitchCase="'number'" [currentItem]="currentItem" (valueChange)="valueChange.emit()"></kendo-filter-numeric-editor>
                    <kendo-filter-boolean-editor *ngSwitchCase="'boolean'" [currentItem]="currentItem" (valueChange)="valueChange.emit()"></kendo-filter-boolean-editor>
                    <kendo-filter-date-editor *ngSwitchCase="'date'" [currentItem]="currentItem" (valueChange)="valueChange.emit()"></kendo-filter-date-editor>
                </ng-container>

                <div class="k-filter-toolbar-item">
                    <button
                        kendoButton
                        icon="close"
                        fillMode="flat"
                        [title]="messageFor('remove')"
                        (click)="removeFilterExpression()">
                    </button>
                </div>
            </div>
        </div>
  `
    }),
    __metadata("design:paramtypes", [FilterService, LocalizationService, ChangeDetectorRef])
], FilterExpressionComponent);

/**
 * @hidden
 */
let FilterGroupComponent = class FilterGroupComponent {
    constructor(filterService, localization, cdr) {
        this.filterService = filterService;
        this.localization = localization;
        this.cdr = cdr;
        this.index = 0;
        this.currentItem = {
            logic: 'or',
            filters: []
        };
        this.valueChange = new EventEmitter();
        this.operators = [];
    }
    ngOnInit() {
        this.operators = this.getLogicOperators();
        this.localizationSubscription = this.localization.changes.subscribe(() => {
            this.operators = this.getLogicOperators();
            this.cdr.detectChanges();
        });
    }
    ngOnDestroy() {
        if (this.localizationSubscription) {
            this.localizationSubscription.unsubscribe();
        }
    }
    getLogicOperators() {
        return localizeOperators(logicOperators)(this.localization);
    }
    messageFor(key) {
        return this.localization.get(key);
    }
    selectedChange(logicOperator) {
        if (this.currentItem.logic !== logicOperator) {
            this.currentItem.logic = logicOperator;
            this.valueChange.emit();
        }
    }
    addFilterExpression() {
        this.filterService.addFilterExpression(this.currentItem);
        this.valueChange.emit();
    }
    addFilterGroup() {
        this.filterService.addFilterGroup(this.currentItem);
        this.valueChange.emit();
    }
    removeFilterGroup() {
        this.filterService.remove(this.currentItem, this.index);
        this.valueChange.emit();
    }
};
__decorate([
    Input(),
    __metadata("design:type", Number)
], FilterGroupComponent.prototype, "index", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], FilterGroupComponent.prototype, "currentItem", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], FilterGroupComponent.prototype, "valueChange", void 0);
FilterGroupComponent = __decorate([
    Component({
        selector: 'kendo-filter-group',
        template: `
        <div class="k-filter-toolbar" role="toolbar" [attr.aria-label]="messageFor('filterToolbarAriaLabel')">
          <div class="k-toolbar">
            <div class="k-filter-toolbar-item">
              <div class="k-widget k-button-group" role="group">
              <button
                    *ngFor="let operator of operators"
                    kendoButton
                    [ngClass]="{'k-group-start': operator.value === 'and', 'k-group-end': operator.value === 'or'}"
                    [selected]="currentItem.logic === operator.value"
                    [title]="operator.text"
                    (click)="selectedChange(operator.value)"
                    >
                    {{operator.text}}
                  </button>
              </div>
            </div>
          <div class="k-filter-toolbar-item">
            <button
                kendoButton
                [title]="messageFor('addFilter')"
                icon="filter-add-expression"
                (click)="addFilterExpression()">
                {{messageFor('addFilter')}}
            </button>
          </div>
          <div class="k-filter-toolbar-item">
            <button
                kendoButton
                [title]="messageFor('addGroup')"
                icon="filter-add-group"
                (click)="addFilterGroup()">
                {{messageFor('addGroup')}}
            </button>
          </div>
          <div class="k-filter-toolbar-item">
            <button
                kendoButton
                icon="close"
                fillMode="flat"
                [title]="messageFor('remove')"
                (click)="removeFilterGroup()">
            </button>
          </div>
        </div>
      </div>

      <ul class="k-filter-lines" *ngIf="currentItem.filters">
        <ng-container *ngFor="let item of currentItem.filters; let i = index;">
          <li class="k-filter-item" *ngIf="!item.filters">
            <kendo-filter-expression (valueChange)="valueChange.emit()" [currentItem]="item" [index]="i">
            </kendo-filter-expression>
          </li>
          <li class="k-filter-item" *ngIf="item.filters" >
            <kendo-filter-group
                (valueChange)="valueChange.emit()"
                [currentItem]="item"
                [index]="i"
                >
            </kendo-filter-group>
          </li>
        </ng-container>
      </ul>
  `
    }),
    __metadata("design:paramtypes", [FilterService, LocalizationService, ChangeDetectorRef])
], FilterGroupComponent);

/**
 * @hidden
 */
class Messages extends ComponentMessages {
}
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "filterExpressionOperators", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "filterExpressionFilters", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "remove", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "addGroup", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "addFilter", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "filterAndLogic", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "filterOrLogic", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "filterEqOperator", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "filterNotEqOperator", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "filterIsNullOperator", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "filterIsNotNullOperator", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "filterIsEmptyOperator", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "filterIsNotEmptyOperator", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "filterStartsWithOperator", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "filterContainsOperator", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "filterNotContainsOperator", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "filterEndsWithOperator", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "filterGteOperator", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "filterGtOperator", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "filterLteOperator", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "filterLtOperator", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "filterIsTrue", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "filterIsFalse", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "filterBooleanAll", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "filterAfterOrEqualOperator", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "filterAfterOperator", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "filterBeforeOperator", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "filterBeforeOrEqualOperator", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "editorNumericDecrement", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "editorNumericIncrement", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "editorDateTodayText", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "editorDateToggleText", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "filterFieldAriaLabel", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "filterOperatorAriaLabel", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "filterValueAriaLabel", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "filterAriaLabel", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "filterToolbarAriaLabel", void 0);

var CustomMessagesComponent_1;
/**
 * Custom component messages override default component messages
 * ([see example]({% slug globalization_filter %}#toc-localization)).
 */
let CustomMessagesComponent = CustomMessagesComponent_1 = class CustomMessagesComponent extends Messages {
    constructor(service) {
        super();
        this.service = service;
    }
    get override() {
        return true;
    }
};
CustomMessagesComponent = CustomMessagesComponent_1 = __decorate([
    Component({
        providers: [
            {
                provide: Messages,
                useExisting: forwardRef(() => CustomMessagesComponent_1)
            }
        ],
        selector: 'kendo-filter-messages',
        template: ``
    }),
    __metadata("design:paramtypes", [LocalizationService])
], CustomMessagesComponent);

var LocalizedMessagesDirective_1;
/**
 * @hidden
 */
let LocalizedMessagesDirective = LocalizedMessagesDirective_1 = class LocalizedMessagesDirective extends Messages {
    constructor(service) {
        super();
        this.service = service;
    }
};
LocalizedMessagesDirective = LocalizedMessagesDirective_1 = __decorate([
    Directive({
        providers: [
            {
                provide: Messages,
                useExisting: forwardRef(() => LocalizedMessagesDirective_1)
            }
        ],
        selector: '[kendoFilterLocalizedMessages]'
    }),
    __metadata("design:paramtypes", [LocalizationService])
], LocalizedMessagesDirective);

const importedModules = [
    CommonModule,
    InputsModule,
    LabelModule,
    DropDownsModule,
    ButtonsModule,
    DateInputsModule
];
/**
 * @hidden
 */
let SharedModule = class SharedModule {
};
SharedModule = __decorate([
    NgModule({
        imports: [...importedModules],
        exports: [...importedModules]
    })
], SharedModule);

/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the Filter component.
 *
 * @example
 *
 * ```
 * // Import the Filter module
 * import { FilterModule } from '@progress/kendo-angular-filter';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, FilterModule], // import Filter module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule { }
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
let FilterModule = class FilterModule {
};
FilterModule = __decorate([
    NgModule({
        imports: [SharedModule],
        declarations: [FilterComponent,
            FilterNumericEditorComponent,
            FilterTextEditorComponent,
            FilterExpressionComponent,
            FilterGroupComponent,
            FilterExpressionOperatorsComponent,
            FilterBooleanEditorComponent,
            FilterDateEditorComponent,
            LocalizedMessagesDirective,
            CustomMessagesComponent,
            AriaLabelValueDirective
        ],
        exports: [FilterComponent,
            FilterNumericEditorComponent,
            FilterTextEditorComponent,
            FilterExpressionComponent,
            FilterGroupComponent,
            FilterExpressionOperatorsComponent,
            FilterBooleanEditorComponent,
            FilterDateEditorComponent,
            LocalizedMessagesDirective,
            CustomMessagesComponent,
            AriaLabelValueDirective],
        providers: [
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.filter'
            }
        ]
    })
], FilterModule);

/**
 * Generated bundle index. Do not edit.
 */

export { AriaLabelValueDirective, FilterBooleanEditorComponent, FilterDateEditorComponent, FilterNumericEditorComponent, FilterTextEditorComponent, FilterExpressionOperatorsComponent, FilterExpressionComponent, FilterGroupComponent, FilterService, CustomMessagesComponent, LocalizedMessagesDirective, Messages, SharedModule, FilterComponent, FilterModule };
